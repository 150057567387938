import React, {
  FunctionComponent,
  useState,
  useEffect,
  useContext,
} from "react";
import PageProps from "../../models/PageProps.interface";

//Libraries Import
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import LookupService from "../../services/lookup/lookup.service";
import { ILotterySummary } from "../../interface/BusinessModels/ILotterySummary";
import { IGameWonLoss } from "../../interface/BusinessModels/ILotterySummary";
import { IGameLotteryTicketsWonLoss } from "../../interface/BusinessModels/ILotterySummary";
//Form
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";

//Tabs
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";

//Calender Input

//Component Import
import LeftMenu from "../../components/leftMenu/leftMenu.component";
import TopNav from "../../components/topNav/topNav.component";
import Footer from "../../components/footer/footer.component";

//Import Css
import "../lotteryGameSummary/lotteryGameSummary.screen.style.css";
import { IGamesConfigModel } from "../../interface/BusinessModels/IGamesConfigModel";
import { IApiResponse } from "../../interface/Response/IApiResponse";
import GamesConfigService from "../../services/gamesConfig/gamesConfig.service";
import { ILotteryHistoryRequest } from "../../interface/Request/ILotteryHistoryRequest";
import { IGameHistoryModel } from "../../interface/BusinessModels/IGameHistoryModel";
import {
  API_ERROR_STANDARD_MESSAGE,
  DEFAULT_PAGESIZE,
  GameResultType,
} from "../../constants/DBConstants.constant";
import { HttpStatusCode } from "axios";

import DownloadForOfflineRoundedIcon from "@mui/icons-material/DownloadForOfflineRounded";
import { ToastSeverity } from "../../constants/toastSeverity.contants";
import { ToastContext } from "../../context/toast.context";
import { LoadingContext } from "../../context/loading.context";
import { IPlayedNumberModel } from "../../interface/BusinessModels/IPlayedNumberModel";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import "../lotteryGameSummary/lotteryGameSummary.screen.style.css";
import { AppConfig } from "../../constants/AppConfig.constant";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Link } from "@mui/material";

const LotteryGameSummary: FunctionComponent<any> = ({
  title,
  predictionSummary,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  //Tabs Related Script Goes Here
  const [value, setValue] = React.useState("1");
  const [gamesConfig, setGamesConfig] = useState<IGamesConfigModel[]>();
  const [gameDates, setGameDates] = useState<IGameHistoryModel[]>();
  const { setLoading } = useContext(LoadingContext);
  const _gamesConfigService = new GamesConfigService(dispatch);
  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };
  const _lookupService = new LookupService(dispatch);
  const [lotteryRequest, setLotteryRequest] = useState<ILotteryHistoryRequest>({
    gameId: -1,
    gameStatus: -1,
    gameHistoryId: -1,
    numberSearch: "",
    pageNumber: 1,
    pageSize: DEFAULT_PAGESIZE,
  });
  const { setToastConfig, setOpen } = useContext(ToastContext);
  const [
    lotterySummary,
    setLotterySummary,
  ] = useState<ILotterySummary | null>();

  useEffect(() => {
    if (predictionSummary != null) {
      setLotterySummary(predictionSummary);
    }
    initializeFunctions();

    async function initializeFunctions() {
      setLoading(true);
      await getAvailableGames();
    }
  }, []);

  useEffect(() => {
    document.title = title;
  }, []);

  const getAvailableGames = async () => {
    try {
      const response: IApiResponse = await _gamesConfigService.getAvailableGames();
      setGamesConfig(response.response as IGamesConfigModel[]);
      getGameDates(response.response[0].id);
    } catch (error) {
      if (
        error &&
        (error as any).response &&
        (error as any).response.status != HttpStatusCode.Unauthorized
      ) {
        setToastConfig(ToastSeverity.Error, API_ERROR_STANDARD_MESSAGE, true);
      }
      setLoading(false);
    }
  };

  const getGameDates = async (gameType: number) => {
    try {
      const gameDatesResponse: IApiResponse = await _gamesConfigService.getGameDates(
        gameType
      );
      var resultsDeclared = (gameDatesResponse.response as IGameHistoryModel[]).filter(
        (x: IGameHistoryModel) => x.gameResultStatus == GameResultType.Declared
      );
      setGameDates(resultsDeclared);
      setLotteryRequest({
        ...lotteryRequest,
        gameId: gameType,
        gameHistoryId: resultsDeclared[0].id,
      });
    } catch (error) {
      if (
        error &&
        (error as any).response &&
        (error as any).response.status != HttpStatusCode.Unauthorized
      ) {
        setToastConfig(ToastSeverity.Error, API_ERROR_STANDARD_MESSAGE, true);
      }
    }
    setLoading(false);
  };

  const getLotterySummary = async () => {
    try {
      setLoading(true);
      const response: IApiResponse = await _lookupService.getLotterySummary(
        lotteryRequest.gameHistoryId
      );
      setLotterySummary(response.response as ILotterySummary);
    } catch (error) {
      if (
        error &&
        (error as any).response &&
        (error as any).response.status != HttpStatusCode.Unauthorized
      ) {
        setToastConfig(ToastSeverity.Error, API_ERROR_STANDARD_MESSAGE, true);
      }
    }
    setLoading(false);
  };

  function GameWonLossCard(props: { row: IGameWonLoss; rowIndex: number }) {
    return (
      <Grid key={props.rowIndex} item xs={12} md={4} lg={4}>
        <Box className="commonCard">
          <Card>
            <CardContent>
              <Box className="commonBgCard saperatorSpacing">
                <Grid container spacing={2}>
                  <Grid item xs={10} md={10} lg={10}>
                    <Typography className="cardTitle whiteColor">
                      {props.row.userId.toUpperCase()}
                    </Typography>
                  </Grid>
                  <Grid item xs={2} md={2} lg={2} className="boxRight padL0">
                    <Box className="verificationStatus verified"></Box>
                  </Grid>
                </Grid>
              </Box>
              <Box className="commonLabelValue">
                <Typography className="commonLabel">App Id</Typography>
                <Link
                  href="/"
                  onClick={(e) => {
                    e.preventDefault(); // Prevent appending #
                    navigate("/myWallet", {
                      state: props.row.userId.toUpperCase(),
                    });
                  }}
                >
                  {props.row.appId}
                </Link>
                {/* <Typography className="commonValue">
                  {props.row.appId}
                </Typography> */}
              </Box>
              <Box className="commonLabelValue">
                <Typography className="commonLabel">Email</Typography>
                <Typography className="commonValue">
                  {props.row.email}
                </Typography>
              </Box>
              <Box className="commonLabelValue">
                <Typography className="commonLabel">PhoneCode</Typography>
                <Typography className="commonValue">
                  {props.row.phoneCode}
                </Typography>
              </Box>
              <Box className="commonLabelValue">
                <Typography className="commonLabel">PhoneNumber</Typography>
                <Typography className="commonValue">
                  {props.row.phoneNumber}
                </Typography>
              </Box>
            </CardContent>
          </Card>
        </Box>
      </Grid>
    );
  }

  function LotteryTicketWonLoss(props: {
    x: IGameLotteryTicketsWonLoss;
    rowIndex: number;
  }) {
    return (
      <Grid item xs={12} md={6} lg={4} key={props.x.id}>
        <Box className="commonCard height100">
          <Card>
            <CardContent>
              <Box className="commonBgCard saperatorSpacing">
                <Grid container spacing={2}>
                  <Grid item xs={10} md={10} lg={10}>
                    <Typography className="cardTitle whiteColor">
                      {props.x.lotteryId.toUpperCase()}
                    </Typography>
                  </Grid>
                  <Grid item xs={2} md={2} lg={2} className="boxRight">
                    <Box className="">
                      <img
                        alt={`${AppConfig.applicationName} - Flag`}
                        className=""
                        src={require("../../assets/images/lotteryHistory/thailand.png")}
                      />
                    </Box>
                  </Grid>
                </Grid>
              </Box>
              <Box>
                <Box className="commonLabelValue">
                  <Typography className="commonLabel">App Id</Typography>
                  <Link
                    href="/"
                    onClick={(e) => {
                      e.preventDefault(); // Prevent appending #
                      navigate("/myWallet", {
                        state: props.x.userId.toUpperCase(),
                      });
                    }}
                  >
                    {props.x.appId}
                  </Link>
                </Box>
                <Box className="commonLabelValue">
                  <Typography className="commonLabel">Game</Typography>
                  <Typography className="commonValue">
                    {props.x.gameType}
                  </Typography>
                </Box>
                <Box className="commonLabelValue">
                  <Typography className="commonLabel">Game Type</Typography>
                  <Typography className="commonValue">
                    {props.x.gameOption}
                  </Typography>
                </Box>
                <Box className="commonLabelValue">
                  <Typography className="commonLabel">Ticket Number</Typography>
                  <Typography className="commonValue">
                    {props.x.ticketNumber}
                  </Typography>
                </Box>
                <Box className="commonLabelValue">
                  <Typography className="commonLabel">Game Result</Typography>
                  <Typography className="commonValue">
                    {props.x.status}
                  </Typography>
                </Box>
                <Box className="commonLabelValue">
                  <Typography className="commonLabel">Created On</Typography>
                  <Typography className="commonValue">
                    {props.x.createdOn}
                  </Typography>
                </Box>
                <Box className="commonLabelValue">
                  <Typography className="commonLabel">
                    Game Draw Date
                  </Typography>
                  <Typography className="commonValue">
                    {props.x.gameDrawDate}
                  </Typography>
                </Box>
              </Box>
              <Box>
                <Box>
                  <Typography className="sectionTitle saperatorBorder">
                    Game Result
                  </Typography>
                </Box>
                <Box>
                  <Box className="commonLabelValue">
                    <Typography className="commonLabel">
                      Total Amount(Played)
                    </Typography>
                    <Typography className="commonValue">
                      &nbsp;{props.x.playedAmount}
                    </Typography>
                  </Box>
                  <Box className="commonLabelValue">
                    <Typography className="commonLabel">
                      Total Amount(Paid)
                    </Typography>
                    <Typography className="commonValue">
                      &nbsp;{props.x.paidAmount}
                    </Typography>
                  </Box>
                  <Box className="commonLabelValue">
                    <Typography className="commonLabel">
                      Discount Amount
                    </Typography>
                    <Typography className="commonValue">
                      &nbsp;{props.x.discountAmount}
                    </Typography>
                  </Box>
                  <Box className="commonLabelValue">
                    <Typography className="commonLabel">
                      Discount Applied
                    </Typography>
                    <Typography className="commonValue">
                      {props.x.discountApplied}&nbsp;%
                    </Typography>
                  </Box>
                  <Box className="commonLabelValue">
                    <Typography className="commonLabel">Amount Won</Typography>
                    <Typography className="commonValue">
                      &nbsp;{props.x.amountWon}
                    </Typography>
                  </Box>
                  <Box className="commonLabelValue">
                    <Typography className="commonLabel">
                      Agent Commission
                    </Typography>
                    <Typography className="commonValue">
                      &nbsp;{props.x.agentCommission}
                    </Typography>
                  </Box>
                  <Box className="commonLabelValue">
                    <Typography className="commonLabel">
                      Final Winning Amount
                    </Typography>
                    <Typography className="commonValue">
                      &nbsp;{props.x.finalWonAmount}
                    </Typography>
                  </Box>
                </Box>
              </Box>
              <Box>
                <Box>
                  <Typography className="sectionTitle saperatorBorder">
                    Numbers Played
                  </Typography>
                </Box>
                <Box>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={12} lg={12}>
                      <TableContainer
                        component={Paper}
                        className="numbersPlayedTable"
                      >
                        <Table
                          aria-label="Game Result Table"
                          className="commonBasicTable"
                        >
                          <TableHead>
                            <TableRow>
                              <TableCell component="th">Number</TableCell>
                              <TableCell component="th">Straight</TableCell>
                              {props.x.isRumble && (
                                <TableCell component="th">Rumble</TableCell>
                              )}
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {props.x.playedNumbers.map(
                              (row: IPlayedNumberModel) => (
                                <TableRow
                                  key={row.number}
                                  sx={{
                                    "&:last-child td, &:last-child th": {
                                      border: 0,
                                    },
                                  }}
                                >
                                  <TableCell align="center">
                                    {row.number}
                                  </TableCell>
                                  <TableCell align="center">
                                    {row.straight}
                                  </TableCell>
                                  {props.x.isRumble && (
                                    <TableCell align="center">
                                      {row.rumble}
                                    </TableCell>
                                  )}
                                </TableRow>
                              )
                            )}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            </CardContent>
          </Card>
        </Box>
      </Grid>
    );
  }

  return (
    <Box className="pageMain">
      <Box className="pageLeft">
        {predictionSummary == null && <LeftMenu />}
      </Box>
      <Box className="pageRight">
        {predictionSummary == null && (
          <Box className="pageHead">
            <TopNav title={title.split("|")[1].trim()} />
          </Box>
        )}
        <Box className="pageView">
          <Box className="pageViewBody commonScroll">
            <Box className="commonBgCard commonBgColorCard saperatorSpacing">
              <Typography className="bannerTitle">
                {predictionSummary == null
                  ? "Lottery Game Summary"
                  : "Lottery Game Prediction Summary"}
              </Typography>
              {predictionSummary == null && (
                <Grid container spacing={1}>
                  <Grid item xs={12} md={4} lg={5}>
                    <FormControl fullWidth>
                      <InputLabel id="lotteryType">
                        Lottery Game Type
                      </InputLabel>
                      <Select
                        className="fontBlack"
                        labelId="lotteryType"
                        id="lotteryType"
                        label="lotteryType"
                        value={lotteryRequest.gameId.toString()}
                        onChange={(evt: SelectChangeEvent) => {
                          getGameDates(+evt.target.value);
                          setLotteryRequest({
                            ...lotteryRequest,
                            gameId: +evt.target.value,
                          });
                        }}
                      >
                        {gamesConfig &&
                          gamesConfig.map((option: IGamesConfigModel) => (
                            <MenuItem
                              key={option.id}
                              value={option.id.toString()}
                            >
                              {option.name}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={4} lg={5}>
                    <FormControl fullWidth>
                      <InputLabel id="lotteryGameId">Game Date</InputLabel>
                      <Select
                        className="fontBlack"
                        labelId="lotteryGameId"
                        id="lotteryGameId"
                        label="lotteryGameId"
                        value={lotteryRequest.gameHistoryId.toString()}
                        onChange={(evt: SelectChangeEvent) => {
                          setLotteryRequest({
                            ...lotteryRequest,
                            gameHistoryId: +evt.target.value,
                          });
                        }}
                      >
                        {gameDates &&
                          gameDates.map((option: IGameHistoryModel) => (
                            <MenuItem
                              key={option.id}
                              value={option.id.toString()}
                            >
                              {option.gameDrawDate}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={4} lg={2}>
                    <Button
                      variant="contained"
                      className="primaryFillBtn sectionBtn fullWidth"
                      onClick={() => getLotterySummary()}
                    >
                      <span>
                        <DownloadForOfflineRoundedIcon className="customIcon" />{" "}
                        &nbsp; Get Results
                      </span>
                    </Button>
                  </Grid>
                </Grid>
              )}

              <Box className="saperationSection">
                {lotterySummary && (
                  <Grid container spacing={1}>
                    <Grid item xs={12} md={4} lg={3}>
                      <Box className="commonLabelValue nextLabelValue">
                        <Typography className="commonLabel">ID</Typography>
                        <Typography className="commonValue">
                          {lotterySummary.gameUIId}
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={12} md={4} lg={3}>
                      <Box className="commonLabelValue nextLabelValue">
                        <Typography className="commonLabel">
                          Game Number
                        </Typography>
                        <Typography className="commonValue">
                          {lotterySummary.gameHistoryId}
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={12} md={4} lg={3}>
                      <Box className="commonLabelValue nextLabelValue">
                        <Typography className="commonLabel">
                          Game Draw Date
                        </Typography>
                        <Typography className="commonValue">
                          {lotterySummary.gameDrawDate}
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={12} md={4} lg={3}>
                      <Box className="commonLabelValue nextLabelValue">
                        <Typography className="commonLabel">
                          Result Announced On
                        </Typography>
                        <Typography className="commonValue">
                          {lotterySummary.resultDeclaredOn}
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={12} md={4} lg={3}>
                      <Box className="commonLabelValue nextLabelValue">
                        <Typography className="commonLabel">
                          First Prize Result
                        </Typography>
                        <Typography className="commonValue">
                          {lotterySummary.firstPrize}
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={12} md={4} lg={3}>
                      <Box className="commonLabelValue nextLabelValue">
                        <Typography className="commonLabel">
                          3UP Staright Result
                        </Typography>
                        <Typography className="commonValue">
                          {lotterySummary.threeUpStraight}
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={12} md={4} lg={3}>
                      <Box className="commonLabelValue nextLabelValue">
                        <Typography className="commonLabel">
                          3UP Rumble Result
                        </Typography>
                        <Typography className="commonValue">
                          {lotterySummary.threeUpRumble}
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={12} md={4} lg={3}>
                      <Box className="commonLabelValue nextLabelValue">
                        <Typography className="commonLabel">
                          2UP Result
                        </Typography>
                        <Typography className="commonValue">
                          {/* {lotterySummary.twoUpTotal} */}
                          {lotterySummary.twoUpSingle.replace(",", "")}
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={12} md={4} lg={3}>
                      <Box className="commonLabelValue nextLabelValue">
                        <Typography className="commonLabel">
                          2DOWN Straight Result
                        </Typography>
                        <Typography className="commonValue">
                          {lotterySummary.twoDownStraight}
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={12} md={4} lg={3}>
                      <Box className="commonLabelValue nextLabelValue">
                        <Typography className="commonLabel">
                          3UP Single Digits Result
                        </Typography>
                        <Typography className="commonValue">
                          {lotterySummary.threeUpSingle}
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={12} md={4} lg={3}>
                      <Box className="commonLabelValue nextLabelValue">
                        <Typography className="commonLabel">
                          2UP Single Digits Result
                        </Typography>
                        <Typography className="commonValue">
                          {lotterySummary.twoUpSingle}
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={12} md={4} lg={3}>
                      <Box className="commonLabelValue nextLabelValue">
                        <Typography className="commonLabel">
                          2DOWN Single Digits Result
                        </Typography>
                        <Typography className="commonValue">
                          {lotterySummary.twoDownSingle}
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={12} md={4} lg={3}>
                      <Box className="commonLabelValue nextLabelValue">
                        <Typography className="commonLabel">
                          3UP Game Total Result
                        </Typography>
                        <Typography className="commonValue">
                          {lotterySummary.threeUpTotal}
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={12} md={4} lg={3}>
                      <Box className="commonLabelValue nextLabelValue">
                        <Typography className="commonLabel">
                          2UP Game Total Result
                        </Typography>
                        <Typography className="commonValue">
                          {lotterySummary.twoUpTotal}
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={12} md={4} lg={3}>
                      <Box className="commonLabelValue nextLabelValue">
                        <Typography className="commonLabel">
                          2DOWN Game Total Result
                        </Typography>
                        <Typography className="commonValue">
                          {lotterySummary.twoDownTotal}
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={12} md={4} lg={3}>
                      <Box className="commonLabelValue nextLabelValue">
                        <Typography className="commonLabel">
                          Total Amount Played
                        </Typography>
                        <Typography className="commonValue">
                          <span></span>
                          {lotterySummary.totalAmountPlayed != ""
                            ? lotterySummary.totalAmountPlayed
                            : " --"}
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={12} md={4} lg={3}>
                      <Box className="commonLabelValue nextLabelValue">
                        <Typography className="commonLabel">
                          Total Amount Paid
                        </Typography>
                        <Typography className="commonValue">
                          <span></span>
                          {lotterySummary.totalAmountPaid != ""
                            ? lotterySummary.totalAmountPaid
                            : " --"}
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={12} md={4} lg={3}>
                      <Box className="commonLabelValue nextLabelValue">
                        <Typography className="commonLabel">
                          Total Discount Amount
                        </Typography>
                        <Typography className="commonValue">
                          <span></span>
                          {lotterySummary.totalDiscountAmount != ""
                            ? lotterySummary.totalDiscountAmount
                            : " --"}
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={12} md={4} lg={3}>
                      <Box className="commonLabelValue nextLabelValue">
                        <Typography className="commonLabel">
                          Total Winning Amount
                        </Typography>
                        <Typography className="commonValue">
                          <span></span>
                          {lotterySummary.totalWinningAmount != ""
                            ? lotterySummary.totalWinningAmount
                            : " --"}
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={12} md={4} lg={3}>
                      <Box className="commonLabelValue nextLabelValue">
                        <Typography className="commonLabel">
                          Total Agent Commission
                        </Typography>
                        <Typography className="commonValue">
                          <span></span>
                          {lotterySummary.totalAgentCommission != ""
                            ? lotterySummary.totalAgentCommission
                            : " --"}
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={12} md={4} lg={3}>
                      <Box className="commonLabelValue nextLabelValue">
                        <Typography className="commonLabel">
                          Final Winning Amount
                        </Typography>
                        <Typography className="commonValue">
                          <span></span>
                          {lotterySummary.finalWinningAmount != ""
                            ? lotterySummary.finalWinningAmount
                            : " --"}
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={12} md={4} lg={3}>
                      <Box className="commonLabelValue nextLabelValue">
                        <Typography className="commonLabel">
                          Total Profit(Paid-Win)
                        </Typography>
                        <Typography className="commonValue">
                          <span></span>
                          {lotterySummary.totalProfit != ""
                            ? lotterySummary.totalProfit
                            : " --"}
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={12} md={4} lg={3}>
                      <Box className="commonLabelValue nextLabelValue">
                        <Typography className="commonLabel">
                          No Of Users Played
                        </Typography>
                        <Typography className="commonValue">
                          {lotterySummary.noOfUsersPlayed != ""
                            ? lotterySummary.noOfUsersPlayed
                            : "0"}
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={12} md={4} lg={3}>
                      <Box className="commonLabelValue nextLabelValue">
                        <Typography className="commonLabel">
                          Total Tickets Played
                        </Typography>
                        <Typography className="commonValue">
                          {lotterySummary.totalTicketsPlayed != ""
                            ? lotterySummary.totalTicketsPlayed
                            : "0"}
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={12} md={4} lg={3}>
                      <Box className="commonLabelValue nextLabelValue">
                        <Typography className="commonLabel">
                          No. of Tickets (Won)
                        </Typography>
                        <Typography className="commonValue">
                          {lotterySummary.ticketsWon != ""
                            ? lotterySummary.ticketsWon
                            : "0"}
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={12} md={4} lg={3}>
                      <Box className="commonLabelValue nextLabelValue">
                        <Typography className="commonLabel">
                          No. of Tickets (Lost)
                        </Typography>
                        <Typography className="commonValue">
                          {lotterySummary.ticketsLost != ""
                            ? lotterySummary.ticketsLost
                            : "0"}
                        </Typography>
                      </Box>
                    </Grid>
                  </Grid>
                )}
              </Box>
            </Box>
            {lotterySummary && (
              <Box className="">
                <TabContext value={value}>
                  <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                    <TabList onChange={handleChange} className="commonTabsHead">
                      <Tab label="Winners List" value="1" />
                      <Tab label="Loosers List" value="2" />
                      <Tab label="Winner Lottery Tikcets" value="3" />
                      <Tab label="Looser Lottery Tickets" value="4" />
                    </TabList>
                  </Box>
                  <TabPanel value="1">
                    <Box className="winnersList">
                      <Grid container spacing={1}>
                        {lotterySummary &&
                          lotterySummary.gameWons
                            .filter((game) => game.value === "Won")
                            .map((won: IGameWonLoss, index: number) => (
                              <GameWonLossCard rowIndex={index} row={won} />
                            ))}
                      </Grid>
                    </Box>
                  </TabPanel>
                  <TabPanel value="2">
                    <Box className="loosersList">
                      <Grid container spacing={1}>
                        {lotterySummary &&
                          lotterySummary.gameWons
                            .filter(
                              (game) =>
                                game.value === "Loss" ||
                                game.value === "Pending"
                            )
                            .map((loss: IGameWonLoss, index: number) => (
                              <GameWonLossCard rowIndex={index} row={loss} />
                            ))}
                      </Grid>
                    </Box>
                  </TabPanel>
                  <TabPanel value="3">
                    <Box className="winnerLotteryTickets">
                      <Box className="">
                        <Grid container spacing={1}>
                          {lotterySummary &&
                            lotterySummary.gameLotteryTicketsWonLosses
                              .filter((game) => game.status === "Won")
                              .map(
                                (
                                  win: IGameLotteryTicketsWonLoss,
                                  index: number
                                ) => (
                                  <LotteryTicketWonLoss
                                    x={win}
                                    rowIndex={index}
                                  />
                                )
                              )}
                        </Grid>
                      </Box>
                    </Box>
                  </TabPanel>
                  <TabPanel value="4">
                    <Box className="looserLotteryTickets">
                      <Box className="">
                        <Grid container spacing={1}>
                          {lotterySummary &&
                            lotterySummary.gameLotteryTicketsWonLosses
                              .filter(
                                (game) =>
                                  game.status === "Loss" ||
                                  game.status === "Pending"
                              )
                              .map(
                                (
                                  loss: IGameLotteryTicketsWonLoss,
                                  index: number
                                ) => (
                                  <LotteryTicketWonLoss
                                    x={loss}
                                    rowIndex={index}
                                  />
                                )
                              )}
                        </Grid>
                      </Box>
                    </Box>
                  </TabPanel>
                </TabContext>
              </Box>
            )}
          </Box>
          {predictionSummary == null && (
            <Box className="pageViewFooter">
              <Footer />
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
};
export default LotteryGameSummary;
