import React, {
  FunctionComponent,
  useContext,
  useEffect,
  useState,
} from "react";
import { useTheme } from "@mui/material/styles";

import Box from "@mui/material/Box";
import { ClockLoader, PropagateLoader } from "react-spinners";

//Css
import "../account/account.component.style.css";
import "../../index.css";
import { Card, CardContent, Grid, Typography } from "@mui/material";
import UserService from "../../services/user/user.service";
import { useDispatch } from "react-redux";
import { ToastContext } from "../../context/toast.context";
import { API_ERROR_STANDARD_MESSAGE } from "../../constants/DBConstants.constant";
import { HttpStatusCode } from "axios";
import { ToastSeverity } from "../../constants/toastSeverity.contants";
import { IApiResponse } from "../../interface/Response/IApiResponse";
import { IUserModel } from "../../interface/Response/IUserModel";
import ApplicationHelperService from "../../services/ApplicationHelperService";

const UserCard = (props: { userId: string }) => {
  const dispatch = useDispatch();
  const { setToastConfig, setOpen } = useContext(ToastContext);
  const _userService = new UserService(dispatch);
  const [userModal, setUserModal] = useState<IUserModel>();
  const _applicationHelperService = new ApplicationHelperService({});
  const geUserInfo = async () => {
    try {
      const userDataResponse: IApiResponse = await _userService.getUserInfoOnUserId(
        props.userId
      );
      setUserModal(userDataResponse.response as IUserModel);
    } catch (error) {
      if (
        error &&
        (error as any).response &&
        (error as any).response.status != HttpStatusCode.Unauthorized
      ) {
        setToastConfig(ToastSeverity.Error, API_ERROR_STANDARD_MESSAGE, true);
      }
    }
  };

  useEffect(() => {
    geUserInfo();
  }, []);

  return (
    <Card sx={{ maxWidth: "100%", margin: "auto", padding: 2, boxShadow: 3 }}>
      {userModal && userModal.appId && (
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Typography variant="body2">Firstname</Typography>
              <Typography variant="body1" fontWeight="bold">
                {userModal.firstname}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body2">Lastname</Typography>
              <Typography variant="body1" fontWeight="bold">
                {userModal.lastname}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body2">App Id</Typography>
              <Typography variant="body1" fontWeight="bold">
                {userModal.appId}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body2">User Id</Typography>
              <Typography variant="body1" fontWeight="bold">
                {userModal.userId}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body2">Email</Typography>
              <Typography variant="body1" fontWeight="bold">
                {userModal.email}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body2">Country Code</Typography>
              <Typography variant="body1" fontWeight="bold">
                {userModal.countryCode}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body2">Country</Typography>
              <Typography variant="body1" fontWeight="bold">
                {userModal.country}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body2">Phone Code</Typography>
              <Typography variant="body1" fontWeight="bold">
                {userModal.phoneCode}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body2">Phone Number</Typography>
              <Typography variant="body1" fontWeight="bold">
                {userModal.phoneNumber}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body2">Date Of Birth</Typography>
              <Typography variant="body1" fontWeight="bold">
                {userModal.dateOfBirth}
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <Typography variant="body2">Created Date Time</Typography>
              <Typography variant="body1" fontWeight="bold">
                {_applicationHelperService.getExpandedDateTimeFormat(
                  userModal.createdOn
                )}
              </Typography>
            </Grid>
          </Grid>
        </CardContent>
      )}
    </Card>
  );
};

export default UserCard;
