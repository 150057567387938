import React, { useContext, useEffect, useState } from "react";
import Box from "@mui/material/Box";
import { Typography } from "@mui/joy";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import { ToastSeverity } from "../../constants/toastSeverity.contants";
import { IApiResponse } from "../../interface/Response/IApiResponse";
import { IApplicationAgentRegistrationModel } from "../../interface/BusinessModels/IApplicationAgentRegistrationModel";
import UserService from "../../services/user/user.service";
import ManageAccountsRoundedIcon from "@mui/icons-material/ManageAccountsRounded";
import { CardContent, TextField } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import Card from "@mui/material/Card";
import IconButton from "@mui/material/IconButton";
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import InputLabel from "@mui/material/InputLabel";

import CardActions from "@mui/material/CardActions";
import { ToastContext } from "../../context/toast.context";
import Stack from "@mui/material/Stack";
import {
  API_ERROR_STANDARD_MESSAGE,
  AGENT_CONTACT_TYPE,
  DEFAULT_ALERT_CONFIG,
  RoleType,
} from "../../constants/DBConstants.constant";
import * as yup from "yup";
import { HttpStatusCode } from "axios";
import { IAlertDialogConfig } from "../../interface/IAlertDialogConfig";
import Modal from "@mui/material/Modal";
import { useDispatch, useSelector } from "react-redux";
import { ICountryModel } from "../../interface/BusinessModels/ICountryModel";
import AlertDialog from "../../components/alertDialog/alertDialog.component";
import { Formik } from "formik";
import { ILookupModel } from "../../interface/BusinessModels/ILookupModel";
import { getCountries } from "../../actions/lookup.actions";
import { LoadingContext } from "../../context/loading.context";
import LookupService from "../../services/lookup/lookup.service";
import AddCircleOutlineRoundedIcon from "@mui/icons-material/AddCircleOutlineRounded";
import EditIcon from "@mui/icons-material/Edit";

const AgentUsers = () => {
  const dispatch = useDispatch();
  const { setLoading } = useContext(LoadingContext);
  const { userInfo } = useSelector((state: any) => state.authReducer);
  const [activeEditId, setActiveEditId] = useState<number>(0);
  const _lookupService = new LookupService(dispatch);
  const _userService = new UserService(dispatch);
  const [openAddCard, setOpenAddCard] = React.useState<boolean>(false);
  const handleClose = () => setOpenAddCard(false);
  const [agentUsers, setAgentUsers] = useState<
    IApplicationAgentRegistrationModel[]
  >([]);
  const { setToastConfig, setOpen } = useContext(ToastContext);

  const getApplicationAgents = async () => {
    const agentUsersList: IApiResponse = await _userService.getAgentUsers();
    const agentUsersListData = agentUsersList.response as IApplicationAgentRegistrationModel[];
    setAgentUsers(agentUsersListData);
  };
  const initializeCountries = () => dispatch<any>(getCountries());
  const { countries } = useSelector((state: any) => state.lookupReducer);
  const [countryId, setCountryId] = useState<number>(0);
  const [activeDeleteId, setActiveDeleteId] = useState<number>(0);
  const [agentPaymentStatuses, setAgentPaymentStatuses] = useState<
    ILookupModel[]
  >();

  const InitialValues: IApplicationAgentRegistrationModel = {
    id: 0,
    name: "",
    contactTypeId: "",
    countryId: 0,
    contactType: "",
    countryCode: "",
    phoneCode: "",
    phoneNumber: "",
    country: "",
    state: "",
    modifiedOn: "",
    modifiedBy: 0,
  };

  const AgentRegistrationSchema = yup.object().shape({
    name: yup.string().required("This field is required"),
    contactTypeId: yup.string().required("Contact Type is required."),
    phoneCode: yup.string().required("Phone Code is required."),
    phoneNumber: yup.string().required("Phone Number is required."),
    country: yup.string().required("Country is required."),
    state: yup.string().required("State is required."),
  });

  const [applicationAgent, setApplicationAgent] = useState<
    IApplicationAgentRegistrationModel
  >(InitialValues);

  useEffect(() => {
    getApplicationAgents();
  }, []);

  useEffect(() => {
    getAgentContactTypes();

    async function getAgentContactTypes() {
      setLoading(true);
      try {
        const contactTypes: IApiResponse = await _lookupService.getConfigs(
          AGENT_CONTACT_TYPE
        );
        const paymentStatuses: ILookupModel[] = contactTypes.response as ILookupModel[];
        setAgentPaymentStatuses(paymentStatuses);

        await getApplicationAgents();
      } catch (error) {
        if (
          error &&
          (error as any).response &&
          (error as any).response.status != HttpStatusCode.Unauthorized
        ) {
          setToastConfig(ToastSeverity.Error, API_ERROR_STANDARD_MESSAGE, true);
        }
      }
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    initializeCountries();
  }, []);

  const [alertConfig, setAlertConfig] = useState<IAlertDialogConfig>({
    isShow: false,
    callBack: () => undefined,
  });

  const saveApplicationAgent = async (
    values: IApplicationAgentRegistrationModel
  ) => {
    try {
      setLoading(true);
      let responseData: IApiResponse;
      if (values.id && values.id > 0) {
        const response: IApiResponse = await _userService.updateAgentUser({
          ...values,
          phoneNumber: values.phoneNumber.toString(),
        });
        responseData = response;
      } else {
        const response: IApiResponse = await _userService.registerAgentUser({
          ...values,
          phoneNumber: values.phoneNumber.toString(),
        });
        responseData = response;
      }
      if (responseData.isSuccess) {
        setAlertConfig({
          description: responseData.message,
          toastSeverity: ToastSeverity.Success,
          isShow: true,
          callBack: () => {
            setAlertConfig(DEFAULT_ALERT_CONFIG);
            getApplicationAgents();
            setOpenAddCard(false);
          },
        });
      } else {
        setToastConfig(ToastSeverity.Error, responseData.message, true);
      }
    } catch (error) {
      if (
        error &&
        (error as any).response &&
        (error as any).response.status != HttpStatusCode.Unauthorized
      ) {
        setToastConfig(ToastSeverity.Error, API_ERROR_STANDARD_MESSAGE, true);
      }
      console.log(error);
    }
    setLoading(false);
  };

  const openAgentEditPopUp = (items: IApplicationAgentRegistrationModel) => {
    setApplicationAgent({
      id: items.id,
      name: items.name,
      contactTypeId: items.contactTypeId,
      countryId: items.countryId,
      phoneCode: items.phoneCode,
      phoneNumber: items.phoneNumber,
      countryCode: items.countryCode,
      country: items.country,
      state: items.state,
      contactType: items.contactType,
      modifiedOn: items.modifiedOn,
      modifiedBy: items.modifiedBy,
    });

    setOpenAddCard(true);
  };

  const deleteApplicationAgent = async () => {
    try {
      var response: IApiResponse = await _userService.deleteAgentUser(
        activeDeleteId
      );
      if (response.isSuccess) {
        setActiveDeleteId(0);
        setAlertConfig({
          description: response.response.message,
          toastSeverity: ToastSeverity.Success,
          isShow: true,
          callBack: () => {
            getApplicationAgents();
            setTimeout(() => {
              setAlertConfig(DEFAULT_ALERT_CONFIG);
            }, 500);
          },
        });
      }
    } catch (error) {
      if (
        error &&
        (error as any).response &&
        (error as any).response.status != HttpStatusCode.Unauthorized
      ) {
        setToastConfig(ToastSeverity.Error, API_ERROR_STANDARD_MESSAGE, true);
      }
    }
  };

  return (
    <Box className="pageView">
      <Box className="pageViewBody commonScroll">
        <Box>
          <Grid container spacing={2}>
            <Grid item xs={12} md={8} lg={9}></Grid>
            {userInfo.user.roleId === RoleType.Admin && (
              <Grid item xs={12} md={4} lg={3} className="boxRight">
                <Button
                  onClick={() => {
                    setApplicationAgent({ ...InitialValues });
                    setOpenAddCard(true);
                  }}
                  className="primaryColor"
                >
                  <span>
                    <AddCircleOutlineRoundedIcon className="customIcon" />
                    Create Agent
                  </span>
                </Button>
              </Grid>
            )}
          </Grid>
        </Box>
        <Box>
          {" "}
          <Grid container spacing={2}>
            {agentUsers &&
              agentUsers.length > 0 &&
              agentUsers.map((item: IApplicationAgentRegistrationModel) => (
                <Grid item sm={12} xs={12} md={6} lg={4} xl={3}>
                  <Box className="commonCard">
                    <Card>
                      <CardContent>
                        <Box className="commonBgCard saperatorSpacing">
                          <Grid container spacing={2}>
                            <Grid item xs={10} md={10} lg={10}>
                              <Typography className="cardTitle whiteColor">
                                {item.name}
                              </Typography>
                            </Grid>
                            <Grid
                              item
                              xs={2}
                              md={2}
                              lg={2}
                              className="boxRight padL0"
                            >
                              <Box className="verificationStatus verified">
                                <ManageAccountsRoundedIcon />
                              </Box>
                            </Grid>
                          </Grid>
                        </Box>
                        <Box className="commonLabelValue">
                          <Typography className="commonLabel">
                            Status
                          </Typography>
                          <Typography className="commonValue">
                            <span className="greenText">Active</span>
                          </Typography>
                        </Box>
                        <Box className="commonLabelValue">
                          <Typography className="commonLabel">
                            Country
                          </Typography>
                          <Typography className="commonValue">
                            {item.country},&nbsp;{item.state}
                          </Typography>
                        </Box>
                        <Box className="commonLabelValue">
                          <Typography className="commonLabel">
                            Contact Type
                          </Typography>
                          <Typography className="commonValue">
                            {item.contactType}
                          </Typography>
                        </Box>
                        <Box className="commonLabelValue">
                          <Typography className="commonLabel">
                            Contact Number
                          </Typography>
                          <Typography className="commonValue">
                            {item.phoneCode}
                            {item.phoneNumber}
                          </Typography>
                        </Box>
                        {/* {userInfo.user.roleId === RoleType.Admin && 
                    (
                      <Box className="bankCardActions">
                        <Box>
                          <IconButton
                            aria-label="edit"
                            className="primaryOverlayIconBtn"
                            onClick={() => console.log("Edit Clicked")}
                          >
                            <EditRoundedIcon />
                          </IconButton>
                          <IconButton
                            aria-label="edit"
                            className="primaryOverlayIconBtn"
                            onClick={() => {
                              const id = item.id ? item.id : 0;
                              setActiveDeleteId(id);
                            }}
                          >
                            <DeleteRoundedIcon />
                          </IconButton>
                        </Box>
                      </Box>
                    )} */}
                      </CardContent>
                      <CardActions>
                        {+item.contactTypeId === 28 ? (
                          <Button
                            variant="contained"
                            className="primaryFillBtn width90"
                            href={`https://api.whatsapp.com/send?text=Hi, I'm the user of your application&phone=+${item.phoneCode}${item.phoneNumber}`}
                          >
                            <span>Contact Agent</span>
                          </Button>
                        ) : (
                          <Button
                            variant="contained"
                            className="primaryFillBtn width90"
                            onClick={() =>
                              setToastConfig(
                                ToastSeverity.Warning,
                                `Contact the agent in telegram using mobile ${item.phoneCode}${item.phoneNumber}`,
                                true
                              )
                            }
                          >
                            <span>Contact Agent</span>
                          </Button>
                        )}
                        {userInfo.user.roleId === RoleType.Admin && (
                          <>
                            <IconButton
                              color="error"
                              onClick={() => {
                                const id = item.id ? item.id : 0;
                                setActiveDeleteId(id);
                              }}
                              aria-label="Reload"
                              sx={{ "&:hover": { cursor: "pointer" } }}
                            >
                              <DeleteRoundedIcon />
                            </IconButton>

                            <IconButton
                              color="primary"
                              onClick={() => {
                                openAgentEditPopUp(item);
                              }}
                              aria-label="Reload"
                              sx={{ "&:hover": { cursor: "pointer" } }}
                            >
                              <EditIcon />
                            </IconButton>
                          </>
                        )}
                      </CardActions>
                    </Card>
                  </Box>
                </Grid>
              ))}

            {activeDeleteId > 0 && (
              <Modal
                className="commonModal"
                open={activeDeleteId > 0}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box className="commonModalBox">
                  <Box className="commonModalBoxInner">
                    <Box id="modal-modal-title" className="commonModalHead">
                      <Grid container spacing={1}>
                        <Grid item xs={11} md={11} lg={11}>
                          <Typography className="modalTitle">
                            Delete Agent
                          </Typography>
                        </Grid>
                        <Grid item xs={1} md={1} lg={1}>
                          <span className="modalClose">
                            <CloseRoundedIcon
                              onClick={() => setActiveDeleteId(0)}
                            />
                          </span>
                        </Grid>
                      </Grid>
                    </Box>
                    <Box
                      id="modal-modal-description"
                      className="commonModalBody"
                    >
                      <Grid container spacing={2}>
                        <Grid item xs={12} md={12} lg={12}>
                          <Typography>
                            Are you sure you want to delete, this action cannot
                            be reverted?
                          </Typography>
                        </Grid>
                      </Grid>
                    </Box>
                    <Box id="modal-modal-footer" className="commonFooter">
                      <Stack spacing={2} direction="row">
                        <Button
                          variant="outlined"
                          className="primaryLineBtn"
                          onClick={() => setActiveDeleteId(0)}
                        >
                          <span>Cancel</span>
                        </Button>
                        <Button
                          variant="contained"
                          className="primaryFillBtn"
                          onClick={() => deleteApplicationAgent()}
                        >
                          <span>Delete</span>
                        </Button>
                      </Stack>
                    </Box>
                  </Box>
                </Box>
              </Modal>
            )}
            <Modal
              className="commonModal"
              open={openAddCard}
              onClose={() => setOpenAddCard(false)}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box className="commonModalBox">
                <Box className="commonModalBoxInner">
                  <Box id="modal-modal-title" className="commonModalHead">
                    <Grid container spacing={1}>
                      <Grid item xs={11} md={11} lg={11}>
                        <Typography className="modalTitle">
                          Edit Agent
                        </Typography>
                      </Grid>
                      <Grid item xs={1} md={1} lg={1}>
                        <span className="modalClose" onClick={handleClose}>
                          <CloseRoundedIcon />
                        </span>
                      </Grid>
                    </Grid>
                  </Box>
                  <Box id="modal-modal-description" className="commonModalBody">
                    <Formik
                      enableReinitialize
                      initialValues={{ ...applicationAgent }}
                      validationSchema={AgentRegistrationSchema}
                      onSubmit={(values, { setSubmitting }) => {
                        setApplicationAgent(values);
                      }}
                    >
                      {({
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        isSubmitting,
                        isValid,
                        setFieldValue,
                      }) => (
                        <form onSubmit={handleSubmit} className="height100">
                          <Box className="">
                            <Box>
                              <Grid container spacing={2}>
                                <Grid item xs={12} md={12} lg={12}>
                                  <TextField
                                    id="name"
                                    label="Name"
                                    variant="outlined"
                                    fullWidth
                                    onChange={handleChange}
                                    value={values.name}
                                  />

                                  {errors.name && touched.name && (
                                    <FormHelperText className="errorMessage">
                                      {errors.name}
                                    </FormHelperText>
                                  )}
                                </Grid>
                                <Grid item xs={12} md={12} lg={4}>
                                  <FormControl variant="outlined" fullWidth>
                                    <InputLabel id="outlined-country-dropdown-label">
                                      Country
                                    </InputLabel>
                                    <Select
                                      labelId="outlined-country-dropdown-label"
                                      id="country-dropdown"
                                      label="Country"
                                      value={countryId.toString()}
                                      onChange={(evt: SelectChangeEvent) => {
                                        if (evt.target.value === "0") {
                                          values.phoneCode = "";
                                          values.country = "";
                                        } else {
                                          const country: ICountryModel[] = countries.filter(
                                            (x: ICountryModel) =>
                                              x.id.toString() ===
                                              evt.target.value.toString()
                                          );
                                          values.phoneCode =
                                            country[0].phoneCode;
                                          values.country = country[0].name;
                                        }
                                        setCountryId(+evt.target.value);
                                      }}
                                    >
                                      <MenuItem value={0}>Select</MenuItem>
                                      {countries &&
                                        countries.map(
                                          (country: ICountryModel) => (
                                            <MenuItem
                                              key={country.id}
                                              value={country.id.toString()}
                                            >
                                              {country.name}
                                            </MenuItem>
                                          )
                                        )}
                                    </Select>
                                    {errors.countryCode &&
                                      touched.countryCode && (
                                        <FormHelperText className="errorMessage">
                                          {errors.countryCode}
                                        </FormHelperText>
                                      )}
                                  </FormControl>
                                </Grid>
                                <Grid item xs={12} md={12} lg={2}>
                                  <TextField
                                    id="phoneCode"
                                    label="Code"
                                    variant="outlined"
                                    fullWidth
                                    disabled
                                    value={values.phoneCode}
                                  />
                                  {errors.phoneCode && touched.phoneCode && (
                                    <FormHelperText className="errorMessage">
                                      {errors.phoneCode}
                                    </FormHelperText>
                                  )}
                                </Grid>
                                <Grid item xs={12} md={12} lg={6}>
                                  <TextField
                                    type="number"
                                    id="phoneNumber"
                                    label="Phone Number"
                                    variant="outlined"
                                    fullWidth
                                    onChange={handleChange}
                                    value={values.phoneNumber.toString()}
                                  />
                                  {errors.phoneNumber &&
                                    touched.phoneNumber && (
                                      <FormHelperText className="errorMessage">
                                        {errors.phoneNumber}
                                      </FormHelperText>
                                    )}
                                </Grid>
                                <Grid item xs={12} md={12} lg={6}>
                                  <FormControl variant="outlined" fullWidth>
                                    <InputLabel id="outlined-country-dropdown-label">
                                      Contact Type
                                    </InputLabel>
                                    <Select
                                      labelId="outlined-country-dropdown-label"
                                      id="contactTypeId"
                                      label="Contact Type"
                                      value={
                                        values.contactTypeId == ""
                                          ? "0"
                                          : values.contactTypeId
                                      }
                                      onChange={(evt: SelectChangeEvent) => {
                                        if (evt.target.value === "0") {
                                          setFieldValue("contactTypeId", "");
                                        } else {
                                          setFieldValue(
                                            "contactTypeId",
                                            evt.target.value
                                          );
                                        }
                                      }}
                                    >
                                      <MenuItem value={0}>Select</MenuItem>
                                      {agentPaymentStatuses &&
                                        agentPaymentStatuses.map(
                                          (paymentStats: ILookupModel) => (
                                            <MenuItem
                                              key={paymentStats.id}
                                              value={paymentStats.id.toString()}
                                            >
                                              {paymentStats.value}
                                            </MenuItem>
                                          )
                                        )}
                                    </Select>
                                    {errors.contactTypeId &&
                                      touched.contactTypeId && (
                                        <FormHelperText className="errorMessage">
                                          {errors.contactTypeId}
                                        </FormHelperText>
                                      )}
                                  </FormControl>
                                </Grid>
                                <Grid item xs={12} md={12} lg={6}>
                                  <TextField
                                    id="state"
                                    label="State"
                                    variant="outlined"
                                    fullWidth
                                    onChange={handleChange}
                                    value={values.state}
                                  />

                                  {errors.state && touched.state && (
                                    <FormHelperText className="errorMessage">
                                      {errors.state}
                                    </FormHelperText>
                                  )}
                                </Grid>
                                <Grid item xs={12} md={12} lg={12}></Grid>
                              </Grid>
                            </Box>
                            <Button
                              type="submit"
                              variant="contained"
                              className="primaryFillBtn width100"
                              onClick={() => saveApplicationAgent(values)}
                            >
                              <span>Submit</span>
                            </Button>
                          </Box>
                        </form>
                      )}
                    </Formik>
                  </Box>
                </Box>
              </Box>
            </Modal>
            {alertConfig && alertConfig.isShow && (
              <AlertDialog
                alertConfig={alertConfig}
                callBack={alertConfig.callBack}
              />
            )}
          </Grid>
        </Box>
      </Box>
    </Box>
  );
};

export default AgentUsers;
