import React, { FunctionComponent, useEffect } from "react";
import PageProps from "../../models/PageProps.interface";
import { useDispatch, useSelector } from "react-redux";

import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";

//Component Import
import LeftMenu from "../../components/leftMenu/leftMenu.component";
import TopNav from "../../components/topNav/topNav.component";
import Footer from "../../components/footer/footer.component";

import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import UserService from "../../services/user/user.service";
import { getRefferals } from "../../actions/user.actions";

//Icons
import HowToRegRoundedIcon from "@mui/icons-material/HowToRegRounded";
import NoAccountsRoundedIcon from "@mui/icons-material/NoAccountsRounded";
import { IRefferals } from "../../interface/BusinessModels/IRefferalsModel";
import { AppConfig } from "../../constants/AppConfig.constant";
import { useNavigate } from "react-router-dom";
import { RoleType } from "../../constants/DBConstants.constant";
import ApplicationHelperService from "../../services/ApplicationHelperService";

const ReferralList: FunctionComponent<PageProps> = ({ title }) => {
  const _applicationHelperService = new ApplicationHelperService({});
  const navigate = useNavigate();
  const { userInfo } = useSelector((state: any) => state.authReducer);
  const dispatch = useDispatch();
  const _userService = new UserService(dispatch);
  const initializeRefferals = () => dispatch<any>(getRefferals());
  const { refferals } = useSelector((state: any) => state.userReducer);

  useEffect(() => {
    initializeRefferals();
  }, []);

  return (
    <Box className="pageMain">
      <Box className="pageLeft">
        <LeftMenu />
      </Box>
      <Box className="pageRight">
        <Box className="pageHead">
          <TopNav title={title.split("|")[1].trim()} />
        </Box>
        <Box className="pageView">
          {/* <Box className="pageViewHead">
                        <Typography variant="h6">Referrals List</Typography>
                    </Box> */}
          <Box className="pageViewBody commonScroll">
            <Box>
              <Grid container spacing={2}>
                {refferals && refferals.length > 0 ? (
                  refferals.map((refferal: IRefferals) => (
                    <Grid item xs={12} md={6} lg={4}>
                      <Box className="commonCard">
                        <Card>
                          <CardContent>
                            <Box className="commonBgCard saperatorSpacing">
                              <Grid container spacing={2}>
                                <Grid item xs={10} md={10} lg={10}>
                                  <Typography className="cardTitle whiteColor"></Typography>
                                </Grid>
                                <Grid
                                  item
                                  xs={2}
                                  md={2}
                                  lg={2}
                                  className="padL0"
                                >
                                  {refferal.isActive == true ? (
                                    <Box className="verificationStatus verified">
                                      <HowToRegRoundedIcon />
                                    </Box>
                                  ) : (
                                    <Box
                                      className={`verificationStatus declined`}
                                    >
                                      <NoAccountsRoundedIcon />
                                    </Box>
                                  )}
                                </Grid>
                              </Grid>
                            </Box>
                            {/* <Box className="commonLabelValue">
                              <Typography className="commonLabel">
                                Email
                              </Typography>
                              <Typography className="commonValue">
                                {refferal.email}
                              </Typography>
                            </Box> */}
                            <Box className="commonLabelValue">
                              <Typography className="commonLabel">
                                Phone Number
                              </Typography>
                              <Typography className="commonValue">
                                {refferal.phoneCode} {refferal.phoneNumber}
                              </Typography>
                            </Box>
                            <Box className="commonLabelValue">
                              <Typography className="commonLabel">
                                App Id
                              </Typography>
                              {userInfo.user.roleId === RoleType.Admin ||
                              userInfo.user.roleId === RoleType.SuperAdmin ? (
                                <Link
                                  href="/"
                                  onClick={(e) => {
                                    e.preventDefault(); // Prevent appending #
                                    navigate("/myWallet", {
                                      state: refferal.userId.toUpperCase(),
                                    });
                                  }}
                                >
                                  {refferal.appId}
                                </Link>
                              ) : (
                                <Typography className="commonValue">
                                  {refferal.appId}
                                </Typography>
                              )}
                            </Box>
                            <Box className="commonLabelValue">
                              <Typography className="commonLabel">
                                Joined On
                              </Typography>
                              <Typography className="commonValue">
                                {_applicationHelperService.getExpandedDateTimeFormat(
                                  refferal.createdOn
                                )}
                              </Typography>
                            </Box>
                          </CardContent>
                        </Card>
                      </Box>
                    </Grid>
                  ))
                ) : (
                  <Box className="noDataFound">
                    <Box className="noDataFoundPreview">
                      <img
                        alt={AppConfig.applicationName}
                        className="commonImage"
                        src={require("../../assets/images/refferals/noRefferal.png")}
                      />
                    </Box>
                    <Typography component="a" className="noDataFoundLabel">
                      You do not have any referrals at this time. Would you like
                      to <Link href="/">add a referral?</Link>
                    </Typography>
                  </Box>
                )}
              </Grid>
            </Box>
          </Box>
          <Box className="pageViewFooter">
            <Footer />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
export default ReferralList;
