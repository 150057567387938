import React, { useContext, useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";

import { useNavigate } from "react-router-dom";
import { ToastSeverity } from "../../constants/toastSeverity.contants";
import { IAlertDialogConfig } from "../../interface/IAlertDialogConfig";

//Icons Import

import PlayGameService from "../../services/playGame/playGame.service";

//Css Import
import "../alertDialog/alertDialog.component.style.css";
import { IApiResponse } from "../../interface/Response/IApiResponse";
import {
  API_ERROR_STANDARD_MESSAGE,
  DEFAULT_ALERT_CONFIG,
  GameResultType,
} from "../../constants/DBConstants.constant";
import { LoadingContext } from "../../context/loading.context";
import { useDispatch } from "react-redux";
import Typography from "@mui/material/Typography";
import { ICreateNewGameRequestModel } from "../../interface/Request/ICreateNewGameRequestModel";
import * as yup from "yup";
import { ToastContext } from "../../context/toast.context";
import { HttpStatusCode } from "axios";
import { Formik } from "formik";
import Grid from "@mui/material/Grid";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import AlertDialog from "../alertDialog/alertDialog.component";
import AccountTreeOutlinedIcon from "@mui/icons-material/AccountTreeOutlined";
import { IGameHistoryModel } from "../../interface/BusinessModels/IGameHistoryModel";
import { IGamesConfigModel } from "../../interface/BusinessModels/IGamesConfigModel";
import MenuItem from "@mui/material/MenuItem";
import GamesConfigService from "../../services/gamesConfig/gamesConfig.service";

const CreateGame = () => {
  const dispatch = useDispatch();
  const _playGameService = new PlayGameService(dispatch);
  const _gamesConfigService = new GamesConfigService(dispatch);
  const [gameDates, setGameDates] = useState<IGameHistoryModel[]>();
  const navigate = useNavigate();
  const { setLoading } = useContext(LoadingContext);
  const { setToastConfig, setOpen } = useContext(ToastContext);
  const [gamesConfig, setGamesConfig] = useState<IGamesConfigModel[]>();
  const [alertConfig, setAlertConfig] = useState<IAlertDialogConfig>({
    isShow: false,
    callBack: () => undefined,
  });
  const [createGameInitialValues, setCreateGameInitialValues] = useState<
    ICreateNewGameRequestModel
  >({
    gameId: 0,
    gameHistoryId: 0,
  });

  const CreateGameSchema = yup.object().shape({
    gameId: yup.number().required("This field is required"),
  });

  useEffect(() => {
    initializeFunctions();

    async function initializeFunctions() {
      await getAvailableGames();
    }
  }, []);

  const _handleNewGameCreation = async (values: ICreateNewGameRequestModel) => {
    try {
      const isValid = await CreateGameSchema.isValid(values);
      if (isValid) {
        setLoading(true);
        var response: IApiResponse = await _playGameService.createNewGameHistory(
          values
        );
        if (!response.isSuccess) {
          setToastConfig(ToastSeverity.Error, response.message, true);
        } else {
          setAlertConfig({
            description: response.message,
            toastSeverity: ToastSeverity.Success,
            isShow: true,
            callBack: () => {
              setAlertConfig(DEFAULT_ALERT_CONFIG);
              navigate("/dashboard");
            },
          });
        }
      }
    } catch (error) {
      if (
        error &&
        (error as any).response &&
        (error as any).response.status != HttpStatusCode.Unauthorized
      ) {
        setToastConfig(ToastSeverity.Error, API_ERROR_STANDARD_MESSAGE, true);
      }
      console.log(error);
    }
    setLoading(false);
  };

  const getAvailableGames = async () => {
    try {
      setLoading(true);
      const response: IApiResponse = await _gamesConfigService.getAvailableGames();
      setGamesConfig(response.response as IGamesConfigModel[]);
      getGameDates(response.response[0].id);
    } catch (error) {
      if (
        error &&
        (error as any).response &&
        (error as any).response.status != HttpStatusCode.Unauthorized
      ) {
        setToastConfig(ToastSeverity.Error, API_ERROR_STANDARD_MESSAGE, true);
      }
    }

    setLoading(false);
  };

  const getGameDates = async (gameId: number) => {
    setLoading(true);
    const gameDatesResponse: IApiResponse = await _gamesConfigService.getGameDatesToCreateGame(
      gameId
    );
    var resultsNotDeclared = gameDatesResponse.response as IGameHistoryModel[];

    setCreateGameInitialValues({
      gameId: gameId,
      gameHistoryId: resultsNotDeclared ? resultsNotDeclared[0].id : 0,
    });

    setGameDates(gameDatesResponse.response as IGameHistoryModel[]);

    setLoading(false);
  };

  return (
    <Box className="commonBgCard commonBgColorCard saperatorSpacing">
      <Typography className="cardTitle">
        Create Game without declaring results.
      </Typography>
      <Formik
        enableReinitialize
        initialValues={{ ...createGameInitialValues }}
        validationSchema={CreateGameSchema}
        onSubmit={(values, { setSubmitting }) => {
          _handleNewGameCreation(values);
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          isValid,
          setFieldValue,
          /* and other goodies */
        }) => (
          <form
            onSubmit={handleSubmit}
            className="height100"
            style={{ marginTop: 20 }}
          >
            <Grid container spacing={1}>
              <Grid item xs={12} md={3} lg={3}>
                <FormControl fullWidth>
                  <InputLabel id="gameId">Lottery Game Type</InputLabel>
                  <Select
                    className="fontBlack"
                    labelId="gameId"
                    id="gameId"
                    label="gameId"
                    value={values.gameId.toString()}
                    onChange={(evt: SelectChangeEvent) => {
                      getGameDates(+evt.target.value);
                      setFieldValue("gameId", evt.target.value);
                    }}
                  >
                    {gamesConfig &&
                      gamesConfig.map((option: IGamesConfigModel) => (
                        <MenuItem key={option.id} value={option.id.toString()}>
                          {option.name}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={3} lg={3}>
                <FormControl fullWidth>
                  <InputLabel id="gameHistoryId">Game Date</InputLabel>
                  <Select
                    className="fontBlack"
                    labelId="gameHistoryId"
                    id="gameHistoryId"
                    label="gameHistoryId"
                    value={values.gameHistoryId.toString()}
                    onChange={(evt: SelectChangeEvent) => {
                      setFieldValue("gameHistoryId", evt.target.value);
                    }}
                  >
                    {gameDates &&
                      gameDates.map((option: IGameHistoryModel) => (
                        <MenuItem
                          key={option.id}
                          value={option.id.toString()}
                          disabled={
                            option.gameResultStatus == GameResultType.Declared
                          }
                        >
                          {option.gameDrawDate}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid
                item
                xs={12}
                md={12}
                lg={12}
                className="verticalCenter boxRight"
              >
                <Box className="floatRightBtn">
                  <FormControl fullWidth>
                    <Button
                      type="submit"
                      variant="contained"
                      className="primaryFillBtn floatR"
                    >
                      <span>
                        <AccountTreeOutlinedIcon
                          style={{ marginRight: 5 }}
                          className="customIcon"
                        />{" "}
                        Create Next Game
                      </span>
                    </Button>
                  </FormControl>
                </Box>
              </Grid>
            </Grid>
            {alertConfig && alertConfig.isShow && (
              <AlertDialog
                alertConfig={alertConfig}
                callBack={alertConfig.callBack}
              />
            )}
          </form>
        )}
      </Formik>
    </Box>
  );
};

export default CreateGame;
