import { createContext } from "react";
import { ToastSeverity } from "../constants/toastSeverity.contants";

export const ToastContext = createContext({
  setOpen: (show: boolean) => {},
  open: false,
  setToastMessage: (message: string) => {},
  message: "",
  toastConfig: {
    severity: "",
    message: "",
    open: false,
  },
  setToastConfig: (
    severity: ToastSeverity,
    message: string,
    open: boolean
  ) => {},
});
