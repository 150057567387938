import { Component, Dispatch } from "react";
import HttpHelperService from "../httpHelper.service";
import { DOWNLOAD_RESULT_CHART } from "../../constants/EndPoints.constant";
import { Action } from "redux";

class ExportService {
  _httpHelperService;
  constructor(dispatch: Dispatch<Action>) {
    this._httpHelperService = new HttpHelperService(dispatch);
  }

  downloadResultChart = async (gameId: number) => {
    return await this._httpHelperService.downloadFile(
      `${DOWNLOAD_RESULT_CHART(gameId)}`
    );
  };
}

export default ExportService;
