import {
  COUNTRIES_SUCCESS,
  WITHDRAWAL_ACCOUNT_TYPE_SUCCESS,
  CONFIG_SETTINGS,
  WALLET_BALANCE_SUCCESS,
} from "../constants/Reducer.constant";
import { ICountryModel } from "../interface/BusinessModels/ICountryModel";
import { IGameSettings } from "../interface/BusinessModels/IGameSettings";
import { ILookupModel } from "../interface/BusinessModels/ILookupModel";
import { IRechargePayments } from "../interface/BusinessModels/IRechargePayments";
import { IWalletBalanceModel } from "../interface/BusinessModels/IWalletBalanceModel";
import { IUserResponseModel } from "../interface/Response/IUserResponseModel";

interface LookUpDataState {
  countries: ICountryModel[];
  withdrawalAccountType: ILookupModel[];
  gameSettings: IGameSettings[];
  getRechargePaymentsData: IRechargePayments[];
  walletBalance: IWalletBalanceModel;
}

const initialState: LookUpDataState = {
  countries: [],
  withdrawalAccountType: [],
  gameSettings: [],
  getRechargePaymentsData: [],
  walletBalance: {
    balance: 0,
  },
};

const staticDataReducer = (prevState = initialState, action: any) => {
  switch (action.type) {
    case COUNTRIES_SUCCESS:
      return { ...prevState, countries: action.payload };
    case WITHDRAWAL_ACCOUNT_TYPE_SUCCESS:
      return { ...prevState, withdrawalAccountType: action.payload };
    case CONFIG_SETTINGS:
      return { ...prevState, gameSettings: action.payload };
    case WALLET_BALANCE_SUCCESS:
      return { ...prevState, walletBalance: { ...action.payload } };
    default:
      return prevState;
  }
};

export default staticDataReducer;
