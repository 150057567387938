import React, {
  FunctionComponent,
  useEffect,
  useState,
  useContext,
} from "react";
import { isReturnStatement } from "typescript";
import PageProps from "../../models/PageProps.interface";
import { useDispatch, useSelector } from "react-redux";
//Libraries Import
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import {
  IRechargePayments,
  IWithdrawalDetailsModal,
} from "../../interface/BusinessModels/IRechargePayments";
import { IApiResponse } from "../../interface/Response/IApiResponse";
import LookupService from "../../services/lookup/lookup.service";

//Modal
import Modal from "@mui/material/Modal";

import { Formik } from "formik";
import { ToastContext } from "../../context/toast.context";
import { ToastSeverity } from "../../constants/toastSeverity.contants";
import { useNavigate } from "react-router-dom";

//Component Import
import LeftMenu from "../../components/leftMenu/leftMenu.component";
import TopNav from "../../components/topNav/topNav.component";
import Footer from "../../components/footer/footer.component";

//Icons Import
import DoneRoundedIcon from "@mui/icons-material/DoneRounded";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import TimelapseRoundedIcon from "@mui/icons-material/TimelapseRounded";
import { IAlertDialogConfig } from "../../interface/IAlertDialogConfig";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import RecommendIcon from "@mui/icons-material/Recommend";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";

//Import Css
import "../paymentTransactions/paymentTransactions.screen.style.css";
import { string } from "yup";
import { ILookupModel } from "../../interface/BusinessModels/ILookupModel";
import {
  API_ERROR_STANDARD_MESSAGE,
  DEFAULT_ALERT_CONFIG,
  RECHARGESTATUS_CONFIG,
  RechargeStatus,
} from "../../constants/DBConstants.constant";
import { HttpStatusCode } from "axios";

import { LoadingContext } from "../../context/loading.context";
import RechargeService from "../../services/recharge/recharge.service";
import AlertDialog from "../../components/alertDialog/alertDialog.component";
import { IWithdrawalWallet } from "../../interface/Request/IWithdrawalWallet";

import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";

const PaymentTransactions: FunctionComponent<PageProps> = ({ title }) => {
  const [alertConfig, setAlertConfig] = useState<IAlertDialogConfig>({
    isShow: false,
    callBack: () => undefined,
  });
  const dispatch = useDispatch();
  const { setToastConfig, setOpen } = useContext(ToastContext);
  const [openAddCard, setOpenAddCard] = React.useState(false);
  const [openWalletCard, setOpenWalletCard] = React.useState(false);
  const [transactionFields, setTransactionFields] = useState([]);

  const [AccontNo, setAccontNo] = useState<string>();
  const [AccountName, setAccountName] = useState<string>();
  const [IFSCCode, setIFSCCode] = useState<string>();

  const navigate = useNavigate();
  const handleOpenAddCard = () => setOpenAddCard(true);
  const handleCloseAddCard = () => setOpenAddCard(false);

  const handleOpenWalletCard = () => setOpenWalletCard(true);
  const handleCloseWalletCard = () => setOpenWalletCard(false);
  const [selectedType, setSelectedType] = useState<number>(0);
  const [recAllhargePayments, setAllRechargePayments] = useState<
    IWithdrawalDetailsModal
  >();

  const [rechargepopUpdetails, setRechargepopUpdetails] = useState<
    IRechargePayments
  >();
  const [tabValue, setTabValue] = React.useState("RECHARGE");
  const [paymentStatus, setPaymentStatus] = useState<ILookupModel[]>();
  const { setLoading } = useContext(LoadingContext);
  const { userInfo } = useSelector((state: any) => state.authReducer);
  const _lookupService = new LookupService(dispatch);
  const _rechargeService = new RechargeService(dispatch);
  const getAllRechargePayments = async () => {
    try {
      const response: IApiResponse = await _rechargeService.getAllRechargePayments(
        selectedType
      );

      setAllRechargePayments(response.response as IWithdrawalDetailsModal);
    } catch (error) {
      if (
        error &&
        (error as any).response &&
        (error as any).response.status != HttpStatusCode.Unauthorized
      ) {
        setToastConfig(ToastSeverity.Error, API_ERROR_STANDARD_MESSAGE, true);
      }
    }
  };

  useEffect(() => {
    async function getPaymentStatus() {
      setLoading(true);
      try {
        const paymentStatusResponse: IApiResponse = await _lookupService.getConfigs(
          RECHARGESTATUS_CONFIG
        );
        const paymentStatuses: ILookupModel[] = paymentStatusResponse.response as ILookupModel[];
        setPaymentStatus(paymentStatuses);
        setSelectedType(RechargeStatus.Pending);
      } catch (error) {
        if (
          error &&
          (error as any).response &&
          (error as any).response.status != HttpStatusCode.Unauthorized
        ) {
          setToastConfig(ToastSeverity.Error, API_ERROR_STANDARD_MESSAGE, true);
        }
      }
      setLoading(false);
    }

    // Call the function immediately on component mount
    getPaymentStatus();

    // Set up an interval to call the function every 20 seconds
    const intervalId = setInterval(() => {
      getPaymentStatus();
    }, 20000); // 20000ms = 20 seconds

    // Cleanup the interval on component unmount
    return () => clearInterval(intervalId);
  }, []); // Empty dependency array ensures this effect runs once on mount

  const handleTypeChange = (event: {
    target: { value: React.SetStateAction<string> };
  }) => {
    setSelectedType(+event.target.value);
  };

  const UPDATE_RECHARGE_REQUEST_INITIAL_VALUES = {
    id: 0,
    rechargeId: "",
    userId: 0,
    transactionType: "",
    transactionFields: "",
    amount: 0.0,
    transactionUTRId: "",
    phoneNumber: "",
    rechargeStatusId: 0,
    type: "",
    comment: "",
    createdOn: new Date(),
    appId: "",
  };

  const UPDATE_WALLET_REQUEST_INITIAL_VALUES = {
    id: 0,
    userId: 0,
    commissionFee: 0,
    netPaidBalance: 0,
    type: "",
    rechargeStatus: "",
    withDrawStatusId: 0,
    withdrawalDetailId: 0,
    createdBy: 0,
    createdOn: new Date(),
    modifiedOn: new Date(),
    email: "",
    withDrawId: "",
    comment: "",
    wId: 0,
    upiId: "",
    phoneNumber: "",
    accountNumber: "",
    accountHolderName: "",
    ifscCode: "",
    transactionUTRId: "",
    appId: "",
  };
  const [updaterechargePayment, setUpdateRechargePayment] = useState<
    IRechargePayments
  >(UPDATE_RECHARGE_REQUEST_INITIAL_VALUES);

  const [updateWalletPayment, setUpdateWalletPayment] = useState<
    IWithdrawalWallet
  >(UPDATE_WALLET_REQUEST_INITIAL_VALUES);

  const _handleUpdateRechargePayments = async (values: IRechargePayments) => {
    try {
      var response: IApiResponse = await _rechargeService.updateRechargePayments(
        {
          ...values,
          rechargeStatusId:
            values != null ? +(values.rechargeStatusId as number) : 0,
        }
      );
      if (!response.isSuccess) {
        setToastConfig(ToastSeverity.Error, response.message, true);
        setUpdateRechargePayment(UPDATE_RECHARGE_REQUEST_INITIAL_VALUES);
      } else {
        setAlertConfig({
          description: response.response.message,
          toastSeverity: ToastSeverity.Success,
          isShow: true,
          callBack: () => {
            setAlertConfig(DEFAULT_ALERT_CONFIG);
            window.location.reload();
            // getAllRechargePayments();
            // setUpdateRechargePayment(UPDATE_RECHARGE_REQUEST_INITIAL_VALUES);
          },
        });
      }
    } catch (error) {
      if (
        error &&
        (error as any).response &&
        (error as any).response.status != HttpStatusCode.Unauthorized
      ) {
        setToastConfig(ToastSeverity.Error, API_ERROR_STANDARD_MESSAGE, true);
      }
      console.log(error);
    }
  };

  const _handleWalletPayments = async (values: IWithdrawalWallet) => {
    try {
      var response: IApiResponse = await _rechargeService.updateWalletPayments({
        ...values,
        withDrawStatusId:
          values != null ? +(values.withDrawStatusId as number) : 0,
      });
      if (!response.isSuccess) {
        setToastConfig(ToastSeverity.Error, response.message, true);
      } else {
        setAlertConfig({
          description: response.response.message,
          toastSeverity: ToastSeverity.Success,
          isShow: true,
          callBack: () => {
            setAlertConfig(DEFAULT_ALERT_CONFIG);
            getAllRechargePayments();
            setUpdateWalletPayment(UPDATE_WALLET_REQUEST_INITIAL_VALUES);
          },
        });
      }
    } catch (error) {
      if (
        error &&
        (error as any).response &&
        (error as any).response.status != HttpStatusCode.Unauthorized
      ) {
        setToastConfig(ToastSeverity.Error, API_ERROR_STANDARD_MESSAGE, true);
      }
      console.log(error);
    }
  };

  const openRechargemodelPopUp = (items: IRechargePayments) => {
    setUpdateRechargePayment({
      id: items.id,
      rechargeId: items.rechargeId,
      userId: items.userId,
      transactionType: items.transactionType,
      transactionFields: items.transactionFields,
      amount: items.amount,
      transactionUTRId: items.transactionUTRId,
      phoneNumber: items.phoneNumber,
      rechargeStatus: items.rechargeStatus,
      rechargeStatusId: items.rechargeStatusId,
      type: items.type,
      comment: items.comment,
      email: items.email,
      createdOn: items.createdOn,
      appId: items.appId,
    });

    var parsedFields = JSON.parse(items.transactionFields || "{}");
    for (let i = 0; i < parsedFields.length; i++) {
      const field = parsedFields[i];

      if (
        field.key.toLowerCase().includes("account_no") ||
        field.key.includes("ACCOUNT_NUMBER")
      ) {
        setAccontNo(field.value);
      }

      if (field.key.toLowerCase().includes("ifsc")) {
        setIFSCCode(field.value);
      } else {
        setIFSCCode("");
      }
      if (field.key.toLowerCase().includes("account_name")) {
        setAccountName(field.value);
      } else {
        setAccountName("");
      }
    }
    setOpenAddCard(true);
  };

  const openWalletPopUp = (items: IWithdrawalWallet) => {
    setUpdateWalletPayment({
      id: items.id,
      userId: items.userId,
      appId: items.appId,
      commissionFee: items.commissionFee,
      netPaidBalance: items.netPaidBalance,
      type: items.type,
      rechargeStatus: items.rechargeStatus,
      withDrawStatusId: items.withDrawStatusId,
      withdrawalDetailId: items.withdrawalDetailId,
      createdBy: items.createdBy,
      createdOn: items.createdOn,
      modifiedOn: items.modifiedOn,
      email: items.email,
      withDrawId: items.withDrawId,
      comment: items.comment,
      wId: items.wId,
      upiId: items.upiId,
      phoneNumber: items.phoneNumber,
      accountNumber: items.accountNumber,
      accountHolderName: items.accountHolderName,
      ifscCode: items.ifscCode,
      transactionUTRId: items.transactionUTRId,
    });

    setOpenWalletCard(true);
  };

  useEffect(() => {
    if (selectedType > 0) {
      getAllRechargePayments();
    }
  }, [selectedType]);

  return (
    <Box className="pageMain paymentTransactionsPage">
      <Box className="pageLeft">
        <LeftMenu />
      </Box>
      <Box className="pageRight">
        <Box className="pageHead">
          <TopNav title={title.split("|")[1].trim()} />
        </Box>
        <Box className="pageView">
          <Box className="pageViewBody commonScroll">
            <Box className="commonBgCard commonBgColorCard saperatorSpacing">
              <Grid container spacing={1}>
                <Grid item xs={12} md={4} lg={6}>
                  <Box>
                    <FormControl fullWidth>
                      <InputLabel id="type">Status Type</InputLabel>
                      <Select
                        className="fontBlack"
                        labelId="type"
                        id="type"
                        value={selectedType.toString()}
                        onChange={handleTypeChange}
                        label="Type"
                      >
                        {paymentStatus &&
                          paymentStatus.map((option: ILookupModel) => (
                            <MenuItem
                              key={option.id}
                              value={option.id.toString()}
                            >
                              {option.value}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                  </Box>
                </Grid>
                <Grid item xs={12} md={4} lg={3}>
                  {/* <Box>
                    <Button
                      variant="contained"
                      className="primaryFillBtn sectionBtn width100"
                    >
                      Search
                    </Button>
                  </Box> */}
                </Grid>
                <Grid item xs={12} md={4} lg={3}>
                  <Box>
                    <Button
                      variant="contained"
                      className="primaryFillBtn sectionBtn width100"
                      onClick={() => navigate("/dbtransactionhistory")}
                    >
                      <span>Txns DB History</span>
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </Box>
            <Box>
              <TabContext value={tabValue}>
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                  <TabList
                    onChange={(event, newValue) => setTabValue(newValue)}
                  >
                    <Tab label="Recharge" value={"RECHARGE"} />
                    <Tab label="Withdraw" value={"WITHDRAW"} />
                  </TabList>
                </Box>
                <TabPanel value={"RECHARGE"}>
                  <Grid container spacing={1}>
                    {recAllhargePayments &&
                      recAllhargePayments.rechargePaymentsModel &&
                      recAllhargePayments.rechargePaymentsModel.length > 0 &&
                      recAllhargePayments.rechargePaymentsModel.map(
                        (item, index) => (
                          <Grid item xs={12} md={4} lg={4} key={index}>
                            <Box
                              className="commonCard width100"
                              component={Button}
                              onClick={() => {
                                // if (
                                //   item.rechargeStatusId ==
                                //   RechargeStatus.Pending
                                // ) {
                                openRechargemodelPopUp(item);
                                // }
                              }}
                            >
                              <Card className="width100">
                                <CardContent>
                                  <Box className="commonBgCard saperatorSpacing">
                                    <Grid container spacing={2}>
                                      <Grid
                                        item
                                        xs={10}
                                        md={10}
                                        lg={10}
                                        className="boxLeft"
                                      >
                                        <Typography className="cardTitle whiteColor">
                                          {item.rechargeId}
                                        </Typography>
                                      </Grid>
                                      <Grid
                                        item
                                        xs={2}
                                        md={2}
                                        lg={2}
                                        className="boxRight"
                                      >
                                        {item.rechargeStatusId ===
                                        RechargeStatus.Pending ? (
                                          <Box className="verificationStatus pending">
                                            <ErrorOutlineIcon />
                                          </Box>
                                        ) : item.rechargeStatusId ===
                                          RechargeStatus.Approved ? (
                                          <Box className="verificationStatus approved">
                                            <RecommendIcon />
                                          </Box>
                                        ) : item.rechargeStatusId ===
                                          RechargeStatus.Declined ? (
                                          <Box className="verificationStatus declined">
                                            <HighlightOffIcon />
                                          </Box>
                                        ) : (
                                          <Box className="verificationStatus pending">
                                            <HighlightOffIcon />
                                          </Box>
                                        )}
                                      </Grid>
                                    </Grid>
                                  </Box>
                                  <Box className="commonLabelValue">
                                    <Typography className="commonLabel">
                                      Requested By
                                    </Typography>
                                    <Typography className="commonValue">
                                      <span>{item.appId}</span>
                                    </Typography>
                                  </Box>
                                  <Box className="commonLabelValue">
                                    <Typography className="commonLabel">
                                      Type
                                    </Typography>
                                    <Typography className="commonValue">
                                      {item.type}
                                    </Typography>
                                  </Box>
                                  <Box className="commonLabelValue">
                                    <Typography className="commonLabel">
                                      Payment Type
                                    </Typography>
                                    <Typography className="commonValue">
                                      {item.transactionType}
                                    </Typography>
                                  </Box>
                                  <Box className="commonLabelValue">
                                    <Typography className="commonLabel">
                                      Amount
                                    </Typography>
                                    <Typography className="commonValue">
                                      <span></span>
                                      {parseFloat(
                                        item.amount.toString()
                                      ).toFixed(2)}
                                    </Typography>
                                  </Box>
                                  <Box className="commonLabelValue">
                                    <Typography className="commonLabel">
                                      Reference ID
                                    </Typography>
                                    <Typography className="commonValue">
                                      {item.transactionUTRId}
                                    </Typography>
                                  </Box>
                                  <Box className="commonLabelValue">
                                    <Typography className="commonLabel">
                                      Reference Status
                                    </Typography>
                                    <Typography className="commonValue">
                                      <span>{item.rechargeStatus}</span>
                                    </Typography>
                                  </Box>

                                  <Box className="commonLabelValue">
                                    <Typography className="commonLabel">
                                      Bonus Amount
                                    </Typography>
                                    <Typography className="commonValue">
                                      {item.bonusAmount}
                                    </Typography>
                                  </Box>

                                  <Box className="commonLabelValue">
                                    <Typography className="commonLabel">
                                      Recharged Amount
                                    </Typography>
                                    <Typography className="commonValue">
                                      {item.rechargedAmount}
                                    </Typography>
                                  </Box>

                                  <Box className="commonLabelValue">
                                    <Typography className="commonLabel">
                                      Referral Bonus
                                    </Typography>
                                    <Typography className="commonValue">
                                      {item.referralBonusAmount}
                                    </Typography>
                                  </Box>
                                  <Box className="commonLabelValue">
                                    <Typography className="commonLabel">
                                      Comment
                                    </Typography>
                                    <Typography className="commonValue">
                                      {item.comment}
                                    </Typography>
                                  </Box>
                                  <Box className="commonLabelValue">
                                    <Typography className="commonLabel">
                                      Created On
                                    </Typography>
                                    <Typography className="commonValue">
                                      {item.createdOn.toLocaleString()}
                                    </Typography>
                                  </Box>
                                </CardContent>
                              </Card>
                            </Box>
                          </Grid>
                        )
                      )}
                  </Grid>
                </TabPanel>
                <TabPanel value={"WITHDRAW"}>
                  <Grid container spacing={1}>
                    {recAllhargePayments &&
                      recAllhargePayments.withdrawalWallets &&
                      recAllhargePayments.withdrawalWallets.length > 0 &&
                      recAllhargePayments.withdrawalWallets.map(
                        (item, index) => (
                          <Grid item xs={12} md={4} lg={4} key={index}>
                            <Box
                              className="commonCard width100"
                              component={Button}
                              onClick={() => {
                                // if (
                                //   item.withDrawStatusId ==
                                //   RechargeStatus.Pending
                                // ) {
                                openWalletPopUp(item);
                                // }
                              }}
                            >
                              <Card className="width100">
                                <CardContent>
                                  <Box className="commonBgCard saperatorSpacing">
                                    <Grid container spacing={2}>
                                      <Grid
                                        item
                                        xs={10}
                                        md={10}
                                        lg={10}
                                        className="boxLeft"
                                      >
                                        <Typography className="cardTitle whiteColor">
                                          {item.withDrawId}
                                        </Typography>
                                      </Grid>
                                      <Grid
                                        item
                                        xs={2}
                                        md={2}
                                        lg={2}
                                        className="boxRight"
                                      >
                                        {item.withDrawStatusId ===
                                        RechargeStatus.Pending ? (
                                          <Box className="verificationStatus pending">
                                            <ErrorOutlineIcon />
                                          </Box>
                                        ) : item.withDrawStatusId ===
                                          RechargeStatus.Approved ? (
                                          <Box className="verificationStatus approved">
                                            <RecommendIcon />
                                          </Box>
                                        ) : item.withDrawStatusId ===
                                          RechargeStatus.Declined ? (
                                          <Box className="verificationStatus declined">
                                            <HighlightOffIcon />
                                          </Box>
                                        ) : (
                                          <Box className="verificationStatus pending">
                                            <HighlightOffIcon />
                                          </Box>
                                        )}
                                      </Grid>
                                    </Grid>
                                  </Box>
                                  <Box className="commonLabelValue">
                                    <Typography className="commonLabel">
                                      Requested By
                                    </Typography>
                                    <Typography className="commonValue">
                                      <span>{item.appId}</span>
                                    </Typography>
                                  </Box>
                                  <Box className="commonLabelValue">
                                    <Typography className="commonLabel">
                                      Type
                                    </Typography>
                                    <Typography className="commonValue">
                                      {item.type}
                                    </Typography>
                                  </Box>
                                  <Box className="commonLabelValue">
                                    <Typography className="commonLabel">
                                      Transaction Fee
                                    </Typography>
                                    <Typography className="commonValue">
                                      {item.commissionFee}
                                    </Typography>
                                  </Box>
                                  <Box className="commonLabelValue">
                                    <Typography className="commonLabel">
                                      Amount
                                    </Typography>
                                    <Typography className="commonValue">
                                      <span></span>
                                      {item.netPaidBalance}
                                    </Typography>
                                  </Box>
                                  {/* <Box className="commonLabelValue">
                              <Typography className="commonLabel">
                                Reference ID
                              </Typography>
                              <Typography className="commonValue">
                                {item.transactionUTRId}
                              </Typography>
                            </Box> */}
                                  <Box className="commonLabelValue">
                                    <Typography className="commonLabel">
                                      Withdraw Status
                                    </Typography>
                                    <Typography className="commonValue">
                                      <span>{item.rechargeStatus}</span>
                                    </Typography>
                                  </Box>
                                  <Box className="commonLabelValue">
                                    <Typography className="commonLabel">
                                      Created On
                                    </Typography>
                                    <Typography className="commonValue">
                                      {item.createdOn.toLocaleString()}
                                    </Typography>
                                  </Box>
                                  <Box className="commonLabelValue">
                                    <Typography className="commonLabel">
                                      Comment
                                    </Typography>
                                    <Typography className="commonValue">
                                      {item.comment}
                                    </Typography>
                                  </Box>
                                </CardContent>
                              </Card>
                            </Box>
                          </Grid>
                        )
                      )}
                  </Grid>
                </TabPanel>
              </TabContext>
            </Box>
          </Box>
          {/* Modal Popup Starts Here - Recharge*/}
          {updaterechargePayment.id > 0 && (
            <Formik
              enableReinitialize
              initialValues={updaterechargePayment}
              onSubmit={(values, { setSubmitting }) => {
                _handleUpdateRechargePayments(values);
              }}
            >
              {({
                values,
                handleChange,
                handleSubmit,
                isSubmitting,
                setFieldValue,
              }) => (
                <form onSubmit={handleSubmit}>
                  <Modal
                    className="commonModal"
                    open={openAddCard}
                    onClose={handleCloseAddCard}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                  >
                    <Box className="commonModalBox">
                      <Box className="commonModalBoxInner">
                        <Box id="modal-modal-title" className="commonModalHead">
                          <Grid container spacing={2}>
                            <Grid item xs={11} md={11} lg={11}>
                              <Typography className="modalTitle">
                                Recharge Details
                              </Typography>
                            </Grid>
                            <Grid item xs={1} md={1} lg={1}>
                              <span className="modalClose">
                                <CloseRoundedIcon
                                  onClick={handleCloseAddCard}
                                />
                              </span>
                            </Grid>
                          </Grid>
                        </Box>
                        <Box
                          id="modal-modal-description"
                          className="commonModalBody"
                        >
                          <Grid container spacing={2}>
                            <Grid item xs={12} md={6} lg={6}>
                              <TextField
                                id=""
                                label="Recharge ID"
                                variant="outlined"
                                fullWidth
                                disabled
                                defaultValue={values.rechargeId}
                              />
                            </Grid>
                            <Grid item xs={12} md={6} lg={6}>
                              <TextField
                                id=""
                                label="App Id"
                                variant="outlined"
                                fullWidth
                                disabled
                                defaultValue={values.appId}
                              />
                            </Grid>
                            <Grid item xs={12} md={6} lg={6}>
                              <TextField
                                id=""
                                label="Phone Number"
                                variant="outlined"
                                fullWidth
                                disabled
                                defaultValue={values.phoneNumber}
                              />
                            </Grid>
                            <Grid item xs={12} md={6} lg={6}>
                              <TextField
                                id=""
                                label="Type"
                                variant="outlined"
                                fullWidth
                                disabled
                                defaultValue={values.type}
                              />
                            </Grid>
                            <Grid item xs={12} md={6} lg={6}>
                              <TextField
                                id=""
                                label="Recharge Amount"
                                variant="outlined"
                                fullWidth
                                disabled
                                defaultValue={values.amount}
                              />
                            </Grid>
                            <Grid item xs={12} md={6} lg={6}>
                              <TextField
                                id=""
                                label="Payment Method"
                                variant="outlined"
                                fullWidth
                                disabled
                                defaultValue={values.transactionType}
                              />
                            </Grid>
                            <Grid item xs={12} md={6} lg={6}>
                              <TextField
                                id=""
                                label="Phone Number"
                                variant="outlined"
                                fullWidth
                                disabled
                                defaultValue={values.phoneNumber}
                              />
                            </Grid>
                            <Grid item xs={12} md={6} lg={6}>
                              <TextField
                                id=""
                                label="Account Number"
                                variant="outlined"
                                fullWidth
                                disabled
                                defaultValue={AccontNo}
                              />
                            </Grid>
                            <Grid item xs={12} md={6} lg={6}>
                              <TextField
                                id=""
                                label="Account Holder Name"
                                variant="outlined"
                                fullWidth
                                disabled
                                defaultValue={AccountName}
                              />
                            </Grid>
                            <Grid item xs={12} md={6} lg={6}>
                              <TextField
                                id=""
                                label="IFSC Code"
                                variant="outlined"
                                fullWidth
                                disabled
                                defaultValue={IFSCCode}
                              />
                            </Grid>
                            <Grid item xs={12} md={6} lg={6}>
                              <TextField
                                id=""
                                label="Reference Number/Txn ID/UTR"
                                variant="outlined"
                                fullWidth
                                disabled
                                defaultValue={values.transactionUTRId}
                              />
                            </Grid>
                            <Grid item xs={12} md={6} lg={6}>
                              <TextField
                                id=""
                                label="Requested User Email"
                                variant="outlined"
                                fullWidth
                                disabled
                                defaultValue={values.email}
                              />
                            </Grid>
                            <Grid item xs={12} md={6} lg={6} className="">
                              <TextField
                                id=""
                                label="Created On"
                                variant="outlined"
                                fullWidth
                                disabled
                                defaultValue={values.createdOn}
                              />
                            </Grid>
                            <Grid item xs={12} md={6} lg={6}>
                              <FormControl fullWidth>
                                <InputLabel id="type">
                                  Payment Status
                                </InputLabel>
                                <Select
                                  labelId="rechargeStatus"
                                  id="rechargeStatus"
                                  value={
                                    values.rechargeStatusId
                                      ? values.rechargeStatusId.toString()
                                      : "0"
                                  }
                                  onChange={(evt: SelectChangeEvent) => {
                                    setFieldValue(
                                      "rechargeStatusId",
                                      evt.target.value
                                    );
                                  }}
                                  label="rechargeStatus"
                                >
                                  {paymentStatus &&
                                    paymentStatus.map(
                                      (option: ILookupModel) => (
                                        <MenuItem
                                          key={option.id}
                                          value={option.id.toString()}
                                        >
                                          {option.value}
                                        </MenuItem>
                                      )
                                    )}
                                </Select>
                              </FormControl>
                            </Grid>
                            <Grid item xs={12} md={12} lg={12}>
                              <TextField
                                id="comment"
                                label="Comments"
                                multiline
                                maxRows={4}
                                variant="outlined"
                                fullWidth
                                value={values.comment}
                                onChange={handleChange}
                                defaultValue={values.comment}
                              />
                            </Grid>
                          </Grid>
                        </Box>
                        <Box id="modal-modal-footer" className="commonFooter">
                          <Stack spacing={2} direction="row">
                            <Button
                              type="submit"
                              variant="contained"
                              className="primaryFillBtn"
                              onClick={() =>
                                _handleUpdateRechargePayments(values)
                              }
                            >
                              <span>Submit</span>
                            </Button>
                            <Button
                              variant="outlined"
                              onClick={handleCloseAddCard}
                              className="primaryLineBtn modalClose"
                            >
                              <span>Cancel</span>
                            </Button>
                          </Stack>
                        </Box>
                      </Box>
                    </Box>
                  </Modal>
                </form>
              )}
            </Formik>
          )}

          {/* Modal Popup Ends Here - Recharge*/}

          {/* Modal Popup Starts Here -  Wallet*/}
          {updateWalletPayment.id > 0 && (
            <Formik
              enableReinitialize
              initialValues={updateWalletPayment}
              onSubmit={(values, { setSubmitting }) => {
                setUpdateWalletPayment(values);
              }}
            >
              {({
                values,
                handleChange,
                handleSubmit,
                isSubmitting,
                setFieldValue,
              }) => (
                <form onSubmit={handleSubmit}>
                  <Modal
                    className="commonModal"
                    open={openWalletCard}
                    onClose={handleCloseWalletCard}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                  >
                    <Box className="commonModalBox">
                      <Box className="commonModalBoxInner">
                        <Box id="modal-modal-title" className="commonModalHead">
                          <Grid container spacing={2}>
                            <Grid item xs={11} md={11} lg={11}>
                              <Typography className="modalTitle">
                                Withdrawal Details
                              </Typography>
                            </Grid>
                            <Grid item xs={1} md={1} lg={1}>
                              <span className="modalClose">
                                <CloseRoundedIcon
                                  onClick={handleCloseWalletCard}
                                />
                              </span>
                            </Grid>
                          </Grid>
                        </Box>
                        <Box
                          id="modal-modal-description"
                          className="commonModalBody"
                        >
                          <Grid container spacing={2}>
                            <Grid item xs={12} md={6} lg={6}>
                              <TextField
                                id=""
                                label="Withdrawal ID"
                                variant="outlined"
                                fullWidth
                                disabled
                                defaultValue={values.id}
                              />
                            </Grid>
                            <Grid item xs={12} md={6} lg={6}>
                              <TextField
                                id=""
                                label="App Id"
                                variant="outlined"
                                fullWidth
                                disabled
                                defaultValue={values.appId}
                              />
                            </Grid>
                            <Grid item xs={12} md={6} lg={6}>
                              <TextField
                                id=""
                                label="Phone Number"
                                variant="outlined"
                                fullWidth
                                disabled
                                defaultValue={values.phoneNumber}
                              />
                            </Grid>
                            <Grid item xs={12} md={6} lg={6}>
                              <TextField
                                id=""
                                label="Type"
                                variant="outlined"
                                fullWidth
                                disabled
                                defaultValue={values.type}
                              />
                            </Grid>
                            <Grid item xs={12} md={6} lg={6}>
                              <TextField
                                id=""
                                label="Withdrawal Amount"
                                variant="outlined"
                                fullWidth
                                disabled
                                defaultValue={values.netPaidBalance}
                              />
                            </Grid>
                            {values && values.upiId && (
                              <Grid item xs={12} md={6} lg={6}>
                                <TextField
                                  id=""
                                  label="Payment Method"
                                  variant="outlined"
                                  fullWidth
                                  disabled
                                  defaultValue={values.upiId}
                                />
                              </Grid>
                            )}
                            {values && values.accountNumber && (
                              <Grid item xs={12} md={6} lg={6}>
                                <TextField
                                  id=""
                                  label="Account No"
                                  variant="outlined"
                                  fullWidth
                                  disabled
                                  defaultValue={values.accountNumber}
                                />
                              </Grid>
                            )}
                            {values && values.accountHolderName && (
                              <Grid item xs={12} md={6} lg={6}>
                                <TextField
                                  id=""
                                  label="Account Holder Name"
                                  variant="outlined"
                                  fullWidth
                                  disabled
                                  defaultValue={values.accountHolderName}
                                />
                              </Grid>
                            )}
                            {values && values.ifscCode && (
                              <Grid item xs={12} md={6} lg={6}>
                                <TextField
                                  id=""
                                  label="Ifsc Code"
                                  variant="outlined"
                                  fullWidth
                                  disabled
                                  defaultValue={values.ifscCode}
                                />
                              </Grid>
                            )}

                            {/* <Grid item xs={12} md={6} lg={6}>
                              <TextField
                                id=""
                                label="Phone Number"
                                variant="outlined"
                                fullWidth
                                disabled
                                defaultValue={values.phoneNumber}
                              />
                            </Grid> */}

                            {/* <Grid xs={12} md={6} lg={6}>
                              <TextField
                                id=""
                                label="Account Number"
                                variant="outlined"
                                fullWidth
                                disabled
                                defaultValue={AccontNo}
                              />
                            </Grid>
                            <Grid item xs={12} md={6} lg={6}>
                              <TextField
                                id=""
                                label="Account Holder Name"
                                variant="outlined"
                                fullWidth
                                disabled
                                defaultValue={AccountName}
                              />
                            </Grid>
                            <Grid item xs={12} md={6} lg={6}>
                              <TextField
                                id=""
                                label="IFSC Code"
                                variant="outlined"
                                fullWidth
                                disabled
                                defaultValue={IFSCCode}
                              />
                            </Grid>

                            <Grid item xs={12} md={6} lg={6}>
                              <TextField
                                id=""
                                label="Reference Number/Txn ID/UTR"
                                variant="outlined"
                                fullWidth
                                disabled
                                defaultValue={values.transactionUTRId}
                              />
                            </Grid> */}

                            <Grid item xs={12} md={6} lg={6}>
                              <TextField
                                id=""
                                label="Requested User Email"
                                variant="outlined"
                                fullWidth
                                disabled
                                defaultValue={values.email}
                              />
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              md={6}
                              lg={6}
                              className="commonDatePickerTopSpacing"
                            >
                              <TextField
                                id=""
                                label="Created On"
                                variant="outlined"
                                fullWidth
                                disabled
                                defaultValue={values.createdOn}
                              />
                            </Grid>
                            <Grid item xs={12} md={6} lg={6}>
                              <FormControl fullWidth>
                                <InputLabel id="type">
                                  Payment Status
                                </InputLabel>
                                <Select
                                  labelId="walletStatus"
                                  id="withDrawStatusId"
                                  value={
                                    values.withDrawStatusId
                                      ? values.withDrawStatusId.toString()
                                      : "0"
                                  }
                                  onChange={(evt: SelectChangeEvent) => {
                                    setFieldValue(
                                      "withDrawStatusId",
                                      evt.target.value
                                    );
                                  }}
                                  label="walletStatus"
                                >
                                  {paymentStatus &&
                                    paymentStatus.map(
                                      (option: ILookupModel) => (
                                        <MenuItem
                                          key={option.id}
                                          value={option.id.toString()}
                                        >
                                          {option.value}
                                        </MenuItem>
                                      )
                                    )}
                                </Select>
                              </FormControl>
                            </Grid>
                            <Grid item xs={12} md={12} lg={12}>
                              <TextField
                                id="transactionUTRId"
                                label="TransactionUTRId"
                                multiline
                                maxRows={4}
                                variant="outlined"
                                fullWidth
                                value={values.transactionUTRId}
                                onChange={handleChange}
                                defaultValue={values.transactionUTRId}
                              />
                            </Grid>
                            <Grid item xs={12} md={12} lg={12}>
                              <TextField
                                id="comment"
                                label="Comments"
                                multiline
                                maxRows={4}
                                variant="outlined"
                                fullWidth
                                value={values.comment}
                                onChange={handleChange}
                                defaultValue={values.comment}
                              />
                            </Grid>
                          </Grid>
                        </Box>
                        <Box id="modal-modal-footer" className="commonFooter">
                          <Stack spacing={2} direction="row">
                            <Button
                              type="submit"
                              variant="contained"
                              className="primaryFillBtn"
                              onClick={() => _handleWalletPayments(values)}
                            >
                              <span>Submit</span>
                            </Button>
                            <Button
                              variant="outlined"
                              onClick={handleCloseWalletCard}
                              className="primaryLineBtn modalClose"
                            >
                              <span>Cancel</span>
                            </Button>
                          </Stack>
                        </Box>
                      </Box>
                    </Box>
                  </Modal>
                </form>
              )}
            </Formik>
          )}

          {/* Modal Popup Ends Here - Wallet*/}

          <Box className="pageViewFooter">
            <Footer />
          </Box>
        </Box>
      </Box>
      {alertConfig && alertConfig.isShow && (
        <AlertDialog
          alertConfig={alertConfig}
          callBack={alertConfig.callBack}
        />
      )}
    </Box>
  );
};
export default PaymentTransactions;
