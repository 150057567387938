import HttpHelperService from "../services/httpHelper.service";
import { REFFERALS_SUCCESS } from "../constants/Reducer.constant";
import { Action, Dispatch } from "redux";
import { IApiResponse } from "../interface/Response/IApiResponse";
import { REFFERALS_DETAILS } from "../constants/EndPoints.constant";
import { IRefferals } from "../interface/BusinessModels/IRefferalsModel";

export const getRefferals = () => async (dispatch: Dispatch<Action>) => {
  try {
    const _httpHelperService = new HttpHelperService(dispatch);
    _httpHelperService
      .get(`${REFFERALS_DETAILS}`)
      .then(async (response: IApiResponse) => {
        dispatch({
          type: REFFERALS_SUCCESS,
          payload: response.response as IRefferals[],
        });
      })
      .catch((error) => {
        console.log("Look Up Actions", error);
      });
  } catch (error) {
    console.log("Look Up Actions", error);
  }
};
