import React, { FunctionComponent } from "react";
import Box from "@mui/material/Box";
import PageProps from "../../models/PageProps.interface";
import { AppConfig } from "../../constants/AppConfig.constant";

const PageConstruction: FunctionComponent<PageProps> = ({ title }) => {
  return (
    <Box className="pageMain">
      <Box className="pageConstructionPage">
        <img
          alt={AppConfig.applicationName}
          className=""
          src={require("../../assets/images/pageConstruction/construction.png")}
        />
      </Box>
    </Box>
  );
};
export default PageConstruction;
