import React, {
  FunctionComponent,
  useEffect,
  useContext,
  useState,
} from "react";
import PageProps from "../../models/PageProps.interface";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { DataGrid, GridColDef, GridToolbar } from "@mui/x-data-grid";
import LeftMenu from "../../components/leftMenu/leftMenu.component";
import TopNav from "../../components/topNav/topNav.component";
import Footer from "../../components/footer/footer.component";
import "../allUsers/allUsers.screen.style.css";
import { useDispatch, useSelector } from "react-redux";
import UserService from "../../services/user/user.service";
import { IApiResponse } from "../../interface/Response/IApiResponse";
import { ToastContext } from "../../context/toast.context";
import { ToastSeverity } from "../../constants/toastSeverity.contants";
import ToggleOffIcon from "@mui/icons-material/ToggleOff";
import ToggleOnIcon from "@mui/icons-material/ToggleOn";
import LookupService from "../../services/lookup/lookup.service";
import { ICountryModel } from "../../interface/BusinessModels/ICountryModel";
import { IAlertDialogConfig } from "../../interface/IAlertDialogConfig";
import AlertDialog from "../../components/alertDialog/alertDialog.component";
import { DEFAULT_ALERT_CONFIG } from "../../constants/DBConstants.constant";

const ManageCountry: FunctionComponent<PageProps> = ({ title }) => {
  const { userInfo } = useSelector((state: any) => state.authReducer);
  const dispatch = useDispatch();
  const _userService = new UserService(dispatch);
  const _lookUpService = new LookupService(dispatch);
  const { setToastConfig, setOpen } = useContext(ToastContext);
  const [openAddCard, setOpenAddCard] = React.useState(false);
  const handleOpenAddCard = () => setOpenAddCard(true);
  const [allCountryConfig, setAllCountryConfig] = useState<ICountryModel[]>([]);
  const [alertConfig, setAlertConfig] = useState<IAlertDialogConfig>({
    isShow: false,
    callBack: () => undefined,
  });

  useEffect(() => {
    getAllCountries();
  }, []);

  useEffect(() => {
    document.title = title;
  }, []);

  const getAllCountries = async () => {
    var response: IApiResponse = await _lookUpService.getAllCountries();
    if (!response.isSuccess) {
      setToastConfig(ToastSeverity.Error, response.message, true);
    } else {
      setAllCountryConfig(response.response as ICountryModel[]);
    }
  };

  const columns: GridColDef[] = [
    { field: "id", headerName: "Id", flex: 1 },
    { field: "code", headerName: "Code", flex: 1 },
    { field: "name", headerName: "Name", flex: 1 },
    { field: "currencyCode", headerName: "Currency Code", flex: 1 },
    { field: "phoneCode", headerName: "Phone Code", flex: 1 },
    {
      field: "active",
      headerName: "Is Active",
      renderCell: (params) => {
        return params.row.isActive ? (
          <ToggleOnIcon
            color="success"
            onClick={() => updateCountryConfig(params.row)}
          />
        ) : (
          <ToggleOffIcon
            color="error"
            onClick={() => updateCountryConfig(params.row)}
          />
        );
      },
      flex: 1,
    },
  ];

  const updateCountryConfig = async (countryModel: ICountryModel) => {
    var response: IApiResponse = await _lookUpService.updateCountry(
      countryModel
    );
    if (!response.isSuccess) {
      setToastConfig(ToastSeverity.Error, response.message, true);
    } else {
      setAlertConfig({
        description: response.message,
        toastSeverity: ToastSeverity.Success,
        isShow: true,
        callBack: () => {
          setAlertConfig(DEFAULT_ALERT_CONFIG);
          getAllCountries();
        },
      });
    }
  };

  return (
    <Box className="pageMain">
      <Box className="pageLeft">
        <LeftMenu />
      </Box>
      <Box className="pageRight">
        <Box className="pageHead">
          <TopNav title={title.split("|")[1].trim()} />
        </Box>
        <Box className="pageView">
          <Box className="pageViewBody commonScroll">
            <Box>
              <Grid container spacing={2}>
                <Grid item xs={12} md={8} lg={9}>
                  <Typography>
                    All Countries <span>({allCountryConfig.length})</span>
                  </Typography>
                </Grid>
              </Grid>
            </Box>
            <DataGrid
              columnVisibilityModel={{
                id: false,
              }}
              getRowId={(row) => row.id}
              rows={allCountryConfig}
              columns={columns}
              slots={{
                toolbar: GridToolbar,
              }}
              pagination
              rowCount={allCountryConfig.length}
              pageSizeOptions={[15]}
              initialState={{
                pagination: {
                  paginationModel: {
                    pageSize: 15,
                  },
                },
              }}
              disableRowSelectionOnClick
              // paginationModel={{
              //   page: allUsersModel.pagination.pageNumber - 1,
              //   pageSize: allUsersModel.pagination.pageSize,
              // }}
            />
          </Box>

          <Box className="pageViewFooter">
            <Footer />
          </Box>
        </Box>
      </Box>
      {alertConfig && alertConfig.isShow && (
        <AlertDialog
          alertConfig={alertConfig}
          callBack={alertConfig.callBack}
        />
      )}
    </Box>
  );
};
export default ManageCountry;
