import React, { FunctionComponent } from "react";
import { useTheme } from "@mui/material/styles";

import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { ClockLoader, PropagateLoader } from "react-spinners";
import Typography from "@mui/material/Typography";
import { useNavigate } from "react-router-dom";
import { Link } from "@mui/material";
//Css

import "../../index.css";
import { AppConfig } from "../../constants/AppConfig.constant";
import { IGameLotteryTicketsWonLoss } from "../../interface/BusinessModels/ILotterySummary";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import Paper from "@mui/material/Paper";
import { IPlayedNumberModel } from "../../interface/BusinessModels/IPlayedNumberModel";

const LotteryTicketWonLoss = (props: {
  x: IGameLotteryTicketsWonLoss;
  rowIndex: number;
}) => {
  const navigate = useNavigate();

  return (
    <Grid item xs={12} md={6} lg={4} key={props.x.id}>
      <Box className="commonCard height100">
        <Card>
          <CardContent>
            <Box className="commonBgCard saperatorSpacing">
              <Grid container spacing={2}>
                <Grid item xs={10} md={10} lg={10}>
                  <Typography className="cardTitle whiteColor">
                    {props.x.lotteryId.toUpperCase()}
                  </Typography>
                </Grid>
                <Grid item xs={2} md={2} lg={2} className="boxRight">
                  <Box className="">
                    <img
                      alt={`${AppConfig.applicationName} - Flag`}
                      className=""
                      src={require("../../assets/images/lotteryHistory/thailand.png")}
                    />
                  </Box>
                </Grid>
              </Grid>
            </Box>
            <Box>
              <Box className="commonLabelValue">
                <Typography className="commonLabel">App Id</Typography>
                <Link
                  href="/"
                  onClick={(e) => {
                    e.preventDefault(); // Prevent appending #
                    navigate("/userInformation", {
                      state: props.x.userId.toUpperCase(),
                    });
                  }}
                >
                  {props.x.appId}
                </Link>
              </Box>
              <Box className="commonLabelValue">
                <Typography className="commonLabel">Game</Typography>
                <Typography className="commonValue">
                  {props.x.gameType}
                </Typography>
              </Box>
              <Box className="commonLabelValue">
                <Typography className="commonLabel">Game Type</Typography>
                <Typography className="commonValue">
                  {props.x.gameOption}
                </Typography>
              </Box>
              <Box className="commonLabelValue">
                <Typography className="commonLabel">Ticket Number</Typography>
                <Typography className="commonValue">
                  {props.x.ticketNumber}
                </Typography>
              </Box>
              <Box className="commonLabelValue">
                <Typography className="commonLabel">Game Result</Typography>
                <Typography className="commonValue">
                  {props.x.status}
                </Typography>
              </Box>
              <Box className="commonLabelValue">
                <Typography className="commonLabel">Created On</Typography>
                <Typography className="commonValue">
                  {props.x.createdOn}
                </Typography>
              </Box>
              <Box className="commonLabelValue">
                <Typography className="commonLabel">Game Draw Date</Typography>
                <Typography className="commonValue">
                  {props.x.gameDrawDate}
                </Typography>
              </Box>
            </Box>
            <Box>
              <Box>
                <Typography className="sectionTitle saperatorBorder">
                  Game Result
                </Typography>
              </Box>
              <Box>
                <Box className="commonLabelValue">
                  <Typography className="commonLabel">
                    Total Amount(Played)
                  </Typography>
                  <Typography className="commonValue">
                    &nbsp;{props.x.playedAmount}
                  </Typography>
                </Box>
                <Box className="commonLabelValue">
                  <Typography className="commonLabel">
                    Total Amount(Paid)
                  </Typography>
                  <Typography className="commonValue">
                    &nbsp;{props.x.paidAmount}
                  </Typography>
                </Box>
                <Box className="commonLabelValue">
                  <Typography className="commonLabel">
                    Discount Amount
                  </Typography>
                  <Typography className="commonValue">
                    &nbsp;{props.x.discountAmount}
                  </Typography>
                </Box>
                <Box className="commonLabelValue">
                  <Typography className="commonLabel">
                    Discount Applied
                  </Typography>
                  <Typography className="commonValue">
                    {props.x.discountApplied}&nbsp;%
                  </Typography>
                </Box>
                <Box className="commonLabelValue">
                  <Typography className="commonLabel">Amount Won</Typography>
                  <Typography className="commonValue">
                    &nbsp;{props.x.amountWon}
                  </Typography>
                </Box>
                <Box className="commonLabelValue">
                  <Typography className="commonLabel">
                    Agent Commission
                  </Typography>
                  <Typography className="commonValue">
                    &nbsp;{props.x.agentCommission}
                  </Typography>
                </Box>
                <Box className="commonLabelValue">
                  <Typography className="commonLabel">
                    Final Winning Amount
                  </Typography>
                  <Typography className="commonValue">
                    &nbsp;{props.x.finalWonAmount}
                  </Typography>
                </Box>
              </Box>
            </Box>
            <Box>
              <Box>
                <Typography className="sectionTitle saperatorBorder">
                  Numbers Played
                </Typography>
              </Box>
              <Box>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={12} lg={12}>
                    <TableContainer
                      component={Paper}
                      className="numbersPlayedTable"
                    >
                      <Table
                        aria-label="Game Result Table"
                        className="commonBasicTable"
                      >
                        <TableHead>
                          <TableRow>
                            <TableCell component="th">Number</TableCell>
                            <TableCell component="th">Straight</TableCell>
                            {props.x.isRumble && (
                              <TableCell component="th">Rumble</TableCell>
                            )}
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {props.x.playedNumbers.map(
                            (row: IPlayedNumberModel) => (
                              <TableRow
                                key={row.number}
                                sx={{
                                  "&:last-child td, &:last-child th": {
                                    border: 0,
                                  },
                                }}
                              >
                                <TableCell align="center">
                                  {row.number}
                                </TableCell>
                                <TableCell align="center">
                                  {row.straight}
                                </TableCell>
                                {props.x.isRumble && (
                                  <TableCell align="center">
                                    {row.rumble}
                                  </TableCell>
                                )}
                              </TableRow>
                            )
                          )}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </CardContent>
        </Card>
      </Box>
    </Grid>
  );
};

export default LotteryTicketWonLoss;
