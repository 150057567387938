import React, { FunctionComponent, useEffect } from "react";
import PageProps from "../../models/PageProps.interface";
import { useDispatch, useSelector } from "react-redux";

import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";

//Component Import
import LeftMenu from "../../components/leftMenu/leftMenu.component";
import TopNav from "../../components/topNav/topNav.component";
import Footer from "../../components/footer/footer.component";

import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import UserService from "../../services/user/user.service";
import { getRefferals } from "../../actions/user.actions";

//Icons
import HowToRegRoundedIcon from "@mui/icons-material/HowToRegRounded";
import NoAccountsRoundedIcon from "@mui/icons-material/NoAccountsRounded";
import { IRefferals } from "../../interface/BusinessModels/IRefferalsModel";
import { AppConfig } from "../../constants/AppConfig.constant";
import { useNavigate } from "react-router-dom";
import { RoleType } from "../../constants/DBConstants.constant";
import ApplicationHelperService from "../../services/ApplicationHelperService";
import ReferralsComponent from "../../components/referrals/referrals.component";

const ReferralList: FunctionComponent<PageProps> = ({ title }) => {
  const _applicationHelperService = new ApplicationHelperService({});
  const navigate = useNavigate();
  const { userInfo } = useSelector((state: any) => state.authReducer);
  const dispatch = useDispatch();
  const _userService = new UserService(dispatch);
  const initializeRefferals = () => dispatch<any>(getRefferals());
  const { refferals } = useSelector((state: any) => state.userReducer);

  useEffect(() => {
    initializeRefferals();
  }, []);

  return (
    <Box className="pageMain">
      <Box className="pageLeft">
        <LeftMenu />
      </Box>
      <Box className="pageRight">
        <Box className="pageHead">
          <TopNav title={title.split("|")[1].trim()} />
        </Box>
        <Box className="pageView">
          {/* <Box className="pageViewHead">
                        <Typography variant="h6">Referrals List</Typography>
                    </Box> */}
          <Box className="pageViewBody commonScroll">
            <Box>
              <Grid container spacing={2}>
                {refferals && refferals.length > 0 ? (
                  refferals.map((refferal: IRefferals) => (
                    <ReferralsComponent refferal={refferal} />
                  ))
                ) : (
                  <Box className="noDataFound">
                    <Box className="noDataFoundPreview">
                      <img
                        alt={AppConfig.applicationName}
                        className="commonImage"
                        src={require("../../assets/images/refferals/noRefferal.png")}
                      />
                    </Box>
                    <Typography component="a" className="noDataFoundLabel">
                      You do not have any referrals at this time. Would you like
                      to <Link href="/">add a referral?</Link>
                    </Typography>
                  </Box>
                )}
              </Grid>
            </Box>
          </Box>
          <Box className="pageViewFooter">
            <Footer />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
export default ReferralList;
