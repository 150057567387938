import { Dispatch } from "react";
import HttpHelperService from "../httpHelper.service";
import { IUserRegistrationModel } from "../../interface/BusinessModels/IUserRegistrationModel";
import {
  CHANGE_PASSWORD,
  PROFILE_SETTINGS,
  REGISTER,
  REFFERALS_DETAILS,
  GET_ALL_USERS,
  SEARCH_USER,
  VALIDATE_SESSION,
  WALLET_BALANCE,
  REFFERALS_COUNT,
  REGISTER_AGENT_USER,
  GET_AGENT_USERS,
  DELETE_AGENT_USER,
  UPDATE_AGENT_USER,
  REGISTER_AGENT,
  DELETE_USER,
} from "../../constants/EndPoints.constant";
import { IProfileSettingsModel } from "../../interface/Request/IProfileSettingsModel";
import { IChangePasswordModel } from "../../interface/Request/IChangePasswordModel";
import { IPaginationRequest } from "../../interface/Request/IPaginationRequest";
import { IApplicationAgentRegistrationModel } from "../../interface/BusinessModels/IApplicationAgentRegistrationModel";
import { Action } from "redux";

class UserService {
  _httpHelperService;
  constructor(dispatch: Dispatch<Action>) {
    this._httpHelperService = new HttpHelperService(dispatch);
  }

  registerUser = async (registrationModel: IUserRegistrationModel) => {
    return await this._httpHelperService.post(REGISTER, registrationModel);
  };

  profileSettings = async (registrationModel: IProfileSettingsModel) => {
    return await this._httpHelperService.post(
      PROFILE_SETTINGS,
      registrationModel
    );
  };

  changePassword = async (registrationModel: IChangePasswordModel) => {
    return await this._httpHelperService.post(
      CHANGE_PASSWORD,
      registrationModel
    );
  };

  refferalDetails = async () => {
    return await this._httpHelperService.get(`${REFFERALS_DETAILS}`);
  };

  refferalCount = async () => {
    return await this._httpHelperService.get(`${REFFERALS_COUNT}`);
  };

  getAllUsers = async (allUsersRequest: IPaginationRequest) => {
    return await this._httpHelperService.post(
      `${GET_ALL_USERS}`,
      allUsersRequest
    );
  };

  searchUsers = async (type: number, text: string) => {
    return await this._httpHelperService.get(
      `${SEARCH_USER}?type=${type}&text=${text}`
    );
  };

  validateSession = async () => {
    return await this._httpHelperService.get(VALIDATE_SESSION);
  };

  walletBalance = async () => {
    return await this._httpHelperService.get(`${WALLET_BALANCE}`);
  };

  registerAgentUser = async (
    registrationModel: IApplicationAgentRegistrationModel
  ) => {
    return await this._httpHelperService.post(
      REGISTER_AGENT_USER,
      registrationModel
    );
  };

  getAgentUsers = async () => {
    return await this._httpHelperService.get(GET_AGENT_USERS);
  };

  deleteAgentUser = async (id: number) => {
    return await this._httpHelperService.delete(
      `${DELETE_AGENT_USER}?id=${id}`
    );
  };

  updateAgentUser = async (
    registrationModel: IApplicationAgentRegistrationModel
  ) => {
    return await this._httpHelperService.post(
      UPDATE_AGENT_USER,
      registrationModel
    );
  };

  registerAgent = async (
    registrationModel: IApplicationAgentRegistrationModel
  ) => {
    return await this._httpHelperService.post(
      REGISTER_AGENT,
      registrationModel
    );
  };

  deleteUser = async (appId: number) => {
    return await this._httpHelperService.delete(`${DELETE_USER}/${appId}`);
  };
}

export default UserService;
