import { Dispatch } from "react";
import HttpHelperService from "../httpHelper.service";
import {
  RECHARGE_PAYMENTS,
  GET_RECHARGE_PAYMENTS,
  GET_ALLRECHARGE_PAYMENTS,
  UPDATE_RECHARGE_PAYMENTS,
  RECHARGE_USER,
  GET_ALL_USER_TRANSACTIONS,
  UPDATE_WALLET_PAYMENTS,
} from "../../constants/EndPoints.constant";

import { IRechargePayments } from "../../interface/BusinessModels/IRechargePayments";
import { IRechargeUserModel } from "../../interface/BusinessModels/IRechargeUserModel";
import { IWithdrawalWallet } from "../../interface/Request/IWithdrawalWallet";
import { Action } from "redux";

class RechargeService {
  _httpHelperService;
  constructor(dispatch: Dispatch<Action>) {
    this._httpHelperService = new HttpHelperService(dispatch);
  }

  rechargePayments = async (rechargePayments: IRechargePayments) => {
    return await this._httpHelperService.post(
      RECHARGE_PAYMENTS,
      rechargePayments
    );
  };

  getrechargePayments = async (rechaargeId: string) => {
    return await this._httpHelperService.get(
      `${GET_RECHARGE_PAYMENTS}?rechargeId=${rechaargeId}`
    );
  };

  getAllRechargePayments = async (type: number) => {
    return await this._httpHelperService.get(
      `${GET_ALLRECHARGE_PAYMENTS}?rechargeStatusId=${type}`
    );
  };

  getAllUserTransactions = async () => {
    return await this._httpHelperService.get(`${GET_ALL_USER_TRANSACTIONS}`);
  };

  updateRechargePayments = async (rechargePayments: IRechargePayments) => {
    return await this._httpHelperService.post(
      UPDATE_RECHARGE_PAYMENTS,
      rechargePayments
    );
  };

  updateWalletPayments = async (withdrawalWallet: IWithdrawalWallet) => {
    return await this._httpHelperService.post(
      UPDATE_WALLET_PAYMENTS,
      withdrawalWallet
    );
  };

  rechargeUser = async (rechargePayments: IRechargeUserModel) => {
    return await this._httpHelperService.post(RECHARGE_USER, rechargePayments);
  };
}

export default RechargeService;
