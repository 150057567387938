import React, {
  FunctionComponent,
  useContext,
  useEffect,
  useState,
} from "react";
import PageProps from "../../models/PageProps.interface";
import Link from "@mui/material/Link";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { useNavigate } from "react-router-dom";

//Component Import
import LeftMenu from "../../components/leftMenu/leftMenu.component";
import TopNav from "../../components/topNav/topNav.component";
import Footer from "../../components/footer/footer.component";

//Modal
import Modal from "@mui/material/Modal";

//Icons
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import RechargeService from "../../services/recharge/recharge.service";
import { IApiResponse } from "../../interface/Response/IApiResponse";
import { IRechargePaymentsModel } from "../../interface/Response/IRechargePaymentsModel";
import { IWithdrawalDetailsModalTr } from "../../interface/BusinessModels/IRechargePayments";
import { IWithdrawalWallet } from "../../interface/Request/IWithdrawalWallet";

import { LoadingContext } from "../../context/loading.context";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { RechargeStatus } from "../../constants/DBConstants.constant";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import RecommendIcon from "@mui/icons-material/Recommend";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { AppConfig } from "../../constants/AppConfig.constant";
import { useDispatch } from "react-redux";

const TransactionSummary: FunctionComponent<PageProps> = ({ title }) => {
  const { setLoading } = useContext(LoadingContext);
  const [openWithDrawDetails, setOpenWithDrawDetails] = React.useState(false);
  const [
    openWithDrawWalletDetails,
    setOpenWithDrawWalletDetails,
  ] = React.useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const _rechargeService = new RechargeService(dispatch);
  const [tabValue, setTabValue] = React.useState("RECHARGE");
  const [rechargePayments, setRechargePayments] = useState<
    IWithdrawalDetailsModalTr
  >();

  const UPDATE_RECHARGE_REQUEST_INITIAL_VALUES = {
    id: 0,
    rechargeId: "",
    userId: 0,
    transactionType: "",
    transactionFields: "",
    amount: 0,
    transactionUTRId: "",
    phoneNumber: "",
    rechargeStatusId: 0,
    rechargeStatus: "",
    type: "",
    email: "",
    comment: "",
    createdOn: "",
    modifiedDate: "",
    rechargePaymentUIId: "",
  };

  const UPDATE_WALLET_REQUEST_INITIAL_VALUES = {
    id: 0,
    userId: 0,
    commissionFee: 0,
    netPaidBalance: 0,
    type: "",
    rechargeStatus: "",
    withDrawStatusId: 0,
    withdrawalDetailId: 0,
    createdBy: 0,
    createdOn: new Date(),
    modifiedOn: new Date(),
    email: "",
    withDrawId: "",
    comment: "",
    wId: 0,
    upiId: "",
    phoneNumber: "",
    accountNumber: "",
    accountHolderName: "",
    ifscCode: "",
    transactionUTRId: "",
    appId: "",
  };

  const [rechargePaymentsPopUp, setRechargePaymentsPopUp] = useState<
    IRechargePaymentsModel
  >(UPDATE_RECHARGE_REQUEST_INITIAL_VALUES);

  const [
    rechargePaymentsWalletPopUp,
    setRechargePaymentsWalletPopUp,
  ] = useState<IWithdrawalWallet>(UPDATE_WALLET_REQUEST_INITIAL_VALUES);

  useEffect(() => {
    document.title = title;
  }, []);

  useEffect(() => {
    getAllUserTransactions();

    async function getAllUserTransactions() {
      try {
        const response: IApiResponse = await _rechargeService.getAllUserTransactions();
        setRechargePayments(response.response as IWithdrawalDetailsModalTr);
      } catch (error) {}
      setLoading(false);
    }
  }, []);

  interface DataItem {
    id: number;
    type: string;
    key: string;
    value: string;
  }

  const _openPopUpDetails = (items: IRechargePaymentsModel) => {
    setRechargePaymentsPopUp({
      id: items.id,
      rechargeId: items.rechargeId,
      userId: items.userId,
      transactionType: items.transactionType,
      transactionFields: items.transactionFields,
      amount: items.amount,
      transactionUTRId: items.transactionUTRId,
      phoneNumber: items.phoneNumber,
      rechargeStatusId: items.rechargeStatusId,
      rechargeStatus: items.rechargeStatus,
      type: items.type,
      email: items.email,
      comment: items.comment,
      createdOn: items.createdOn,
      modifiedDate: items.modifiedDate,
      rechargePaymentUIId: items.rechargePaymentUIId,
    });
    setOpenWithDrawDetails(true);
  };

  function _openPopUpWalletDetails(items: IWithdrawalWallet) {
    setRechargePaymentsWalletPopUp({
      id: items.id,
      userId: items.userId,
      commissionFee: items.commissionFee,
      netPaidBalance: items.netPaidBalance,
      type: items.type,
      rechargeStatus: items.rechargeStatus,
      withDrawStatusId: items.withDrawStatusId,
      withdrawalDetailId: items.withdrawalDetailId,
      createdBy: items.createdBy,
      createdOn: items.createdOn,
      modifiedOn: items.modifiedOn,
      email: items.email,
      withDrawId: items.withDrawId,
      comment: items.comment,
      wId: items.wId,
      upiId: items.upiId,
      phoneNumber: items.phoneNumber,
      accountNumber: items.accountNumber,
      accountHolderName: items.accountHolderName,
      ifscCode: items.ifscCode,
      transactionUTRId: items.transactionUTRId,
      appId: items.appId,
    });
    setOpenWithDrawWalletDetails(true);
  }

  const getFriendlyName = (key: string): string => {
    switch (key) {
      case "BANK_ACCOUNT_NUMBER":
        return "Bank Account";

      case "UPI_ID":
        return "UPI";
      case "UPI_QR_CODE":
        return "";
      // Add more cases here for other keys if needed
      default:
        return "UPI"; // Return the original key if no match is found
    }
  };
  const extractKeys = (data: string): string[] => {
    // Deserialize the JSON data
    const deserializedData: DataItem[] = JSON.parse(data);

    // Extract the 'key' values
    return deserializedData.map((item) => getFriendlyName(item.key));
  };

  return (
    <Box className="pageMain">
      <Box className="pageLeft">
        <LeftMenu />
      </Box>
      <Box className="pageRight">
        <Box className="pageHead">
          <TopNav title={title.split("|")[1].trim()} />
        </Box>
        <Box className="pageView">
          <Box className="pageViewBody commonScroll">
            <Box className="tabSpacing">
              <TabContext value={tabValue}>
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                  <TabList
                    onChange={(event, newValue) => setTabValue(newValue)}
                  >
                    <Tab label="Recharge" value={"RECHARGE"} />
                    <Tab label="Withdraw" value={"WITHDRAW"} />
                  </TabList>
                </Box>
                <TabPanel value={"RECHARGE"}>
                  <Grid container spacing={1}>
                    {rechargePayments &&
                      rechargePayments.rechargePaymentsModel.length > 0 &&
                      rechargePayments.rechargePaymentsModel &&
                      rechargePayments.rechargePaymentsModel.map((x, index) => (
                        <Grid key={index} item xs={12} md={4} lg={4} xl={3}>
                          <Box
                            className="commonCard textDecorationNone"
                            onClick={() => _openPopUpDetails(x)}
                          >
                            <Card>
                              <CardContent>
                                <Box className="commonBgCard saperatorSpacing">
                                  <Grid container spacing={2}>
                                    <Grid item xs={10} md={10} lg={10}>
                                      <Typography className="cardTitle whiteColor">
                                        {x.rechargePaymentUIId}
                                      </Typography>
                                    </Grid>
                                    <Grid
                                      item
                                      xs={2}
                                      md={2}
                                      lg={2}
                                      className="boxRight"
                                    >
                                      {x.rechargeStatusId ===
                                      RechargeStatus.Pending ? (
                                        <Box className="verificationStatus pending">
                                          <ErrorOutlineIcon />
                                        </Box>
                                      ) : x.rechargeStatusId ===
                                        RechargeStatus.Approved ? (
                                        <Box className="verificationStatus approved">
                                          <RecommendIcon />
                                        </Box>
                                      ) : x.rechargeStatusId ===
                                        RechargeStatus.Declined ? (
                                        <Box className="verificationStatus declined">
                                          <HighlightOffIcon />
                                        </Box>
                                      ) : (
                                        <Box className="verificationStatus pending">
                                          <HighlightOffIcon />
                                        </Box>
                                      )}
                                    </Grid>
                                  </Grid>
                                </Box>
                                <Box className="commonLabelValue">
                                  <Typography className="commonLabel">
                                    Type
                                  </Typography>
                                  <Typography className="commonValue">
                                    {x.type}
                                  </Typography>
                                </Box>
                                <Box className="commonLabelValue">
                                  <Typography className="commonLabel">
                                    Amount
                                  </Typography>
                                  <Typography className="commonValue">
                                    {x.amount}
                                  </Typography>
                                </Box>
                                <Box className="commonLabelValue">
                                  <Typography className="commonLabel">
                                    Payment Mode
                                  </Typography>
                                  <Typography className="commonValue">
                                    {x.transactionFields
                                      ? extractKeys(x.transactionFields)
                                      : "UPI"}
                                  </Typography>
                                </Box>

                                <Box className="commonLabelValue">
                                  <Typography className="commonLabel">
                                    Transaction UTR
                                  </Typography>
                                  <Typography className="commonValue">
                                    {x.transactionUTRId}
                                  </Typography>
                                </Box>
                                <Box className="commonLabelValue">
                                  <Typography className="commonLabel">
                                    Status
                                  </Typography>
                                  <Typography className="commonValue">
                                    {x.rechargeStatus}
                                  </Typography>
                                </Box>
                                {x.comment && (
                                  <Box className="commonLabelValue">
                                    <Typography className="commonLabel">
                                      Comment
                                    </Typography>
                                    <Typography className="commonValue">
                                      {x.comment}
                                    </Typography>
                                  </Box>
                                )}
                                <Box className="commonLabelValue">
                                  <Typography className="commonLabel">
                                    Created On
                                  </Typography>
                                  <Typography className="commonValue">
                                    {x.createdOn}
                                  </Typography>
                                </Box>
                              </CardContent>
                            </Card>
                          </Box>
                        </Grid>
                      ))}
                  </Grid>
                </TabPanel>
                <TabPanel value={"WITHDRAW"}>
                  <Grid container spacing={1}>
                    {rechargePayments &&
                      rechargePayments.withdrawalWallets.length > 0 &&
                      rechargePayments.withdrawalWallets &&
                      rechargePayments.withdrawalWallets.map((x, index) => (
                        <Grid key={x.id} item xs={12} md={4} lg={4} xl={3}>
                          <Box
                            className="commonCard textDecorationNone"
                            onClick={() => _openPopUpWalletDetails(x)}
                          >
                            <Card>
                              <CardContent>
                                <Box className="commonBgCard saperatorSpacing">
                                  <Grid container spacing={2}>
                                    <Grid item xs={10} md={10} lg={10}>
                                      <Typography className="cardTitle whiteColor">
                                        {x.withDrawId}
                                      </Typography>
                                    </Grid>
                                    <Grid item xs={2} md={2} lg={2}>
                                      {x.withDrawStatusId ===
                                      RechargeStatus.Pending ? (
                                        <Box className="verificationStatus pending">
                                          <ErrorOutlineIcon />
                                        </Box>
                                      ) : x.withDrawStatusId ===
                                        RechargeStatus.Approved ? (
                                        <Box className="verificationStatus approved">
                                          <RecommendIcon />
                                        </Box>
                                      ) : x.withDrawStatusId ===
                                        RechargeStatus.Declined ? (
                                        <Box className="verificationStatus declined">
                                          <HighlightOffIcon />
                                        </Box>
                                      ) : (
                                        <Box className="verificationStatus pending">
                                          <HighlightOffIcon />
                                        </Box>
                                      )}
                                    </Grid>
                                  </Grid>
                                </Box>
                                <Box className="commonLabelValue">
                                  <Typography className="commonLabel">
                                    Type
                                  </Typography>
                                  <Typography className="commonValue">
                                    {x.type}
                                  </Typography>
                                </Box>
                                <Box className="commonLabelValue">
                                  <Typography className="commonLabel">
                                    Amount
                                  </Typography>
                                  <Typography className="commonValue">
                                    {x.netPaidBalance}
                                  </Typography>
                                </Box>
                                {x && x.upiId && (
                                  <Box className="commonLabelValue">
                                    <Typography className="commonLabel">
                                      Payment Mode
                                    </Typography>
                                    <Typography className="commonValue">
                                      {x.upiId}
                                    </Typography>
                                  </Box>
                                )}
                                {x && x.accountNumber && (
                                  <Box className="commonLabelValue">
                                    <Typography className="commonLabel">
                                      Account No
                                    </Typography>
                                    <Typography className="commonValue">
                                      {x.accountNumber}
                                    </Typography>
                                  </Box>
                                )}
                                <Box className="commonLabelValue">
                                  <Typography className="commonLabel">
                                    Status
                                  </Typography>
                                  <Typography className="commonValue">
                                    {x.rechargeStatus}
                                  </Typography>
                                </Box>

                                {x.comment && (
                                  <Box className="commonLabelValue">
                                    <Typography className="commonLabel">
                                      Comment
                                    </Typography>
                                    <Typography className="commonValue">
                                      {x.comment}
                                    </Typography>
                                  </Box>
                                )}

                                <Box className="commonLabelValue">
                                  <Typography className="commonLabel">
                                    Created On
                                  </Typography>
                                  <Typography className="commonValue">
                                    {x.createdOn.toString()}
                                  </Typography>
                                </Box>
                              </CardContent>
                            </Card>
                          </Box>
                        </Grid>
                      ))}
                  </Grid>
                </TabPanel>
              </TabContext>

              {rechargePayments &&
                rechargePayments.rechargePaymentsModel.length == 0 &&
                rechargePayments.withdrawalWallets.length == 0 && (
                  <Box className="noDataFound">
                    <Box className="noDataFoundPreview">
                      <img
                        alt={AppConfig.applicationName}
                        className="commonImage"
                        src={require("../../assets/images/transactionSummary/rechargeGame.png")}
                      />
                    </Box>
                    <Typography component="a" className="noDataFoundLabel">
                      You have not made any transactions yet. Please{" "}
                      <Link href="/myWalletRecharge">
                        recharge your account
                      </Link>{" "}
                      to start playing the game and enter the lottery
                    </Typography>
                  </Box>
                )}
            </Box>
          </Box>
          <Box className="pageViewFooter">
            <Footer />
          </Box>
        </Box>
        {/* Modal Popup Starts Here */}
        <Modal
          className="commonModal"
          open={openWithDrawDetails}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box className="commonModalBox">
            <Box className="commonModalBoxInner">
              <Box id="modal-modal-title" className="commonModalHead">
                <Grid container spacing={2}>
                  <Grid item xs={11} md={11} lg={11}>
                    <Typography className="modalTitle">
                      {rechargePaymentsPopUp.type} Details
                    </Typography>
                  </Grid>
                  <Grid item xs={1} md={1} lg={1}>
                    <span className="modalClose">
                      <CloseRoundedIcon
                        onClick={() => setOpenWithDrawDetails(false)}
                      />
                    </span>
                  </Grid>
                </Grid>
              </Box>
              <Box id="modal-modal-description" className="commonModalBody">
                <Box>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={12} lg={12}>
                      <Box className="commonLabelValue">
                        <Typography className="commonLabel">Type</Typography>
                        <Typography className="commonValue">
                          {rechargePaymentsPopUp.type}
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={12} md={12} lg={12}>
                      <Box className="commonLabelValue">
                        <Typography className="commonLabel">
                          Recharge to
                        </Typography>
                        <Typography className="commonValue">
                          {rechargePaymentsPopUp.rechargeId}
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={12} md={12} lg={12}>
                      <Box className="commonLabelValue">
                        <Typography className="commonLabel">
                          Recharge Amount
                        </Typography>
                        <Typography className="commonValue">
                          <span></span>
                          {rechargePaymentsPopUp.amount}
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={12} md={12} lg={12}>
                      <Box className="commonLabelValue">
                        <Typography className="commonLabel">
                          Processed Amount
                        </Typography>
                        <Typography className="commonValue">
                          <span></span>
                          {rechargePaymentsPopUp.amount}
                        </Typography>
                      </Box>
                    </Grid>
                    {/* <Grid item xs={12} md={12} lg={12}>
                      <Box className="commonLabelValue">
                        <Typography className="commonLabel">
                          Account Number
                        </Typography>
                        <Typography className="commonValue">
                          {rechargePaymentsPopUp.transactionFields}
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={12} md={12} lg={12}>
                      <Box className="commonLabelValue">
                        <Typography className="commonLabel">
                          Account Holder Name
                        </Typography>
                        <Typography className="commonValue">
                          {rechargePaymentsPopUp.transactionFields}
                        </Typography>
                      </Box>
                    </Grid> */}
                    <Grid item xs={12} md={12} lg={12}>
                      <Box className="commonLabelValue">
                        <Typography className="commonLabel">
                          Created Date Time
                        </Typography>
                        <Typography className="commonValue">
                          {rechargePaymentsPopUp.createdOn}
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={12} md={12} lg={12}>
                      <Box className="commonLabelValue">
                        <Typography className="commonLabel">
                          Updated Date Time
                        </Typography>
                        <Typography className="commonValue">
                          {rechargePaymentsPopUp.modifiedDate}
                        </Typography>
                      </Box>
                    </Grid>
                    {/* <Grid item xs={12} md={12} lg={12}>
                      <Box className="commonLabelValue">
                        <Typography className="commonLabel">
                          Approved Date Time
                        </Typography>
                        <Typography className="commonValue">
                          {rechargePaymentsPopUp.modifiedDate}
                        </Typography>
                      </Box>
                    </Grid> */}
                    <Grid item xs={12} md={12} lg={12}>
                      <Box className="commonLabelValue">
                        <Typography className="commonLabel">
                          Reference Number/Txn ID/UTR
                        </Typography>
                        <Typography className="commonValue">
                          {" "}
                          {rechargePaymentsPopUp.rechargePaymentUIId}
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={12} md={12} lg={12}>
                      <Box className="commonLabelValue">
                        <Typography className="commonLabel">
                          Recharge Status
                        </Typography>
                        <Typography className="commonValue">
                          {rechargePaymentsPopUp.rechargeStatus}
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={12} md={12} lg={12}>
                      <Box className="commonLabelValue">
                        <Typography className="commonLabel">
                          Comments
                        </Typography>
                        <Typography className="commonValue">
                          {rechargePaymentsPopUp.comment}
                        </Typography>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              </Box>
              <Box id="modal-modal-footer" className="commonFooter"></Box>
            </Box>
          </Box>
        </Modal>
        {/* Modal Popup Ends Here */}

        {/* Modal Popup Starts Wallet Here */}
        <Modal
          className="commonModal"
          open={openWithDrawWalletDetails}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box className="commonModalBox">
            <Box className="commonModalBoxInner">
              <Box id="modal-modal-title" className="commonModalHead">
                <Grid container spacing={2}>
                  <Grid item xs={11} md={11} lg={11}>
                    <Typography className="modalTitle">
                      {rechargePaymentsWalletPopUp.type} Details
                    </Typography>
                  </Grid>
                  <Grid item xs={1} md={1} lg={1}>
                    <span className="modalClose">
                      <CloseRoundedIcon
                        onClick={() => setOpenWithDrawWalletDetails(false)}
                      />
                    </span>
                  </Grid>
                </Grid>
              </Box>
              <Box id="modal-modal-description" className="commonModalBody">
                <Box>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={12} lg={12}>
                      <Box className="commonLabelValue">
                        <Typography className="commonLabel">Type</Typography>
                        <Typography className="commonValue">
                          {rechargePaymentsWalletPopUp.type}
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={12} md={12} lg={12}>
                      <Box className="commonLabelValue">
                        <Typography className="commonLabel">
                          Withdraw to
                        </Typography>
                        <Typography className="commonValue">
                          {rechargePaymentsWalletPopUp.withDrawId}
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={12} md={12} lg={12}>
                      <Box className="commonLabelValue">
                        <Typography className="commonLabel">
                          withdraw Amount
                        </Typography>
                        <Typography className="commonValue">
                          <span></span>
                          {rechargePaymentsWalletPopUp.netPaidBalance +
                            rechargePaymentsWalletPopUp.commissionFee}
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={12} md={12} lg={12}>
                      <Box className="commonLabelValue">
                        <Typography className="commonLabel">
                          Processed Amount
                        </Typography>
                        <Typography className="commonValue">
                          <span></span>
                          {rechargePaymentsWalletPopUp.netPaidBalance}
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={12} md={12} lg={12}>
                      <Box className="commonLabelValue">
                        <Typography className="commonLabel">UTR ID</Typography>
                        <Typography className="commonValue">
                          {rechargePaymentsWalletPopUp.transactionUTRId}
                        </Typography>
                      </Box>
                    </Grid>
                    {rechargePaymentsWalletPopUp &&
                      rechargePaymentsWalletPopUp.upiId && (
                        <Grid item xs={12} md={12} lg={12}>
                          <Box className="commonLabelValue">
                            <Typography className="commonLabel">
                              Payment Mode
                            </Typography>
                            <Typography className="commonValue">
                              {rechargePaymentsWalletPopUp.upiId}
                            </Typography>
                          </Box>
                        </Grid>
                      )}
                    {rechargePaymentsWalletPopUp &&
                      rechargePaymentsWalletPopUp.accountNumber && (
                        <Grid item xs={12} md={12} lg={12}>
                          <Box className="commonLabelValue">
                            <Typography className="commonLabel">
                              Account Number
                            </Typography>
                            <Typography className="commonValue">
                              {rechargePaymentsWalletPopUp.accountNumber}
                            </Typography>
                          </Box>
                        </Grid>
                      )}
                    {rechargePaymentsWalletPopUp &&
                      rechargePaymentsWalletPopUp.accountHolderName && (
                        <Grid item xs={12} md={12} lg={12}>
                          <Box className="commonLabelValue">
                            <Typography className="commonLabel">
                              Account HolderName
                            </Typography>
                            <Typography className="commonValue">
                              {rechargePaymentsWalletPopUp.accountHolderName}
                            </Typography>
                          </Box>
                        </Grid>
                      )}
                    {rechargePaymentsWalletPopUp &&
                      rechargePaymentsWalletPopUp.ifscCode && (
                        <Grid item xs={12} md={12} lg={12}>
                          <Box className="commonLabelValue">
                            <Typography className="commonLabel">
                              IfscCode
                            </Typography>
                            <Typography className="commonValue">
                              {rechargePaymentsWalletPopUp.ifscCode}
                            </Typography>
                          </Box>
                        </Grid>
                      )}
                    <Grid item xs={12} md={12} lg={12}>
                      <Box className="commonLabelValue">
                        <Typography className="commonLabel">
                          Created Date Time
                        </Typography>
                        <Typography className="commonValue">
                          {rechargePaymentsWalletPopUp.createdOn.toString()}
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={12} md={12} lg={12}>
                      <Box className="commonLabelValue">
                        <Typography className="commonLabel">
                          Updated Date Time
                        </Typography>
                        <Typography className="commonValue">
                          {rechargePaymentsWalletPopUp &&
                          rechargePaymentsWalletPopUp.modifiedOn != null
                            ? rechargePaymentsWalletPopUp.modifiedOn.toString()
                            : ""}
                        </Typography>
                      </Box>
                    </Grid>
                    {/* <Grid item xs={12} md={12} lg={12}>
                      <Box className="commonLabelValue">
                        <Typography className="commonLabel">
                          Approved Date Time
                        </Typography>
                        <Typography className="commonValue">
                          {rechargePaymentsWalletPopUp &&
                          rechargePaymentsWalletPopUp.modifiedOn != null
                            ? rechargePaymentsWalletPopUp.modifiedOn.toString()
                            : ""}
                        </Typography>
                      </Box>
                    </Grid> */}

                    <Grid item xs={12} md={12} lg={12}>
                      <Box className="commonLabelValue">
                        <Typography className="commonLabel">
                          WithDraw Status
                        </Typography>
                        <Typography className="commonValue">
                          {rechargePaymentsWalletPopUp.rechargeStatus}
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={12} md={12} lg={12}>
                      <Box className="commonLabelValue">
                        <Typography className="commonLabel">
                          Comments
                        </Typography>
                        <Typography className="commonValue">
                          {rechargePaymentsWalletPopUp.comment}
                        </Typography>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              </Box>
              <Box id="modal-modal-footer" className="commonFooter"></Box>
            </Box>
          </Box>
        </Modal>
        {/* Modal Popup Ends Wallet Here */}
      </Box>
    </Box>
  );
};
export default TransactionSummary;
