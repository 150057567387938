import {
    REFFERALS_SUCCESS,
    
  } from "../constants/Reducer.constant";
  
  import { IRefferals } from "../interface/BusinessModels/IRefferalsModel";
  
  interface UserUpDataState {
    refferals: IRefferals[];
    
  }
  
  const initialState: UserUpDataState = {
    refferals: []
    
  };
  
  const staticDataReducer = (prevState = initialState, action: any) => {
    switch (action.type) {
      case REFFERALS_SUCCESS:
        return { ...prevState, refferals: action.payload };
      default:
        return prevState ;
    }
  };
  
  export default staticDataReducer;
  