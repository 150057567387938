import React, {
  FunctionComponent,
  useEffect,
  useContext,
  useState,
} from "react";
import PageProps from "../../models/PageProps.interface";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import {
  API_ERROR_STANDARD_MESSAGE,
  APPLICATION_SETTINGS,
  DEFAULT_ALERT_CONFIG,
  RechargeStatus,
} from "../../constants/DBConstants.constant";
import { HttpStatusCode } from "axios";

//Form
import TextField from "@mui/material/TextField";
//Gadgets Import
//Component Import
import LeftMenu from "../../components/leftMenu/leftMenu.component";
import TopNav from "../../components/topNav/topNav.component";
import Footer from "../../components/footer/footer.component";
import WithdrawalService from "../../services/withdrawal/withdrawal.service";
//Css
import "../myWalletWithdraw/myWalletWithdraw.screen.style.css";
import { IApiResponse } from "../../interface/Response/IApiResponse";
import UserService from "../../services/user/user.service";
import { ToastSeverity } from "../../constants/toastSeverity.contants";
import { ToastContext } from "../../context/toast.context";
import "../myBankCards/myBankCards.screen.style.css";
import { IWithdrawalModel } from "../../interface/BusinessModels/IWithdrawalModel";
import { IAlertDialogConfig } from "../../interface/IAlertDialogConfig";
import { LoadingContext } from "../../context/loading.context";
import RechargeService from "../../services/recharge/recharge.service";
import AlertDialog from "../../components/alertDialog/alertDialog.component";
import { useNavigate } from "react-router-dom";
import { IWithdrawalWallet } from "../../interface/Request/IWithdrawalWallet";
import LookupService from "../../services/lookup/lookup.service";
import { ILookupModel } from "../../interface/BusinessModels/ILookupModel";
import AgentUsers from "../../components/agentUsers/agentUsers.component";
import { getWalletBalance } from "../../actions/lookup.actions";
import { useDispatch, useSelector } from "react-redux";
import { AppConfig } from "../../constants/AppConfig.constant";
import { Link } from "@mui/material";

const MyWalletWithdraw: FunctionComponent<PageProps> = ({ title }) => {
  const { userInfo } = useSelector((state: any) => state.authReducer);
  useEffect(() => {
    document.title = title;
  }, []);
  const dispatch = useDispatch();
  const getUserWalletBalance = () => dispatch<any>(getWalletBalance());
  const { walletBalance } = useSelector((state: any) => state.lookupReducer);
  const _withdrawalService = new WithdrawalService(dispatch);
  const { setToastConfig } = useContext(ToastContext);

  const _userService = new UserService(dispatch);
  const { setLoading } = useContext(LoadingContext);

  type SavedAccountPageModel = {
    upi: IWithdrawalModel[];
    bankAccount: IWithdrawalModel[];
  };
  const navigate = useNavigate();
  const _rechargePaymentsService = new RechargeService(dispatch);
  const _lookupService = new LookupService(dispatch);
  const [withdrawalAmount, setwithdrawalAmount] = useState("0");
  const [withdrawAmount, setWithdrawAmount] = useState("0"); // Assume initial balance is 100 for this example
  const [errorMessage, setErrorMessage] = useState("");
  const [finalAmount, setFinalAmount] = useState(0);
  const [CommissionFee, setCommissionFee] = useState(0);
  const [minWithdrawalAmount, setMinWithdrawalAmount] = useState<number>(0);
  const [maxWithdrawalAmount, setMaxWithdrawalAmount] = useState<number>(0);
  const [transactionPercentage, setTransactionPercentage] = useState<number>(0);
  const [walletPayment, setWalletWithdrawalPayment] = useState<
    IWithdrawalWallet
  >({
    id: 0,
    userId: 0,
    commissionFee: 0,
    netPaidBalance: 0,
    type: "Withdrawal",
    rechargeStatus: "",
    withDrawStatusId: RechargeStatus.Pending,
    withdrawalDetailId: 0,
    createdBy: 0,
    email: "",
    createdOn: new Date(),
    modifiedOn: new Date(),
    withDrawId: "",
    comment: "",
    wId: 0,
    upiId: "",
    phoneNumber: "",
    accountNumber: "",
    accountHolderName: "",
    ifscCode: "",
    transactionUTRId: "",
    appId: "",
  });
  const [alertConfig, setAlertConfig] = useState<IAlertDialogConfig>({
    isShow: false,
    callBack: () => undefined,
  });
  const [savedAccounts, setSavedAccounts] = useState<SavedAccountPageModel>();
  const [isNotValidWithDrawal, setIsNotValidWithDrawal] = useState<boolean>(
    true
  );

  const [showAgentUsers, setshowAgentUsers] = useState<boolean>(false);

  useEffect(() => {
    loadPageData();
    async function loadPageData() {
      await loadSavedCards();
    }
  }, []);
  useEffect(() => {
    getUserWalletBalance();
    getGamesSettings();

    async function getGamesSettings() {
      try {
        setLoading(true);
        const gameGeneralSettings: IApiResponse = await _lookupService.getGameSettings(
          APPLICATION_SETTINGS,
          0
        );
        const minamount = gameGeneralSettings.response.filter(
          (x: ILookupModel) => x.key === "MINIMUM_WITHDRAW"
        )[0].value;
        if (minamount !== "") {
          setMinWithdrawalAmount(minamount);
        }

        const maxamount = gameGeneralSettings.response.filter(
          (x: ILookupModel) => x.key === "MAXIMUM_WITHDRAW"
        )[0].value;
        if (maxamount !== "") {
          setMaxWithdrawalAmount(maxamount);
        }

        const transactionFees = gameGeneralSettings.response.filter(
          (x: ILookupModel) => x.key === "TRANSACTION_FEE"
        )[0].value;
        if (transactionFees !== "") {
          setTransactionPercentage(transactionFees);
        }
      } catch (error) {
        if (
          error &&
          (error as any).response &&
          (error as any).response.status != HttpStatusCode.Unauthorized
        ) {
          setToastConfig(ToastSeverity.Error, API_ERROR_STANDARD_MESSAGE, true);
        }
      }
    }
  }, []);

  const handleWithdrawAmountChange = (event: { target: { value: string } }) => {
    let value = parseFloat(event.target.value);
    if (Number.isNaN(value)) {
      value = 0;
      setWithdrawAmount("0");
    } else {
      setWithdrawAmount(value.toString());
    }

    // Calculate the final amount after deducting the transaction fee (4.99%)
    const transactionFee = (value * transactionPercentage) / 100;
    const amountAfterFee = value - transactionFee;
    setCommissionFee(transactionFee);
    // Update the final amount
    setFinalAmount(amountAfterFee);

    if (walletBalance.balance < value) {
      setErrorMessage("Insufficient funds");
      setIsNotValidWithDrawal(true);
    } else if (value < minWithdrawalAmount) {
      setErrorMessage(`Minimum withdrawal amount is ${minWithdrawalAmount}`);
      setIsNotValidWithDrawal(true);
    } else if (value > maxWithdrawalAmount) {
      setErrorMessage(`Maximum withdrawal amount is ${maxWithdrawalAmount}`);
      setIsNotValidWithDrawal(true);
    } else {
      setErrorMessage("");
      setIsNotValidWithDrawal(false);
    }
    const upiPrimaryDetails =
      savedAccounts &&
      savedAccounts.upi &&
      savedAccounts.upi
        .filter((item: IWithdrawalModel) => item.isPrimary === true)
        .map((item: IWithdrawalModel) => ({
          // Here you can return the data for UPI
          // For example, you might return an object containing relevant details
          // Replace these placeholders with actual data fields from your IWithdrawalModel
          id: item.id,
          upiId: item.upiId,
          upiName: item.accountHolderName,
          upiType: item.accountType,
          phone: item.phoneNumber,
          // Add more fields as needed
        }));

    const bankPrimaryDetails =
      savedAccounts &&
      savedAccounts.bankAccount &&
      savedAccounts.bankAccount
        .filter((item: IWithdrawalModel) => item.isPrimary === true)
        .map((item: IWithdrawalModel) => ({
          // Here you can return the data for bank account
          // Similar to UPI, replace placeholders with actual data fields
          id: item.id,
          accountId: item.upiId,
          accountName: item.accountHolderName,
          bankName: item.accountType,
          phone: item.phoneNumber,
          // Add more fields as needed
        }));

    let phoneVal = "";
    let transactionTypeval = 0;
    if (
      bankPrimaryDetails &&
      bankPrimaryDetails.length > 0 &&
      bankPrimaryDetails[0].phone != null
    ) {
      phoneVal = bankPrimaryDetails[0].phone;
      transactionTypeval = bankPrimaryDetails[0].id;
    } else if (
      upiPrimaryDetails &&
      upiPrimaryDetails.length > 0 &&
      upiPrimaryDetails[0].phone != null
    ) {
      phoneVal = upiPrimaryDetails[0].phone;
      transactionTypeval = upiPrimaryDetails[0].id;
    }
    setWalletWithdrawalPayment((prevState) => ({
      ...prevState,
      userId: 0,
      commissionFee: transactionFee,
      netPaidBalance: amountAfterFee,
      type: "Withdrawal",
      withDrawStatusId: RechargeStatus.Pending,
      withdrawalDetailId: transactionTypeval,
      createdBy: 0,
    }));
  };

  const loadSavedCards = async () => {
    try {
      setLoading(true);
      var response: IApiResponse = await _withdrawalService.withdrawalDetails();
      if (response.isSuccess) {
        const responseData = response.response as SavedAccountPageModel;
        setSavedAccounts(responseData);
      }
    } catch (error) {
      if (
        error &&
        (error as any).response &&
        (error as any).response.status != HttpStatusCode.Unauthorized
      ) {
        setToastConfig(ToastSeverity.Error, API_ERROR_STANDARD_MESSAGE, true);
      }
    }
    setLoading(false);
  };

  const withdrawal = async (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    setLoading(true);
    try {
      event.preventDefault();
      if (walletPayment.withdrawalDetailId > 0) {
        var response: IApiResponse = await _withdrawalService.withdrawalWalletDetails(
          walletPayment
        );
        if (response.response === null && !response.isSuccess) {
          setToastConfig(ToastSeverity.Error, response.message, true);
        } else if (+response.response.status <= 0) {
          setToastConfig(ToastSeverity.Error, response.response.message, true);
        } else {
          setAlertConfig({
            description: response.response.message,
            toastSeverity: ToastSeverity.Success,
            isShow: true,
            notifyWhatsapp: true,
            whatsappMessageText: `I have submitted a withdrawal request for ${walletPayment.netPaidBalance}, and my AppId is ${userInfo.user.appId}.`,
            callBack: () => {
              getUserWalletBalance();
              setAlertConfig(DEFAULT_ALERT_CONFIG);
              setshowAgentUsers(true);
              setWithdrawAmount("");
            },
          });
        }
      } else {
        setAlertConfig({
          description: "Add Account details & proceed with withdrawal request.",
          toastSeverity: ToastSeverity.Error,
          isShow: true,
          callBack: () => {
            getUserWalletBalance();
            setAlertConfig(DEFAULT_ALERT_CONFIG);
            setWithdrawAmount("");
          },
        });
      }
    } catch (error) {
      if (
        error &&
        (error as any).response &&
        (error as any).response.status != HttpStatusCode.Unauthorized
      ) {
        setToastConfig(ToastSeverity.Error, API_ERROR_STANDARD_MESSAGE, true);
      }
      console.log(error);
    }
    setLoading(false);
  };

  return (
    <Box className="pageMain">
      <Box className="pageLeft">
        <LeftMenu />
      </Box>
      <Box className="pageRight">
        <Box className="pageHead">
          <TopNav title={title.split("|")[1].trim()} />
        </Box>
        <Box className="pageView">
          {/* <Box className="pageViewHead">
                        <Typography variant="h6">My Wallet</Typography>
                    </Box> */}
          <Box className="pageViewBody commonScroll">
            <Box className="commonBgCard saperatorSpacing">
              <Grid container spacing={2}>
                <Grid item xs={12} md={12} lg={12} className="walletBox">
                  <Typography className="amountTitle whiteColor">
                    Available Balance
                  </Typography>
                  <Box>
                    <Typography className="amount whiteColor">
                      {walletBalance.balance} INR
                    </Typography>
                  </Box>
                </Grid>
                {/* <Grid item xs={12} md={6} lg={6}>
                  <Box className="bannerImage">
                    <img
                      alt={`${AppConfig.applicationName} - Wallet`}
                      className=""
                      src={require("../../assets/images/myWallet/walletAmount.png")}
                    />
                  </Box>
                </Grid> */}
              </Grid>
            </Box>
            <Box className="withDrawSection">
              <Grid container spacing={2}>
                <Grid item xs={12} md={6} lg={4} xl={5}>
                  <Box>
                    <Grid container spacing={1} className="">
                      {savedAccounts &&
                        savedAccounts.upi &&
                        savedAccounts.upi.length > 0 &&
                        savedAccounts.upi.map(
                          (item: IWithdrawalModel) =>
                            item.isPrimary == true && (
                              <Grid
                                key={item.id}
                                item
                                xs={12}
                                md={4}
                                lg={4}
                                xl={3}
                                className="bankCardHeight"
                              >
                                <Box className="card">
                                  <Box className="card-inner">
                                    <Box className="front">
                                      <Box className="badgeContent">
                                        Primary
                                      </Box>
                                      <Box>
                                        <img
                                          alt={AppConfig.applicationName}
                                          className="commonImage map-img"
                                          src={require("../../assets/images/myBankCards/map.png")}
                                        />
                                        <Box className="row">
                                          <img
                                            alt={AppConfig.applicationName}
                                            className="commonImage Chip"
                                            style={{ width: "30px" }}
                                            src={require("../../assets/images/myBankCards/chip.png")}
                                          />
                                          <img
                                            alt={AppConfig.applicationName}
                                            className="commonImage Chip"
                                            style={{ width: "30px" }}
                                            src={require("../../assets/images/myBankCards/upi.png")}
                                          />
                                        </Box>
                                        <Box className="card-no">
                                          <Typography className="cardValue">
                                            {item.upiId}
                                          </Typography>
                                        </Box>
                                        <Box className="">
                                          <Typography className="cardLabel card-holder">
                                            Phone Number
                                          </Typography>
                                          <Typography className="cardValue name">
                                            {item.phoneNumber}
                                          </Typography>
                                        </Box>
                                      </Box>
                                    </Box>
                                  </Box>
                                </Box>
                              </Grid>
                            )
                        )}
                    </Grid>
                    <Grid container spacing={1}>
                      {savedAccounts &&
                        savedAccounts.bankAccount &&
                        savedAccounts.bankAccount.length > 0 &&
                        savedAccounts.bankAccount.map(
                          (item: IWithdrawalModel) =>
                            item.isPrimary == true && (
                              <Grid
                                key={item.id}
                                item
                                xs={12}
                                md={4}
                                lg={4}
                                xl={3}
                                className="bankCardHeight"
                              >
                                <Box className="card">
                                  <Box className="card-inner">
                                    <Box className="front">
                                      <Box className="badgeContent">
                                        Primary
                                      </Box>
                                      <Box>
                                        <img
                                          alt={AppConfig.applicationName}
                                          className="commonImage map-img"
                                          src={require("../../assets/images/myBankCards/map.png")}
                                        />
                                        <Box className="row">
                                          <img
                                            alt={AppConfig.applicationName}
                                            className="commonImage Chip"
                                            style={{ width: "30px" }}
                                            src={require("../../assets/images/myBankCards/chip.png")}
                                          />
                                          <img
                                            alt={AppConfig.applicationName}
                                            className="commonImage bankLogo"
                                            src={require("../../assets/images/myBankCards/bank.png")}
                                          />
                                        </Box>
                                        <Box className="row card-no">
                                          <Typography className="cardValue">
                                            {item.accountNumber}
                                          </Typography>
                                        </Box>
                                        <Box className="row">
                                          <Box>
                                            <Typography className="cardLabel card-holder">
                                              Account Holder Name
                                            </Typography>
                                            <Typography className="cardValue name">
                                              {item.accountHolderName}
                                            </Typography>
                                          </Box>
                                          <Box className="textRight">
                                            <Typography className="cardLabel card-holder">
                                              IFSC Code
                                            </Typography>
                                            <Typography className="cardValue name">
                                              {item.ifscCode}
                                            </Typography>
                                          </Box>
                                        </Box>
                                      </Box>
                                    </Box>
                                  </Box>
                                </Box>
                              </Grid>
                            )
                        )}
                    </Grid>
                  </Box>
                  {savedAccounts &&
                    savedAccounts.bankAccount.length == 0 &&
                    savedAccounts.upi.length == 0 && (
                      <Box className="noDataFound">
                        <Box className="noDataFoundPreview">
                          <img
                            alt={`${AppConfig.applicationName} - No Referrals`}
                            className="commonImage"
                            src={require("../../assets/images/myBankCards/noCards.png")}
                          />
                        </Box>
                        <Typography component="a" className="noDataFoundLabel">
                          You currently have no account to withdraw.{" "}
                          <Link href="/myBankCards">Click</Link> and add account
                          to continue withdrawal.
                        </Typography>
                      </Box>
                    )}
                </Grid>

                {savedAccounts &&
                  (savedAccounts.bankAccount.length > 0 ||
                    savedAccounts.upi.length > 0) && (
                    <Grid
                      item
                      xs={12}
                      md={12}
                      lg={12}
                      xl={12}
                      className="highResolutionResponsiveCenter"
                    >
                      <Box>
                        <TextField
                          id="withdrawAmount"
                          label="Withdraw Amount"
                          variant="outlined"
                          fullWidth
                          value={withdrawAmount === "0" ? "" : withdrawAmount} // Bind the value to the state
                          onChange={handleWithdrawAmountChange} // Attach the onChange handler
                          error={!!errorMessage} // Show error style if there's an error message
                          helperText={errorMessage} // Display the error message as helper text
                        />
                        <Typography
                          variant="body1"
                          className="boxTopSpacing"
                          style={{ color: "red", fontWeight: "bold" }}
                        >
                          Note: Transaction fee ({transactionPercentage}%) is{" "}
                          {CommissionFee.toFixed(2)}. You will get{" "}
                          {parseInt(finalAmount.toFixed(2))}.
                        </Typography>
                      </Box>
                      <Box className="boxTopSpacing">
                        <Button
                          variant="contained"
                          className="width100 primaryFillBtn"
                          onClick={withdrawal}
                          disabled={isNotValidWithDrawal}
                        >
                          <span>Submit</span>
                        </Button>
                      </Box>
                    </Grid>
                  )}
              </Grid>

              {savedAccounts &&
                (savedAccounts.bankAccount.length > 0 ||
                  savedAccounts.upi.length > 0) && (
                  <Grid container spacing={2} style={{ marginTop: 0 }}>
                    <Grid item xs={12} md={12} lg={12}>
                      <Box className="textCenter">
                        <Typography component="a">
                          Would you like to use a{" "}
                          <Link href="/myBankCards">different card?</Link>
                        </Typography>
                      </Box>
                    </Grid>
                  </Grid>
                )}

              {showAgentUsers && (
                <Grid container spacing={2}>
                  <Grid item xs={12} md={12} lg={12}>
                    <Box>
                      <Typography>
                        Contact below Agents to process your withdrawal
                      </Typography>
                      <AgentUsers />
                    </Box>
                  </Grid>
                </Grid>
              )}
            </Box>
          </Box>
          {alertConfig && alertConfig.isShow && (
            <AlertDialog
              alertConfig={alertConfig}
              callBack={alertConfig.callBack}
            />
          )}
          <Box className="pageViewFooter">
            <Footer />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
export default MyWalletWithdraw;
