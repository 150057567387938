import { Component, Dispatch } from "react";
import HttpHelperService from "../httpHelper.service";
import { IOffersModel } from "../../interface/Request/IOffersModel";
import {
  DELETE_OFFER,
  GET_OFFERS,
  OFFERS_PAGE,
} from "../../constants/EndPoints.constant";
import { Action } from "redux";

class OfferService {
  _httpHelperService;
  constructor(dispatch: Dispatch<Action>) {
    this._httpHelperService = new HttpHelperService(dispatch);
  }

  saveOffer = async (offersModel: FormData) => {
    return await this._httpHelperService.postFormData(OFFERS_PAGE, offersModel);
  };

  getOffers = async () => {
    return await this._httpHelperService.get(GET_OFFERS);
  };

  deleteOffer = async (id: number) => {
    return await this._httpHelperService.delete(`${DELETE_OFFER}?id=${id}`);
  };
}

export default OfferService;
