import React, {
  FunctionComponent,
  useContext,
  useEffect,
  useState,
} from "react";
import PageProps from "../../models/PageProps.interface";
import { useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import { GridColDef } from "@mui/x-data-grid";
import Box from "@mui/material/Box";

//Component Import
import LeftMenu from "../../components/leftMenu/leftMenu.component";
import TopNav from "../../components/topNav/topNav.component";
import Footer from "../../components/footer/footer.component";

//Css
import "../myWallet/myWallet.screen.style.css";
import { ITransactionsRequest } from "../../interface/Request/ITransactionsRequest";
import TransactionService from "../../services/transaction/transaction.service";
import { ITransactionsResponse } from "../../interface/Response/ITransactionsResponse";
import { ToastContext } from "../../context/toast.context";

//Icons Import
import { ToastSeverity } from "../../constants/toastSeverity.contants";
import { API_ERROR_STANDARD_MESSAGE } from "../../constants/DBConstants.constant";
import { HttpStatusCode } from "axios";

import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TablePagination from "@mui/material/TablePagination";
import { UUID } from "crypto";
import { useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Grid } from "@mui/material";

import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import UserService from "../../services/user/user.service";
import { IRefferals } from "../../interface/BusinessModels/IRefferalsModel";
import ReferralsComponent from "../../components/referrals/referrals.component";
import UserCard from "../../components/userCard/userCard.component";
import PlayGameService from "../../services/playGame/playGame.service";
import { IGameLotteryTicketsWonLoss } from "../../interface/BusinessModels/ILotterySummary";
import { IApiResponse } from "../../interface/Response/IApiResponse";
import LotteryTicketWonLoss from "../../components/lotteryTicketWonLoss/lotteryTicketWonLoss.component";
import { LoadingContext } from "../../context/loading.context";

const columns: GridColDef[] = [
  { field: "description", headerName: "Description", width: 60 },
  { field: "createdOn", headerName: "Created On", width: 25 },
  { field: "amount", headerName: "Amount", width: 15 },
];

const UserInformation: FunctionComponent<PageProps> = ({ title }) => {
  const { setLoading, isWebView } = useContext(LoadingContext);
  const [value, setValue] = React.useState<string>("1");
  const [refferals, setRefferals] = useState<IRefferals[]>();
  const handleTabsChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };
  const dispatch = useDispatch();
  const location = useLocation();
  const _transactionService = new TransactionService(dispatch);
  const _userService = new UserService(dispatch);
  const _playGameService = new PlayGameService(dispatch);
  const [lotteryHistory, setLotteryHistory] = useState<
    IGameLotteryTicketsWonLoss[]
  >([]);
  const [transactionRequest, setTransactionRequest] = useState<
    ITransactionsRequest
  >({
    transactionType: "",
    PageNumber: 1,
    PageSize: 10,
  });
  const [transactionResponse, setTransactionResponse] = useState<
    ITransactionsResponse
  >({
    transactions: [],
    pagination: {
      totalPages: 0,
      totalRecords: 0,
      pageNumber: 0,
      pageSize: 0,
    },
  });
  const { setToastConfig, setOpen } = useContext(ToastContext);
  const [userId] = useState<UUID>(location.state as UUID);
  useEffect(() => {
    document.title = title;
  }, []);

  useEffect(() => {
    loadTabsData();
  }, [userId]);

  const loadTabsData = async () => {
    setLoading(true);
    const refs: any = await _userService.getReferrals(userId);
    setRefferals(refs.response as IRefferals[]);

    const playHistory: IApiResponse = await _playGameService.getUserLotteryTickets(
      userId
    );

    setLotteryHistory(playHistory.response as IGameLotteryTicketsWonLoss[]);
    setLoading(false);
  };

  const getTransaction = async () => {
    setLoading(true);
    try {
      if (userId) {
        const response = await _transactionService.userTransactionHistory(
          userId,
          transactionRequest
        );
        setTransactionResponse(response.response as ITransactionsResponse);
      }
    } catch (error) {
      if (
        error &&
        (error as any).response &&
        (error as any).response.status != HttpStatusCode.Unauthorized
      ) {
        setToastConfig(ToastSeverity.Error, API_ERROR_STANDARD_MESSAGE, true);
      }
    }
    setLoading(false);
  };

  useEffect(() => {
    getTransaction();
  }, [transactionRequest, userId]);

  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setTransactionRequest({
      ...transactionRequest,
      PageNumber: value,
    });
  };

  const formatDescription = (description: string) => {
    // Check if the description contains JSON
    const jsonPattern = /\[.*?\]/; // Matches JSON array inside the description
    const match = description.match(jsonPattern);
    if (match) {
      try {
        // Parse the JSON
        const jsonData = JSON.parse(match[0]);
        // Format the parsed data
        return (
          <ul>
            {jsonData
              .filter((item: { key: string }) => item.key !== "UPI_QR_CODE") // Exclude the UPI_QR_CODE key
              .map(
                (
                  item: {
                    key:
                      | string
                      | number
                      | boolean
                      | React.ReactElement<
                          any,
                          string | React.JSXElementConstructor<any>
                        >
                      | React.ReactFragment
                      | React.ReactPortal
                      | null
                      | undefined;
                    value: any;
                  },
                  index: React.Key | null | undefined
                ) => (
                  <p key={index}>
                    {index === 0 ? "Recharge - " : ""}
                    {item.key}: {item.value || "N/A"}
                  </p>
                )
              )}
          </ul>
        );
      } catch (error) {
        console.error("Error parsing JSON:", error);
      }
    }
    return description; // Return the original description if no JSON found
  };

  function Row(props: { row: any; rowNumber: number }) {
    const { row, rowNumber } = props;
    return (
      <React.Fragment>
        <TableRow key={rowNumber} sx={{ "& > *": { borderBottom: "unset" } }}>
          <TableCell key={0} data-label="S.No">
            {rowNumber}
          </TableCell>
          <TableCell key={1} data-label="Description">
            {formatDescription(row.description)}
          </TableCell>
          <TableCell key={2} data-label="Created On">
            {row.createdOn}
          </TableCell>
          <TableCell key={3} data-label="Amount">
            {row.amount}
          </TableCell>
        </TableRow>
      </React.Fragment>
    );
  }

  return (
    <Box className="pageMain myWalletPage">
      <Box className="pageLeft">
        <LeftMenu />
      </Box>
      <Box className="pageRight">
        <Box className="pageHead">
          <TopNav title={title.split("|")[1].trim()} />
        </Box>

        <Box className="pageView">
          <Box className="pageViewBody commonScroll">
            <Box className="commonBgCard saperatorSpacing">
              <Grid container spacing={2}>
                <Grid item xs={12} md={12} lg={12} className="walletBox">
                  <Typography className="amountTitle whiteColor">
                    Available Balance
                  </Typography>
                  <Box>
                    <Typography className="amount whiteColor">
                      {" "}
                      {transactionResponse &&
                        transactionResponse.walletBalance &&
                        transactionResponse.walletBalance.balance}
                    </Typography>
                  </Box>
                </Grid>
                {/* <Grid item xs={12} md={6} lg={6}>
                  <Box className="bannerImage">
                    <img
                    alt={`${AppConfig.applicationName} - Wallet`}
                      className=""
                      src={require("../../assets/images/myWallet/walletAmount.png")}
                    />
                  </Box>
                </Grid> */}
              </Grid>
            </Box>
            <Box sx={{ width: "100%" }}>
              <TabContext value={value}>
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                  <TabList
                    onChange={handleTabsChange}
                    aria-label="lab API tabs example"
                  >
                    <Tab label="User Information" value="1" />
                    <Tab label="Wallet Transactions" value="2" />
                    <Tab label="Referrals" value="3" />
                    <Tab label="Played History" value="4" />
                  </TabList>
                </Box>
                <TabPanel value={"1"}>
                  <UserCard userId={userId} />
                </TabPanel>
                <TabPanel value={"2"}>
                  <Box>
                    {transactionResponse.transactions.length > 0 && (
                      <Grid container spacing={2}>
                        <Grid item xs={12} md={12} lg={12}>
                          <TableContainer
                            component={Paper}
                            className="commonTable responsiveTable"
                          >
                            <Table aria-label="collapsible table">
                              <TableHead>
                                <TableRow>
                                  <TableCell
                                    width={60}
                                    key={0}
                                    variant="head"
                                    // style={{ width: column.width }}
                                    sx={{
                                      backgroundColor: "background.paper",
                                    }}
                                  >
                                    {"S.No"}
                                  </TableCell>
                                  {columns.map((column, index) => (
                                    <TableCell
                                      width={`${column.width}%`}
                                      key={index + 1}
                                      variant="head"
                                      // style={{ width: column.width }}
                                      sx={{
                                        backgroundColor: "background.paper",
                                      }}
                                    >
                                      {column.headerName}
                                    </TableCell>
                                  ))}
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {transactionResponse.transactions.map(
                                  (row, index) => (
                                    <Row
                                      row={row}
                                      rowNumber={
                                        (transactionResponse.pagination
                                          .pageNumber -
                                          1) *
                                          transactionResponse.pagination
                                            .pageSize +
                                        (index + 1)
                                      }
                                    />
                                  )
                                )}
                              </TableBody>
                            </Table>
                          </TableContainer>
                          <TablePagination
                            rowsPerPageOptions={[10]}
                            component="div"
                            count={transactionResponse.pagination.totalRecords}
                            rowsPerPage={
                              transactionResponse.pagination.pageSize
                            }
                            page={transactionResponse.pagination.pageNumber - 1}
                            onPageChange={(event, page) => {
                              setTransactionRequest({
                                ...transactionRequest,
                                PageNumber: page + 1,
                              });
                            }}
                          />
                        </Grid>
                      </Grid>
                    )}
                  </Box>
                </TabPanel>
                <TabPanel value="3">
                  <Grid container spacing={2}>
                    {refferals && refferals.length > 0 ? (
                      refferals.map((refferal: IRefferals) => (
                        <ReferralsComponent refferal={refferal} />
                      ))
                    ) : (
                      <></>
                    )}
                  </Grid>
                </TabPanel>
                <TabPanel value="4">
                  <Grid container spacing={1}>
                    {lotteryHistory && lotteryHistory.length > 0 ? (
                      lotteryHistory.map(
                        (
                          hsiotry: IGameLotteryTicketsWonLoss,
                          index: number
                        ) => (
                          <LotteryTicketWonLoss x={hsiotry} rowIndex={index} />
                        )
                      )
                    ) : (
                      <></>
                    )}
                  </Grid>
                </TabPanel>
              </TabContext>
            </Box>
          </Box>

          <Box className="pageViewFooter">
            <Footer />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
export default UserInformation;
