import { Dispatch } from "react";
import HttpHelperService from "../httpHelper.service";
import {
  DELETE,
  SAVE_ACCOUNT_DETAILS,
  SAVE_UPI_DETAILS,
  SET_PRIMARY_ACCOUNT,
  WITHDRAWAL_DETAILS,
  WALLET_WITHDRAWAL_DETAILS,
} from "../../constants/EndPoints.constant";
import { ISaveBankAccountModel } from "../../interface/Request/ISaveBankAccountModel";
import { ISaveUPIModel } from "../../interface/Request/ISaveUPIModel";
import { IWithdrawalWallet } from "../../interface/Request/IWithdrawalWallet";
import { Action } from "redux";

class WithdrawalService {
  _httpHelperService;
  constructor(dispatch: Dispatch<Action>) {
    this._httpHelperService = new HttpHelperService(dispatch);
  }

  saveAccountDetails = async (registrationModel: ISaveBankAccountModel) => {
    return await this._httpHelperService.post(
      SAVE_ACCOUNT_DETAILS,
      registrationModel
    );
  };

  withdrawalWalletDetails = async (registrationModel: IWithdrawalWallet) => {
    return await this._httpHelperService.post(
      WALLET_WITHDRAWAL_DETAILS,
      registrationModel
    );
  };

  saveUPIDetails = async (saveUpiModel: ISaveUPIModel) => {
    return await this._httpHelperService.post(SAVE_UPI_DETAILS, saveUpiModel);
  };

  withdrawalDetails = async () => {
    return await this._httpHelperService.get(WITHDRAWAL_DETAILS);
  };

  delete = async (id: number) => {
    return await this._httpHelperService.delete(`${DELETE}/${id}`);
  };

  setPrimaryAccount = async (id: number) => {
    return await this._httpHelperService.put(
      `${SET_PRIMARY_ACCOUNT}/${id}`,
      null
    );
  };
}

export default WithdrawalService;
