import { applyMiddleware, combineReducers } from "redux";
import { configureStore } from "@reduxjs/toolkit";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import authReducer from "../reducers/auth.reducer";
import lookupReducer from "../reducers/lookup.reducer";
import userReducer from "../reducers/user.reducer";

const persistConfig = {
  key: "root",
  storage,
};

const defaultMiddlewareConfig = {
  serializableCheck: false,
};

function configureAppStore(initialState = {}) {
  const rootReducer = combineReducers({
    authReducer,
    lookupReducer,
    userReducer,
  });
  const persistedReducer = persistReducer(persistConfig, rootReducer);
  const store = configureStore({
    reducer: persistReducer(
      {
        key: "root",
        debug: true,
        storage,
      },
      persistedReducer
    ),
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware(defaultMiddlewareConfig),
  });
  console.log("initialState", store.getState());

  const persistor = persistStore(store, undefined, () => {
    console.log("restoredState", store.getState());
  });

  return { store, persistor };
}

export default configureAppStore;
