import React, {
  FunctionComponent,
  useContext,
  useEffect,
  useState,
} from "react";
import PageProps from "../../../models/PageProps.interface";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Link from "@mui/material/Link";
import { useNavigate } from "react-router-dom";

//Component Import

//Form
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";

//Icons

//Styles
import "../signup/signup.screen.style.css";

import * as yup from "yup";
import { Formik } from "formik";
import { getCountries } from "../../../actions/lookup.actions";
import { useDispatch, useSelector } from "react-redux";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { ICountryModel } from "../../../interface/BusinessModels/ICountryModel";
import FormHelperText from "@mui/material/FormHelperText";
import { ToastContext } from "../../../context/toast.context";
import { ToastSeverity } from "../../../constants/toastSeverity.contants";
import { LoadingContext } from "../../../context/loading.context";
import UserService from "../../../services/user/user.service";
import { IApiResponse } from "../../../interface/Response/IApiResponse";
import AlertDialog from "../../../components/alertDialog/alertDialog.component";
import { IAlertDialogConfig } from "../../../interface/IAlertDialogConfig";
import {
  AGENT_CONTACT_TYPE,
  API_ERROR_STANDARD_MESSAGE,
  DEFAULT_ALERT_CONFIG,
} from "../../../constants/DBConstants.constant";
import { HttpStatusCode } from "axios";

import { IApplicationAgentRegistrationModel } from "../../../interface/BusinessModels/IApplicationAgentRegistrationModel";
import LookupService from "../../../services/lookup/lookup.service";
import { ILookupModel } from "../../../interface/BusinessModels/ILookupModel";

const AgentRegistration: FunctionComponent<PageProps> = ({ title }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { setToastConfig, setOpen } = useContext(ToastContext);
  const { setLoading } = useContext(LoadingContext);
  const [contactTypes, setContactTypes] = React.useState<ILookupModel[]>([]);
  const initializeCountries = () => dispatch<any>(getCountries());
  const { countries } = useSelector((state: any) => state.lookupReducer);
  const _userService = new UserService(dispatch);
  const _lookupService = new LookupService(dispatch);
  const [alertConfig, setAlertConfig] = useState<IAlertDialogConfig>({
    isShow: false,
    callBack: () => undefined,
  });

  useEffect(() => {
    getAgentContactTypes();

    async function getAgentContactTypes() {
      setLoading(true);
      try {
        const contactTypes: IApiResponse = await _lookupService.getConfigs(
          AGENT_CONTACT_TYPE
        );
        setContactTypes(contactTypes.response as ILookupModel[]);
      } catch (error) {
        if (
          error &&
          (error as any).response &&
          (error as any).response.status != HttpStatusCode.Unauthorized
        ) {
          setToastConfig(ToastSeverity.Error, API_ERROR_STANDARD_MESSAGE, true);
        }
      }
      setLoading(false);
    }
  }, []);

  const InitialValues: IApplicationAgentRegistrationModel = {
    name: "",
    contactTypeId: "28",
    phoneCode: "",
    phoneNumber: "",
    countryCode: "",
    country: "",
    state: "",
    countryId: 0,
  };

  const SignupSchema = yup.object().shape({
    name: yup.string().required("Name is required"),
    contactTypeId: yup.string().required("Contact Type is required."),
    countryCode: yup.string().required("Country Code is required."),
    phoneCode: yup.string().required("Phone Code is required."),
    phoneNumber: yup.string().required("Phone Number is required."),
    state: yup.string().required("State is required."),
    countryId: yup
      .number()
      .required("This field is required")
      .moreThan(0, "Select valid country"),
  });

  useEffect(() => {
    setLoading(true);
    initializeCountries();
    setLoading(false);
  }, []);

  const _handleSignUp = async (values: IApplicationAgentRegistrationModel) => {
    try {
      const isValid = await SignupSchema.isValid(values);
      if (isValid) {
        var response: IApiResponse = await _userService.registerAgent({
          ...values,
          phoneNumber: values.phoneNumber.toString(),
        });
        if (!response.isSuccess) {
          setToastConfig(ToastSeverity.Error, response.message, true);
        } else {
          setAlertConfig({
            description: response.message,
            toastSeverity: ToastSeverity.Success,
            isShow: true,
            callBack: () => {
              setAlertConfig(DEFAULT_ALERT_CONFIG);
              navigate("/");
            },
          });
        }
      }
    } catch (error) {
      if (
        error &&
        (error as any).response &&
        (error as any).response.status != HttpStatusCode.Unauthorized
      ) {
        setToastConfig(ToastSeverity.Error, API_ERROR_STANDARD_MESSAGE, true);
      }
      console.log(error);
    }
  };

  return (
    <Formik
      initialValues={{ ...InitialValues }}
      validationSchema={SignupSchema}
      onSubmit={(values, { setSubmitting }) => {
        _handleSignUp(values);
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        setFieldValue,
        isSubmitting,
        isValid,
        /* and other goodies */
      }) => (
        <form onSubmit={handleSubmit} className="height100">
          <Box className="pageMain accountMain">
            <Box className="pageView">
              <Box className="accountBody height100">
                <Grid container spacing={2} className="height100">
                  <Grid item xs={12} md={12} lg={12}>
                    <Box className="accountBodyRight height100">
                      <Box className="commonCard">
                        <Card className="height100">
                          <CardContent>
                            <Typography className="accountTitle">
                              Agent Registration
                            </Typography>
                            <Box>
                              <Grid container spacing={2}>
                                <Grid item xs={12} md={12} lg={12}>
                                  <TextField
                                    type="text"
                                    id="name"
                                    label="Name"
                                    variant="outlined"
                                    fullWidth
                                    onChange={handleChange}
                                    value={values.name}
                                  />

                                  {errors.name && touched.name && (
                                    <FormHelperText className="errorMessage">
                                      {errors.name}
                                    </FormHelperText>
                                  )}
                                </Grid>
                                <Grid item xs={12} md={12} lg={4}>
                                  <FormControl variant="outlined" fullWidth>
                                    <InputLabel id="outlined-country-dropdown-label">
                                      Contact Type
                                    </InputLabel>
                                    <Select
                                      labelId="outlined-country-dropdown-label"
                                      id="country-dropdown"
                                      label="Country"
                                      value={values.contactTypeId.toString()}
                                      onChange={(evt: SelectChangeEvent) => {
                                        setFieldValue(
                                          "contactTypeId",
                                          +evt.target.value
                                        );
                                      }}
                                    >
                                      {contactTypes &&
                                        contactTypes.map(
                                          (contactType: ILookupModel) => (
                                            <MenuItem
                                              key={contactType.id}
                                              value={contactType.id.toString()}
                                            >
                                              {contactType.value}
                                            </MenuItem>
                                          )
                                        )}
                                    </Select>
                                    {errors.contactTypeId &&
                                      touched.contactTypeId && (
                                        <FormHelperText className="errorMessage">
                                          {errors.contactTypeId}
                                        </FormHelperText>
                                      )}
                                  </FormControl>
                                </Grid>
                                <Grid item xs={12} md={12} lg={4}>
                                  <FormControl variant="outlined" fullWidth>
                                    <InputLabel id="outlined-country-dropdown-label">
                                      Country
                                    </InputLabel>
                                    <Select
                                      labelId="outlined-country-dropdown-label"
                                      id="country-dropdown"
                                      label="Country"
                                      value={
                                        values.countryId
                                          ? values.countryId.toString()
                                          : "0"
                                      }
                                      onChange={(evt: SelectChangeEvent) => {
                                        if (
                                          evt.target.value.toString() === "0"
                                        ) {
                                          setFieldValue("phoneCode", "");
                                          setFieldValue("country", "");
                                          setFieldValue("countryCode", "");
                                        } else {
                                          const country: ICountryModel[] = countries.filter(
                                            (x: ICountryModel) =>
                                              x.id.toString() ===
                                              evt.target.value.toString()
                                          );
                                          setFieldValue(
                                            "phoneCode",
                                            country[0].phoneCode
                                          );

                                          setFieldValue(
                                            "country",
                                            country[0].name
                                          );

                                          setFieldValue(
                                            "countryCode",
                                            country[0].code
                                          );
                                        }

                                        setFieldValue(
                                          "countryId",
                                          +evt.target.value
                                        );
                                      }}
                                    >
                                      <MenuItem value={0}>Select</MenuItem>
                                      {countries &&
                                        countries.map(
                                          (country: ICountryModel) => (
                                            <MenuItem
                                              key={country.id}
                                              value={country.id.toString()}
                                            >
                                              {country.name}
                                            </MenuItem>
                                          )
                                        )}
                                    </Select>
                                    {errors.countryId && touched.countryId && (
                                      <FormHelperText className="errorMessage">
                                        {errors.countryId}
                                      </FormHelperText>
                                    )}
                                  </FormControl>
                                </Grid>
                                <Grid item xs={12} md={12} lg={2}>
                                  <TextField
                                    id="phoneCode"
                                    label="Code"
                                    variant="outlined"
                                    fullWidth
                                    disabled
                                    value={values.phoneCode}
                                  />
                                  {errors.phoneCode && touched.phoneCode && (
                                    <FormHelperText className="errorMessage">
                                      {errors.phoneCode}
                                    </FormHelperText>
                                  )}
                                </Grid>
                                <Grid item xs={12} md={12} lg={6}>
                                  <TextField
                                    type="text"
                                    id="state"
                                    label="State"
                                    variant="outlined"
                                    fullWidth
                                    onChange={handleChange}
                                    value={values.state.toString()}
                                  />
                                  {errors.state && touched.state && (
                                    <FormHelperText className="errorMessage">
                                      {errors.state}
                                    </FormHelperText>
                                  )}
                                </Grid>
                                <Grid item xs={12} md={12} lg={6}>
                                  <TextField
                                    type="number"
                                    id="phoneNumber"
                                    label="Phone Number"
                                    variant="outlined"
                                    fullWidth
                                    onChange={handleChange}
                                    value={values.phoneNumber.toString()}
                                  />
                                  {errors.phoneNumber &&
                                    touched.phoneNumber && (
                                      <FormHelperText className="errorMessage">
                                        {errors.phoneNumber}
                                      </FormHelperText>
                                    )}
                                </Grid>
                              </Grid>
                            </Box>
                          </CardContent>
                          <CardActions>
                            <Grid container spacing={2}>
                              <Grid item xs={12}>
                                <Button
                                  type="submit"
                                  variant="contained"
                                  className="primaryFillBtn width100"
                                  disabled={isSubmitting}
                                >
                                  <span>Submit</span>
                                </Button>
                              </Grid>
                              <Grid item xs={12}>
                                <Box className="textCenter">
                                  <Typography component="a">
                                    Back to <Link href="/">Login</Link>
                                  </Typography>
                                </Box>
                              </Grid>
                            </Grid>
                          </CardActions>
                        </Card>
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </Box>
          {alertConfig && alertConfig.isShow && (
            <AlertDialog
              alertConfig={alertConfig}
              callBack={alertConfig.callBack}
            />
          )}
        </form>
      )}
    </Formik>
  );
};
export default AgentRegistration;
