import React, {
  FunctionComponent,
  useEffect,
  useState,
  useContext,
} from "react";
// import ImageList from '@mui/material/ImageList';
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";

//List Import
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { Typography } from "@mui/joy";

//Icons
import LogoutRoundedIcon from "@mui/icons-material/LogoutRounded";
import Fab from "@mui/material/Fab";
import { useDispatch } from "react-redux";
import { logOut, sessionExpired } from "../../actions/auth.actions";

//Import TopNav Css
import "../topNav/topNav.component.style.css";
import UserService from "../../services/user/user.service";
import { IWalletBalanceModel } from "../../interface/BusinessModels/IWalletBalanceModel";
import { useSelector } from "react-redux";
import { IApiResponse } from "../../interface/Response/IApiResponse";
import PageProps from "../../models/PageProps.interface";
import { AxiosError, HttpStatusCode } from "axios";
import { ToastSeverity } from "../../constants/toastSeverity.contants";
import { IAlertDialogConfig } from "../../interface/IAlertDialogConfig";
import AlertDialog from "../alertDialog/alertDialog.component";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";
import { AppConfig } from "../../constants/AppConfig.constant";
import CachedIcon from "@mui/icons-material/Cached";
import IconButton from "@mui/material/IconButton";
import { getWalletBalance } from "../../actions/lookup.actions";
import GoogleTranslate from "../../screens/googleTranslate";
import { ILookupModel } from "../../interface/BusinessModels/ILookupModel";
import {
  API_ERROR_STANDARD_MESSAGE,
  LINKING_WHATSAPP,
  WHATSAPP_DEFAULT_MESSAGE,
} from "../../constants/DBConstants.constant";
import LookupService from "../../services/lookup/lookup.service";
import { LoadingContext } from "../../context/loading.context";
import { ToastContext } from "../../context/toast.context";
import ApplicationHelperService from "../../services/ApplicationHelperService";

const TopNav: FunctionComponent<PageProps> = ({ title }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const logoutUser = () => dispatch<any>(logOut());
  const { userInfo } = useSelector((state: any) => state.authReducer);
  const { walletBalance } = useSelector((state: any) => state.lookupReducer);
  const [whatsappLink, setWhatsappLink] = useState<string>("");
  const [whatsappPhone, setWhatsappPhone] = useState<string>("");
  const _lookupService = new LookupService(dispatch);
  const { setLoading, isWebView } = useContext(LoadingContext);
  const _applicationHelperService = new ApplicationHelperService({});
  const { setToastConfig } = useContext(ToastContext);
  const getUserWalletBalance = () => dispatch<any>(getWalletBalance());
  const [alertConfig, setAlertConfig] = useState<IAlertDialogConfig>({
    isShow: false,
    callBack: () => undefined,
  });

  const handleWhatsAppClick = () => {
    const linkData = _applicationHelperService.generateWhatsAppLink(
      isWebView,
      WHATSAPP_DEFAULT_MESSAGE,
      whatsappPhone
    );
    _applicationHelperService.sendMessageOnDeviceType(
      isWebView,
      LINKING_WHATSAPP,
      linkData
    );
  };

  const dispatchSessionExpired = () => dispatch<any>(sessionExpired());

  useEffect(() => {
    getUserWalletBalance();
  }, []);

  const reloadWindow = (values: any) => {
    window.location.reload();
  };

  useEffect(() => {
    getSocialConnectionSettings();

    async function getSocialConnectionSettings() {
      try {
        const socialConnectionSettings: IApiResponse = await _lookupService.getSocialGameSettings();

        const whatsLink = socialConnectionSettings.response.filter(
          (x: ILookupModel) => x.key === "WHATSAPP_LINK"
        )[0].value;
        const whatsPhone = socialConnectionSettings.response.filter(
          (x: ILookupModel) => x.key === "AGENT_WHATSAPP"
        )[0].value;

        if (whatsLink !== "") {
          setWhatsappLink(whatsLink);
          setWhatsappPhone(whatsPhone);
        }
        setLoading(false);
      } catch (error) {
        if (
          error &&
          (error as any).response &&
          (error as any).response.status != HttpStatusCode.Unauthorized
        ) {
          setToastConfig(ToastSeverity.Error, API_ERROR_STANDARD_MESSAGE, true);
        }
        setLoading(false);
      }
    }
  }, []);

  return (
    <Box className="">
      <Box>
        {userInfo && userInfo.user && userInfo.user.userId != "" ? (
          <Grid container spacing={2}>
            <Grid item xs={12} md={6} lg={6}>
              <Box>
                <Typography className="pageTitle">{title}</Typography>
              </Box>
            </Grid>

            <Grid item xs={12} md={6} lg={6} className="headerItemsCenter">
              {/* <GoogleTranslate /> */}
              {whatsappLink != "" && (
                <Link
                  style={{ borderRadius: "12px" }}
                  href={whatsappLink}
                  className="socialMediaBtn whatsapp d-none"
                  target="_blank"
                >
                  <span>{whatsappPhone}</span>{" "}
                  <span className="socialMediaIcon">
                    <img
                      alt={`${AppConfig.applicationName}  - Whatsapp`}
                      className=""
                      src={require("../../assets/images/dashboard/whatsapp.png")}
                    />
                    {/* <WhatsAppIcon /> */}
                  </span>
                </Link>
              )}
              <Box className="headerItem">
                <Typography className="headerAppId">
                  ID:{userInfo.user.appId}
                </Typography>
              </Box>
              <Box className="headerItem">
                <IconButton
                  className="navBtn reloadBtn"
                  onClick={reloadWindow}
                  aria-label="Reload"
                  sx={{ "&:hover": { cursor: "pointer" } }}
                >
                  <CachedIcon />
                </IconButton>
              </Box>
              <Box className="topBalance navBtn headerItem">
                {/* <span className="currencySymbol"></span> */}
                <span>
                  <img
                    alt="Profile Photo"
                    className=""
                    src={require("../../assets/images/common/currencyIcon.png")}
                  />
                </span>
                <span>
                  {walletBalance.balance} INR
                  {/* {walletBalance.currency} */}
                </span>
              </Box>
              <Box className="userAccount">
                <Box className="userPhoto">
                  <img
                    alt="Profile Photo"
                    className=""
                    src={require("../../assets/images/common/profile_user.jpg")}
                  />
                </Box>
                <Box className="hoverCard">
                  <Box className="userName">
                    <Typography onClick={() => logoutUser()}>
                      <span className="floatL">
                        <LogoutRoundedIcon />
                      </span>
                      <span className="floatL">Logout</span>
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Grid>
            {/* <Grid item xs={2} md={1} lg={1} className="headerItemsCenter" sx={{justifyContent:"end"}}>
           
            </Grid>
            <Grid item xs={4} md={2} lg={2} className="headerItemsCenter" sx={{justifyContent:"end"}}>
              
              
            </Grid>
            <Grid item xs={2} md={1} lg={1} className="headerItemsCenter" sx={{justifyContent:"center"}}>
              
            </Grid> */}
          </Grid>
        ) : (
          <Grid item xs={12} md={9} lg={8}>
            {/* <GoogleTranslate /> */}
            <Stack spacing={2} direction="row" className="floatR">
              <Button
                type="submit"
                variant="contained"
                className="primaryFillBtn"
                onClick={(event) => {
                  event.preventDefault();
                  navigate("/login");
                }}
              >
                <span>Login</span>
              </Button>
              <Button
                type="submit"
                variant="outlined"
                className="primaryLineBtn"
                onClick={(event) => {
                  event.preventDefault();
                  navigate("/signup");
                }}
              >
                <span>Signup</span>
              </Button>
            </Stack>
          </Grid>
        )}

        <Box className="fabBtn">
          <Fab
            color="secondary"
            aria-label="Whatsapp"
            onClick={handleWhatsAppClick}
          >
            <img
              alt={`${AppConfig.applicationName} - Whatsapp`}
              src={require("../../assets/images/landingPage2V2/whatsapp.png")}
            />
          </Fab>
        </Box>
      </Box>
      {alertConfig && alertConfig.isShow && (
        <AlertDialog
          alertConfig={alertConfig}
          callBack={alertConfig.callBack}
        />
      )}
    </Box>
  );
};

export default TopNav;
