import React, { useEffect, useState } from "react";
import { Typography } from "@mui/material";
import ApplicationHelperService from "../../services/ApplicationHelperService";

const CountdownTimer = (props: { date: Date }) => {
  const _applicationHelperService = new ApplicationHelperService({});
  const targetDate = props.date; // Set the target date (example)
  const [remainingTime, setRemainingTime] = useState<number>(
    targetDate.getTime() - Date.now()
  );

  useEffect(() => {
    // Update the countdown every second
    const interval = setInterval(() => {
      const newRemainingTime = targetDate.getTime() - Date.now();
      setRemainingTime(newRemainingTime);

      // If the countdown is over, stop updating
      if (newRemainingTime <= 0) {
        clearInterval(interval);
      }
    }, 1000);

    // Cleanup interval on component unmount
    return () => clearInterval(interval);
  }, [targetDate]);

  const {
    days,
    hours,
    minutes,
    seconds,
  } = _applicationHelperService.formatTime(remainingTime);

  return seconds < 0 ? (
    <Typography variant="h5" color="red" style={{ textAlign: "center" }}>
      Game Ended
    </Typography>
  ) : (
    <Typography variant="h5" color={"green"} style={{ textAlign: "center" }}>
      Ends in {days > 0 && `${days} Days `} {/* Conditionally render days */}
      {hours > 0 && `${hours} Hours `}
      {minutes} Mins {seconds} Secs
    </Typography>
  );
};

export default CountdownTimer;
