/**
 * LOGIN
 */
export const AUTH_REQUESTED = "AUTH_REQUESTED";
export const AUTH_SUCCESS = "AUTH_SUCCESS";
export const AUTH_ERROR = "AUTH_ERROR";
export const AUTH_LOGOUT = "AUTH_LOGOUT";
export const AUTH_UNAUTHORIZED = "AUTH_UNAUTHORIZED";

/**
 * LOOK_UP
 */
export const COUNTRIES_SUCCESS = "COUNTRIES_SUCCESS";

/**
 * LOOK_UP
 */
export const WITHDRAWAL_ACCOUNT_TYPE_SUCCESS =
  "WITHDRAWAL_ACCOUNT_TYPE_SUCCESS";

/**
 * USER
 */

export const REFFERALS_SUCCESS = "REFFERALS_SUCCESS";

/**
 * CONFIG_SETTINGS
 */
export const CONFIG_SETTINGS = "CONFIG_SETTINGS";

/**
 * WALLET_BALANCE
 */
export const WALLET_BALANCE_SUCCESS = "WALLET_BALANCE";
