import React, {
  FunctionComponent,
  useState,
  useContext,
  ChangeEvent,
} from "react";
import PageProps from "../../models/PageProps.interface";

//Libraries Import
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { TextField } from "@mui/material";

//Icons Import
import WhatsAppIcon from "@mui/icons-material/WhatsApp";

//Component Import
import LeftMenu from "../../components/leftMenu/leftMenu.component";
import TopNav from "../../components/topNav/topNav.component";
import Footer from "../../components/footer/footer.component";

import Cards from "react-credit-cards";

//Import Css
import "../rechargeCreditCard/rechargeCreditCard.screen.style.css";

const RechargeCreditCard: FunctionComponent<PageProps> = ({ title }) => {
  const [number, setNumber] = useState("");
  const [name, setName] = useState("");
  const [expiry, setExpiry] = useState("");
  const [cvc, setCvc] = useState("");
  const [focus, setFocus] = useState<
    "number" | "name" | "expiry" | "cvc" | undefined
  >(undefined);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    switch (name) {
      case "number":
        setNumber(value);
        break;
      case "name":
        setName(value);
        break;
      case "expiry":
        setExpiry(value);
        break;
      case "cvc":
        setCvc(value);
        break;
      default:
        break;
    }
  };

  const handleInputFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    setFocus(e.target.name as "number" | "name" | "expiry" | "cvc");
  };

  return (
    <Box className="pageMain">
      <Box className="pageLeft">
        <LeftMenu />
      </Box>
      <Box className="pageRight">
        <Box className="pageHead">
          <TopNav title={title.split("|")[1].trim()} />
        </Box>
        <Box className="pageView">
          <Box className="pageViewBody commonScroll">
            <Box>
              <form>
                <div id="PaymentForm">
                  <Cards
                    cvc={cvc}
                    expiry={expiry}
                    focused={focus}
                    name={name}
                    number={number}
                  />
                  <form>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <TextField
                          type="tel"
                          name="number"
                          placeholder="Card Number"
                          onChange={handleInputChange}
                          onFocus={handleInputFocus}
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          type="text"
                          name="name"
                          placeholder="Name"
                          onChange={handleInputChange}
                          onFocus={handleInputFocus}
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          type="text"
                          name="expiry"
                          placeholder="Expiry"
                          onChange={handleInputChange}
                          onFocus={handleInputFocus}
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          type="tel"
                          name="cvc"
                          placeholder="CVC"
                          onChange={handleInputChange}
                          onFocus={handleInputFocus}
                          fullWidth
                        />
                      </Grid>
                      {/* Add other TextField components for name, expiry, and cvc similarly */}
                    </Grid>
                  </form>
                </div>
              </form>
            </Box>
          </Box>
          <Box className="pageViewFooter">
            <Footer />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
export default RechargeCreditCard;
