/*This is an Example of Calling Other Class Function in React Native*/
import { Dispatch } from "react";
import axios, { HttpStatusCode } from "axios";
import { AxiosRequestConfig } from "axios";
import { ILoginModel } from "../interface/Request/ILoginModel";
import { AppConfig } from "../constants/AppConfig.constant";
import { AUTH_UNAUTHORIZED } from "../constants/Reducer.constant";
import { Action } from "redux";

class HttpHelperService {
  dispatch: Dispatch<any> = () => {
    throw new Error("Dispatch function must be overridden by a provider.");
  };
  constructor(dispatch: Dispatch<any>) {
    this.dispatch = dispatch;
  }

  config: AxiosRequestConfig = {
    headers: {
      "Content-Type": "application/json",
      Accept: "*/*",
    },
    data: {},
  };

  processEndpoint = `${process.env.REACT_APP_BASE_URL}`;

  checkIfUnAuthorized = (error: any) => {
    const errorReponse = error.response;

    if (errorReponse && errorReponse.status == HttpStatusCode.Unauthorized) {
      console.log("[Axios Error]  Session Expired", error.response);
      this.dispatch({
        type: AUTH_UNAUTHORIZED,
        payload: { isUnAuthorised: true },
      });
    }
  };

  /**
   *
   * @param url
   * @returns
   */
  get = async (url: string) => {
    try {
      const endpoint = `${this.processEndpoint}/${url}`;
      this.appendAuthorization();
      console.log(
        `[API REQUEST] -> [GET] -> [ENDPOINT] -> ${endpoint} -> \n [HEADERS] -> ${this.config.headers}`
      );
      const response = await axios.get(endpoint, this.config);
      console.log(
        `[API REQUEST] -> [GET] -> [ENDPOINT] -> ${endpoint} \n [RESPONSE] -> ${JSON.stringify(
          response.data
        )}`
      );
      return response.data;
    } catch (e) {
      this.checkIfUnAuthorized(e);
      throw e;
    }
  };

  login = async (loginRequest: ILoginModel) => {
    try {
      const endpoint = `${this.processEndpoint}/api/Auth/login`;
      let formData = new FormData();
      formData.append("Username", loginRequest.username);
      formData.append("Password", loginRequest.password);

      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: endpoint,
        headers: {
          "Content-Type": "multipart/form-data;",
        },
        data: formData,
      };

      const response = await axios.request(config);
      return response.data;
    } catch (e) {
      throw e;
    }
  };

  /**
   *
   * @param url
   * @param requestBody
   * @returns
   */
  post = async (url: string, requestBody: any) => {
    try {
      const endpoint = `${this.processEndpoint}/${url}`;
      this.appendAuthorization();
      console.log(
        `[API REQUEST] -> [POST] -> ${endpoint} \n [BODY] -> ${JSON.stringify(
          requestBody
        )} -> \n [HEADERS] -> ${this.config.headers}`
      );
      const response = await axios.post(endpoint, requestBody, this.config);
      console.log(
        `[API REQUEST] -> [POST] -> ${endpoint} \n [BODY] -> ${JSON.stringify(
          requestBody
        )} \n [RESPONSE] -> ${JSON.stringify(response.data)}`
      );
      return response.data;
    } catch (e) {
      this.checkIfUnAuthorized(e);
      throw e;
    }
  };

  /**
   *
   * @param urlSS
   * @param requestBody
   * @returns
   */
  postFormData = async (url: string, requestBody: FormData) => {
    try {
      const endpoint = `${this.processEndpoint}/${url}`;
      this.appendAuthorization();
      this.appendFormHeader();
      console.log(
        `[API REQUEST] -> [POST] -> ${endpoint} \n [BODY] -> ${JSON.stringify(
          requestBody
        )} -> \n [HEADERS] -> ${this.config.headers}`
      );
      const response = await axios.post(endpoint, requestBody, this.config);
      console.log(
        `[API REQUEST] -> [POST] -> ${endpoint} \n [BODY] -> ${JSON.stringify(
          requestBody
        )} \n [RESPONSE] -> ${JSON.stringify(response.data)}`
      );
      return response.data;
    } catch (e) {
      this.checkIfUnAuthorized(e);
      throw e;
    }
  };

  /**
   *
   * @param url
   * @param requestBody
   * @returns
   */
  delete = async (url: string) => {
    try {
      const endpoint = `${this.processEndpoint}/${url}`;
      this.appendAuthorization();
      console.log(
        `[API REQUEST] -> [DELETE] -> [ENDPOINT] -> ${endpoint} -> \n [HEADERS] -> ${this.config.headers}`
      );
      const response = await axios.delete(endpoint, this.config);
      console.log(
        `[API REQUEST] -> [DELETE] -> [ENDPOINT] -> ${endpoint} \n [RESPONSE] -> ${JSON.stringify(
          response.data
        )}`
      );
      return response.data;
    } catch (e) {
      this.checkIfUnAuthorized(e);
      throw e;
    }
  };

  /**
   *
   * @param url
   * @param requestBody
   * @returns
   */
  put = async (url: string, requestBody: any) => {
    try {
      const endpoint = `${this.processEndpoint}/${url}`;
      this.appendAuthorization();
      console.log(
        `[API REQUEST] -> [PUT] -> ${endpoint} \n [BODY] -> ${JSON.stringify(
          requestBody
        )} -> \n [HEADERS] -> ${this.config.headers}`
      );
      const response = await axios.put(endpoint, requestBody, this.config);
      console.log(
        `[API REQUEST] -> [PUT] -> ${endpoint} \n [BODY] -> ${JSON.stringify(
          requestBody
        )} \n [RESPONSE] -> ${JSON.stringify(response.data)}`
      );
      return response.data;
    } catch (e) {
      this.checkIfUnAuthorized(e);
      throw e;
    }
  };

  /**
   *
   * @param url
   * @param requestBody
   * @returns
   */
  downloadFile = async (url: string) => {
    try {
      const endpoint = `${this.processEndpoint}/${url}`;
      this.appendAuthorization();
      return await axios.get(endpoint, {
        ...this.config,
        responseType: "blob",
      });
      // .then((response) => {
      //   // create file link in browser's memory
      //   const href = URL.createObjectURL(response.data);
      //   // create "a" HTML element with href to file & click
      //   const link = document.createElement("a");
      //   link.href = href;
      //   link.setAttribute("download", "file.pdf"); //or any other extension
      //   document.body.appendChild(link);
      //   link.click();

      //   // clean up "a" element & remove ObjectURL
      //   document.body.removeChild(link);
      //   URL.revokeObjectURL(href);
      // });
    } catch (e) {
      this.checkIfUnAuthorized(e);
      throw e;
    }
  };

  appendAuthorization = async () => {
    var loggedInUser = localStorage.getItem(
      `${AppConfig.applicationName}LoggedInUserInfo`
    );
    if (loggedInUser) {
      this.config.headers = {
        ...this.config.headers,
        Authorization: `Bearer ${JSON.parse(loggedInUser).accessToken.token}`,
      };
    }
  };

  appendLoginHeader = async () => {
    this.config.headers = {
      "Content-Type": "application/x-www-form-urlencoded",
    };
  };

  appendFormHeader = async () => {
    this.config.headers = {
      ...this.config.headers,
      "Content-Type": "multipart/form-data",
    };
  };
}

export default HttpHelperService;
