import React, {
  useState,
  useMemo,
  useCallback,
  useEffect,
  Component,
} from "react";
import "./App.css";
// import { BrowserRouter as Router, Routes, Route, Link, useNavigate } from 'react-router-dom';
import { Routes, Route, useNavigate } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import { Box } from "@mui/material";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert, { AlertProps } from "@mui/material/Alert";
import { ToastContext } from "./context/toast.context";

// import Login from './screens/account/login/login.screen'

//Landing Page
import LandingPage from "./screens/landingPage/landingPage.screen";

//Home
import Home from "./screens/home/home.screen";

//User
import Dashboard from "./screens/dashboard/dashboard.screen";
import ExchangeRates from "./screens/exchangeRates/exchangeRates.screen";
import MyWallet from "./screens/myWallet/myWallet.screen";
import MyWalletRecharge from "./screens/myWalletRecharge/myWalletRecharge.screen";
import MyWalletWithdraw from "./screens/myWalletWithdraw/myWalletWithdraw.screen";
import MyBankCards from "./screens/myBankCards/myBankCards.screen";
import PlayGames from "./screens/playGames/playGames.screen";
import PlayGamesLottery from "./screens/playGamesLottery/playGamesLottery.screen";
import DeclareResults from "./screens/declareResults/declareResults.screen";

import { AppConfig } from "./constants/AppConfig.constant";
import LotteryPlayHistory from "./screens/lotteryPlayHistory/lotteryPlayHistory.screen";
import TransactionSummary from "./screens/transactionSummary/transactionSummary.screen";
import ProfileSettings from "./screens/profileSettings/profileSettings.screen";
import Offers from "./screens/offers/offers.screen";
import ReferralList from "./screens/referralList/referralList.screen";
import Help from "./screens/help/help.screen";
import ResultsHistory from "./screens/resultsHistory/resultsHistory.screen";
import PageConstruction from "./screens/pageConstruction/pageConstruction.screen";
import Rules from "./screens/rules/rules.screen";

//Admin
import ManageMedia from "./screens/manageMedia/manageMedia.screen";
import RechargeUser from "./screens/rechargeUser/rechargeUser.screen";
import RechargeDetails from "./screens/rechargeDetails/rechargeDetails.screen";
import AgentRechargeList from "./screens/agentRechargeList/agentRechargeList.screen";
import AllUsers from "./screens/allUsers/allUsers.screen";
import GameSettings from "./screens/gameSettings/gameSettings.screen";
import LotteryGameSettings from "./screens/lotteryGameSettings/lotteryGameSettings.screen";
import LotteryGameSummary from "./screens/lotteryGameSummary/lotteryGameSummary.screen";
import PaymentTransactions from "./screens/paymentTransactions/paymentTransactions.screen";
import ReferralsHistory from "./screens/referralsHistory/referralsHistory.screen";
import RechargeCreditCard from "./screens/rechargeCreditCard/rechargeCreditCard.screen";

//Account
import Login from "././screens/account/login/login.screen";
import Signup from "././screens/account/signup/signup.screen";
import ForgotPassword from "././screens/account/forgotPassword/forgotPassword.screen";
import ChangePassword from "././screens/account/changePassword/changePassword.screen";
import { PreferencesContext } from "./context/preferences.context";
import { LoadingContext } from "./context/loading.context";
import { useDispatch, useSelector } from "react-redux";
import { ToastSeverity } from "./constants/toastSeverity.contants";
import { getConfigs, getCountries } from "./actions/lookup.actions";
import { getRefferals } from "./actions/user.actions";
import {
  DEFAULT_ALERT_CONFIG,
  ROLE_CONFIG,
  RoleType,
  WITHDRAWALACCOUNTTYPE_CONFIG,
} from "./constants/DBConstants.constant";
import ProtectedRoute from "./components/protectedRoute.component";
import ManageCountry from "./screens/manageCountry/manageCountry.screen";
import { ClockLoader, PropagateLoader } from "react-spinners";
import { Label } from "@mui/icons-material";
// import LandingPage from "./screens/landingPage/landingPage.screen";
import Typography from "@mui/material/Typography";
import PageVisibility from "react-page-visibility";
import UserService from "./services/user/user.service";
import { IAlertDialogConfig } from "./interface/IAlertDialogConfig";
import { sessionExpired } from "./actions/auth.actions";
import AlertDialog from "./components/alertDialog/alertDialog.component";
import { useLocation } from "react-router-dom";
import ApplicationAgents from "./screens/applicationAgents/applicationAgents.screen";
import AgentRegistration from "./screens/account/agentRegistration/agentRegistration.screen";
import DBTransactionHistory from "./screens/dbTransactionHistory/dbTransactionHistory.screen";
import { LanguageProvider, useLanguage } from "./screens/LanguageProvider";

import GoogleTranslate from "./screens/googleTranslate";
import LandingPageV2 from "./screens/landingPageV2/landingPageV2.screen";
import UserInformation from "./screens/userInformation/userInformation.screen";

function App() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [activeMenuItem, setMenuItem] = useState<string>("");
  const [toastConfig, setConfig] = useState<any>({
    severity: "",
    message: "",
    open: false,
  });
  const [message, setMessage] = useState<string>("");
  const [open, setIsOpen] = useState<boolean>(false);
  const [isTabActive, setIsTabActive] = useState<boolean>(false);
  const [loading, setIsLoading] = useState<boolean>(false);
  const [isWebView, setIsWebView] = useState<boolean>(false);
  const { userInfo, isUnAuthorised } = useSelector(
    (state: any) => state.authReducer
  );
  const [alertConfig, setAlertConfig] = useState<IAlertDialogConfig>({
    isShow: false,
    callBack: () => undefined,
  });
  const loadWithdrawalAccountType = () =>
    dispatch<any>(getConfigs(WITHDRAWALACCOUNTTYPE_CONFIG));
  const dispatchSessionExpired = () => dispatch<any>(sessionExpired());

  const getTitle = (screen: string) => {
    const title = `${AppConfig.applicationName} | ${screen}`;
    return title;
  };

  const location = useLocation();

  const checkifWebView = () => {
    return (
      navigator.userAgent.includes("wv") ||
      window.location.search.includes("X-WebView=true")
    );
  };

  useEffect(() => {
    if (checkifWebView()) {
      setIsWebView(true);
      console.log("App is running in a WebView");
    } else {
      setIsWebView(false);
      console.log("App is running in a browser");
    }

    if ((window as any).isWebView) {
      console.log("window.isWebView App is running in a WebView");
    } else {
      console.log("window.isWebView App is running in a browser");
    }

    return () => {};
  }, []);

  // useEffect(() => {
  //   if (isTabActive) {
  //     validateSession();
  //   }

  //   async function validateSession() {
  //     try {
  //       await _userService.validateSession();
  //     } catch (error) {
  //       var loggedInUser = localStorage.getItem(
  //         `${AppConfig.applicationName}LoggedInUserInfo`
  //       );
  //       if (loggedInUser) {
  //         if (
  //           error &&
  //           (error as any).response &&
  //           (error as any).response.status == 401
  //         ) {
  //           setAlertConfig({
  //             description: "Session Expired",
  //             toastSeverity: ToastSeverity.Error,
  //             isShow: true,
  //             callBack: () => {
  //               dispatchSessionExpired();
  //               setTimeout(() => {
  //                 setAlertConfig(DEFAULT_ALERT_CONFIG);
  //               }, 500);
  //             },
  //           });
  //         } else {
  //           setAlertConfig({
  //             description: "Somthing went wrong, try again later.",
  //             toastSeverity: ToastSeverity.Error,
  //             isShow: true,
  //             callBack: () => {
  //               dispatchSessionExpired();
  //               setTimeout(() => {
  //                 setAlertConfig(DEFAULT_ALERT_CONFIG);
  //               }, 500);
  //             },
  //           });
  //         }
  //       }
  //     }
  //   }
  // }, [isTabActive]);

  useEffect(() => {
    if (isUnAuthorised) {
      setAlertConfig({
        description: "Your session expired, Please login again",
        toastSeverity: ToastSeverity.Error,
        isShow: true,
        callBack: () => {
          dispatchSessionExpired();
          setTimeout(() => {
            setAlertConfig(DEFAULT_ALERT_CONFIG);
          }, 500);
        },
      });
    }
  }, [isUnAuthorised]);

  const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
    props,
    ref
  ) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  /**
   *
   */
  const setActiveMenuItem = useCallback(
    (item: string) => {
      return setMenuItem(item);
    },
    [activeMenuItem]
  );

  /**
   *
   */
  const preferencesMemo = useMemo(
    () => ({
      setActiveMenuItem,
      activeMenuItem,
    }),
    [activeMenuItem]
  );

  /**
   *
   */
  const setOpen = useCallback(
    (open: boolean) => {
      return setIsOpen(open);
    },
    [open]
  );

  const setToastConfig = useCallback(
    (severity: ToastSeverity, message: string, open: boolean) => {
      return setConfig({ severity, message, open });
    },
    [toastConfig]
  );

  const setToastMessage = useCallback(
    (message: string) => {
      return setMessage(message);
    },
    [open]
  );

  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setConfig({ ...toastConfig, open: false });
  };

  const toastMemo = useMemo(
    () => ({
      open,
      setOpen,
      message,
      setToastMessage,
      toastConfig,
      setToastConfig,
    }),
    [open, message, toastConfig]
  );

  /**
   *
   */
  const setLoading = useCallback(
    (loading: boolean) => {
      return setIsLoading(loading);
    },
    [loading]
  );

  /**
   *
   */
  const loadingMemo = useMemo(
    () => ({
      setLoading,
      loading,
      isWebView,
    }),
    [loading, isWebView]
  );

  useEffect(() => {
    if (userInfo && userInfo.user && userInfo.user.userId != "") {
      loadWithdrawalAccountType();
      console.log(location.pathname);

      // if(userInfo.user.isPasswordReset) {
      //   navigate("/forgotPassword");
      // }
      if (location.pathname === "/login" || location.pathname === "/") {
        navigate("/dashboard");
      }
    } else {
      if (location.pathname) {
        if (
          location.pathname === "/resultsHistory" ||
          location.pathname === "/pageConstruction" ||
          location.pathname === "/rules" ||
          location.pathname === "/signup"
        ) {
          let navigation =
            `${location.pathname}` +
            (location.search ? `/${location.search}` : "");
          navigate(navigation);
        }
      } else {
        navigate("/");
      }
    }
  }, [userInfo]);

  return (
    <LanguageProvider>
      <LoadingContext.Provider value={loadingMemo}>
        <PageVisibility onChange={setIsTabActive}>
          <Box className="App height100">
            <PreferencesContext.Provider value={preferencesMemo}>
              <ToastContext.Provider value={toastMemo}>
                <Box
                  className="page_loader"
                  style={{ display: loadingMemo.loading ? "flex" : "none" }}
                >
                  <ClockLoader className="loaderIcon" color="#407bff" />
                </Box>
                {/* Language Toggle Button */}

                <Routes>
                  <Route
                    path="/"
                    element={<LandingPageV2 title={getTitle("Home")} />}
                  />
                  <Route
                    path="/resultsHistory"
                    element={
                      <ResultsHistory title={getTitle("Result History")} />
                    }
                  />

                  <Route
                    path="/pageConstruction"
                    element={
                      <PageConstruction title={getTitle("Page Construction")} />
                    }
                  />

                  <Route
                    path="/rules"
                    element={<Rules title={getTitle("Rules")} />}
                  />

                  <Route
                    path="/login"
                    element={<Login title={getTitle("Login")} />}
                  />

                  <Route
                    path="/signup"
                    element={<Signup title={getTitle("Signup")} />}
                  />

                  <Route
                    path="/agentRegistration"
                    element={
                      <AgentRegistration
                        title={getTitle("Agent Registration")}
                      />
                    }
                  />

                  <Route
                    path="/forgotPassword"
                    element={
                      <ForgotPassword title={getTitle("Forgot Password")} />
                    }
                  />

                  <Route
                    path="/rechargeCreditCard"
                    element={
                      <RechargeCreditCard
                        title={getTitle("Recharge Credit Card")}
                      />
                    }
                  />

                  <Route
                    path="/rechargeDetails"
                    element={
                      <ProtectedRoute
                        allowedRoles={[
                          RoleType.SuperAdmin,
                          RoleType.Admin,
                          RoleType.User,
                        ]}
                      >
                        <RechargeDetails title={getTitle("Recharge Details")} />
                      </ProtectedRoute>
                    }
                  />

                  <Route
                    path="/changePassword"
                    element={
                      <ProtectedRoute
                        allowedRoles={[
                          RoleType.SuperAdmin,
                          RoleType.Admin,
                          RoleType.User,
                          RoleType.Agent,
                        ]}
                      >
                        <ChangePassword title={getTitle("Change Password")} />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="/landingPage"
                    element={<LandingPage title={getTitle("Landing Page")} />}
                  />
                  <Route
                    path="/home"
                    element={
                      <ProtectedRoute
                        allowedRoles={[
                          RoleType.SuperAdmin,
                          RoleType.Admin,
                          RoleType.User,
                        ]}
                      >
                        <Home title={getTitle("Home")} />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="/dashboard"
                    element={
                      <ProtectedRoute
                        allowedRoles={[
                          RoleType.SuperAdmin,
                          RoleType.Admin,
                          RoleType.User,
                          RoleType.Agent,
                        ]}
                      >
                        <Dashboard title={getTitle("Dashboard")} />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="/exchangeRates"
                    element={
                      <ProtectedRoute
                        allowedRoles={[
                          RoleType.SuperAdmin,
                          RoleType.Admin,
                          RoleType.User,
                        ]}
                      >
                        <ExchangeRates title={getTitle("Exchange Rates")} />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="/myWallet"
                    element={
                      <ProtectedRoute
                        allowedRoles={[
                          RoleType.SuperAdmin,
                          RoleType.Admin,
                          RoleType.User,
                          RoleType.Agent,
                        ]}
                      >
                        <MyWallet title={getTitle("My Wallet")} />
                      </ProtectedRoute>
                    }
                  />

                  <Route
                    path="/userInformation"
                    element={
                      <ProtectedRoute
                        allowedRoles={[RoleType.SuperAdmin, RoleType.Admin]}
                      >
                        <UserInformation title={getTitle("User Information")} />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="/myWalletRecharge"
                    element={
                      <ProtectedRoute
                        allowedRoles={[
                          RoleType.SuperAdmin,
                          RoleType.Admin,
                          RoleType.User,
                          RoleType.Agent,
                        ]}
                      >
                        <MyWalletRecharge
                          title={getTitle("My Wallet Recharge")}
                        />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="/myWalletWithdraw"
                    element={
                      <ProtectedRoute
                        allowedRoles={[
                          RoleType.SuperAdmin,
                          RoleType.Admin,
                          RoleType.User,
                          RoleType.Agent,
                        ]}
                      >
                        <MyWalletWithdraw title={getTitle("Withdraw")} />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="/myBankCards"
                    element={
                      <ProtectedRoute
                        allowedRoles={[
                          RoleType.SuperAdmin,
                          RoleType.Admin,
                          RoleType.User,
                          RoleType.Agent,
                        ]}
                      >
                        <MyBankCards title={getTitle("My Bank Cards")} />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="/playGames"
                    element={
                      <ProtectedRoute
                        allowedRoles={[
                          RoleType.SuperAdmin,
                          RoleType.Admin,
                          RoleType.User,
                          RoleType.Agent,
                        ]}
                      >
                        <PlayGames title={getTitle("Play Games")} />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="/playGamesLottery"
                    element={
                      <ProtectedRoute
                        allowedRoles={[RoleType.SuperAdmin, RoleType.Admin]}
                      >
                        <PlayGamesLottery
                          title={getTitle("Play Games Lottery")}
                        />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="/lotteryPlayHistory"
                    element={
                      <ProtectedRoute
                        allowedRoles={[
                          RoleType.SuperAdmin,
                          RoleType.Admin,
                          RoleType.User,
                          RoleType.Agent,
                        ]}
                      >
                        <LotteryPlayHistory title={getTitle("Play History")} />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="/profileSettings"
                    element={
                      <ProtectedRoute
                        allowedRoles={[
                          RoleType.SuperAdmin,
                          RoleType.Admin,
                          RoleType.User,
                          RoleType.Agent,
                        ]}
                      >
                        <ProfileSettings title={getTitle("Profile Settings")} />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="/transactionSummary"
                    element={
                      <ProtectedRoute
                        allowedRoles={[
                          RoleType.SuperAdmin,
                          RoleType.Admin,
                          RoleType.User,
                          RoleType.Agent,
                        ]}
                      >
                        <TransactionSummary
                          title={getTitle("Transaction Summary")}
                        />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="/offers"
                    element={
                      <ProtectedRoute
                        allowedRoles={[
                          RoleType.SuperAdmin,
                          RoleType.Admin,
                          RoleType.User,
                          RoleType.Agent,
                        ]}
                      >
                        <Offers title={getTitle("Offers")} />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="/referralList"
                    element={
                      <ProtectedRoute
                        allowedRoles={[
                          RoleType.SuperAdmin,
                          RoleType.Admin,
                          RoleType.User,
                          RoleType.Agent,
                        ]}
                      >
                        <ReferralList title={getTitle("Referral List")} />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="/help"
                    element={
                      <ProtectedRoute
                        allowedRoles={[
                          RoleType.User,
                          RoleType.SuperAdmin,
                          RoleType.Admin,
                          RoleType.Agent,
                        ]}
                      >
                        <Help title={getTitle("Help")} />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="/manageMedia"
                    element={
                      <ProtectedRoute
                        allowedRoles={[RoleType.SuperAdmin, RoleType.Admin]}
                      >
                        <ManageMedia title={getTitle("Manage Media")} />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="/rechargeUser"
                    element={
                      <ProtectedRoute
                        allowedRoles={[
                          RoleType.SuperAdmin,
                          RoleType.Admin,
                          RoleType.Agent,
                        ]}
                      >
                        <RechargeUser title={getTitle("Recharge User")} />
                      </ProtectedRoute>
                    }
                  />

                  <Route
                    path="/agentRechargeList"
                    element={
                      <ProtectedRoute
                        allowedRoles={[RoleType.SuperAdmin, RoleType.Admin]}
                      >
                        <AgentRechargeList
                          title={getTitle("Agent Recharge List")}
                        />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="/allUsers"
                    element={
                      <ProtectedRoute
                        allowedRoles={[RoleType.SuperAdmin, RoleType.Admin]}
                      >
                        <AllUsers title={getTitle("All Users")} />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="/gameSettings"
                    element={
                      <ProtectedRoute
                        allowedRoles={[RoleType.SuperAdmin, RoleType.Admin]}
                      >
                        <GameSettings title={getTitle("Game Settings")} />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="/lotteryGameSettings"
                    element={
                      <ProtectedRoute
                        allowedRoles={[RoleType.SuperAdmin, RoleType.Admin]}
                      >
                        <LotteryGameSettings
                          title={getTitle("Lottery Game Settings")}
                        />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="/LotteryGameSummary"
                    element={
                      <ProtectedRoute
                        allowedRoles={[RoleType.SuperAdmin, RoleType.Admin]}
                      >
                        <LotteryGameSummary
                          title={getTitle("Lottery Game Summary")}
                        />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="/paymentTransactions"
                    element={
                      <ProtectedRoute
                        allowedRoles={[RoleType.SuperAdmin, RoleType.Admin]}
                      >
                        <PaymentTransactions
                          title={getTitle("Payment Transactions")}
                        />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="/referralsHistory"
                    element={
                      <ProtectedRoute
                        allowedRoles={[RoleType.SuperAdmin, RoleType.Admin]}
                      >
                        <ReferralsHistory
                          title={getTitle("Referrals History")}
                        />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="/manageCountry"
                    element={
                      <ProtectedRoute allowedRoles={[RoleType.SuperAdmin]}>
                        <ManageCountry title={getTitle("Manage Country")} />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="/declareResults"
                    element={
                      <ProtectedRoute
                        allowedRoles={[RoleType.SuperAdmin, RoleType.Admin]}
                      >
                        <DeclareResults title={getTitle("Declare Results")} />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="/playLottery"
                    element={
                      <ProtectedRoute
                        allowedRoles={[
                          RoleType.SuperAdmin,
                          RoleType.Admin,
                          RoleType.User,
                          RoleType.Agent,
                        ]}
                      >
                        <PlayGamesLottery title={getTitle("Play Lottery")} />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="/applicationAgents"
                    element={
                      <ProtectedRoute allowedRoles={[RoleType.Admin]}>
                        <ApplicationAgents
                          title={getTitle("Application Agents")}
                        />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="/dbtransactionhistory"
                    element={
                      <ProtectedRoute allowedRoles={[RoleType.Admin]}>
                        <DBTransactionHistory
                          title={getTitle("DB Transaction History")}
                        />
                      </ProtectedRoute>
                    }
                  />
                </Routes>
                {alertConfig && alertConfig.isShow && (
                  <AlertDialog
                    alertConfig={alertConfig}
                    callBack={alertConfig.callBack}
                  />
                )}

                <Snackbar
                  open={toastConfig.open}
                  autoHideDuration={6000}
                  onClose={handleClose}
                >
                  <Alert
                    onClose={handleClose}
                    severity={toastConfig.severity}
                    sx={{ width: "100%" }}
                  >
                    {toastConfig.message}
                  </Alert>
                </Snackbar>
              </ToastContext.Provider>
            </PreferencesContext.Provider>
          </Box>
        </PageVisibility>
      </LoadingContext.Provider>
    </LanguageProvider>
  );
}

export default App;
