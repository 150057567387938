import React, { useContext, useEffect, useState } from "react";
import Box from "@mui/material/Box";
import { Typography } from "@mui/joy";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import { ToastSeverity } from "../../constants/toastSeverity.contants";
import { IApiResponse } from "../../interface/Response/IApiResponse";
import { IApplicationAgentRegistrationModel } from "../../interface/BusinessModels/IApplicationAgentRegistrationModel";
import UserService from "../../services/user/user.service";
import ManageAccountsRoundedIcon from "@mui/icons-material/ManageAccountsRounded";
import { CardContent } from "@mui/material";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import { ToastContext } from "../../context/toast.context";
import Stack from "@mui/material/Stack";
import {
  API_ERROR_STANDARD_MESSAGE,
  DEFAULT_ALERT_CONFIG,
} from "../../constants/DBConstants.constant";
import { HttpStatusCode } from "axios";
import { IAlertDialogConfig } from "../../interface/IAlertDialogConfig";
import Modal from "@mui/material/Modal";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { useDispatch, useSelector } from "react-redux";
import AlertDialog from "../../components/alertDialog/alertDialog.component";

const AgentUsers = () => {
  const dispatch = useDispatch();
  const { userInfo } = useSelector((state: any) => state.authReducer);
  const [activeDeleteId, setActiveDeleteId] = useState<number>(0);
  const _userService = new UserService(dispatch);
  const [agentUsers, setAgentUsers] = useState<
    IApplicationAgentRegistrationModel[]
  >([]);
  const { setToastConfig, setOpen } = useContext(ToastContext);

  const getApplicationAgents = async () => {
    const agentUsersList: IApiResponse = await _userService.getAgentUsers();
    const agentUsersListData = agentUsersList.response as IApplicationAgentRegistrationModel[];
    setAgentUsers(agentUsersListData);
  };

  useEffect(() => {
    getApplicationAgents();
  }, []);

  const [alertConfig, setAlertConfig] = useState<IAlertDialogConfig>({
    isShow: false,
    callBack: () => undefined,
  });

  const deleteApplicationAgent = async () => {
    try {
      var response: IApiResponse = await _userService.deleteAgentUser(
        activeDeleteId
      );
      if (response.isSuccess) {
        setActiveDeleteId(0);
        setAlertConfig({
          description: response.response.message,
          toastSeverity: ToastSeverity.Success,
          isShow: true,
          callBack: () => {
            getApplicationAgents();
            setTimeout(() => {
              setAlertConfig(DEFAULT_ALERT_CONFIG);
            }, 500);
          },
        });
      }
    } catch (error) {
      if (
        error &&
        (error as any).response &&
        (error as any).response.status != HttpStatusCode.Unauthorized
      ) {
        setToastConfig(ToastSeverity.Error, API_ERROR_STANDARD_MESSAGE, true);
      }
    }
  };

  return (
    <Grid container spacing={2}>
      {agentUsers &&
        agentUsers.length > 0 &&
        agentUsers.map((item: IApplicationAgentRegistrationModel) => (
          <Grid item sm={12} xs={12} md={6} lg={4} xl={3}>
            <Box className="commonCard">
              <Card>
                <CardContent>
                  <Box className="commonBgCard saperatorSpacing">
                    <Grid container spacing={2}>
                      <Grid item xs={10} md={10} lg={10}>
                        <Typography className="cardTitle whiteColor">
                          {item.name}
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        xs={2}
                        md={2}
                        lg={2}
                        className="boxRight padL0"
                      >
                        <Box className="verificationStatus verified">
                          <ManageAccountsRoundedIcon />
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
                  <Box className="commonLabelValue">
                    <Typography className="commonLabel">Status</Typography>
                    <Typography className="commonValue">
                      <span className="greenText">Active</span>
                    </Typography>
                  </Box>
                  <Box className="commonLabelValue">
                    <Typography className="commonLabel">Country</Typography>
                    <Typography className="commonValue">
                      {item.country},&nbsp;{item.state}
                    </Typography>
                  </Box>
                  <Box className="commonLabelValue">
                    <Typography className="commonLabel">
                      Contact Type
                    </Typography>
                    <Typography className="commonValue">
                      {item.contactType}
                    </Typography>
                  </Box>
                  <Box className="commonLabelValue">
                    <Typography className="commonLabel">
                      Contact Number
                    </Typography>
                    <Typography className="commonValue">
                      {item.phoneCode}
                      {item.phoneNumber}
                    </Typography>
                  </Box>
                  {/* {userInfo.user.roleId === RoleType.Agent &&
                    userInfo.user.roleId === RoleType.SuperAdmin && (
                      <Box className="bankCardActions">
                        <Box>
                          <IconButton
                            aria-label="edit"
                            className="primaryOverlayIconBtn"
                            onClick={() => console.log("Edit Clicked")}
                          >
                            <EditRoundedIcon />
                          </IconButton>
                          <IconButton
                            aria-label="edit"
                            className="primaryOverlayIconBtn"
                            onClick={() => {
                              const id = item.id ? item.id : 0;
                              setActiveDeleteId(id);
                            }}
                          >
                            <DeleteOutlineOutlinedIcon />
                          </IconButton>
                        </Box>
                      </Box>
                    )} */}
                </CardContent>
                <CardActions>
                  {+item.contactTypeId === 28 ? (
                    <Button
                      variant="contained"
                      className="primaryFillBtn width90"
                      href={`https://api.whatsapp.com/send?text=Hi, I'm the user of your application&phone=+${item.phoneCode}${item.phoneNumber}`}
                    >
                      <span>Contact Agent</span>
                    </Button>
                  ) : (
                    <Button
                      variant="contained"
                      className="primaryFillBtn width90"
                      onClick={() =>
                        setToastConfig(
                          ToastSeverity.Warning,
                          `Contact the agent in telegram using mobile ${item.phoneCode}${item.phoneNumber}`,
                          true
                        )
                      }
                    >
                      <span>Contact Agent</span>
                    </Button>
                  )}
                  {/* {userInfo.user.roleId === RoleType.Agent &&
                    userInfo.user.roleId === RoleType.SuperAdmin && (
                      <IconButton
                        color="error"
                        onClick={() => {
                          const id = item.id ? item.id : 0;
                          setActiveDeleteId(id);
                        }}
                        aria-label="Reload"
                        sx={{ "&:hover": { cursor: "pointer" } }}
                      >
                        <DeleteOutlineOutlinedIcon />
                      </IconButton>
                  )} */}
                </CardActions>
              </Card>
            </Box>
            {activeDeleteId > 0 && (
              <Modal
                className="commonModal"
                open={activeDeleteId > 0}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box className="commonModalBox">
                  <Box className="commonModalBoxInner">
                    <Box id="modal-modal-title" className="commonModalHead">
                      <Grid container spacing={1}>
                        <Grid item xs={11} md={11} lg={11}>
                          <Typography className="modalTitle">
                            Delete Agent
                          </Typography>
                        </Grid>
                        <Grid item xs={1} md={1} lg={1}>
                          <span className="modalClose">
                            <CloseRoundedIcon
                              onClick={() => setActiveDeleteId(0)}
                            />
                          </span>
                        </Grid>
                      </Grid>
                    </Box>
                    <Box
                      id="modal-modal-description"
                      className="commonModalBody"
                    >
                      <Grid container spacing={2}>
                        <Grid item xs={12} md={12} lg={12}>
                          <Typography>
                            Are you sure you want to delete, this action cannot
                            be reverted?
                          </Typography>
                        </Grid>
                      </Grid>
                    </Box>
                    <Box id="modal-modal-footer" className="commonFooter">
                      <Stack spacing={2} direction="row">
                        <Button
                          variant="outlined"
                          className="primaryLineBtn"
                          onClick={() => setActiveDeleteId(0)}
                        >
                          <span>Cancel</span>
                        </Button>
                        <Button
                          variant="contained"
                          className="primaryFillBtn"
                          onClick={() => deleteApplicationAgent()}
                        >
                          <span>Delete</span>
                        </Button>
                      </Stack>
                    </Box>
                  </Box>
                </Box>
              </Modal>
            )}
          </Grid>
        ))}
      {alertConfig && alertConfig.isShow && (
        <AlertDialog
          alertConfig={alertConfig}
          callBack={alertConfig.callBack}
        />
      )}
    </Grid>
  );
};

export default AgentUsers;
