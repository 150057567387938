import {
  AUTH_REQUESTED,
  AUTH_SUCCESS,
  AUTH_LOGOUT,
  AUTH_ERROR,
  AUTH_UNAUTHORIZED,
} from "../constants/Reducer.constant";

import { ISignInResponseModel } from "../interface/Response/ISignInResponseModel";

interface AuthState {
  userInfo: ISignInResponseModel | null;
  isLoading: boolean;
  success: boolean | null;
  message: string | null;
  isUnAuthorised: boolean;
}

const initialState: AuthState = {
  userInfo: null,
  isLoading: false,
  success: false,
  message: "",
  isUnAuthorised: false,
};

const authReducer = (prevState = initialState, action: any) => {
  switch (action.type) {
    case AUTH_REQUESTED:
      return { ...initialState, isLoading: true, success: true, message: null };
    case AUTH_SUCCESS:
      return {
        ...prevState,
        userInfo: action.payload.response,
        isLoading: false,
        isUnAuthorised: false,
      };
    case AUTH_LOGOUT:
    case AUTH_UNAUTHORIZED:
      return { ...initialState };
    case AUTH_ERROR:
      return {
        ...prevState,
        userInfo: null,
        isLoading: false,
        success: false,
        message: action.payload.message,
      };
    default:
      return prevState;
  }
};

export default authReducer;
