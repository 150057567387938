import React, { FunctionComponent } from "react";
import { isReturnStatement } from "typescript";
import PageProps from "../../models/PageProps.interface";

//Libraries Import
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";

//Component Import
import LeftMenu from "../../components/leftMenu/leftMenu.component";
import TopNav from "../../components/topNav/topNav.component";
import Footer from "../../components/footer/footer.component";

//Icons
import HowToRegRoundedIcon from "@mui/icons-material/HowToRegRounded";
import NoAccountsRoundedIcon from "@mui/icons-material/NoAccountsRounded";

//Import Css
import "../referralsHistory/referralsHistory.screen.style.css";

const ReferralsHistory: FunctionComponent<PageProps> = ({ title }) => {
  return (
    <Box className="pageMain">
      <Box className="pageLeft">
        <LeftMenu />
      </Box>
      <Box className="pageRight">
        <Box className="pageHead">
          <TopNav title={title.split("|")[1].trim()} />
        </Box>
        <Box className="pageView">
          <Box className="pageViewBody commonScroll">
            <Box>
              <Box>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6} lg={4}>
                    <Box className="commonCard">
                      <Card>
                        <CardContent>
                          <Box className="commonBgCard saperatorSpacing">
                            <Grid container spacing={2}>
                              <Grid item xs={10} md={10} lg={10}>
                                <Typography className="cardTitle">
                                  6505f0f3523f1a4ea9c304c6
                                </Typography>
                              </Grid>
                              <Grid item xs={2} md={2} lg={2} className="padL0">
                                <Box className="verificationStatus verified">
                                  <HowToRegRoundedIcon />
                                </Box>
                              </Grid>
                            </Grid>
                          </Box>
                          <Box className="commonLabelValue">
                            <Typography className="commonLabel">
                              Email
                            </Typography>
                            <Typography className="commonValue">
                              almas000769@gmail.com
                            </Typography>
                          </Box>
                          <Box className="commonLabelValue">
                            <Typography className="commonLabel">
                              User Id
                            </Typography>
                            <Typography className="commonValue">
                              1411852293
                            </Typography>
                          </Box>
                          <Box className="commonLabelValue">
                            <Typography className="commonLabel">
                              Joined On
                            </Typography>
                            <Typography className="commonValue">
                              September 16, 2023 11:46 PM
                            </Typography>
                          </Box>
                        </CardContent>
                      </Card>
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={6} lg={4}>
                    <Box className="commonCard">
                      <Card>
                        <CardContent>
                          <Box className="commonBgCard saperatorSpacing">
                            <Grid container spacing={2}>
                              <Grid item xs={10} md={10} lg={10}>
                                <Typography className="cardTitle">
                                  6505f0f3523f1a4ea9c304c6
                                </Typography>
                              </Grid>
                              <Grid item xs={2} md={2} lg={2}>
                                <Box className="verificationStatus declined">
                                  <NoAccountsRoundedIcon />
                                </Box>
                              </Grid>
                            </Grid>
                          </Box>
                          <Box className="commonLabelValue">
                            <Typography className="commonLabel">
                              Email
                            </Typography>
                            <Typography className="commonValue">
                              almas000769@gmail.com
                            </Typography>
                          </Box>
                          <Box className="commonLabelValue">
                            <Typography className="commonLabel">
                              User Id
                            </Typography>
                            <Typography className="commonValue">
                              1411852293
                            </Typography>
                          </Box>
                          <Box className="commonLabelValue">
                            <Typography className="commonLabel">
                              Joined On
                            </Typography>
                            <Typography className="commonValue">
                              September 16, 2023 11:46 PM
                            </Typography>
                          </Box>
                        </CardContent>
                      </Card>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </Box>
          <Box className="pageViewFooter">
            <Footer />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
export default ReferralsHistory;
