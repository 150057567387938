import React, {
  FunctionComponent,
  useContext,
  useEffect,
  useState,
} from "react";
import PageProps from "../../models/PageProps.interface";

//Gadgets
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import Link from "@mui/material/Link";
import Fab from "@mui/material/Fab";
//Gadgest - List

//Icons
import { useNavigate } from "react-router-dom";
//Component Import

import "../landingPageV2/landingPageV2.screen.style.css";
import PlayGameService from "../../services/playGame/playGame.service";
import { IApiResponse } from "../../interface/Response/IApiResponse";
import { IHomepageGameDataResponseModel } from "../../interface/Response/IHomepageGameDataResponseModel";
import { AppConfig } from "../../constants/AppConfig.constant";
import LookupService from "../../services/lookup/lookup.service";
import { ILookupModel } from "../../interface/BusinessModels/ILookupModel";
import { ToastSeverity } from "../../constants/toastSeverity.contants";
import {
  API_ERROR_STANDARD_MESSAGE,
  LINKING_WHATSAPP,
  WHATSAPP_DEFAULT_MESSAGE,
} from "../../constants/DBConstants.constant";
import { HttpStatusCode } from "axios";

import CountdownTimer from "../landingPage/countdownTimer";
import { LoadingContext } from "../../context/loading.context";
import { ToastContext } from "../../context/toast.context";
import { useDispatch } from "react-redux";

//Icons
import ApplicationHelperService from "../../services/ApplicationHelperService";

const LandingPageV2: FunctionComponent<PageProps> = ({ title }) => {
  const dispatch = useDispatch();
  const [whatsappLink, setWhatsappLink] = useState<string>("");
  const [whatsappPhone, setWhatsappPhone] = useState<string>("");
  const _lookupService = new LookupService(dispatch);
  const _applicationHelperService = new ApplicationHelperService({});
  const { setLoading, isWebView } = useContext(LoadingContext);
  const { setToastConfig } = useContext(ToastContext);
  // YouTube video options
  const opts = {
    height: "400",
    width: "100%",
    playerVars: {
      autoplay: 0, // Autoplay the video
      controls: 1, // Show player controls
      mute: 0, // Mute the video
      modestbranding: 1, // Remove YouTube logo from player
      fs: 1, // Show fullscreen button
      rel: 0, // Do not show related videos at the end
    },
  };

  const [homeGameData, setHomeGameDate] = useState<
    IHomepageGameDataResponseModel[]
  >();

  // YouTube video ID
  const videoId = "vH864uS3Juk";
  const navigate = useNavigate();
  // const { language, toggleLanguage } = useLanguage();
  const _playGameService = new PlayGameService(dispatch);
  // const content = {
  //   EN: {
  //     welcome: `Welcome to ${AppConfig.applicationName}!`,
  //     play: "Play Thailottery Online",
  //   },
  //   TH: {
  //     welcome: `ยินดีต้อนรับสู่ ${AppConfig.applicationName}!`,
  //     play: "เล่นหวยออนไลน์",
  //   },
  // };

  const handleWhatsAppClick = async () => {
    const linkData = _applicationHelperService.generateWhatsAppLink(
      isWebView,
      WHATSAPP_DEFAULT_MESSAGE,
      whatsappPhone
    );
    _applicationHelperService.sendMessageOnDeviceType(
      isWebView,
      LINKING_WHATSAPP,
      linkData
    );
  };

  useEffect(() => {
    getGameData();
    async function getGameData() {
      var response: IApiResponse = await _playGameService.getHomeGameData();
      var homepageData = response.response as IHomepageGameDataResponseModel[];
      setHomeGameDate(homepageData);
    }
  }, []);

  useEffect(() => {
    getSocialConnectionSettings();

    async function getSocialConnectionSettings() {
      try {
        const socialConnectionSettings: IApiResponse = await _lookupService.getSocialGameSettings();

        const whatsLink = socialConnectionSettings.response.filter(
          (x: ILookupModel) => x.key === "WHATSAPP_LINK"
        )[0].value;
        const whatsPhone = socialConnectionSettings.response.filter(
          (x: ILookupModel) => x.key === "AGENT_WHATSAPP"
        )[0].value;

        if (whatsLink !== "") {
          setWhatsappLink(whatsLink);
          setWhatsappPhone(whatsPhone);
        }
        setLoading(false);
      } catch (error) {
        if (
          error &&
          (error as any).response &&
          (error as any).response.status != HttpStatusCode.Unauthorized
        ) {
          setToastConfig(ToastSeverity.Error, API_ERROR_STANDARD_MESSAGE, true);
        }
        setLoading(false);
      }
    }
  }, []);

  return (
    <Box className="landingV2">
      <Box className="heroSection">
        <Container className="heroSectionContainer">
          <Box className="topNavbar">
            <Box>
              <img
                alt={AppConfig.applicationName}
                className="mainLogo"
                src={require("../../assets/images/common/logo.png")}
              />
            </Box>
            <Box className="topNavLinks">
              <Link href="#" className="linkNav">
                Home
              </Link>
              <Link href="#" className="linkNav">
                About Us
              </Link>
              {/* <Link href="#" className="linkNav">
                <GoogleTranslate />
              </Link> */}
            </Box>
            <Box>
              <Button
                variant="contained"
                className="primaryBtn btnRightSpacing"
                onClick={(event) => {
                  event.preventDefault();
                  navigate("/login");
                }}
              >
                Login
              </Button>
              <Button
                variant="outlined"
                className="secondaryBtn"
                onClick={(event) => {
                  event.preventDefault();
                  navigate("/signup");
                }}
              >
                Signup
              </Button>
              {whatsappLink != "" && (
                <Link
                  href={whatsappLink}
                  className="socialMediaBtn whatsapp d-none"
                  target="_blank"
                >
                  <span>{whatsappPhone}</span>{" "}
                  <span className="socialMediaIcon">
                    <img
                      alt={`${AppConfig.applicationName}  - Whatsapp`}
                      className=""
                      src={require("../../assets/images/dashboard/whatsapp.png")}
                    />
                    {/* <WhatsAppIcon /> */}
                  </span>
                </Link>
              )}
            </Box>
          </Box>
          <Box>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6} lg={6}>
                <Box className="contentCenter heroSectionContent">
                  {/* <Typography className="secondaryTitle">
                    {AppConfig.applicationName} Lottery
                  </Typography> */}
                  <h1 className="mainTitle">
                    Welcome To {AppConfig.applicationName}
                  </h1>
                  <Typography className="commonText">
                    Here you can play different type of 2D, 3D... Upto 6D
                    lotteries(Thailand lottery (2D , 3UP , 6UP), Singapore
                    Lottery(TOTO), Malysia Lottery(4D, Magnum , TOTO),
                    Myanmar(Burma) Lottery(2D,4D), Philippines lottery(PCSO 4D).
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12} md={6} lg={6} className="">
                <Box className="">
                  <img
                    alt={`${AppConfig.applicationName} - Flag`}
                    className="rotatingImage"
                    src={require("../../assets/images/landingPage2V2/bhatBalls.png")}
                  />
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Container>
      </Box>
      <Box
        sx={{
          //width: "100%", // Responsive width
          // maxWidth: "900px", // Restrict maximum width
          //aspectRatio: "3 / 1", // Maintain 3:1 aspect ratio
          overflow: "hidden",
          position: "relative",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row-reverse",
            animation: "scroll 15s linear infinite",
            width: "fit-content",
          }}
        >
          {/* Static Image 1 */}
          <Box
            component="img"
            src={require("../../assets/images/landingPage2V2/banner1.jpg")} // Replace with your image path
            alt="Slide 1"
            sx={{
              width: "900px", // Match container width
              height: "300px", // Match container height
              objectFit: "cover", // Fill container without distortion
              flexShrink: 0,
            }}
          />
          {/* Static Image 2 */}
          <Box
            component="img"
            src={require("../../assets/images/landingPage2V2/banner2.jpg")} // Replace with your image path
            alt="Slide 2"
            sx={{
              width: "900px",
              height: "300px",
              objectFit: "cover",
              flexShrink: 0,
            }}
          />
          {/* Static Image 3 */}
          <Box
            component="img"
            src={require("../../assets/images/landingPage2V2/banner3.jpg")} // Replace with your image path
            alt="Slide 3"
            sx={{
              width: "900px",
              height: "300px",
              objectFit: "cover",
              flexShrink: 0,
            }}
          />
          <Box
            component="img"
            src={require("../../assets/images/landingPage2V2/banner4.jpg")} // Replace with your image path
            alt="Slide 3"
            sx={{
              width: "900px",
              height: "300px",
              objectFit: "cover",
              flexShrink: 0,
            }}
          />
          <Box
            component="img"
            src={require("../../assets/images/landingPage2V2/banner5.jpg")} // Replace with your image path
            alt="Slide 3"
            sx={{
              width: "900px",
              height: "300px",
              objectFit: "cover",
              flexShrink: 0,
            }}
          />
        </Box>

        <style>
          {`
      @keyframes scroll {
        0% {
          transform: translateX(0);
        }
        100% {
          transform: translateX(-2700px); /* Adjust for total width (900px * 3) */
        }
      }
    `}
        </style>
      </Box>

      <Box className="howToPlaySection">
        <Container>
          <Typography className="secondaryTitle text-center">
            A2Z LOTTO in 3 Steps
          </Typography>
          {/* <h2 className="mainTitle text-center">How to Win the Lottery</h2> */}
          {/* <Typography className="commonText text-center stepDescription">
            To play the A2z Lottery, If your number matches the winning one, you win
            a prize. Here's how to get started:
          </Typography> */}
          <Grid container spacing={2}>
            <Grid item xs={12} md={4} lg={4}>
              <Box className="playType">
                <h3 className="mainTitle">
                  <span className="count">01</span>Register
                </h3>
                <Box className="playTypeBody">
                  <img
                    alt={AppConfig.applicationName}
                    className="playTypeImage"
                    src={require("../../assets/images/landingPage2V2/register.svg")}
                  />
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} md={4} lg={4}>
              <Box className="playType">
                <h3 className="mainTitle">
                  <span className="count">02</span>Play Game
                </h3>
                <Box className="playTypeBody">
                  <img
                    alt={AppConfig.applicationName}
                    className="playTypeImage"
                    src={require("../../assets/images/landingPage2V2/play.svg")}
                  />
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} md={4} lg={4}>
              <Box className="playType">
                <h3 className="mainTitle">
                  <span className="count">03</span>Win Lottery
                </h3>
                <Box className="playTypeBody">
                  <img
                    alt={AppConfig.applicationName}
                    className="playTypeImage"
                    src={require("../../assets/images/landingPage2V2/win.svg")}
                  />
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>
      <Box className="eventsSection saperationSection">
        <Container>
          <Grid container spacing={1}>
            {homeGameData &&
              homeGameData.length > 0 &&
              homeGameData.map((item: IHomepageGameDataResponseModel) => (
                <Grid item xs={12} md={6} lg={6}>
                  <Box className="lotteryResult commonCard">
                    <Box className="commonBgCard">
                      <Typography className="gameDate cardTitle textCenter">
                        {item.title}
                      </Typography>

                      <Box className="lotteryNumbers">
                        <Box className="firstPrizeBox">
                          <Box className="prizeType textCenter">
                            First Prize
                          </Box>
                          <Box className="numberCircleGroup">
                            {item.firstPrize
                              .split("")
                              .map((x: string, index: number) => (
                                <span key={index} className="numberCircle">
                                  {x}
                                </span>
                              ))}
                          </Box>
                        </Box>
                        <Box>
                          <Grid container spacing={1}>
                            <Grid item xs={6} md={6} lg={6}>
                              <Box className="secondPrizeBox">
                                <Box className="prizeType textCenter">3-Up</Box>
                                <Box className="numberCircleGroup">
                                  {item.threeUpStraight
                                    .split("")
                                    .map((x: string, index: number) => (
                                      <span
                                        key={index}
                                        className="numberCircle"
                                      >
                                        {x}
                                      </span>
                                    ))}
                                </Box>
                              </Box>
                            </Grid>
                            <Grid item xs={6} md={6} lg={6}>
                              <Box className="thirdPrizeBox">
                                <Box className="prizeType textCenter">
                                  2-Down
                                </Box>
                                <Box className="numberCircleGroup">
                                  {item.twoDownStraight
                                    .split("")
                                    .map((x: string, index: number) => (
                                      <span
                                        key={index}
                                        className="numberCircle"
                                      >
                                        {x}
                                      </span>
                                    ))}
                                </Box>
                              </Box>
                            </Grid>
                          </Grid>
                        </Box>
                        <Box>
                          <Button
                            onClick={() =>
                              navigate("resultsHistory", {
                                state: item.gameId,
                              })
                            }
                            variant="text"
                            className="width100 allResultsList"
                          >
                            See All Lottery Results
                          </Button>
                        </Box>
                      </Box>
                      <Box className="nextDraw">
                        <Box className="prizeType textCenter">NEXT DRAW</Box>
                        <Typography className="prizeType">
                          {item.nextDrawDate}
                        </Typography>
                        <Box className="timer">
                          <CountdownTimer calcFor={item.nextDrawDateTime} />
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </Grid>
              ))}
          </Grid>
        </Container>
      </Box>
      <Box className="acceptedPaymentsSection boxSpacing">
        <Container>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6} lg={6}>
              <div className="scroll-container">
                <div className="scroll-content">
                  <Box className="wh100">
                    <Box className="mainPart">
                      <Box className="imgPart">
                        <img
                          alt={AppConfig.applicationName}
                          className="paymentType"
                          src={require("../../assets/images/landingPage2V2/Frame 2.png")}
                        />
                      </Box>
                      <Box className="imgPart">
                        <img
                          alt={AppConfig.applicationName}
                          className="paymentType"
                          src={require("../../assets/images/landingPage2V2/Frame 5.png")}
                        />
                      </Box>
                      <Box className="imgPart">
                        <img
                          alt={AppConfig.applicationName}
                          className="paymentType"
                          src={require("../../assets/images/landingPage2V2/Frame 2.png")}
                        />
                      </Box>
                      <Box className="imgPart">
                        <img
                          alt={AppConfig.applicationName}
                          className="paymentType"
                          src={require("../../assets/images/landingPage2V2/Frame 5.png")}
                        />
                      </Box>
                      <Box className="imgPart">
                        <img
                          alt={AppConfig.applicationName}
                          className="paymentType"
                          src={require("../../assets/images/landingPage2V2/Frame 2.png")}
                        />
                      </Box>
                      <Box className="imgPart">
                        <img
                          alt={AppConfig.applicationName}
                          className="paymentType"
                          src={require("../../assets/images/landingPage2V2/Frame 5.png")}
                        />
                      </Box>
                      <Box className="imgPart">
                        <img
                          alt={AppConfig.applicationName}
                          className="paymentType"
                          src={require("../../assets/images/landingPage2V2/Frame 2.png")}
                        />
                      </Box>
                      <Box className="imgPart">
                        <img
                          alt={AppConfig.applicationName}
                          className="paymentType"
                          src={require("../../assets/images/landingPage2V2/Frame 5.png")}
                        />
                      </Box>
                      <Box className="imgPart">
                        <img
                          alt={AppConfig.applicationName}
                          className="paymentType"
                          src={require("../../assets/images/landingPage2V2/Frame 2.png")}
                        />
                      </Box>
                      <Box className="imgPart">
                        <img
                          alt={AppConfig.applicationName}
                          className="paymentType"
                          src={require("../../assets/images/landingPage2V2/Frame 5.png")}
                        />
                      </Box>
                      <Box className="imgPart">
                        <img
                          alt={AppConfig.applicationName}
                          className="paymentType"
                          src={require("../../assets/images/landingPage2V2/Frame 2.png")}
                        />
                      </Box>
                      <Box className="imgPart">
                        <img
                          alt={AppConfig.applicationName}
                          className="paymentType"
                          src={require("../../assets/images/landingPage2V2/Frame 5.png")}
                        />
                      </Box>
                      <Box className="imgPart">
                        <img
                          alt={AppConfig.applicationName}
                          className="paymentType"
                          src={require("../../assets/images/landingPage2V2/Frame 2.png")}
                        />
                      </Box>
                      <Box className="imgPart">
                        <img
                          alt={AppConfig.applicationName}
                          className="paymentType"
                          src={require("../../assets/images/landingPage2V2/Frame 5.png")}
                        />
                      </Box>
                      <Box className="imgPart">
                        <img
                          alt={AppConfig.applicationName}
                          className="paymentType"
                          src={require("../../assets/images/landingPage2V2/Frame 2.png")}
                        />
                      </Box>
                      <Box className="imgPart">
                        <img
                          alt={AppConfig.applicationName}
                          className="paymentType"
                          src={require("../../assets/images/landingPage2V2/Frame 5.png")}
                        />
                      </Box>
                    </Box>
                    <Box className="mainPart">
                      <Box className="imgPart">
                        <img
                          alt={AppConfig.applicationName}
                          className="paymentType"
                          src={require("../../assets/images/landingPage2V2/Frame 4.png")}
                        />
                      </Box>
                      <Box className="imgPart">
                        <img
                          alt={AppConfig.applicationName}
                          className="paymentType"
                          src={require("../../assets/images/landingPage2V2/Frame 3.png")}
                        />
                      </Box>
                      <Box className="imgPart">
                        <img
                          alt={AppConfig.applicationName}
                          className="paymentType"
                          src={require("../../assets/images/landingPage2V2/Frame 4.png")}
                        />
                      </Box>
                      <Box className="imgPart">
                        <img
                          alt={AppConfig.applicationName}
                          className="paymentType"
                          src={require("../../assets/images/landingPage2V2/Frame 3.png")}
                        />
                      </Box>
                      <Box className="imgPart">
                        <img
                          alt={AppConfig.applicationName}
                          className="paymentType"
                          src={require("../../assets/images/landingPage2V2/Frame 4.png")}
                        />
                      </Box>
                      <Box className="imgPart">
                        <img
                          alt={AppConfig.applicationName}
                          className="paymentType"
                          src={require("../../assets/images/landingPage2V2/Frame 3.png")}
                        />
                      </Box>
                      <Box className="imgPart">
                        <img
                          alt={AppConfig.applicationName}
                          className="paymentType"
                          src={require("../../assets/images/landingPage2V2/Frame 4.png")}
                        />
                      </Box>
                      <Box className="imgPart">
                        <img
                          alt={AppConfig.applicationName}
                          className="paymentType"
                          src={require("../../assets/images/landingPage2V2/Frame 3.png")}
                        />
                      </Box>
                      <Box className="imgPart">
                        <img
                          alt={AppConfig.applicationName}
                          className="paymentType"
                          src={require("../../assets/images/landingPage2V2/Frame 4.png")}
                        />
                      </Box>
                      <Box className="imgPart">
                        <img
                          alt={AppConfig.applicationName}
                          className="paymentType"
                          src={require("../../assets/images/landingPage2V2/Frame 3.png")}
                        />
                      </Box>
                      <Box className="imgPart">
                        <img
                          alt={AppConfig.applicationName}
                          className="paymentType"
                          src={require("../../assets/images/landingPage2V2/Frame 4.png")}
                        />
                      </Box>
                      <Box className="imgPart">
                        <img
                          alt={AppConfig.applicationName}
                          className="paymentType"
                          src={require("../../assets/images/landingPage2V2/Frame 3.png")}
                        />
                      </Box>
                      <Box className="imgPart">
                        <img
                          alt={AppConfig.applicationName}
                          className="paymentType"
                          src={require("../../assets/images/landingPage2V2/Frame 4.png")}
                        />
                      </Box>
                      <Box className="imgPart">
                        <img
                          alt={AppConfig.applicationName}
                          className="paymentType"
                          src={require("../../assets/images/landingPage2V2/Frame 3.png")}
                        />
                      </Box>
                      <Box className="imgPart">
                        <img
                          alt={AppConfig.applicationName}
                          className="paymentType"
                          src={require("../../assets/images/landingPage2V2/Frame 4.png")}
                        />
                      </Box>
                      <Box className="imgPart">
                        <img
                          alt={AppConfig.applicationName}
                          className="paymentType"
                          src={require("../../assets/images/landingPage2V2/Frame 3.png")}
                        />
                      </Box>
                    </Box>
                  </Box>
                </div>
              </div>
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              <Box className="">
                <h2 className="mainTitle">
                  Accepted <br /> Payment Methods
                </h2>
                <Box>
                  <Typography className="secondaryTitle">
                    Cards and Digital Wallets
                  </Typography>
                  <Typography className="commonText">
                    Secure options like Visa, MasterCard, and JCB are widely
                    accepted, while PayPal, Apple Pay, Google Pay, and Samsung
                    Pay offer fast, convenient payments for lottery
                    participants.
                  </Typography>
                </Box>
                <Box>
                  <Typography className="secondaryTitle">
                    Bank Transfers
                  </Typography>
                  <Typography className="commonText">
                    Direct bank transfers and eChecks are trusted methods, ideal
                    for larger ticket purchases or claiming winnings, ensuring
                    secure and smooth transactions.
                  </Typography>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>
      <Box className="socialMediaSection boxSpacing">
        <Box className="socialBg">
          <Box className="contentCenter">
            <Grid container spacing={2}>
              <Grid item xs={12} md={4} lg={4} className="contentCenter">
                <Box>
                  <img
                    alt={AppConfig.applicationName}
                    className="footerLogo"
                    src={require("../../assets/images/landingPage2V2/adult.png")}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} md={8} lg={8} alignItems={"flex-start"}>
                <Box sx={{ width: 800 }}>
                  <h2>Play Responsibly</h2>
                  <Typography sx={{ fontSize: 20 }}>
                    At The a2zlotto , we are deeply committed to Responsible
                    Gaming. We believe that lottery games should be an enjoyable
                    form of entertainment, and our mission is to provide a safe
                    and secure environment for our customers. · While playing
                    lottery games is a leisure activity for many, we understand
                    that, for some, it can lead to problematic behaviour. That’s
                    why we&quot;ve implemented a range of tools and support
                    measures to help players manage their play, set limits, and
                    take a break if needed.
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Box>
      <Box className="acceptedPaymentsSection boxSpacing">
        <Container>
          <h2 className="mainTitle">Responsible Gaming Guidelines</h2>
          <Grid container spacing={2}>
            <Grid item xs={12} md={12} lg={12}>
              <Typography className="commonText">
                Lottery games are games of chance, and outcomes are never
                guaranteed. Playing should be for entertainment only. To help
                you stay in control, we encourage you to follow these simple
                guidelines:
              </Typography>
            </Grid>
            <Grid
              justifyContent={"flex-start"}
              alignItems={"flex-start"}
              item
              xs={12}
              md={12}
              lg={12}
            >
              <Box>
                <Typography className="secondaryTitle">
                  Only play with what you can afford to lose
                </Typography>
                <Typography className="commonText">
                  It&quot;s essential to set limits and ensure your spending
                  does not exceed what you are comfortable with
                </Typography>
              </Box>
            </Grid>
            <Grid textAlign={"right"} item xs={12} md={12} lg={12}>
              <Box>
                <Typography className="secondaryTitle">
                  Set time limits
                </Typography>
                <Typography className="commonText">
                  Decide how much time you wish to spend playing and stick to
                  it.
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} md={12} lg={12}>
              <Box>
                <Typography className="secondaryTitle">
                  Establish a budget
                </Typography>
                <Typography className="commonText">
                  Plan how much you are willing to spend and avoid exceeding
                  your budget.
                </Typography>
              </Box>
            </Grid>
            <Grid textAlign={"right"} item xs={12} md={12} lg={12}>
              <Box>
                <Typography className="secondaryTitle">
                  Avoid chasing losses
                </Typography>
                <Typography className="commonText">
                  Accept that losses are a part of the game. Chasing losses can
                  lead to harmful behaviour.
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} md={12} lg={12}>
              <Box>
                <Typography className="secondaryTitle">
                  Know the rules
                </Typography>
                <Typography className="commonText">
                  Make sure you are familiar with the rules of the games before
                  playing to ensure you make informed decisions.
                </Typography>
              </Box>
            </Grid>
            <Grid textAlign={"right"} item xs={12} md={12} lg={12}>
              <Box>
                <Typography className="secondaryTitle">
                  Balance your entertainment
                </Typography>
                <Typography className="commonText">
                  Make sure lottery play is just one of many enjoyable
                  activities in your life.
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} md={12} lg={12}>
              <Box>
                <Typography className="secondaryTitle">
                  Avoid playing under the influence
                </Typography>
                <Typography className="commonText">
                  Gaming while under the influence of alcohol can impair
                  judgment and lead to risky decisions.
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>
      <Box className="footerSection">
        <Box className="footerSectionTop">
          <Box>
            <img
              alt={AppConfig.applicationName}
              className="footerLogo"
              src={require("../../assets/images/common/logo.png")}
            />
          </Box>
          {/* <Typography className="commonText footerInfo">
            The Thailand Lottery is a government-regulated lottery held twice a
            month, offering cash prizes, including a grand prize. Proceeds
            support social welfare programs in the country.
          </Typography> */}
          <Box className="footerLinksPart">
            <Link href="/agentRegistration" className="linkNav">
              Agent Registration
            </Link>
            <Link href="/rules" className="linkNav">
              Rules & Conditions
            </Link>
            {/* <Link href="/exchangeRates" className="linkNav">
              Exchange Rates
            </Link> */}
            <Link href="/resultsHistory" className="linkNav">
              All Lottery Game Results
            </Link>
          </Box>
          <Box>
            <a href={"https://a2zlotto.com/A2zLotto.apk"}>
              <img
                alt="Play Store"
                className="btnRightSpacing"
                src={require("../../assets/images/landingPage2V2/PlayStoreIcon.png")}
              />
            </a>

            <img
              alt="Play Store"
              className=""
              src={require("../../assets/images/landingPage2V2/AppStoreIcon.png")}
            />
          </Box>
        </Box>
        <Box className="footerSectionBottom">
          <Typography>
            © 2025 {AppConfig.applicationName}.COM, All Rights Reserved.
          </Typography>
        </Box>
      </Box>
      <Box className="fabBtn">
        <Fab
          color="secondary"
          aria-label="Whatsapp"
          onClick={handleWhatsAppClick}
        >
          <img
            alt={`${AppConfig.applicationName}  - Whatsapp`}
            src={require("../../assets/images/landingPage2V2/whatsapp.png")}
          />
        </Fab>
      </Box>
    </Box>
  );
};
export default LandingPageV2;
