import React from "react";
import Box from "@mui/material/Box";
import { Typography } from "@mui/joy";

import { useNavigate } from "react-router-dom";

//Gadgets
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Link from "@mui/material/Link";

//Gadgest - List
import List from "@mui/material/List";
import ListItemText from "@mui/material/ListItemText";
import ListItem from "@mui/material/ListItem";

//Icons
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import TelegramIcon from "@mui/icons-material/Telegram";
import YouTubeIcon from "@mui/icons-material/YouTube";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import MailIcon from "@mui/icons-material/Mail";
import TwitterIcon from "@mui/icons-material/Twitter";

//Css
import "../mainFooter/mainFooter.component.style.css";

const MainFooter = () => {
  const navigate = useNavigate();
  return (
    <Box className="">
      <Container>
        <Box>
          <Grid container spacing={1} className="footerBodyPart">
            <Grid item xs={2} md={2} lg={2} className="footerLogo">
              <Box className="">
                <img
                  alt="A2zLotto - Brand Logo"
                  className="commonImage"
                  src={require("../../assets/images/common/logoWhite.png")}
                />
              </Box>
            </Grid>
            <Grid item xs={10} md={10} lg={10}>
              <Box>
                <Grid container spacing={1}>
                  <Grid item xs={12} md={12} lg={12}>
                    <Box>
                      <Typography className="footerSectionTitle">
                        Our Link
                      </Typography>
                      <List component="ol" className="footerList">
                        <ListItem
                          component="a"
                          href="#item1"
                          className="whiteText"
                        >
                          <ListItemText
                            onClick={(event) => {
                              event.preventDefault();
                              navigate("/login");
                            }}
                            primary="Sign In"
                          />
                        </ListItem>
                        <ListItem
                          component="a"
                          href="#item2"
                          className="whiteText"
                        >
                          <ListItemText
                            onClick={(event) => {
                              event.preventDefault();
                              navigate("/signup");
                            }}
                            primary="Create new Account"
                          />
                        </ListItem>
                        <ListItem
                          component="a"
                          href="#item3"
                          className="whiteText"
                        >
                          <ListItemText
                            onClick={(event) => {
                              event.preventDefault();
                              navigate("/agentRegistration");
                            }}
                            primary="Agent Registration"
                          />
                        </ListItem>
                        <ListItem
                          component="a"
                          href="#item4"
                          className="whiteText"
                        >
                          <ListItemText
                            onClick={(event) => {
                              event.preventDefault();
                              navigate("/rules");
                            }}
                            primary="Rules and Conditions"
                          />
                        </ListItem>
                        <ListItem
                          component="a"
                          href="#item5"
                          className="whiteText"
                        >
                          <ListItemText
                            onClick={(event) => {
                              event.preventDefault();
                              navigate("/exchangeRates");
                            }}
                            primary="Exchange Rates"
                          />
                        </ListItem>
                        <ListItem
                          component="a"
                          href="#item6"
                          className="whiteText"
                        >
                          <ListItemText
                            onClick={(event) => {
                              event.preventDefault();
                              navigate("/resultsHistory");
                            }}
                            primary="All Lottery Game Results"
                          />
                        </ListItem>
                        {/* <ListItem
                                component="a"
                                href="#item7"
                                className="whiteText"
                              >
                                <ListItemText primary="Lottery Result Chart" />
                              </ListItem> */}
                      </List>
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={5} lg={5}>
                    <Typography className="footerSectionTitle">
                      Stay With Us
                    </Typography>
                    <Box className="socialMediaLinks">
                      <Box className="socialMediaType">
                        <Link href="https://www.facebook.com/" target="_blank">
                          <FacebookIcon />
                        </Link>
                      </Box>
                      <Box className="socialMediaType">
                        <Link href="https://www.instagram.com/" target="_blank">
                          <InstagramIcon />
                        </Link>
                      </Box>
                      <Box className="socialMediaType">
                        <Link
                          href="https://web.telegram.org/.socialMediaLinks"
                          target="_blank"
                        >
                          <TelegramIcon />
                        </Link>
                      </Box>
                      <Box className="socialMediaType">
                        <Link href="https://www.youtube.com/" target="_blank">
                          <YouTubeIcon />
                        </Link>
                      </Box>
                      <Box className="socialMediaType">
                        <Link href="https://www.whatsapp.com/" target="_blank">
                          <WhatsAppIcon />
                        </Link>
                      </Box>
                      <Box className="socialMediaType">
                        <Link href="https://www.gmail.com/" target="_blank">
                          <MailIcon />
                        </Link>
                      </Box>
                      <Box className="socialMediaType">
                        <Link href="https://www.twitter.com/" target="_blank">
                          <TwitterIcon />
                        </Link>
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={1} lg={1}>
                    <Box>&nbsp;</Box>
                  </Grid>
                  <Grid item xs={12} md={6} lg={6}>
                    <Typography className="footerSectionTitle">
                      Download Application
                    </Typography>
                    <Stack spacing={2} direction="row" className="floatL">
                      <Button
                        type="submit"
                        variant="outlined"
                        className="appDownloadBtn"
                      >
                        <img
                          alt="Play Store"
                          className=""
                          src={require("../../assets/images/landingPage/PlayStoreIcon.png")}
                        />
                      </Button>
                      <Button
                        type="submit"
                        variant="outlined"
                        className="appDownloadBtn"
                      >
                        <img
                          alt="Profile Photo"
                          className="App Store"
                          src={require("../../assets/images/landingPage/AppStoreIcon.png")}
                        />
                      </Button>
                    </Stack>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </Box>
  );
};

export default MainFooter;
