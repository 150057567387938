import { Component, Dispatch } from "react";
import HttpHelperService from "../httpHelper.service";
import { ILoginModel } from "../../interface/Request/ILoginModel";
import { IForgotPasswordModel } from "../../interface/Request/IForgotPasswordModel";
import {
  FORGOT_PASSWORD,
  RESET_PASSWORD,
} from "../../constants/EndPoints.constant";
import { IResetPasswordModel } from "../../interface/Request/IResetPasswordModel";
import { Action } from "redux";

class AuthService {
  _httpHelperService;
  constructor(dispatch: Dispatch<Action>) {
    this._httpHelperService = new HttpHelperService(dispatch);
  }

  userLogin = async (loginModel: ILoginModel) => {
    return await this._httpHelperService.login(loginModel);
  };

  forgotPassword = async (forgotPasswordModel: IForgotPasswordModel) => {
    return await this._httpHelperService.post(
      FORGOT_PASSWORD,
      forgotPasswordModel
    );
  };

  resetPassword = async (forgotPasswordModel: IResetPasswordModel) => {
    return await this._httpHelperService.post(
      RESET_PASSWORD,
      forgotPasswordModel
    );
  };
}

export default AuthService;
