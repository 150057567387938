import React, {
  FunctionComponent,
  useContext,
  useEffect,
  useState,
} from "react";
import PageProps from "../../../models/PageProps.interface";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Link from "@mui/material/Link";
import { useLocation, useNavigate } from "react-router-dom";

//Component Import

//Form
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputAdornment from "@mui/material/InputAdornment";

//Icons
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

//Styles
import "../signup/signup.screen.style.css";
import { IUserRegistrationModel } from "../../../interface/BusinessModels/IUserRegistrationModel";

import * as yup from "yup";
import { Formik } from "formik";
import { getCountries } from "../../../actions/lookup.actions";
import { useDispatch, useSelector } from "react-redux";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { ICountryModel } from "../../../interface/BusinessModels/ICountryModel";
import FormHelperText from "@mui/material/FormHelperText";
import { ToastContext } from "../../../context/toast.context";
import { ToastSeverity } from "../../../constants/toastSeverity.contants";
import { LoadingContext } from "../../../context/loading.context";
import UserService from "../../../services/user/user.service";
import { IApiResponse } from "../../../interface/Response/IApiResponse";
import { IAlertDialogConfig } from "../../../interface/IAlertDialogConfig";
import {
  API_ERROR_STANDARD_MESSAGE,
  DEFAULT_ALERT_CONFIG,
  RoleType,
} from "../../../constants/DBConstants.constant";
import { HttpStatusCode } from "axios";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import { AppConfig } from "../../../constants/AppConfig.constant";

// const Transition = React.forwardRef(function Transition(
//   props: TransitionProps & {
//     children: React.ReactElement<any, any>;
//   },
//   ref: React.Ref<unknown>
// ) {
//   return <Slide direction="up" ref={ref} {...props} />;
// });

const Signup: FunctionComponent<PageProps> = ({ title }) => {
  //Navigation
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { setToastConfig, setOpen } = useContext(ToastContext);
  const { setLoading } = useContext(LoadingContext);
  //Password Show Hide
  const [showPassword, setShowPassword] = React.useState<boolean>(false);
  const [confirmPassword, setConfirmPassword] = useState<string>("");
  const [countryId, setCountryId] = useState(0);
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const initializeCountries = () => dispatch<any>(getCountries());
  const { countries } = useSelector((state: any) => state.lookupReducer);
  const _userService = new UserService(dispatch);
  const [alertConfig, setAlertConfig] = useState<IAlertDialogConfig>(
    DEFAULT_ALERT_CONFIG
  );
  const [referral, setReferral] = useState<string>("");
  const [signUpData, setSignUpData] = React.useState<any>({
    username: "",
    password: "",
  });

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  let location = useLocation();
  const referralId = new URLSearchParams(location.search).get("ref");

  useEffect(() => {
    if (referralId) {
      setReferral(referralId);
    }
  }, [location]);

  const InitialValues: IUserRegistrationModel = {
    email: "bhat@gmail.com",
    countryCode: "",
    phoneCode: "",
    phoneNumber: "",
    password: "",
    referredBy: referralId ? referralId : "",
    roleId: RoleType.User,
  };

  const SignupSchema = yup.object().shape({
    // email: yup
    //   .string()
    //   .email("Please enter a valid email")
    //   .required("This field is required"),
    countryCode: yup.string().required("Country Code is required."),
    phoneCode: yup.string().required("Phone Code is required."),
    phoneNumber: yup.string().required("Phone Number is required."),
    password: yup
      .string()
      .required("Password is required.")
      .min(8, "Should be minimum of 8 characters"),
    referredBy: yup.string(),
  });

  useEffect(() => {
    setLoading(true);
    initializeCountries();
    setLoading(false);
  }, []);

  const _handleSignUp = async (
    values: IUserRegistrationModel,
    formikHelpers: any
  ) => {
    try {
      setLoading(true);
      localStorage.removeItem(`${AppConfig.applicationName}LoggedInUserInfo`);
      localStorage.removeItem(`A2Z LOTTOLoggedInUserInfo`);
      const isValid = await SignupSchema.isValid(values);
      if (isValid) {
        if (confirmPassword != values.password) {
          setToastConfig(
            ToastSeverity.Error,
            "Password & confirm password doesn't match.",
            true
          );
        } else {
          var response: IApiResponse = await _userService.registerUser({
            ...values,
            phoneNumber: values.phoneNumber.toString(),
          });
          if (!response.isSuccess) {
            setToastConfig(ToastSeverity.Error, response.message, true);
          } else {
            setSignUpData({
              password: values.password,
              username: response.response.user.appId,
            });
            setAlertConfig({
              description: response.message,
              toastSeverity: ToastSeverity.Success,
              isShow: true,
              callBack: () => {
                setAlertConfig(DEFAULT_ALERT_CONFIG);
                navigate("/login");
              },
            });
          }
        }
      }
    } catch (error) {
      if (
        error &&
        (error as any).response &&
        (error as any).response.status != HttpStatusCode.Unauthorized
      ) {
        setToastConfig(ToastSeverity.Error, API_ERROR_STANDARD_MESSAGE, true);
      }
      console.log(error);
    }
    formikHelpers.setSubmitting(false);
    setLoading(false);
  };

  return (
    <Formik
      initialValues={{ ...InitialValues }}
      validationSchema={SignupSchema}
      onSubmit={(values, formikHelpers) => {
        formikHelpers.setSubmitting(true);
        _handleSignUp(values, formikHelpers);
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        isValid,
        /* and other goodies */
      }) => (
        <form
          onSubmit={(e) => {
            e.preventDefault();
            handleSubmit(e);
          }}
          className="height100"
        >
          <Box className="pageMain accountMain">
            <Box className="pageView">
              <Box className="accountBody height100">
                <Grid container spacing={2} className="height100">
                  <Grid item xs={12} md={12} lg={12}>
                    <Box className="accountBodyRight height100">
                      <Box className="commonCard">
                        <Card className="height100">
                          <CardContent>
                            <Typography className="accountTitle">
                              Signup
                            </Typography>
                            <Box>
                              <Grid container spacing={2}>
                                {/* <Grid item xs={12} md={12} lg={12}>
                                  <TextField
                                    type="email"
                                    id="email"
                                    label="Email"
                                    variant="outlined"
                                    fullWidth
                                    onChange={handleChange}
                                    value={values.email}
                                  />

                                  {errors.email && touched.email && (
                                    <FormHelperText className="errorMessage">
                                      {errors.email}
                                    </FormHelperText>
                                  )}
                                </Grid> */}
                                <Grid item xs={12} md={12} lg={4}>
                                  <FormControl variant="outlined" fullWidth>
                                    <InputLabel id="outlined-country-dropdown-label">
                                      Country
                                    </InputLabel>
                                    <Select
                                      labelId="outlined-country-dropdown-label"
                                      id="country-dropdown"
                                      label="Country"
                                      value={countryId.toString()}
                                      onChange={(evt: SelectChangeEvent) => {
                                        if (evt.target.value === "0") {
                                          values.phoneCode = "";
                                          values.countryCode = "";
                                        } else {
                                          const country: ICountryModel[] = countries.filter(
                                            (x: ICountryModel) =>
                                              x.id.toString() ===
                                              evt.target.value.toString()
                                          );
                                          values.phoneCode =
                                            country[0].phoneCode;
                                          values.countryCode = country[0].code;
                                        }
                                        setCountryId(+evt.target.value);
                                      }}
                                    >
                                      <MenuItem value={0}>Select</MenuItem>
                                      {countries &&
                                        countries.map(
                                          (country: ICountryModel) => (
                                            <MenuItem
                                              key={country.id}
                                              value={country.id.toString()}
                                            >
                                              {country.name}
                                            </MenuItem>
                                          )
                                        )}
                                    </Select>
                                    {errors.countryCode &&
                                      touched.countryCode && (
                                        <FormHelperText className="errorMessage">
                                          {errors.countryCode}
                                        </FormHelperText>
                                      )}
                                  </FormControl>
                                </Grid>
                                <Grid item xs={12} md={12} lg={2}>
                                  <TextField
                                    id="countryCode"
                                    label="Code"
                                    variant="outlined"
                                    fullWidth
                                    disabled
                                    value={values.phoneCode}
                                  />
                                </Grid>
                                <Grid item xs={12} md={12} lg={6}>
                                  <TextField
                                    type="number"
                                    id="phoneNumber"
                                    label="Phone Number"
                                    variant="outlined"
                                    fullWidth
                                    onChange={handleChange}
                                    value={values.phoneNumber.toString()}
                                  />
                                  {errors.phoneNumber &&
                                    touched.phoneNumber && (
                                      <FormHelperText className="errorMessage">
                                        {errors.phoneNumber}
                                      </FormHelperText>
                                    )}
                                </Grid>
                                <Grid item xs={12} md={12} lg={6}>
                                  <FormControl variant="outlined" fullWidth>
                                    <InputLabel htmlFor="password-label">
                                      Password
                                    </InputLabel>
                                    <OutlinedInput
                                      id="password"
                                      type={showPassword ? "text" : "password"}
                                      endAdornment={
                                        <InputAdornment position="end">
                                          <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={handleClickShowPassword}
                                            onMouseDown={
                                              handleMouseDownPassword
                                            }
                                            edge="end"
                                          >
                                            {showPassword ? (
                                              <VisibilityOff />
                                            ) : (
                                              <Visibility />
                                            )}
                                          </IconButton>
                                        </InputAdornment>
                                      }
                                      label="Password"
                                      onChange={handleChange}
                                      value={values.password}
                                    />
                                    {errors.password && touched.password && (
                                      <FormHelperText className="errorMessage">
                                        {errors.password}
                                      </FormHelperText>
                                    )}
                                  </FormControl>
                                </Grid>
                                <Grid item xs={12} md={12} lg={6}>
                                  <FormControl variant="outlined" fullWidth>
                                    <InputLabel htmlFor="outlined-adornment-confirm-password">
                                      Confirm Password
                                    </InputLabel>
                                    <OutlinedInput
                                      id="outlined-adornment-confirm-password"
                                      type={showPassword ? "text" : "password"}
                                      endAdornment={
                                        <InputAdornment position="end">
                                          <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={handleClickShowPassword}
                                            onMouseDown={
                                              handleMouseDownPassword
                                            }
                                            edge="end"
                                          >
                                            {showPassword ? (
                                              <VisibilityOff />
                                            ) : (
                                              <Visibility />
                                            )}
                                          </IconButton>
                                        </InputAdornment>
                                      }
                                      label="Confirm Password"
                                      onChange={(event: any) =>
                                        setConfirmPassword(event.target.value)
                                      }
                                      value={confirmPassword}
                                    />
                                    {errors.password &&
                                      touched.password &&
                                      !confirmPassword && (
                                        <FormHelperText className="errorMessage">
                                          Confirm {errors.password}
                                        </FormHelperText>
                                      )}
                                  </FormControl>
                                </Grid>

                                <Grid item xs={12} md={12} lg={12}>
                                  <TextField
                                    id="referredBy"
                                    label="Referral Code(Optional)"
                                    variant="outlined"
                                    fullWidth
                                    onChange={handleChange}
                                    value={
                                      referral.length > 0
                                        ? referral.toUpperCase()
                                        : values.referredBy
                                    }
                                    disabled={referral.length > 0}
                                  />
                                </Grid>
                              </Grid>
                            </Box>
                          </CardContent>
                          <CardActions>
                            <Grid container spacing={2}>
                              <Grid item xs={12}>
                                <Button
                                  type="submit"
                                  variant="contained"
                                  className="primaryFillBtn width100"
                                  disabled={isSubmitting}
                                >
                                  <span>Submit</span>
                                </Button>
                              </Grid>
                              <Grid item xs={12}>
                                <Box className="textCenter">
                                  <Typography component="a">
                                    Back to{" "}
                                    <Link
                                      onClick={(event) => {
                                        event.preventDefault();
                                        navigate("/login");
                                      }}
                                    >
                                      Login
                                    </Link>
                                  </Typography>
                                </Box>
                              </Grid>
                            </Grid>
                          </CardActions>
                        </Card>
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </Box>
          {alertConfig && alertConfig.isShow && (
            <Dialog
              open={alertConfig.isShow}
              className="alertModel"
              // TransitionComponent={Transition}
              keepMounted
              aria-describedby="alert-dialog-slide-description"
            >
              <Box className="alertIcon">
                <img
                  alt="A2zLotto"
                  className="alertLogo"
                  src={require("../../../assets/images/common/logo.png")}
                />
                {/* <CheckCircleOutlineRoundedIcon className="successColor" /> */}
              </Box>
              {alertConfig.title && (
                <DialogTitle className="pageHead">
                  {alertConfig.title}
                </DialogTitle>
              )}
              <DialogContent>
                <DialogContentText id="alert-dialog-slide-description">
                  <Typography className="alertTitle">
                    {alertConfig.description}
                  </Typography>

                  <Box>
                    <Typography>
                      <span>User Id</span> :{" "}
                      <span className="commonValue1">
                        {signUpData.username}
                      </span>
                    </Typography>
                  </Box>
                  <Box>
                    <Typography>
                      <span>Password</span> :{" "}
                      <span className="commonValue1">
                        {signUpData.password}
                      </span>
                    </Typography>
                  </Box>
                </DialogContentText>
              </DialogContent>
              <DialogActions className="alertFooter">
                <Button
                  variant="outlined"
                  onClick={() => {
                    setAlertConfig(DEFAULT_ALERT_CONFIG);
                    navigate("/");
                  }}
                  className="alertAction"
                >
                  <span>Okay!</span>
                </Button>
              </DialogActions>
            </Dialog>
          )}
        </form>
      )}
    </Formik>
  );
};
export default Signup;
