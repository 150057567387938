import { Component, Dispatch } from "react";
import HttpHelperService from "../httpHelper.service";
import {
  CREATE_GAME_HISTORY,
  DECLARE_RESULTS,
  GET_GAME_HISTORY,
  GET_GAME_RESULTS,
  GET_HOME_DATE,
  GET_LOTTERY_HISTORY,
  HOME_GAME_RULE_DATA,
  POST_GAME,
  PREDICT_RESULTS,
  USER_LOTERY_TICKETS,
} from "../../constants/EndPoints.constant";
import { IPlayGameRequestModel } from "../../interface/Request/IPlayGameRequestModel";
import { ILotteryHistoryRequest } from "../../interface/Request/ILotteryHistoryRequest";
import { ICreateNewGameRequestModel } from "../../interface/Request/ICreateNewGameRequestModel";
import { Action } from "redux";

class PlayGameService {
  _httpHelperService;
  constructor(dispatch: Dispatch<Action>) {
    this._httpHelperService = new HttpHelperService(dispatch);
  }

  submitGame = async (playGameRequestModel: IPlayGameRequestModel) => {
    return await this._httpHelperService.post(POST_GAME, playGameRequestModel);
  };

  getGameHistory = async (request: ILotteryHistoryRequest) => {
    return await this._httpHelperService.post(`${GET_GAME_HISTORY}`, request);
  };

  getGameResults = async (gameId: number) => {
    return await this._httpHelperService.get(
      `${GET_GAME_RESULTS}?gameId=${gameId}`
    );
  };

  declareResults = async (request: any) => {
    return await this._httpHelperService.post(`${DECLARE_RESULTS}`, request);
  };

  predictResults = async (request: any) => {
    return await this._httpHelperService.post(`${PREDICT_RESULTS}`, request);
  };

  createNewGameHistory = async (request: ICreateNewGameRequestModel) => {
    return await this._httpHelperService.post(
      `${CREATE_GAME_HISTORY}`,
      request
    );
  };

  getHomeGameData = async () => {
    return await this._httpHelperService.get(`${GET_HOME_DATE}`);
  };

  getLotteryHistory = async (gameId: number) => {
    return await this._httpHelperService.get(
      `${GET_LOTTERY_HISTORY}?gameId=${gameId}`
    );
  };

  getHomeGameRuleData = async () => {
    return await this._httpHelperService.get(`${HOME_GAME_RULE_DATA}`);
  };

  getUserLotteryTickets = async (userId: string) => {
    return await this._httpHelperService.get(
      `${USER_LOTERY_TICKETS}?userId=${userId}`
    );
  };
}

export default PlayGameService;
