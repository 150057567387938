import { Navigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import React, { useContext } from "react";
import { logOut } from "../actions/auth.actions";
import { ToastContext } from "../context/toast.context";
import { ToastSeverity } from "../constants/toastSeverity.contants";
import { LOGGING_OUT_API_ERROR_STANDARD_MESSAGE } from "../constants/DBConstants.constant";

const ProtectedRoute = (props: {
  children: React.ReactNode;
  allowedRoles: number[];
}): JSX.Element => {
  const dispatch = useDispatch();
  const logoutUser = () => dispatch<any>(logOut());
  const { setToastConfig } = useContext(ToastContext);
  const { children } = props;
  const { userInfo } = useSelector((state: any) => state.authReducer);
  if (userInfo && userInfo.user && userInfo.user.roleId === 0) {
    logoutUser();
    setToastConfig(
      ToastSeverity.Error,
      LOGGING_OUT_API_ERROR_STANDARD_MESSAGE,
      true
    );
  }
  return userInfo && userInfo.user && userInfo.user.userId != "" ? (
    props.allowedRoles.includes(userInfo.user.roleId) ? (
      <>{children}</>
    ) : (
      <Navigate replace={true} to="/dashboard" />
    )
  ) : (
    <Navigate replace={true} to="/" />
  );
};

export default ProtectedRoute;
