import React, { FunctionComponent, useEffect } from "react";
import PageProps from "../../models/PageProps.interface";
import Box from "@mui/material/Box";
import LeftMenu from "../../components/leftMenu/leftMenu.component";
import TopNav from "../../components/topNav/topNav.component";
import Footer from "../../components/footer/footer.component";
import { IGamesConfigModel } from "../../interface/BusinessModels/IGamesConfigModel";
import "../playGames/playGames.screen.style.css";
import AgentUsers from "../../components/agentUsers/agentUsers.component";

const ApplicationAgents: FunctionComponent<PageProps> = ({ title }) => {
  useEffect(() => {
    document.title = title;
  }, []);

  return (
    <Box className="pageMain">
      <Box className="pageLeft">
        <LeftMenu />
      </Box>
      <Box className="pageRight">
        <Box className="pageHead">
          <TopNav title={title.split("|")[1].trim()} />
        </Box>
        <Box className="pageView">
          <Box className="pageViewBody commonScroll">
            <AgentUsers />
          </Box>
          <Box className="pageViewFooter">
            <Footer />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
export default ApplicationAgents;
