import React, {
  FunctionComponent,
  useContext,
  useState,
  useEffect,
} from "react";
import PageProps from "../../models/PageProps.interface";

//Libraries Import
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";

//Tabs
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";

//Component Import
import LeftMenu from "../../components/leftMenu/leftMenu.component";
import TopNav from "../../components/topNav/topNav.component";
import Footer from "../../components/footer/footer.component";

//Import Css
import "../lotteryGameSettings/lotteryGameSettings.screen.style.css";
import { IGamePermissions } from "../../interface/BusinessModels/IGamePermissions";
import { IGameSettings } from "../../interface/BusinessModels/IGameSettings";
import ApplicationHelperService from "../../services/ApplicationHelperService";
import { IApiResponse } from "../../interface/Response/IApiResponse";
import { ToastContext } from "../../context/toast.context";
import { IAlertDialogConfig } from "../../interface/IAlertDialogConfig";
import LookupService from "../../services/lookup/lookup.service";
import {
  GAME_DISCOUNTS,
  GAME_GENERAL_SETTINGS,
  GAME_SPECIAL_DISCOUNTS,
  GAME_WINNINGS,
  LOTTERY_HISTORY,
  GAME_PERMISSION,
  LAST_DAY_DISCOUNTS,
  API_ERROR_STANDARD_MESSAGE,
  DEFAULT_ALERT_CONFIG,
  UPLOAD_RESULT_CHART,
} from "../../constants/DBConstants.constant";
import { HttpStatusCode } from "axios";

import { Formik } from "formik";
import { IGameGeneralSettings } from "../../interface/BusinessModels/IGameGeneralSettings";
import * as yup from "yup";
import { IGameDiscountSettings } from "../../interface/BusinessModels/IGameDiscountSettings";
import { IGameSpecialDiscountSettings } from "../../interface/BusinessModels/IGameSpecialDiscountSettings";
import { IDailyGameWinnings } from "../../interface/BusinessModels/IDailyGameWinnings";
import { ToastSeverity } from "../../constants/toastSeverity.contants";
import { useNavigate } from "react-router-dom";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import GamesConfigService from "../../services/gamesConfig/gamesConfig.service";
import { IGamesConfigModel } from "../../interface/BusinessModels/IGamesConfigModel";
import MenuItem from "@mui/material/MenuItem";
import FormHelperText from "@mui/material/FormHelperText";
import { LoadingContext } from "../../context/loading.context";
import AlertDialog from "../../components/alertDialog/alertDialog.component";
import { ILastDayDiscountSettings } from "../../interface/BusinessModels/ILastDayDiscountSettings";
import PlayGameService from "../../services/playGame/playGame.service";
import { IGameResultsModel } from "../../interface/Response/IGameResultsModel";
import { useDispatch } from "react-redux";
import { OutlinedInput } from "@mui/material";
import {
  DateTimePicker,
  LocalizationProvider,
  MobileTimePicker,
  TimePicker,
} from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";

const LotteryGameSettings: FunctionComponent<PageProps> = ({ title }) => {
  const dispatch = useDispatch();
  //Tabs Related Script Goes Here
  const [tabValue, setTabValue] = React.useState(GAME_GENERAL_SETTINGS);
  const [selectedGame, setselectedGame] = React.useState<IGamesConfigModel>();
  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setTabValue(newValue);
  };
  const _applicationHelperService = new ApplicationHelperService({});
  const _lookupService = new LookupService(dispatch);
  const { setToastConfig, setOpen } = useContext(ToastContext);
  const [alertConfig, setAlertConfig] = useState<IAlertDialogConfig>({
    isShow: false,
    callBack: () => undefined,
  });
  const [isValueLoded, setIsValueLoded] = useState<boolean>(false);
  const [gamesConfig, setGamesConfig] = useState<IGamesConfigModel[]>([]);
  const [lotteryHistory, setLotteryHistory] = useState<IGameResultsModel[]>([]);
  const navigate = useNavigate();
  const _gamesConfigService = new GamesConfigService(dispatch);
  const { setLoading } = useContext(LoadingContext);
  const _playGameService = new PlayGameService(dispatch);

  const GamePermissionsInitials: IGamePermissions = {
    gameAvailable: false,
    runningLotteryGame: false,
    enableLastDayDiscounts: false,
    showGameWinnersListScroller: false,
    singleDigitGameAvailable: false,
    gameTotalAvailable: false,
  };
  const [GamePermissions, setGamePermissions] = useState<IGamePermissions>(
    GamePermissionsInitials
  );

  const handleCheckboxChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    var key = event.target.id;
    var input = { [key]: event.target.checked.toString().toUpperCase() };
    await _saveSettings(input, GAME_PERMISSION);
    fetchSettings(GAME_PERMISSION);
  };

  const GameGeneralSettingsInitials: IGameGeneralSettings = {
    gameStopHour: "",
    gameDiscountStopHour: "",
    minAmountPlay: "",
    agentCommission: "",
  };

  const [
    GameGeneralSettingsInitialValues,
    setGameGeneralSettingsInitialValues,
  ] = useState<IGameGeneralSettings>(GameGeneralSettingsInitials);

  const GameGeneralSettingsSchema = yup.object().shape({
    gameStopHour: yup.string(),
    gameDiscountStopHour: yup.string(),
    minAmountPlay: yup.number(),
    agentCommission: yup.number().max(100, "Should be lessthan 100"),
  });

  const GameDiscountSettingsInitials: IGameDiscountSettings = {
    firstPrizeDiscount: "",
    threeUpDiscount: "",
    twoUpDiscount: "",
    twoDownDiscount: "",
    threeUpSingleDigitDiscount: "",
    twoUpSingleDigitDiscount: "",
    twoDownSingleDigitDiscount: "",
    threeUpGameTotalDiscount: "",
    twoUpGameTotalDiscount: "",
    twoDownGameTotalDiscount: "",
  };

  const [
    GameDiscountSettingsInitialValues,
    setGameDiscountSettingsInitialValues,
  ] = useState<IGameDiscountSettings>(GameDiscountSettingsInitials);

  const GameDiscountSettingsSchema = yup.object().shape({
    firstPrizeDiscount: yup.number().max(100, "Should be lessthan 100"),
    threeUpDiscount: yup.number().max(100, "Should be lessthan 100"),
    twoUpDiscount: yup.number().max(100, "Should be lessthan 100"),
    twoDownDiscount: yup.number().max(100, "Should be lessthan 100"),
    threeUpSingleDigitDiscount: yup.number().max(100, "Should be lessthan 100"),
    twoUpSingleDigitDiscount: yup.number().max(100, "Should be lessthan 100"),
    twoDownSingleDigitDiscount: yup.number().max(100, "Should be lessthan 100"),
    threeUpGameTotalDiscount: yup.number().max(100, "Should be lessthan 100"),
    twoUpGameTotalDiscount: yup.number().max(100, "Should be lessthan 100"),
    twoDownGameTotalDiscount: yup.number().max(100, "Should be lessthan 100"),
  });

  const GameSpecialDiscountSettingsInitials: IGameSpecialDiscountSettings = {
    firstPrizeSpecialDiscount: "",
    threeUpSpecialDiscount: "",
    twoUpSpecialDiscount: "",
    twoDownSpecialDiscount: "",
    threeUpSingleDigitSpecialDiscount: "",
    twoUpSingleDigitSpecialDiscount: "",
    twoDownSingleDigitSpecialDiscount: "",
    threeUpGameTotalSpecialDiscount: "",
    twoUpGameTotalSpecialDiscount: "",
    twoDownGameTotalSpecialDiscount: "",
  };

  const [
    GameSpecialDiscountSettingsInitialValues,
    setGameSpecialDiscountSettingsInitialValues,
  ] = useState<IGameSpecialDiscountSettings>(
    GameSpecialDiscountSettingsInitials
  );

  const GameSpecialDiscountSettingsSchema = yup.object().shape({
    firstPrizeSpecialDiscount: yup.number().max(100, "Should be lessthan 100"),
    threeUpSpecialDiscount: yup.number().max(100, "Should be lessthan 100"),
    twoUpSpecialDiscount: yup.number().max(100, "Should be lessthan 100"),
    twoDownSpecialDiscount: yup.number().max(100, "Should be lessthan 100"),
    threeUpSingleDigitSpecialDiscount: yup
      .number()
      .max(100, "Should be lessthan 100"),
    twoUpSingleDigitSpecialDiscount: yup
      .number()
      .max(100, "Should be lessthan 100"),
    twoDownSingleDigitSpecialDiscount: yup
      .number()
      .max(100, "Should be lessthan 100"),
    threeUpGameTotalSpecialDiscount: yup
      .number()
      .max(100, "Should be lessthan 100"),
    twoUpGameTotalSpecialDiscount: yup
      .number()
      .max(100, "Should be lessthan 100"),
    twoDownGameTotalSpecialDiscount: yup
      .number()
      .max(100, "Should be lessthan 100"),
  });

  const DailyGameWinningsInitials: IDailyGameWinnings = {
    firstPrizeStraightWinAmount: "",
    firstPrizeRumbleWinAmount: "",
    threeUpStraightWinAmount: "",
    threeUpRumbleWinAmount: "",
    twoUpWinAmount: "",
    twoDownWinAmount: "",
    threeUpSingleDigitWinAmount: "",
    twoUpSingleDigitWinAmount: "",
    twoDownSingleDigitWinAmount: "",
    threeUpTotalWinAmount: "",
    twoUpTotalWinAmount: "",
    twoDownTotalWinAmount: "",
  };

  const [
    DailyGameWinningsInitialValues,
    setDailyGameWinningsInitialValues,
  ] = useState<IDailyGameWinnings>(DailyGameWinningsInitials);

  const LastDayDiscountSettingsInitials: ILastDayDiscountSettings = {
    firstPrizeStraightLastDayDiscount: "",
    threeUpStraightLastDayDiscount: "",
    twoUpLastDayDiscount: "",
    twoDownLastDayDiscount: "",
    threeUpSingleDigitLastDayDiscount: "",
    twoUpSingleDigitLastDayDiscount: "",
    twoDownSingleDigitLastDayDiscount: "",
    threeUpTotalLastDayDiscount: "",
    twoUpTotalLastDayDiscount: "",
    twoDownTotalLastDayDiscount: "",
  };

  const [
    LastDayDiscountSettingsInitialValues,
    setLastDayDiscountSettingsInitialValues,
  ] = useState<ILastDayDiscountSettings>(LastDayDiscountSettingsInitials);

  const LastDayDiscountSettingsSchema = yup.object().shape({
    firstPrizeStraightLastDayDiscount: yup
      .number()
      .max(100, "Should be lessthan 100"),
    threeUpStraightLastDayDiscount: yup
      .number()
      .max(100, "Should be lessthan 100"),
    twoUpLastDayDiscount: yup.number().max(100, "Should be lessthan 100"),
    twoDownLastDayDiscount: yup.number().max(100, "Should be lessthan 100"),
    threeUpSingleDigitLastDayDiscount: yup
      .number()
      .max(100, "Should be lessthan 100"),
    twoUpSingleDigitLastDayDiscount: yup
      .number()
      .max(100, "Should be lessthan 100"),
    twoDownSingleDigitLastDayDiscount: yup
      .number()
      .max(100, "Should be lessthan 100"),
    threeUpTotalLastDayDiscount: yup
      .number()
      .max(100, "Should be lessthan 100"),
    twoUpTotalLastDayDiscount: yup.number().max(100, "Should be lessthan 100"),
    twoDownTotalLastDayDiscount: yup
      .number()
      .max(100, "Should be lessthan 100"),
  });

  const DailyGameWinningsSchema = yup.object().shape({
    firstPrizeStraightWinAmount: yup.number(),
    firstPrizeRumbleWinAmount: yup.number(),
    threeUpStraightWinAmount: yup.number(),
    threeUpRumbleWinAmount: yup.number(),
    twoUpWinAmount: yup.number(),
    twoDownWinAmount: yup.number(),
    threeUpSingleDigitWinAmount: yup.number(),
    twoUpSingleDigitWinAmount: yup.number(),
    twoDownSingleDigitWinAmount: yup.number(),
    threeUpTotalWinAmount: yup.number(),
    twoUpTotalWinAmount: yup.number(),
    twoDownTotalWinAmount: yup.number(),
  });

  const fetchSettings = async (type: string) => {
    if (selectedGame) {
      const configType = `${type}`;
      setLoading(true);
      var response: IApiResponse = await _lookupService.getGameSettings(
        configType,
        selectedGame.id
      );
      if (!response.isSuccess) {
        setToastConfig(ToastSeverity.Error, response.message, true);
      } else {
        const settingsArray: IGameSettings[] = response.response as IGameSettings[];
        let settings: any = [];
        switch (type) {
          case GAME_GENERAL_SETTINGS:
            settings = GameGeneralSettingsInitialValues;
            break;
          case GAME_DISCOUNTS:
            settings = GameDiscountSettingsInitialValues;
            break;
          case GAME_SPECIAL_DISCOUNTS:
            settings = GameSpecialDiscountSettingsInitialValues;
            break;
          case GAME_WINNINGS:
            settings = DailyGameWinningsInitialValues;
            break;
          case LAST_DAY_DISCOUNTS:
            settings = LastDayDiscountSettingsInitialValues;
            break;
          case GAME_PERMISSION:
            settings = GamePermissions;
            break;
        }
        if (settingsArray) {
          for (let index = 0; index < settingsArray.length; index++) {
            const element = settingsArray[index];
            let key: any = await _applicationHelperService.getFormIdOnConfigKey(
              element.key
            );
            if (type === GAME_PERMISSION) {
              settings[key] = element.value === "TRUE" ? true : false;
            } else {
              settings[key] = element.value;
            }
          }

          switch (type) {
            case GAME_GENERAL_SETTINGS:
              setGameGeneralSettingsInitialValues(settings);
              break;
            case GAME_DISCOUNTS:
              setGameDiscountSettingsInitialValues(settings);
              break;
            case GAME_SPECIAL_DISCOUNTS:
              setGameSpecialDiscountSettingsInitialValues(settings);
              break;
            case GAME_WINNINGS:
              setDailyGameWinningsInitialValues(settings);
              break;
            case LAST_DAY_DISCOUNTS:
              setLastDayDiscountSettingsInitialValues(settings);
              break;
            case GAME_PERMISSION:
              setGamePermissions({
                ...settings,
                ...GamePermissions,
              });
              break;
          }
        }

        setIsValueLoded(true);
        setLoading(false);
      }
    }
  };

  const _saveSettings = async (values: any, type: string) => {
    try {
      console.log("Values: ", values);
      if (selectedGame) {
        let settings: IGameSettings[] = [];
        for (const [key, value] of Object.entries(values)) {
          console.log(`${key} ${value}`); // "a 5", "b 7", "c 9"
          var databaseKey = await _applicationHelperService.getConfigKeyOnFormId(
            key
          );
          if (databaseKey) {
            values[key] = value === "" ? 0 : +(value as any);
            const gameSettings: IGameSettings = {
              key: databaseKey,
              value: value,
              type: `${type}`,
            };
            settings.push(gameSettings);
          }
        }
        var isValidInputs = false;
        switch (type) {
          case GAME_GENERAL_SETTINGS:
            isValidInputs = await GameGeneralSettingsSchema.isValid(values);
            break;
          case GAME_DISCOUNTS:
            isValidInputs = await GameDiscountSettingsSchema.isValid(values);
            break;
          case GAME_SPECIAL_DISCOUNTS:
            isValidInputs = await GameSpecialDiscountSettingsSchema.isValid(
              values
            );
            break;
          case GAME_WINNINGS:
            isValidInputs = await DailyGameWinningsSchema.isValid(values);
            break;
          case GAME_PERMISSION:
            isValidInputs = true;
            break;
        }

        if (isValidInputs) {
          var response: IApiResponse = await _lookupService.manageSettings(
            selectedGame.id,
            {
              gameSettingsModels: settings,
            }
          );
          if (!response.isSuccess) {
            setToastConfig(ToastSeverity.Error, response.message, true);
          } else {
            if (type !== GAME_PERMISSION) {
              setAlertConfig({
                description: response.response.message,
                toastSeverity: ToastSeverity.Success,
                isShow: true,
                callBack: () => {
                  setAlertConfig(DEFAULT_ALERT_CONFIG);
                  navigate("/lotteryGameSettings");
                },
              });
            }
          }
        }
      }
    } catch (error) {
      if (
        error &&
        (error as any).response &&
        (error as any).response.status != HttpStatusCode.Unauthorized
      ) {
        setToastConfig(ToastSeverity.Error, API_ERROR_STANDARD_MESSAGE, true);
      }
      console.log(error);
    }
  };

  useEffect(() => {
    initializeFunctions();

    async function initializeFunctions() {
      await getAvailableGames();
    }
  }, []);

  const getAvailableGames = async () => {
    try {
      const response: IApiResponse = await _gamesConfigService.getAvailableGames();
      setGamesConfig(response.response as IGamesConfigModel[]);
      setselectedGame(response.response[0]);
    } catch (error) {
      if (
        error &&
        (error as any).response &&
        (error as any).response.status != HttpStatusCode.Unauthorized
      ) {
        setToastConfig(ToastSeverity.Error, API_ERROR_STANDARD_MESSAGE, true);
      }
    }
  };

  useEffect(() => {
    setIsValueLoded(false);
    if (tabValue === "LOTTERY_HISTORY") {
      fetchLotteryHistory();
    } else {
      fetchSettings(tabValue);
    }
    resetIntialValues();
  }, [tabValue]);

  const fetchLotteryHistory = async () => {
    try {
      setLotteryHistory([]);
      if (selectedGame) {
        const lotteryHist: IApiResponse = await _playGameService.getGameResults(
          selectedGame.id
        );
        setLotteryHistory(lotteryHist.response as IGameResultsModel[]);
      }
    } catch (error) {
      if (
        error &&
        (error as any).response &&
        (error as any).response.status != HttpStatusCode.Unauthorized
      ) {
        setToastConfig(ToastSeverity.Error, API_ERROR_STANDARD_MESSAGE, true);
      }
    }
  };

  useEffect(() => {
    resetIntialValues();
    setTimeout(() => {
      if (selectedGame) {
        setIsValueLoded(false);
        fetchSettings(GAME_PERMISSION);
        fetchSettings(GAME_GENERAL_SETTINGS);
      }
    }, 1000);
  }, [selectedGame]);

  const resetIntialValues = () => {
    // setGamePermissions(GamePermissionsInitials);
    setGameGeneralSettingsInitialValues(GameGeneralSettingsInitials);
    setGameDiscountSettingsInitialValues(GameDiscountSettingsInitials);
    setGameSpecialDiscountSettingsInitialValues(
      GameSpecialDiscountSettingsInitials
    );
    setDailyGameWinningsInitialValues(DailyGameWinningsInitials);
    setLastDayDiscountSettingsInitialValues(
      LastDayDiscountSettingsInitialValues
    );
  };

  const UploadResultChart = () => {
    const [uploadedFile, setUploadedFile] = useState<any>();

    const _handleUploadResultChart = async () => {
      try {
        const formData = new FormData();
        if (selectedGame) {
          formData.append("gameId", selectedGame.id.toString());
          formData.append("file", uploadedFile);
          var response: IApiResponse = await _lookupService.uploadGameResultImage(
            formData
          );
          if (!response.isSuccess) {
            setTimeout(() => {
              setToastConfig(ToastSeverity.Error, response.message, true);
            }, 1000);
          } else {
          }
        }
      } catch (error) {
        if (
          error &&
          (error as any).response &&
          (error as any).response.status != HttpStatusCode.Unauthorized
        ) {
          setToastConfig(ToastSeverity.Error, API_ERROR_STANDARD_MESSAGE, true);
        }
        console.log(error);
      }
    };

    return (
      <Box className="generalSettings">
        <Grid container spacing={1}>
          <Grid item xs={12} md={6} lg={4}>
            <Box className="commonFormField">
              <FormControl fullWidth variant="outlined" margin="normal">
                <OutlinedInput
                  id="file"
                  type="file"
                  label="Offer Logo"
                  onChange={(event: any) => {
                    setUploadedFile(event.currentTarget.files[0]);
                  }}
                />
              </FormControl>
            </Box>
          </Grid>
        </Grid>
        <CardActions className="floatRightBtn">
          <Grid container spacing={2}>
            <Grid item xs={6} md={6} lg={6}>
              <Button
                onClick={() => _handleUploadResultChart()}
                type="submit"
                variant="contained"
                className="primaryFillBtn width100"
              >
                <span>Upload</span>
              </Button>
            </Grid>
          </Grid>
        </CardActions>
      </Box>
    );
  };

  return (
    <Box className="pageMain">
      <Box className="pageLeft">
        <LeftMenu />
      </Box>
      <Box className="pageRight">
        <Box className="pageHead">
          <TopNav title={title.split("|")[1].trim()} />
        </Box>
        <Box className="pageView">
          {selectedGame && selectedGame.id > 0 && (
            <Box className="pageViewBody commonScroll">
              <Grid
                item
                xs={12}
                md={12}
                lg={4}
                style={{ marginBottom: "10px" }}
              >
                <FormControl variant="outlined" fullWidth>
                  <InputLabel id="outlined-country-dropdown-label">
                    Country
                  </InputLabel>
                  <Select
                    labelId="outlined-country-dropdown-label"
                    id="country-dropdown"
                    label="Country"
                    value={selectedGame.id.toString()}
                    onChange={(evt: SelectChangeEvent) => {
                      const selectedGames: IGamesConfigModel[] = gamesConfig.filter(
                        (x: IGamesConfigModel) =>
                          x.id.toString() === evt.target.value.toString()
                      );
                      setselectedGame(selectedGames[0]);
                    }}
                  >
                    {gamesConfig &&
                      gamesConfig.map(
                        (game: IGamesConfigModel, index: number) => (
                          <MenuItem key={game.id} value={game.id}>
                            {game.name}
                          </MenuItem>
                        )
                      )}
                  </Select>
                </FormControl>
              </Grid>
              <Box className="commonBgCard commonBgColorCard saperatorSpacing">
                <Typography className="bannerTitle">
                  {`${selectedGame.name.split(" ")[0]} Game Permissions`}
                </Typography>
                <Grid container spacing={1}>
                  <Grid item xs={12} md={6} lg={4}>
                    <Box className="commonFormField">
                      <Typography className="floatL normalText">
                        Game Available
                      </Typography>
                      <FormControlLabel
                        className="commonSwitch"
                        control={
                          <Switch
                            id="gameAvailable"
                            checked={GamePermissions.gameAvailable}
                            onChange={handleCheckboxChange}
                          />
                        }
                        label=""
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={6} lg={4}>
                    <Box className="commonFormField">
                      <Typography className="floatL normalText">
                        Running Lottery Game
                      </Typography>
                      <FormControlLabel
                        className="commonSwitch"
                        control={
                          <Switch
                            id="runningLotteryGame"
                            checked={GamePermissions.runningLotteryGame}
                            onChange={handleCheckboxChange}
                          />
                        }
                        label=""
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={6} lg={4}>
                    <Box className="commonFormField">
                      <Typography className="floatL normalText">
                        Enable Last Day discounts
                      </Typography>
                      <FormControlLabel
                        className="commonSwitch"
                        control={
                          <Switch
                            id="enableLastDayDiscounts"
                            checked={GamePermissions.enableLastDayDiscounts}
                            onChange={handleCheckboxChange}
                          />
                        }
                        label=""
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={6} lg={4}>
                    <Box className="commonFormField">
                      <Typography className="floatL normalText">
                        Show Game Winners List Scroller
                      </Typography>
                      <FormControlLabel
                        className="commonSwitch"
                        control={
                          <Switch
                            id="showGameWinnersListScroller"
                            checked={
                              GamePermissions.showGameWinnersListScroller
                            }
                            onChange={handleCheckboxChange}
                          />
                        }
                        label=""
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={6} lg={4}>
                    <Box className="commonFormField">
                      <Typography className="floatL normalText">
                        Single Digit Game Available
                      </Typography>
                      <FormControlLabel
                        className="commonSwitch"
                        control={
                          <Switch
                            id="singleDigitGameAvailable"
                            checked={GamePermissions.singleDigitGameAvailable}
                            onChange={handleCheckboxChange}
                          />
                        }
                        label=""
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={6} lg={4}>
                    <Box className="commonFormField">
                      <Typography className="floatL normalText">
                        Game Total Available
                      </Typography>
                      <FormControlLabel
                        className="commonSwitch"
                        control={
                          <Switch
                            id="gameTotalAvailable"
                            checked={GamePermissions.gameTotalAvailable}
                            onChange={handleCheckboxChange}
                          />
                        }
                        label=""
                      />
                    </Box>
                  </Grid>
                </Grid>
              </Box>
              <Box>
                <TabContext value={tabValue}>
                  <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                    <TabList onChange={handleChange} className="commonTabsHead">
                      <Tab
                        label={`Game General Settings`}
                        value={GAME_GENERAL_SETTINGS}
                      />
                      <Tab label={`Game Discounts`} value={GAME_DISCOUNTS} />
                      <Tab
                        label={`Game Special Discounts`}
                        value={GAME_SPECIAL_DISCOUNTS}
                      />
                      {GamePermissions.enableLastDayDiscounts && (
                        <Tab
                          label={`Last Day Discounts`}
                          value={LAST_DAY_DISCOUNTS}
                        />
                      )}
                      <Tab label={`Game Winnings`} value={GAME_WINNINGS} />
                      <Tab label={`Lottery History`} value={LOTTERY_HISTORY} />
                      <Tab
                        label={`Upload Result Chart`}
                        value={UPLOAD_RESULT_CHART}
                      />
                    </TabList>
                  </Box>
                  <TabPanel value={GAME_GENERAL_SETTINGS}>
                    {isValueLoded && (
                      <Formik
                        enableReinitialize
                        initialValues={GameGeneralSettingsInitialValues}
                        validationSchema={GameGeneralSettingsSchema}
                        onSubmit={(values, { setSubmitting }) => {
                          _saveSettings(values, GAME_GENERAL_SETTINGS);
                        }}
                      >
                        {({
                          values,
                          errors,
                          touched,
                          handleChange,
                          handleBlur,
                          handleSubmit,
                          isSubmitting,
                          isValid,
                          setFieldValue,
                          /* and other goodies */
                        }) => (
                          <form onSubmit={handleSubmit} className="height100">
                            <Box className="generalSettings">
                              <Grid container spacing={1}>
                                <Grid item xs={12} md={6} lg={4}>
                                  <Box className="commonFormField">
                                    <LocalizationProvider
                                      dateAdapter={AdapterDayjs}
                                    >
                                      <TimePicker
                                        label="Lottery Game Stop Hour"
                                        defaultValue={dayjs(
                                          values.gameStopHour
                                            ? `2022-04-17T${values.gameStopHour}`
                                            : `2022-04-17T00:00`
                                        )}
                                        ampm={false}
                                        slotProps={{
                                          textField: { fullWidth: true },
                                        }}
                                        onChange={(newValue) => {
                                          const dateDayJs = dayjs(newValue);
                                          setFieldValue(
                                            "gameStopHour",
                                            dateDayJs.format("HH:mm")
                                          );
                                        }}
                                      />
                                      {/* <MobileTimePicker
                                        value={values.gameStopHour}
                                        label={"Lottery Game Stop Hour"}
                                        openTo="hours"
                                        ampm={false}
                                        slotProps={{
                                          textField: { fullWidth: true },
                                        }}
                                        onChange={(newValue) => {
                                          const dateDayJs = dayjs(newValue);
                                          setFieldValue(
                                            "gameStopHour",
                                            dateDayJs.format("HH:mm")
                                          );
                                        }}
                                        defaultValue={dayjs("2022-04-17T18:30")}
                                      /> */}
                                    </LocalizationProvider>
                                  </Box>
                                  {errors.gameStopHour &&
                                    touched.gameStopHour && (
                                      <FormHelperText className="errorMessage">
                                        {errors.gameStopHour.toString()}
                                      </FormHelperText>
                                    )}
                                </Grid>
                                <Grid item xs={12} md={6} lg={4}>
                                  <Box className="commonFormField">
                                    <LocalizationProvider
                                      dateAdapter={AdapterDayjs}
                                    >
                                      <TimePicker
                                        label="Lottery Game Discount Stop Hour"
                                        defaultValue={dayjs(
                                          values.gameDiscountStopHour
                                            ? `2022-04-17T${values.gameDiscountStopHour}`
                                            : `2022-04-17T00:00`
                                        )}
                                        ampm={false}
                                        slotProps={{
                                          textField: { fullWidth: true },
                                        }}
                                        onChange={(newValue) => {
                                          const dateDayJs = dayjs(newValue);
                                          setFieldValue(
                                            "gameDiscountStopHour",
                                            dateDayJs.format("HH:mm")
                                          );
                                        }}
                                      />
                                      {/* <MobileTimePicker
                                        value={values.gameDiscountStopHour}
                                        label={
                                          "Lottery Game Discount Stop Hour"
                                        }
                                        openTo="hours"
                                        ampm={false}
                                        slotProps={{
                                          textField: { fullWidth: true },
                                        }}
                                        onChange={(newValue) => {
                                          const dateDayJs = dayjs(newValue);
                                          setFieldValue(
                                            "gameDiscountStopHour",
                                            dateDayJs.format("HH:mm")
                                          );
                                        }}
                                        defaultValue={dayjs('2022-04-17T18:30')}
                                      /> */}
                                    </LocalizationProvider>
                                  </Box>
                                  {errors.gameDiscountStopHour &&
                                    touched.gameDiscountStopHour && (
                                      <FormHelperText className="errorMessage">
                                        {errors.gameDiscountStopHour.toString()}
                                      </FormHelperText>
                                    )}
                                </Grid>
                                <Grid item xs={12} md={6} lg={4}>
                                  <Box className="commonFormField">
                                    <TextField
                                      id="minAmountPlay"
                                      label="Minimum Amount To Play"
                                      variant="outlined"
                                      fullWidth
                                      onChange={handleChange}
                                      value={values.minAmountPlay}
                                    />
                                  </Box>
                                  {errors.minAmountPlay &&
                                    touched.minAmountPlay && (
                                      <FormHelperText className="errorMessage">
                                        {errors.minAmountPlay.toString()}
                                      </FormHelperText>
                                    )}
                                </Grid>
                                <Grid item xs={12} md={6} lg={4}>
                                  <Box className="commonFormField">
                                    <TextField
                                      id="agentCommission"
                                      label="Agent Commision(%)"
                                      variant="outlined"
                                      fullWidth
                                      onChange={handleChange}
                                      value={values.agentCommission}
                                    />
                                  </Box>
                                  {errors.agentCommission &&
                                    touched.agentCommission && (
                                      <FormHelperText className="errorMessage">
                                        {errors.agentCommission.toString()}
                                      </FormHelperText>
                                    )}
                                </Grid>
                              </Grid>
                              <CardActions className="floatRightBtn">
                                <Grid container spacing={2}>
                                  <Grid item xs={6} md={6} lg={6}>
                                    <Button
                                      type="submit"
                                      variant="contained"
                                      className="primaryFillBtn width100"
                                    >
                                      <span>Submit</span>
                                    </Button>
                                  </Grid>
                                </Grid>
                              </CardActions>
                            </Box>
                          </form>
                        )}
                      </Formik>
                    )}
                  </TabPanel>
                  <TabPanel value={GAME_DISCOUNTS}>
                    {isValueLoded && (
                      <Formik
                        enableReinitialize
                        initialValues={GameDiscountSettingsInitialValues}
                        validationSchema={GameDiscountSettingsSchema}
                        onSubmit={(values, { setSubmitting }) => {
                          _saveSettings(values, GAME_DISCOUNTS);
                        }}
                      >
                        {({
                          values,
                          errors,
                          touched,
                          handleChange,
                          handleBlur,
                          handleSubmit,
                          isSubmitting,
                          isValid,
                          /* and other goodies */
                        }) => (
                          <form onSubmit={handleSubmit} className="height100">
                            <Box className="discounts">
                              <Grid container spacing={1}>
                                <Grid item xs={12} md={6} lg={4}>
                                  <Box className="commonFormField">
                                    <TextField
                                      id="firstPrizeDiscount"
                                      label="First Prize Discount(%)"
                                      variant="outlined"
                                      fullWidth
                                      onChange={handleChange}
                                      value={values.firstPrizeDiscount}
                                    />
                                  </Box>
                                  {errors.firstPrizeDiscount &&
                                    touched.firstPrizeDiscount && (
                                      <FormHelperText className="errorMessage">
                                        {errors.firstPrizeDiscount.toString()}
                                      </FormHelperText>
                                    )}
                                </Grid>
                                <Grid item xs={12} md={6} lg={4}>
                                  <Box className="commonFormField">
                                    <TextField
                                      id="threeUpDiscount"
                                      label="3UP Discount(%)"
                                      variant="outlined"
                                      fullWidth
                                      onChange={handleChange}
                                      value={values.threeUpDiscount}
                                    />
                                  </Box>
                                  {errors.threeUpDiscount &&
                                    touched.threeUpDiscount && (
                                      <FormHelperText className="errorMessage">
                                        {errors.threeUpDiscount.toString()}
                                      </FormHelperText>
                                    )}
                                </Grid>
                                <Grid item xs={12} md={6} lg={4}>
                                  <Box className="commonFormField">
                                    <TextField
                                      id="twoUpDiscount"
                                      label="2UP Discount(%)"
                                      variant="outlined"
                                      fullWidth
                                      onChange={handleChange}
                                      value={values.twoUpDiscount}
                                    />
                                  </Box>
                                  {errors.twoUpDiscount &&
                                    touched.twoUpDiscount && (
                                      <FormHelperText className="errorMessage">
                                        {errors.twoUpDiscount.toString()}
                                      </FormHelperText>
                                    )}
                                </Grid>
                                <Grid item xs={12} md={6} lg={4}>
                                  <Box className="commonFormField">
                                    <TextField
                                      id="twoDownDiscount"
                                      label="2DOWN Discount(%)"
                                      variant="outlined"
                                      fullWidth
                                      onChange={handleChange}
                                      value={values.twoDownDiscount}
                                    />
                                  </Box>
                                  {errors.twoDownDiscount &&
                                    touched.twoDownDiscount && (
                                      <FormHelperText className="errorMessage">
                                        {errors.twoDownDiscount.toString()}
                                      </FormHelperText>
                                    )}
                                </Grid>
                                <Grid item xs={12} md={6} lg={4}>
                                  <Box className="commonFormField">
                                    <TextField
                                      id="threeUpSingleDigitDiscount"
                                      label="3UP Single Digit Discount(%)"
                                      variant="outlined"
                                      fullWidth
                                      onChange={handleChange}
                                      value={values.threeUpSingleDigitDiscount}
                                    />
                                  </Box>
                                  {errors.threeUpSingleDigitDiscount &&
                                    touched.threeUpSingleDigitDiscount && (
                                      <FormHelperText className="errorMessage">
                                        {errors.threeUpSingleDigitDiscount.toString()}
                                      </FormHelperText>
                                    )}
                                </Grid>
                                <Grid item xs={12} md={6} lg={4}>
                                  <Box className="commonFormField">
                                    <TextField
                                      id="twoUpSingleDigitDiscount"
                                      label="2UP Single Digit Discount(%)"
                                      variant="outlined"
                                      fullWidth
                                      onChange={handleChange}
                                      value={values.twoUpSingleDigitDiscount}
                                    />
                                  </Box>
                                  {errors.twoUpSingleDigitDiscount &&
                                    touched.twoUpSingleDigitDiscount && (
                                      <FormHelperText className="errorMessage">
                                        {errors.twoUpSingleDigitDiscount.toString()}
                                      </FormHelperText>
                                    )}
                                </Grid>
                                <Grid item xs={12} md={6} lg={4}>
                                  <Box className="commonFormField">
                                    <TextField
                                      id="twoDownSingleDigitDiscount"
                                      label="2DOWN Single Digit Discount(%)"
                                      variant="outlined"
                                      fullWidth
                                      onChange={handleChange}
                                      value={values.twoDownSingleDigitDiscount}
                                    />
                                  </Box>
                                  {errors.twoDownSingleDigitDiscount &&
                                    touched.twoDownSingleDigitDiscount && (
                                      <FormHelperText className="errorMessage">
                                        {errors.twoDownSingleDigitDiscount.toString()}
                                      </FormHelperText>
                                    )}
                                </Grid>
                                <Grid item xs={12} md={6} lg={4}>
                                  <Box className="commonFormField">
                                    <TextField
                                      id="threeUpGameTotalDiscount"
                                      label="3UP Game Total Discount(%)"
                                      variant="outlined"
                                      fullWidth
                                      onChange={handleChange}
                                      value={values.threeUpGameTotalDiscount}
                                    />
                                  </Box>
                                  {errors.threeUpGameTotalDiscount &&
                                    touched.threeUpGameTotalDiscount && (
                                      <FormHelperText className="errorMessage">
                                        {errors.threeUpGameTotalDiscount.toString()}
                                      </FormHelperText>
                                    )}
                                </Grid>
                                <Grid item xs={12} md={6} lg={4}>
                                  <Box className="commonFormField">
                                    <TextField
                                      id="twoUpGameTotalDiscount"
                                      label="2UP Game Total Discount(%)"
                                      variant="outlined"
                                      fullWidth
                                      onChange={handleChange}
                                      value={values.twoUpGameTotalDiscount}
                                    />
                                  </Box>
                                  {errors.twoUpGameTotalDiscount &&
                                    touched.twoUpGameTotalDiscount && (
                                      <FormHelperText className="errorMessage">
                                        {errors.twoUpGameTotalDiscount.toString()}
                                      </FormHelperText>
                                    )}
                                </Grid>
                                <Grid item xs={12} md={6} lg={4}>
                                  <Box className="commonFormField">
                                    <TextField
                                      id="twoDownGameTotalDiscount"
                                      label="2DOWN Game Total Discount(%)"
                                      variant="outlined"
                                      fullWidth
                                      onChange={handleChange}
                                      value={values.twoDownGameTotalDiscount}
                                    />
                                  </Box>
                                  {errors.twoDownGameTotalDiscount &&
                                    touched.twoDownGameTotalDiscount && (
                                      <FormHelperText className="errorMessage">
                                        {errors.twoDownGameTotalDiscount.toString()}
                                      </FormHelperText>
                                    )}
                                </Grid>
                              </Grid>
                              <CardActions className="floatRightBtn">
                                <Grid container spacing={2}>
                                  <Grid item xs={6} md={6} lg={6}>
                                    <Button
                                      type="submit"
                                      variant="contained"
                                      className="primaryFillBtn width100"
                                    >
                                      <span>Submit</span>
                                    </Button>
                                  </Grid>
                                </Grid>
                              </CardActions>
                            </Box>
                          </form>
                        )}
                      </Formik>
                    )}
                  </TabPanel>
                  <TabPanel value={GAME_SPECIAL_DISCOUNTS}>
                    {isValueLoded && (
                      <Formik
                        enableReinitialize
                        initialValues={GameSpecialDiscountSettingsInitialValues}
                        validationSchema={GameSpecialDiscountSettingsSchema}
                        onSubmit={(values, { setSubmitting }) => {
                          _saveSettings(values, GAME_SPECIAL_DISCOUNTS);
                        }}
                      >
                        {({
                          values,
                          errors,
                          touched,
                          handleChange,
                          handleBlur,
                          handleSubmit,
                          isSubmitting,
                          isValid,
                          /* and other goodies */
                        }) => (
                          <form onSubmit={handleSubmit} className="height100">
                            <Box className="specialDiscounts">
                              <Grid container spacing={1}>
                                <Grid item xs={12} md={6} lg={4}>
                                  <Box className="commonFormField">
                                    <TextField
                                      id="firstPrizeSpecialDiscount"
                                      label="First Prize Special Discount(%)"
                                      variant="outlined"
                                      fullWidth
                                      onChange={handleChange}
                                      value={values.firstPrizeSpecialDiscount}
                                    />
                                  </Box>
                                  {errors.firstPrizeSpecialDiscount &&
                                    touched.firstPrizeSpecialDiscount && (
                                      <FormHelperText className="errorMessage">
                                        {errors.firstPrizeSpecialDiscount.toString()}
                                      </FormHelperText>
                                    )}
                                </Grid>
                                <Grid item xs={12} md={6} lg={4}>
                                  <Box className="commonFormField">
                                    <TextField
                                      id="threeUpSpecialDiscount"
                                      label="3UP Special Discount(%)"
                                      variant="outlined"
                                      fullWidth
                                      onChange={handleChange}
                                      value={values.threeUpSpecialDiscount}
                                    />
                                  </Box>
                                  {errors.threeUpSpecialDiscount &&
                                    touched.threeUpSpecialDiscount && (
                                      <FormHelperText className="errorMessage">
                                        {errors.threeUpSpecialDiscount.toString()}
                                      </FormHelperText>
                                    )}
                                </Grid>
                                <Grid item xs={12} md={6} lg={4}>
                                  <Box className="commonFormField">
                                    <TextField
                                      id="twoUpSpecialDiscount"
                                      label="2UP Special Discount(%)"
                                      variant="outlined"
                                      fullWidth
                                      onChange={handleChange}
                                      value={values.twoUpSpecialDiscount}
                                    />
                                  </Box>
                                  {errors.twoUpSpecialDiscount &&
                                    touched.twoUpSpecialDiscount && (
                                      <FormHelperText className="errorMessage">
                                        {errors.twoUpSpecialDiscount.toString()}
                                      </FormHelperText>
                                    )}
                                </Grid>
                                <Grid item xs={12} md={6} lg={4}>
                                  <Box className="commonFormField">
                                    <TextField
                                      id="twoDownSpecialDiscount"
                                      label="2DOWN Special Discount(%)"
                                      variant="outlined"
                                      fullWidth
                                      onChange={handleChange}
                                      value={values.twoDownSpecialDiscount}
                                    />
                                  </Box>
                                  {errors.twoDownSpecialDiscount &&
                                    touched.twoDownSpecialDiscount && (
                                      <FormHelperText className="errorMessage">
                                        {errors.twoDownSpecialDiscount.toString()}
                                      </FormHelperText>
                                    )}
                                </Grid>
                                <Grid item xs={12} md={6} lg={4}>
                                  <Box className="commonFormField">
                                    <TextField
                                      id="threeUpSingleDigitSpecialDiscount"
                                      label="3UP Single Digit Special Discount(%)"
                                      variant="outlined"
                                      fullWidth
                                      onChange={handleChange}
                                      value={
                                        values.threeUpSingleDigitSpecialDiscount
                                      }
                                    />
                                  </Box>
                                  {errors.threeUpSingleDigitSpecialDiscount &&
                                    touched.threeUpSingleDigitSpecialDiscount && (
                                      <FormHelperText className="errorMessage">
                                        {errors.threeUpSingleDigitSpecialDiscount.toString()}
                                      </FormHelperText>
                                    )}
                                </Grid>
                                <Grid item xs={12} md={6} lg={4}>
                                  <Box className="commonFormField">
                                    <TextField
                                      id="twoUpSingleDigitSpecialDiscount"
                                      label="2UP Single Digit Special Discount(%)"
                                      variant="outlined"
                                      fullWidth
                                      onChange={handleChange}
                                      value={
                                        values.twoUpSingleDigitSpecialDiscount
                                      }
                                    />
                                  </Box>
                                  {errors.twoUpSingleDigitSpecialDiscount &&
                                    touched.twoUpSingleDigitSpecialDiscount && (
                                      <FormHelperText className="errorMessage">
                                        {errors.twoUpSingleDigitSpecialDiscount.toString()}
                                      </FormHelperText>
                                    )}
                                </Grid>
                                <Grid item xs={12} md={6} lg={4}>
                                  <Box className="commonFormField">
                                    <TextField
                                      id="twoDownSingleDigitSpecialDiscount"
                                      label="2DOWN Single Digit Special Discount(%)"
                                      variant="outlined"
                                      fullWidth
                                      onChange={handleChange}
                                      value={
                                        values.twoDownSingleDigitSpecialDiscount
                                      }
                                    />
                                  </Box>
                                  {errors.twoDownSingleDigitSpecialDiscount &&
                                    touched.twoDownSingleDigitSpecialDiscount && (
                                      <FormHelperText className="errorMessage">
                                        {errors.twoDownSingleDigitSpecialDiscount.toString()}
                                      </FormHelperText>
                                    )}
                                </Grid>
                                <Grid item xs={12} md={6} lg={4}>
                                  <Box className="commonFormField">
                                    <TextField
                                      id="threeUpGameTotalSpecialDiscount"
                                      label="3UP Game Total Special Discount(%)"
                                      variant="outlined"
                                      fullWidth
                                      onChange={handleChange}
                                      value={
                                        values.threeUpGameTotalSpecialDiscount
                                      }
                                    />
                                  </Box>
                                  {errors.threeUpGameTotalSpecialDiscount &&
                                    touched.threeUpGameTotalSpecialDiscount && (
                                      <FormHelperText className="errorMessage">
                                        {errors.threeUpGameTotalSpecialDiscount.toString()}
                                      </FormHelperText>
                                    )}
                                </Grid>
                                <Grid item xs={12} md={6} lg={4}>
                                  <Box className="commonFormField">
                                    <TextField
                                      id="twoUpGameTotalSpecialDiscount"
                                      label="2UP Game Total Special Discount(%)"
                                      variant="outlined"
                                      fullWidth
                                      onChange={handleChange}
                                      value={
                                        values.twoUpGameTotalSpecialDiscount
                                      }
                                    />
                                  </Box>
                                  {errors.twoUpGameTotalSpecialDiscount &&
                                    touched.twoUpGameTotalSpecialDiscount && (
                                      <FormHelperText className="errorMessage">
                                        {errors.twoUpGameTotalSpecialDiscount.toString()}
                                      </FormHelperText>
                                    )}
                                </Grid>
                                <Grid item xs={12} md={6} lg={4}>
                                  <Box className="commonFormField">
                                    <TextField
                                      id="twoDownGameTotalSpecialDiscount"
                                      label="2DOWN Game Total Special Discount(%)"
                                      variant="outlined"
                                      fullWidth
                                      onChange={handleChange}
                                      value={
                                        values.twoDownGameTotalSpecialDiscount
                                      }
                                    />
                                  </Box>
                                  {errors.twoDownGameTotalSpecialDiscount &&
                                    touched.twoDownGameTotalSpecialDiscount && (
                                      <FormHelperText className="errorMessage">
                                        {errors.twoDownGameTotalSpecialDiscount.toString()}
                                      </FormHelperText>
                                    )}
                                </Grid>
                              </Grid>
                              <CardActions className="floatRightBtn">
                                <Grid container spacing={2}>
                                  <Grid item xs={6} md={6} lg={6}>
                                    <Button
                                      type="submit"
                                      variant="contained"
                                      className="primaryFillBtn width100"
                                    >
                                      <span>Submit</span>
                                    </Button>
                                  </Grid>
                                </Grid>
                              </CardActions>
                            </Box>
                          </form>
                        )}
                      </Formik>
                    )}
                  </TabPanel>
                  {GamePermissions.enableLastDayDiscounts && (
                    <TabPanel value={LAST_DAY_DISCOUNTS}>
                      {isValueLoded && (
                        <Formik
                          enableReinitialize
                          initialValues={LastDayDiscountSettingsInitialValues}
                          validationSchema={LastDayDiscountSettingsSchema}
                          onSubmit={(values, { setSubmitting }) => {
                            _saveSettings(values, LAST_DAY_DISCOUNTS);
                          }}
                        >
                          {({
                            values,
                            errors,
                            touched,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                            isSubmitting,
                            isValid,
                            /* and other goodies */
                          }) => (
                            <form onSubmit={handleSubmit} className="height100">
                              <Box className="gameWinnings">
                                <Grid container spacing={1}>
                                  <Grid item xs={12} md={6} lg={4}>
                                    <Box className="commonFormField">
                                      <TextField
                                        id="firstPrizeStraightLastDayDiscount"
                                        label="1st Prize Last Day Discount(%)"
                                        variant="outlined"
                                        fullWidth
                                        onChange={handleChange}
                                        value={
                                          values.firstPrizeStraightLastDayDiscount
                                        }
                                      />
                                    </Box>
                                    {errors.firstPrizeStraightLastDayDiscount &&
                                      touched.firstPrizeStraightLastDayDiscount && (
                                        <FormHelperText className="errorMessage">
                                          {errors.firstPrizeStraightLastDayDiscount.toString()}
                                        </FormHelperText>
                                      )}
                                  </Grid>
                                  <Grid item xs={12} md={6} lg={4}>
                                    <Box className="commonFormField">
                                      <TextField
                                        id="threeUpStraightLastDayDiscount"
                                        label="3UP Straight Last Day Discount(%)"
                                        variant="outlined"
                                        fullWidth
                                        onChange={handleChange}
                                        value={
                                          values.threeUpStraightLastDayDiscount
                                        }
                                      />
                                    </Box>
                                    {errors.threeUpStraightLastDayDiscount &&
                                      touched.threeUpStraightLastDayDiscount && (
                                        <FormHelperText className="errorMessage">
                                          {errors.threeUpStraightLastDayDiscount.toString()}
                                        </FormHelperText>
                                      )}
                                  </Grid>
                                  <Grid item xs={12} md={6} lg={4}>
                                    <Box className="commonFormField">
                                      <TextField
                                        id="twoUpLastDayDiscount"
                                        label="2UP Last Day Discount(%)"
                                        variant="outlined"
                                        fullWidth
                                        onChange={handleChange}
                                        value={values.twoUpLastDayDiscount}
                                      />
                                    </Box>
                                    {errors.twoUpLastDayDiscount &&
                                      touched.twoUpLastDayDiscount && (
                                        <FormHelperText className="errorMessage">
                                          {errors.twoUpLastDayDiscount.toString()}
                                        </FormHelperText>
                                      )}
                                  </Grid>
                                  <Grid item xs={12} md={6} lg={4}>
                                    <Box className="commonFormField">
                                      <TextField
                                        id="twoDownLastDayDiscount"
                                        label="2DOWN Last Day Discount(%)"
                                        variant="outlined"
                                        fullWidth
                                        onChange={handleChange}
                                        value={values.twoDownLastDayDiscount}
                                      />
                                    </Box>
                                    {errors.twoDownLastDayDiscount &&
                                      touched.twoDownLastDayDiscount && (
                                        <FormHelperText className="errorMessage">
                                          {errors.twoDownLastDayDiscount.toString()}
                                        </FormHelperText>
                                      )}
                                  </Grid>
                                  <Grid item xs={12} md={6} lg={4}>
                                    <Box className="commonFormField">
                                      <TextField
                                        id="threeUpSingleDigitLastDayDiscount"
                                        label="3UP Single Digit Last Day Discount(%)"
                                        variant="outlined"
                                        fullWidth
                                        onChange={handleChange}
                                        value={
                                          values.threeUpSingleDigitLastDayDiscount
                                        }
                                      />
                                    </Box>
                                    {errors.threeUpSingleDigitLastDayDiscount &&
                                      touched.threeUpSingleDigitLastDayDiscount && (
                                        <FormHelperText className="errorMessage">
                                          {errors.threeUpSingleDigitLastDayDiscount.toString()}
                                        </FormHelperText>
                                      )}
                                  </Grid>
                                  <Grid item xs={12} md={6} lg={4}>
                                    <Box className="commonFormField">
                                      <TextField
                                        id="twoUpSingleDigitLastDayDiscount"
                                        label="2UP Single Digit Last Day Discount(%)"
                                        variant="outlined"
                                        fullWidth
                                        onChange={handleChange}
                                        value={
                                          values.twoUpSingleDigitLastDayDiscount
                                        }
                                      />
                                    </Box>
                                    {errors.twoUpSingleDigitLastDayDiscount &&
                                      touched.twoUpSingleDigitLastDayDiscount && (
                                        <FormHelperText className="errorMessage">
                                          {errors.twoUpSingleDigitLastDayDiscount.toString()}
                                        </FormHelperText>
                                      )}
                                  </Grid>
                                  <Grid item xs={12} md={6} lg={4}>
                                    <Box className="commonFormField">
                                      <TextField
                                        id="twoDownSingleDigitLastDayDiscount"
                                        label="2DOWN Single Digit Last Day Discount(%)"
                                        variant="outlined"
                                        fullWidth
                                        onChange={handleChange}
                                        value={
                                          values.twoDownSingleDigitLastDayDiscount
                                        }
                                      />
                                    </Box>
                                    {errors.twoDownSingleDigitLastDayDiscount &&
                                      touched.twoDownSingleDigitLastDayDiscount && (
                                        <FormHelperText className="errorMessage">
                                          {errors.twoDownSingleDigitLastDayDiscount.toString()}
                                        </FormHelperText>
                                      )}
                                  </Grid>
                                  <Grid item xs={12} md={6} lg={4}>
                                    <Box className="commonFormField">
                                      <TextField
                                        id="threeUpTotalLastDayDiscount"
                                        label="3UP Total Last Day Discount(%)"
                                        variant="outlined"
                                        fullWidth
                                        onChange={handleChange}
                                        value={
                                          values.threeUpTotalLastDayDiscount
                                        }
                                      />
                                    </Box>
                                    {errors.threeUpTotalLastDayDiscount &&
                                      touched.threeUpTotalLastDayDiscount && (
                                        <FormHelperText className="errorMessage">
                                          {errors.threeUpTotalLastDayDiscount.toString()}
                                        </FormHelperText>
                                      )}
                                  </Grid>
                                  <Grid item xs={12} md={6} lg={4}>
                                    <Box className="commonFormField">
                                      <TextField
                                        id="twoUpTotalLastDayDiscount"
                                        label="2UP Total Last Day Discount(%)"
                                        variant="outlined"
                                        fullWidth
                                        onChange={handleChange}
                                        value={values.twoUpTotalLastDayDiscount}
                                      />
                                    </Box>
                                    {errors.twoUpTotalLastDayDiscount &&
                                      touched.twoUpTotalLastDayDiscount && (
                                        <FormHelperText className="errorMessage">
                                          {errors.twoUpTotalLastDayDiscount.toString()}
                                        </FormHelperText>
                                      )}
                                  </Grid>
                                  <Grid item xs={12} md={6} lg={4}>
                                    <Box className="commonFormField">
                                      <TextField
                                        id="twoDownTotalLastDayDiscount"
                                        label="2DOWN Total Last Day Discount(%)"
                                        variant="outlined"
                                        fullWidth
                                        onChange={handleChange}
                                        value={
                                          values.twoDownTotalLastDayDiscount
                                        }
                                      />
                                    </Box>
                                    {errors.twoDownTotalLastDayDiscount &&
                                      touched.twoDownTotalLastDayDiscount && (
                                        <FormHelperText className="errorMessage">
                                          {errors.twoDownTotalLastDayDiscount.toString()}
                                        </FormHelperText>
                                      )}
                                  </Grid>
                                </Grid>
                                <CardActions className="floatRightBtn">
                                  <Grid container spacing={2}>
                                    <Grid item xs={6} md={6} lg={6}>
                                      <Button
                                        type="submit"
                                        variant="contained"
                                        className="primaryFillBtn width100"
                                      >
                                        <span>Submit</span>
                                      </Button>
                                    </Grid>
                                  </Grid>
                                </CardActions>
                              </Box>
                            </form>
                          )}
                        </Formik>
                      )}
                    </TabPanel>
                  )}
                  <TabPanel value={GAME_WINNINGS}>
                    {isValueLoded && (
                      <Formik
                        enableReinitialize
                        initialValues={DailyGameWinningsInitialValues}
                        validationSchema={DailyGameWinningsSchema}
                        onSubmit={(values, { setSubmitting }) => {
                          _saveSettings(values, GAME_WINNINGS);
                        }}
                      >
                        {({
                          values,
                          errors,
                          touched,
                          handleChange,
                          handleBlur,
                          handleSubmit,
                          isSubmitting,
                          isValid,
                          /* and other goodies */
                        }) => (
                          <form onSubmit={handleSubmit} className="height100">
                            <Box className="gameWinnings">
                              <Grid container spacing={1}>
                                <Grid item xs={12} md={6} lg={4}>
                                  <Box className="commonFormField">
                                    <TextField
                                      id="firstPrizeStraightWinAmount"
                                      label="1st Prize Straight Win Amount(%)"
                                      variant="outlined"
                                      fullWidth
                                      onChange={handleChange}
                                      value={values.firstPrizeStraightWinAmount}
                                    />
                                  </Box>
                                  {errors.firstPrizeStraightWinAmount &&
                                    touched.firstPrizeStraightWinAmount && (
                                      <FormHelperText className="errorMessage">
                                        {errors.firstPrizeStraightWinAmount.toString()}
                                      </FormHelperText>
                                    )}
                                </Grid>
                                <Grid item xs={12} md={6} lg={4}>
                                  <Box className="commonFormField">
                                    <TextField
                                      id="firstPrizeRumbleWinAmount"
                                      label="1st Prize Rumble Win Amount(%)"
                                      variant="outlined"
                                      fullWidth
                                      onChange={handleChange}
                                      value={values.firstPrizeRumbleWinAmount}
                                    />
                                  </Box>
                                  {errors.firstPrizeRumbleWinAmount &&
                                    touched.firstPrizeRumbleWinAmount && (
                                      <FormHelperText className="errorMessage">
                                        {errors.firstPrizeRumbleWinAmount.toString()}
                                      </FormHelperText>
                                    )}
                                </Grid>
                                <Grid item xs={12} md={6} lg={4}>
                                  <Box className="commonFormField">
                                    <TextField
                                      id="threeUpStraightWinAmount"
                                      label="3UP Straight Win Amount(%)"
                                      variant="outlined"
                                      fullWidth
                                      onChange={handleChange}
                                      value={values.threeUpStraightWinAmount}
                                    />
                                  </Box>
                                  {errors.threeUpStraightWinAmount &&
                                    touched.threeUpStraightWinAmount && (
                                      <FormHelperText className="errorMessage">
                                        {errors.threeUpStraightWinAmount.toString()}
                                      </FormHelperText>
                                    )}
                                </Grid>
                                <Grid item xs={12} md={6} lg={4}>
                                  <Box className="commonFormField">
                                    <TextField
                                      id="threeUpRumbleWinAmount"
                                      label="3UP Rumble Win Amount(%)"
                                      variant="outlined"
                                      fullWidth
                                      onChange={handleChange}
                                      value={values.threeUpRumbleWinAmount}
                                    />
                                  </Box>
                                  {errors.threeUpRumbleWinAmount &&
                                    touched.threeUpRumbleWinAmount && (
                                      <FormHelperText className="errorMessage">
                                        {errors.threeUpRumbleWinAmount.toString()}
                                      </FormHelperText>
                                    )}
                                </Grid>
                                <Grid item xs={12} md={6} lg={4}>
                                  <Box className="commonFormField">
                                    <TextField
                                      id="twoUpWinAmount"
                                      label="2UP Win Amount(%)"
                                      variant="outlined"
                                      fullWidth
                                      onChange={handleChange}
                                      value={values.twoUpWinAmount}
                                    />
                                  </Box>
                                  {errors.twoUpWinAmount &&
                                    touched.twoUpWinAmount && (
                                      <FormHelperText className="errorMessage">
                                        {errors.twoUpWinAmount.toString()}
                                      </FormHelperText>
                                    )}
                                </Grid>
                                <Grid item xs={12} md={6} lg={4}>
                                  <Box className="commonFormField">
                                    <TextField
                                      id="twoDownWinAmount"
                                      label="2DOWN Win Amount(%)"
                                      variant="outlined"
                                      fullWidth
                                      onChange={handleChange}
                                      value={values.twoDownWinAmount}
                                    />
                                  </Box>
                                  {errors.twoDownWinAmount &&
                                    touched.twoDownWinAmount && (
                                      <FormHelperText className="errorMessage">
                                        {errors.twoDownWinAmount.toString()}
                                      </FormHelperText>
                                    )}
                                </Grid>
                                <Grid item xs={12} md={6} lg={4}>
                                  <Box className="commonFormField">
                                    <TextField
                                      id="threeUpSingleDigitWinAmount"
                                      label="3UP Single Digit Win Amount(%)"
                                      variant="outlined"
                                      fullWidth
                                      onChange={handleChange}
                                      value={values.threeUpSingleDigitWinAmount}
                                    />
                                  </Box>
                                  {errors.threeUpSingleDigitWinAmount &&
                                    touched.threeUpSingleDigitWinAmount && (
                                      <FormHelperText className="errorMessage">
                                        {errors.threeUpSingleDigitWinAmount.toString()}
                                      </FormHelperText>
                                    )}
                                </Grid>
                                <Grid item xs={12} md={6} lg={4}>
                                  <Box className="commonFormField">
                                    <TextField
                                      id="twoUpSingleDigitWinAmount"
                                      label="2UP Single Digit Win Amount(%)"
                                      variant="outlined"
                                      fullWidth
                                      onChange={handleChange}
                                      value={values.twoUpSingleDigitWinAmount}
                                    />
                                  </Box>
                                  {errors.twoUpSingleDigitWinAmount &&
                                    touched.twoUpSingleDigitWinAmount && (
                                      <FormHelperText className="errorMessage">
                                        {errors.twoUpSingleDigitWinAmount.toString()}
                                      </FormHelperText>
                                    )}
                                </Grid>
                                <Grid item xs={12} md={6} lg={4}>
                                  <Box className="commonFormField">
                                    <TextField
                                      id="twoDownSingleDigitWinAmount"
                                      label="2DOWN Single Digit Win Amount(%)"
                                      variant="outlined"
                                      fullWidth
                                      onChange={handleChange}
                                      value={values.twoDownSingleDigitWinAmount}
                                    />
                                  </Box>
                                  {errors.twoDownSingleDigitWinAmount &&
                                    touched.twoDownSingleDigitWinAmount && (
                                      <FormHelperText className="errorMessage">
                                        {errors.twoDownSingleDigitWinAmount.toString()}
                                      </FormHelperText>
                                    )}
                                </Grid>
                                <Grid item xs={12} md={6} lg={4}>
                                  <Box className="commonFormField">
                                    <TextField
                                      id="threeUpTotalWinAmount"
                                      label="3UP Total Win Amount(%)"
                                      variant="outlined"
                                      fullWidth
                                      onChange={handleChange}
                                      value={values.threeUpTotalWinAmount}
                                    />
                                  </Box>
                                  {errors.threeUpTotalWinAmount &&
                                    touched.threeUpTotalWinAmount && (
                                      <FormHelperText className="errorMessage">
                                        {errors.threeUpTotalWinAmount.toString()}
                                      </FormHelperText>
                                    )}
                                </Grid>
                                <Grid item xs={12} md={6} lg={4}>
                                  <Box className="commonFormField">
                                    <TextField
                                      id="twoUpTotalWinAmount"
                                      label="2UP Total Win Amount(%)"
                                      variant="outlined"
                                      fullWidth
                                      onChange={handleChange}
                                      value={values.twoUpTotalWinAmount}
                                    />
                                  </Box>
                                  {errors.twoUpTotalWinAmount &&
                                    touched.twoUpTotalWinAmount && (
                                      <FormHelperText className="errorMessage">
                                        {errors.twoUpTotalWinAmount.toString()}
                                      </FormHelperText>
                                    )}
                                </Grid>
                                <Grid item xs={12} md={6} lg={4}>
                                  <Box className="commonFormField">
                                    <TextField
                                      id="twoDownTotalWinAmount"
                                      label="2DOWN Total Win Amount(%)"
                                      variant="outlined"
                                      fullWidth
                                      onChange={handleChange}
                                      value={values.twoDownTotalWinAmount}
                                    />
                                  </Box>
                                  {errors.twoDownTotalWinAmount &&
                                    touched.twoDownTotalWinAmount && (
                                      <FormHelperText className="errorMessage">
                                        {errors.twoDownTotalWinAmount.toString()}
                                      </FormHelperText>
                                    )}
                                </Grid>
                              </Grid>
                              <CardActions className="floatRightBtn">
                                <Grid container spacing={2}>
                                  <Grid item xs={6} md={6} lg={6}>
                                    <Button
                                      type="submit"
                                      variant="contained"
                                      className="primaryFillBtn width100"
                                    >
                                      <span>Submit</span>
                                    </Button>
                                  </Grid>
                                </Grid>
                              </CardActions>
                            </Box>
                          </form>
                        )}
                      </Formik>
                    )}
                  </TabPanel>
                  <TabPanel value={LOTTERY_HISTORY}>
                    <Box className="lotteryHistory">
                      <Grid container spacing={2}>
                        {lotteryHistory &&
                          lotteryHistory.map(
                            (item: IGameResultsModel, index: number) => (
                              <Grid item xs={12} md={6} lg={4}>
                                <Box className="commonCard">
                                  <Card>
                                    <CardContent key={index}>
                                      <Box className="commonBgCard saperatorSpacing">
                                        <Grid container spacing={2}>
                                          <Grid item xs={10} md={10} lg={10}>
                                            <Typography className="cardTitle whiteColor">
                                              {item.gameUIId}
                                            </Typography>
                                          </Grid>
                                          <Grid
                                            item
                                            xs={2}
                                            md={2}
                                            lg={2}
                                            className="boxRight padL0"
                                          >
                                            <Box className="verificationStatus verified"></Box>
                                          </Grid>
                                        </Grid>
                                      </Box>
                                      <Box className="commonLabelValue">
                                        <Typography className="commonLabel">
                                          Game Draw Date
                                        </Typography>
                                        <Typography className="commonValue">
                                          {item.resultDeclaredOn}
                                        </Typography>
                                      </Box>
                                      <Box className="commonLabelValue">
                                        <Typography className="commonLabel">
                                          First Prize Result
                                        </Typography>
                                        <Typography className="commonValue">
                                          {item.firstPrize}
                                        </Typography>
                                      </Box>
                                      <Box className="commonLabelValue">
                                        <Typography className="commonLabel">
                                          3Up Staright Result
                                        </Typography>
                                        <Typography className="commonValue">
                                          {item.threeUpStraight}
                                        </Typography>
                                      </Box>
                                      <Box className="commonLabelValue">
                                        <Typography className="commonLabel">
                                          3Up Rumble Result
                                        </Typography>
                                        <Typography className="commonValue">
                                          {item.threeUpRumble}
                                        </Typography>
                                      </Box>
                                      <Box className="commonLabelValue">
                                        <Typography className="commonLabel">
                                          2 Up Result
                                        </Typography>
                                        <Typography className="commonValue">
                                          {item.twoUpStraight}
                                        </Typography>
                                      </Box>
                                      <Box className="commonLabelValue">
                                        <Typography className="commonLabel">
                                          2 Down Result
                                        </Typography>
                                        <Typography className="commonValue">
                                          {item.twoDownStraight}
                                        </Typography>
                                      </Box>
                                      <Box className="commonLabelValue">
                                        <Typography className="commonLabel">
                                          3 Up Single Result
                                        </Typography>
                                        <Typography className="commonValue">
                                          {item.threeUpSingle}
                                        </Typography>
                                      </Box>
                                      <Box className="commonLabelValue">
                                        <Typography className="commonLabel">
                                          2 Up Single Result
                                        </Typography>
                                        <Typography className="commonValue">
                                          {item.twoUpSingle}
                                        </Typography>
                                      </Box>
                                      <Box className="commonLabelValue">
                                        <Typography className="commonLabel">
                                          2 Down Single Result
                                        </Typography>
                                        <Typography className="commonValue">
                                          {item.twoDownSingle}
                                        </Typography>
                                      </Box>
                                      <Box className="commonLabelValue">
                                        <Typography className="commonLabel">
                                          3 Up Total Result
                                        </Typography>
                                        <Typography className="commonValue">
                                          {item.threeUpTotal}
                                        </Typography>
                                      </Box>
                                      <Box className="commonLabelValue">
                                        <Typography className="commonLabel">
                                          2 Up Total Result
                                        </Typography>
                                        <Typography className="commonValue">
                                          {item.twoUpTotal}
                                        </Typography>
                                      </Box>
                                      <Box className="commonLabelValue">
                                        <Typography className="commonLabel">
                                          2 Down Total Result
                                        </Typography>
                                        <Typography className="commonValue">
                                          {item.twoDownTotal}
                                        </Typography>
                                      </Box>
                                    </CardContent>
                                  </Card>
                                </Box>
                              </Grid>
                            )
                          )}
                      </Grid>
                    </Box>
                  </TabPanel>
                  <TabPanel value={UPLOAD_RESULT_CHART}>
                    <UploadResultChart />
                  </TabPanel>
                </TabContext>
              </Box>
              {alertConfig && alertConfig.isShow && (
                <AlertDialog
                  alertConfig={alertConfig}
                  callBack={alertConfig.callBack}
                />
              )}
            </Box>
          )}
          <Box className="pageViewFooter">
            <Footer />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
export default LotteryGameSettings;
