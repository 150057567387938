import React, { createContext, useContext, useState, ReactNode } from 'react';

type LanguageContextType = {
  language: 'EN' | 'TH';
  translatedText: Record<string, string>;
  toggleLanguage: () => void;
};

const LanguageContext = createContext<LanguageContextType | undefined>(undefined);

export const useLanguage = (): LanguageContextType => {
  const context = useContext(LanguageContext);
  if (!context) {
    throw new Error('useLanguage must be used within a LanguageProvider');
  }
  return context;
};

interface LanguageProviderProps {
  children: ReactNode;
}

export const LanguageProvider: React.FC<LanguageProviderProps> = ({ children }) => {
  const [language, setLanguage] = useState<'EN' | 'TH'>('EN');
  const [translatedText, setTranslatedText] = useState<Record<string, string>>({});

  const toggleLanguage = async () => {
    const targetLang = language === 'EN' ? 'TH' : 'EN';
    setLanguage(targetLang);
    
    // Call Google Translate API
    try {
      const response = await fetch(`https://translation.googleapis.com/language/translate/v2?key=YOUR_GOOGLE_API_KEY`, {
        method: 'POST',
        body: JSON.stringify({
          q: Object.values(translatedText),
          target: targetLang,
        }),
        headers: {
          'Content-Type': 'application/json',
        },
      });
      
      const result = await response.json();
      const translations = result.data.translations;
      
      const updatedText = Object.keys(translatedText).reduce((acc, key, index) => {
        acc[key] = translations[index].translatedText;
        return acc;
      }, {} as Record<string, string>);

      setTranslatedText(updatedText);
    } catch (error) {
      console.error('Translation Error:', error);
    }
  };

  return (
    <LanguageContext.Provider value={{ language, translatedText, toggleLanguage }}>
      {children}
    </LanguageContext.Provider>
  );
};
