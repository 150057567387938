import React, { useContext, useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";

import { useNavigate } from "react-router-dom";
import { ToastSeverity } from "../../constants/toastSeverity.contants";
import { IAlertDialogConfig } from "../../interface/IAlertDialogConfig";

//Icons Import
import HighlightOffRoundedIcon from "@mui/icons-material/HighlightOffRounded";
import WarningAmberRoundedIcon from "@mui/icons-material/WarningAmberRounded";
import CheckCircleOutlineRoundedIcon from "@mui/icons-material/CheckCircleOutlineRounded";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";

//Css Import
import "../alertDialog/alertDialog.component.style.css";
import { IApiResponse } from "../../interface/Response/IApiResponse";
import { ILookupModel } from "../../interface/BusinessModels/ILookupModel";
import { LINKING_WHATSAPP } from "../../constants/DBConstants.constant";
import LookupService from "../../services/lookup/lookup.service";
import { LoadingContext } from "../../context/loading.context";
import ApplicationHelperService from "../../services/ApplicationHelperService";
import { useDispatch } from "react-redux";

const AlertDialog = (props: {
  alertConfig: IAlertDialogConfig;
  callBack: () => void | undefined;
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const _lookupService = new LookupService(dispatch);
  const [isOpen, setIsOpen] = useState<boolean>(true);
  const [whatsappPhone, setWhatsappPhone] = useState<string>("");
  const { isWebView } = useContext(LoadingContext);
  const _applicationHelperService = new ApplicationHelperService({});
  useEffect(() => {
    getSocialConnectionSettings();

    async function getSocialConnectionSettings() {
      try {
        const socialConnectionSettings: IApiResponse = await _lookupService.getSocialGameSettings();

        const whatsLink = socialConnectionSettings.response.filter(
          (x: ILookupModel) => x.key === "WHATSAPP_LINK"
        )[0].value;
        const whatsPhone = socialConnectionSettings.response.filter(
          (x: ILookupModel) => x.key === "AGENT_WHATSAPP"
        )[0].value;

        if (whatsLink !== "") {
          setWhatsappPhone(whatsPhone);
        }
      } catch (error) {}
    }
  }, []);

  const _closeModel = () => {
    setIsOpen(!isOpen);
    props.callBack();
  };

  const sendWhatsappMessage = () => {
    if (props.alertConfig.whatsappMessageText) {
      const linkData = _applicationHelperService.generateWhatsAppLink(
        isWebView,
        props.alertConfig.whatsappMessageText,
        whatsappPhone
      );
      _applicationHelperService.sendMessageOnDeviceType(
        isWebView,
        LINKING_WHATSAPP,
        linkData
      );
    }
  };

  // const Transition = React.forwardRef(function Transition(
  //   props: TransitionProps & {
  //     children: React.ReactElement<any, any>;
  //   },
  //   ref: React.Ref<unknown>
  // ) {
  //   return <Slide direction="up" ref={ref} {...props} />;
  // });

  const GetIconOnToastType = (props: any) => {
    switch (props.toastType.toLowerCase()) {
      case ToastSeverity.Error:
        return <HighlightOffRoundedIcon className="errorColor" />;
      case ToastSeverity.Warning:
        return <WarningAmberRoundedIcon className="warningColor" />;
      case ToastSeverity.Success:
        return <CheckCircleOutlineRoundedIcon className="successColor" />;
      case ToastSeverity.Info:
        return <InfoOutlinedIcon className="infoColor" />;
      default:
        return <></>;
    }
  };

  return (
    <Box className="">
      <Dialog
        open={isOpen}
        fullWidth
        maxWidth={props.alertConfig.notifyWhatsapp ? "sm" : "xs"}
        className="alertModel"
        // TransitionComponent={Transition}
        keepMounted
        onClose={_closeModel}
        aria-describedby="alert-dialog-slide-description"
      >
        <Box className="alertIcon">
          <GetIconOnToastType toastType={props.alertConfig.toastSeverity} />
        </Box>
        {props.alertConfig.title && (
          <DialogTitle>{props.alertConfig.title}</DialogTitle>
        )}
        <DialogContent className="alertDialogDescription">
          <DialogContentText
            id="alert-dialog-slide-description"
            className="content"
          >
            {props.alertConfig.description}
          </DialogContentText>

          {props.alertConfig.notifyWhatsapp && whatsappPhone && (
            <DialogContentText
              id="alert-dialog-slide-description"
              className="whatsapp notifyWhatsapp"
            >
              Click on Notify Agent to quickly process your transaction.
            </DialogContentText>
          )}
        </DialogContent>
        <DialogActions className="alertFooter">
          <Button
            variant="outlined"
            onClick={_closeModel}
            className="alertAction"
          >
            {props.alertConfig.buttonTitle ? (
              props.alertConfig.buttonTitle
            ) : (
              <span>Okay!</span>
            )}
          </Button>
          {props.alertConfig.notifyWhatsapp && whatsappPhone && (
            <Button
              variant="contained"
              onClick={() => sendWhatsappMessage()}
              className="alertAction whatsapp"
              startIcon={<WhatsAppIcon />}
            >
              {props.alertConfig.buttonTitle ? (
                props.alertConfig.buttonTitle
              ) : (
                <span>Notify Agent</span>
              )}
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default AlertDialog;
