import { UUID } from "crypto";

/**
 * AUTH CONTROLLER
 */
export const USER_LOGIN: string = "api/auth/login";

export const FORGOT_PASSWORD: string = "api/auth/forgot-password";

export const RESET_PASSWORD: string = "api/auth/reset-password";

/**
 * LOOK UP CONTROLLER
 */
export const COUNTRY_CONFIG: string = "api/lookup/country-config";

export const UPDATE_COUNTRY_CONFIG: string = "api/lookup/update-country-config";

export const UPDATE_HELP: string = "api/lookup/update-help";

export const COUNTRY_CONFIG_ALL: string = "api/lookup/country-config-all";

export const CONFIG: string = "api/lookup/config";

export const CONVERT_CURRENCY: string = "api/lookup/currency-convert";

export const CONTACT_HELP: string = "api/lookup/contact-help";

export const GET_CONTACT_HELP: string = "api/lookup/Get-contact-help";

export const GET_GAME_SETTINGS: string = "api/lookup/game-settings";
export const GET_SOCIAL_GAME_SETTINGS: string =
  "api/lookup/game-social-connection-settings";

export const MANAGE_SETTINGS: string = "api/lookup/manage-game-settings";

export const UPLOAD_GAME_RESULT_IMAGE: string =
  "api/lookup/upload-game-result-image";

/**
 * RECHARGE CONTROLLER
 */
export const RECHARGE_PAYMENTS: string = "api/recharge/save-recharge-payments";

export const UPDATE_RECHARGE_PAYMENTS: string =
  "api/recharge/update-recharge-payments";

export const UPDATE_WALLET_PAYMENTS: string =
  "api/recharge/update-withdrawal-payments";

export const GET_RECHARGE_PAYMENTS: string =
  "api/recharge/get-recharge-payments";

export const GET_ALLRECHARGE_PAYMENTS: string =
  "api/recharge/get-all-recharge-payments";

export const RECHARGE_USER: string = "api/recharge/recharge-user";

export const GET_ALL_USER_TRANSACTIONS: string =
  "api/recharge/get-all-user-transactions";

/**
 * USER CONTROLLER
 */
export const REGISTER: string = "api/user/register";

export const PROFILE_SETTINGS: string = "api/user/profile-settings";

export const CHANGE_PASSWORD: string = "api/user/change-password";

export const REFFERALS_DETAILS: string = "api/user/getreferrals-list";

export const REFFERALS_COUNT: string = "api/user/referrals-count";

export const GET_ALL_USERS: string = "api/user/get-all-users";

export const SEARCH_USER: string = "api/user/search-user";

export const VALIDATE_SESSION: string = "api/user/validate-session";

export const WALLET_BALANCE: string = "api/user/wallet-balance";

export const REGISTER_AGENT_USER: string = "api/user/register-agent-users";

export const GET_AGENT_USERS: string = "api/user/get-agent-users";

export const DELETE_AGENT_USER: string = "api/user/delete-agent-users";

export const UPDATE_AGENT_USER: string = "api/user/update-agent-users";

export const REGISTER_AGENT: string = "api/user/register-agent";

export const DELETE_USER: string = "api/user/delete-user";

/**
 * WITHDRAWAL CONTROLLER
 */
export const SAVE_ACCOUNT_DETAILS: string =
  "api/withdrawal/save-account-details";

export const SAVE_UPI_DETAILS: string = "api/withdrawal/save-upi-details";

export const WITHDRAWAL_DETAILS: string = "api/withdrawal/withdrawal-details";

export const WALLET_WITHDRAWAL_DETAILS: string =
  "api/withdrawal/save-withdrawal-Wallet-details";

export const DELETE: string = "api/withdrawal/delete";

export const SET_PRIMARY_ACCOUNT: string = "api/withdrawal/set-primary-account";

/**
 * GAMESCONFIG CONTROLLER
 */
export const GET_GAMESCONFIG: string = "api/gamesconfig/game-configs";

export const GET_AVAILABLE_GAMES: string = "api/gamesconfig/available-games";

export const GAME_CONFIG_OPTIONS: string = "api/gamesconfig/game-options";

export const GAME_CONFIG_RULES = (
  configGameId: number,
  gameOptionId: number
): string =>
  `api/gamesconfig/game-rules?id=${configGameId}&gameOptionId=${gameOptionId}`;

export const GAME_OPTION_DISCOUNTS: string =
  "api/gamesconfig/discount-percentage";

export const GAME_DATES: string = "api/gamesconfig/game-dates";

export const GAME_DATES_TO_CREATE_GAME: string =
  "api/gamesconfig/game-dates-create-game";

/**
 * PLAYGAME CONTROLLER
 */
export const POST_GAME: string = "api/playgame";

export const GET_GAME_HISTORY: string = "api/playgame/game-history";

export const GET_GAME_RESULTS: string = "api/playgame/game-results";

export const GET_LOTTERY_SUMMARY: string = "api/playgame/lottery-game-summary";

export const DECLARE_RESULTS: string = "api/playgame/declare-results";

export const PREDICT_RESULTS: string = "api/playgame/predict-results";

export const GET_HOME_DATE: string = "api/playgame/home-game-data";

export const GET_LOTTERY_HISTORY: string = "api/playgame/lottery-history";

export const CREATE_GAME_HISTORY: string = "api/playgame/create-game-history";

export const HOME_GAME_RULE_DATA: string = "api/playgame/home-game-rule-data";

/**
 * TRANSACTION CONTROLLER
 */
export const TRANSACTION_HISTORY: string =
  "api/transactions/transaction-history";

export const USER_TRANSACTION_HISTORY = (userId: UUID): string =>
  `api/transactions/user-transaction-history/${userId}`;

export const AGENT_RECHARGE_LIST: string =
  "api/transactions/agent-recharge-list";

export const ADMIN_TRANSACTIONS = (fromDate: string, toDate: string): string =>
  `api/transactions/admin-transactions?fromDate=${fromDate}&toDate=${toDate}`;

/**
 * OFFERS CONTROLLER
 */
export const OFFERS_PAGE: string = "api/offers/offers-create";

export const GET_OFFERS: string = "api/offers/get-offers";

export const DELETE_OFFER: string = "api/offers/delete-offer";

/**
 * EXPORT CONTROLLER
 */
export const DOWNLOAD_RESULT_CHART = (gameId: number): string =>
  `api/export/download-result-chart?gameId=${gameId}`;
