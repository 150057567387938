import React, { FunctionComponent } from "react";
import ImageList from "@mui/material/ImageList";
import Box from "@mui/material/Box";
import { Typography } from "@mui/joy";

import { useNavigate } from "react-router-dom";
import { AppConfig } from "../../constants/AppConfig.constant";

const Footer = () => {
  const navigate = useNavigate();
  return (
    <Box className="">
      <Typography>
        © 2025 
        <Box component="a" onClick={() => navigate("/dashboard")}>
          {" "}
          {AppConfig.applicationName}.com,
        </Box>
        All Rights Reserved.
      </Typography>
    </Box>
  );
};

export default Footer;
