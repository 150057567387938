import { Dispatch } from "react";
import HttpHelperService from "../httpHelper.service";
import {
  GAME_CONFIG_OPTIONS,
  GAME_CONFIG_RULES,
  GAME_DATES,
  GAME_DATES_TO_CREATE_GAME,
  GAME_OPTION_DISCOUNTS,
  GET_AVAILABLE_GAMES,
  GET_GAMESCONFIG,
} from "../../constants/EndPoints.constant";
import { Action } from "redux";

class GamesConfigService {
  _httpHelperService;
  constructor(dispatch: Dispatch<Action>) {
    this._httpHelperService = new HttpHelperService(dispatch);
  }

  getGamesConfig = async () => {
    return await this._httpHelperService.get(GET_GAMESCONFIG);
  };

  getAvailableGames = async () => {
    return await this._httpHelperService.get(GET_AVAILABLE_GAMES);
  };

  getGameOptions = async (configGameId: number) => {
    return await this._httpHelperService.get(
      `${GAME_CONFIG_OPTIONS}?id=${configGameId}`
    );
  };

  getGameDiscount = async (gameOptionId: number) => {
    return await this._httpHelperService.get(
      `${GAME_OPTION_DISCOUNTS}?gameOptionId=${gameOptionId}`
    );
  };

  getGameRules = async (configGameId: number, gameOptionId: number) => {
    return await this._httpHelperService.get(
      `${GAME_CONFIG_RULES(configGameId, gameOptionId)}`
    );
  };

  getGameDates = async (configGameId: number) => {
    return await this._httpHelperService.get(
      `${GAME_DATES}?gameId=${configGameId}`
    );
  };

  getGameDatesToCreateGame = async (configGameId: number) => {
    return await this._httpHelperService.get(
      `${GAME_DATES_TO_CREATE_GAME}?gameId=${configGameId}`
    );
  };
}

export default GamesConfigService;
