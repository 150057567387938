import React, { FunctionComponent, useEffect, useState } from "react";
import PageProps from "../../models/PageProps.interface";

//Gadgets
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";

//Gadgest - List

//Table Import
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";

//Icons
import { useNavigate } from "react-router-dom";
//Import Css
import "../rules/rules.screen.style.css";
//Component Import
import PlayGameService from "../../services/playGame/playGame.service";
import { IApiResponse } from "../../interface/Response/IApiResponse";
import { AppConfig } from "../../constants/AppConfig.constant";
import {
  GameDataWinningsDiscount,
  GameDetailChart,
  IHomepageGameRuleDataResponseModel,
} from "../../interface/Response/IHomepageGameRuleDataResponseModel";
import Link from "@mui/material/Link";
import { useDispatch } from "react-redux";

const Rules: FunctionComponent<PageProps> = ({ title }) => {
  const dispatch = useDispatch();
  const [homeGameRuleData, setHomeGameRuleDate] = useState<
    IHomepageGameRuleDataResponseModel
  >();

  // YouTube video ID
  const videoId = "vH864uS3Juk";
  const navigate = useNavigate();
  const _playGameService = new PlayGameService(dispatch);

  useEffect(() => {
    getGameData();
    async function getGameData() {
      var response: IApiResponse = await _playGameService.getHomeGameRuleData();
      var homepageRules = response.response as IHomepageGameRuleDataResponseModel;
      setHomeGameRuleDate(homepageRules);
    }
  }, []);

  return (
    <Box className="landingV2">
      <Box className="heroSectionRules">
        <Container className="heroSectionContainer">
          <Box className="topNavbar">
            <Box>
              <img
                alt={AppConfig.applicationName}
                className="mainLogo"
                src={require("../../assets/images/common/logo.png")}
              />
            </Box>
            <Box className="topNavLinks">
              <Link href="/" className="linkNav">
                Home
              </Link>
              {/* <Link href="#" className="linkNav">
                About Us
              </Link> */}
              {/* <Link href="#" className="linkNav">
                <GoogleTranslate />
              </Link> */}
            </Box>
            <Box>
              <Button
                variant="contained"
                className="primaryBtn btnRightSpacing"
                onClick={(event) => {
                  event.preventDefault();
                  navigate("/login");
                }}
              >
                Login
              </Button>
              <Button
                variant="outlined"
                className="secondaryBtn"
                onClick={(event) => {
                  event.preventDefault();
                  navigate("/signup");
                }}
              >
                Signup
              </Button>
            </Box>
          </Box>
        </Container>
      </Box>
      <Box className="howToPlaySection">
        <Container>
          <Typography className="pageTitleRules">Rules & Conditions</Typography>
          {homeGameRuleData && (
            <Box>
              <Box className="commonRules saperationSection">
                <Box className="sectionTitleRules">Application Rules</Box>
                <Typography className="rulesFont">
                  <span className="bulletPoint"></span>One man One account &
                  should be verified.
                </Typography>
                <Typography className="rulesFont">
                  <span className="bulletPoint"></span>The minimum recharge
                  amount is{" "}
                  <span className="ruleValue">
                    {homeGameRuleData.minimumRechargeAmount}
                  </span>
                  .
                </Typography>
                <Typography className="rulesFont">
                  <span className="bulletPoint"></span>The maximum recharge
                  amount is{" "}
                  <span className="ruleValue">
                    {homeGameRuleData.maximumRechargeAmount}
                  </span>
                  .
                </Typography>
                <Typography className="rulesFont">
                  <span className="bulletPoint"></span>The minimum withdraw
                  amount is{" "}
                  <span className="ruleValue">
                    {homeGameRuleData.minimumWithdrawAmount}
                  </span>
                  .
                </Typography>
                <Typography className="rulesFont">
                  <span className="bulletPoint"></span>The maximum amount you
                  can withdraw is{" "}
                  <span className="ruleValue">
                    {homeGameRuleData.maximumWithdrawAmount}
                  </span>
                  .
                </Typography>
                <Typography className="rulesFont">
                  <span className="bulletPoint"></span>Transaction processing
                  fee is{" "}
                  <span className="ruleValue">
                    {homeGameRuleData.transactionProcessingFee}%
                  </span>
                  .
                </Typography>
              </Box>

              {homeGameRuleData.gameDetailCharts.map((x: GameDetailChart) => {
                return (
                  <Box>
                    <Box className="commonRules saperationSection">
                      <Box className="sectionTitleRules">{x.title}</Box>
                      <Typography className="rulesFont">
                        <span className="bulletPoint"></span>Once you played,
                        lottery ticket numbers will not be editable and
                        cancelable.
                      </Typography>
                      <Typography className="rulesFont">
                        <span className="bulletPoint"></span>The Lottery Game
                        Play will be stopped at {x.gameStopHour} IST on every
                        Game Result Date.
                      </Typography>
                      <Typography className="rulesFont">
                        <span className="bulletPoint"></span>The lottery game
                        discounts and winnings as follows.
                      </Typography>
                    </Box>

                    <Box className="commonRuleTable saperationSection">
                      <TableContainer component={Paper}>
                        <Table aria-label="spanning table">
                          <TableHead className="commonBgCard">
                            <TableRow>
                              <TableCell
                                rowSpan={2}
                                className="textCenter rulesFont"
                              >
                                Game Type
                              </TableCell>
                              <TableCell
                                colSpan={2}
                                className="textCenter rulesFont"
                              >
                                Winning Amount
                              </TableCell>
                              <TableCell
                                rowSpan={2}
                                className="textCenter rulesFont"
                              >
                                Play Discount
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell className="textCenter rulesFont">
                                Straight
                              </TableCell>
                              <TableCell className="textCenter rulesFont">
                                Rumble
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {x.gameDataWinningsDiscounts &&
                              x.gameDataWinningsDiscounts.map(
                                (y: GameDataWinningsDiscount) => (
                                  <TableRow>
                                    <TableCell className="textCenter">
                                      {y.gameType}
                                    </TableCell>
                                    <TableCell className="textCenter">
                                      {y.straight}
                                    </TableCell>
                                    <TableCell className="textCenter">
                                      {y.rumble}
                                    </TableCell>
                                    <TableCell className="textCenter">
                                      {y.playDiscount}%
                                    </TableCell>
                                  </TableRow>
                                )
                              )}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Box>
                  </Box>
                );
              })}
            </Box>
          )}
        </Container>
      </Box>
      <Box className="acceptedPaymentsSection boxSpacing">
        <Container>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6} lg={6}>
              <div className="scroll-container">
                <div className="scroll-content">
                  <Box className="wh100">
                    <Box className="mainPart">
                      <Box className="imgPart">
                        <img
                          alt={AppConfig.applicationName}
                          className="paymentType"
                          src={require("../../assets/images/landingPage2V2/Frame 2.png")}
                        />
                      </Box>
                      <Box className="imgPart">
                        <img
                          alt={AppConfig.applicationName}
                          className="paymentType"
                          src={require("../../assets/images/landingPage2V2/Frame 5.png")}
                        />
                      </Box>
                      <Box className="imgPart">
                        <img
                          alt={AppConfig.applicationName}
                          className="paymentType"
                          src={require("../../assets/images/landingPage2V2/Frame 2.png")}
                        />
                      </Box>
                      <Box className="imgPart">
                        <img
                          alt={AppConfig.applicationName}
                          className="paymentType"
                          src={require("../../assets/images/landingPage2V2/Frame 5.png")}
                        />
                      </Box>
                      <Box className="imgPart">
                        <img
                          alt={AppConfig.applicationName}
                          className="paymentType"
                          src={require("../../assets/images/landingPage2V2/Frame 2.png")}
                        />
                      </Box>
                      <Box className="imgPart">
                        <img
                          alt={AppConfig.applicationName}
                          className="paymentType"
                          src={require("../../assets/images/landingPage2V2/Frame 5.png")}
                        />
                      </Box>
                      <Box className="imgPart">
                        <img
                          alt={AppConfig.applicationName}
                          className="paymentType"
                          src={require("../../assets/images/landingPage2V2/Frame 2.png")}
                        />
                      </Box>
                      <Box className="imgPart">
                        <img
                          alt={AppConfig.applicationName}
                          className="paymentType"
                          src={require("../../assets/images/landingPage2V2/Frame 5.png")}
                        />
                      </Box>
                      <Box className="imgPart">
                        <img
                          alt={AppConfig.applicationName}
                          className="paymentType"
                          src={require("../../assets/images/landingPage2V2/Frame 2.png")}
                        />
                      </Box>
                      <Box className="imgPart">
                        <img
                          alt={AppConfig.applicationName}
                          className="paymentType"
                          src={require("../../assets/images/landingPage2V2/Frame 5.png")}
                        />
                      </Box>
                      <Box className="imgPart">
                        <img
                          alt={AppConfig.applicationName}
                          className="paymentType"
                          src={require("../../assets/images/landingPage2V2/Frame 2.png")}
                        />
                      </Box>
                      <Box className="imgPart">
                        <img
                          alt={AppConfig.applicationName}
                          className="paymentType"
                          src={require("../../assets/images/landingPage2V2/Frame 5.png")}
                        />
                      </Box>
                      <Box className="imgPart">
                        <img
                          alt={AppConfig.applicationName}
                          className="paymentType"
                          src={require("../../assets/images/landingPage2V2/Frame 2.png")}
                        />
                      </Box>
                      <Box className="imgPart">
                        <img
                          alt={AppConfig.applicationName}
                          className="paymentType"
                          src={require("../../assets/images/landingPage2V2/Frame 5.png")}
                        />
                      </Box>
                      <Box className="imgPart">
                        <img
                          alt={AppConfig.applicationName}
                          className="paymentType"
                          src={require("../../assets/images/landingPage2V2/Frame 2.png")}
                        />
                      </Box>
                      <Box className="imgPart">
                        <img
                          alt={AppConfig.applicationName}
                          className="paymentType"
                          src={require("../../assets/images/landingPage2V2/Frame 5.png")}
                        />
                      </Box>
                    </Box>
                    <Box className="mainPart">
                      <Box className="imgPart">
                        <img
                          alt={AppConfig.applicationName}
                          className="paymentType"
                          src={require("../../assets/images/landingPage2V2/Frame 4.png")}
                        />
                      </Box>
                      <Box className="imgPart">
                        <img
                          alt={AppConfig.applicationName}
                          className="paymentType"
                          src={require("../../assets/images/landingPage2V2/Frame 3.png")}
                        />
                      </Box>
                      <Box className="imgPart">
                        <img
                          alt={AppConfig.applicationName}
                          className="paymentType"
                          src={require("../../assets/images/landingPage2V2/Frame 4.png")}
                        />
                      </Box>
                      <Box className="imgPart">
                        <img
                          alt={AppConfig.applicationName}
                          className="paymentType"
                          src={require("../../assets/images/landingPage2V2/Frame 3.png")}
                        />
                      </Box>
                      <Box className="imgPart">
                        <img
                          alt={AppConfig.applicationName}
                          className="paymentType"
                          src={require("../../assets/images/landingPage2V2/Frame 4.png")}
                        />
                      </Box>
                      <Box className="imgPart">
                        <img
                          alt={AppConfig.applicationName}
                          className="paymentType"
                          src={require("../../assets/images/landingPage2V2/Frame 3.png")}
                        />
                      </Box>
                      <Box className="imgPart">
                        <img
                          alt={AppConfig.applicationName}
                          className="paymentType"
                          src={require("../../assets/images/landingPage2V2/Frame 4.png")}
                        />
                      </Box>
                      <Box className="imgPart">
                        <img
                          alt={AppConfig.applicationName}
                          className="paymentType"
                          src={require("../../assets/images/landingPage2V2/Frame 3.png")}
                        />
                      </Box>
                      <Box className="imgPart">
                        <img
                          alt={AppConfig.applicationName}
                          className="paymentType"
                          src={require("../../assets/images/landingPage2V2/Frame 4.png")}
                        />
                      </Box>
                      <Box className="imgPart">
                        <img
                          alt={AppConfig.applicationName}
                          className="paymentType"
                          src={require("../../assets/images/landingPage2V2/Frame 3.png")}
                        />
                      </Box>
                      <Box className="imgPart">
                        <img
                          alt={AppConfig.applicationName}
                          className="paymentType"
                          src={require("../../assets/images/landingPage2V2/Frame 4.png")}
                        />
                      </Box>
                      <Box className="imgPart">
                        <img
                          alt={AppConfig.applicationName}
                          className="paymentType"
                          src={require("../../assets/images/landingPage2V2/Frame 3.png")}
                        />
                      </Box>
                      <Box className="imgPart">
                        <img
                          alt={AppConfig.applicationName}
                          className="paymentType"
                          src={require("../../assets/images/landingPage2V2/Frame 4.png")}
                        />
                      </Box>
                      <Box className="imgPart">
                        <img
                          alt={AppConfig.applicationName}
                          className="paymentType"
                          src={require("../../assets/images/landingPage2V2/Frame 3.png")}
                        />
                      </Box>
                      <Box className="imgPart">
                        <img
                          alt={AppConfig.applicationName}
                          className="paymentType"
                          src={require("../../assets/images/landingPage2V2/Frame 4.png")}
                        />
                      </Box>
                      <Box className="imgPart">
                        <img
                          alt={AppConfig.applicationName}
                          className="paymentType"
                          src={require("../../assets/images/landingPage2V2/Frame 3.png")}
                        />
                      </Box>
                    </Box>
                  </Box>
                </div>
              </div>
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              <Box className="">
                <h2 className="mainTitle">
                  Accepted <br /> Payment Methods
                </h2>
                <Box>
                  <Typography className="secondaryTitle">
                    Cards and Digital Wallets
                  </Typography>
                  <Typography className="commonText">
                    Secure options like Visa, MasterCard, and JCB are widely
                    accepted, while PayPal, Apple Pay, Google Pay, and Samsung
                    Pay offer fast, convenient payments for lottery
                    participants.
                  </Typography>
                </Box>
                <Box>
                  <Typography className="secondaryTitle">
                    Bank Transfers
                  </Typography>
                  <Typography className="commonText">
                    Direct bank transfers and eChecks are trusted methods, ideal
                    for larger ticket purchases or claiming winnings, ensuring
                    secure and smooth transactions.
                  </Typography>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>

      <Box className="footerSection">
        <Box className="footerSectionTop">
          <Box>
            <img
              alt={AppConfig.applicationName}
              className="footerLogo"
              src={require("../../assets/images/common/logo.png")}
            />
          </Box>
          <Typography className="commonText footerInfo">
            The A2z Lottery is a government-regulated lottery held twice a
            month, offering cash prizes, including a grand prize. Proceeds
            support social welfare programs in the country.
          </Typography>
          <Box className="footerLinksPart">
            <Link href="/agentRegistration" className="linkNav">
              Agent Registration
            </Link>
            <Link href="/rules" className="linkNav">
              Rules & Conditions
            </Link>
            {/* <Link href="/exchangeRates" className="linkNav">
              Exchange Rates
            </Link> */}
            <Link href="/resultsHistory" className="linkNav">
              All Lottery Game Results
            </Link>
          </Box>
          <Box>
            <img
              alt="Play Store"
              className="btnRightSpacing"
              src={require("../../assets/images/landingPage2V2/PlayStoreIcon.png")}
            />
            <img
              alt="Play Store"
              className=""
              src={require("../../assets/images/landingPage2V2/AppStoreIcon.png")}
            />
          </Box>
        </Box>
        <Box className="footerSectionBottom">
          <Typography>
            © 2025 {AppConfig.applicationName}.com,All Rights Reserved.
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};
export default Rules;
