import React, { FunctionComponent, useEffect, useState } from "react";
import PageProps from "../../models/PageProps.interface";

//Gadgets
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Marquee from "react-fast-marquee";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import YouTube from "react-youtube";

//Gadgest - List
import List from "@mui/material/List";
import ListItemText from "@mui/material/ListItemText";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";

//Icons
import { useNavigate } from "react-router-dom";
import CountdownTimer from "./countdownTimer";

//Component Import
import MainFooter from "../../components/mainFooter/mainFooter.component";
import Footer from "../../components/footer/footer.component";

import "../landingPage/landingPage.screen.style.css";
import PlayGameService from "../../services/playGame/playGame.service";
import { IApiResponse } from "../../interface/Response/IApiResponse";
import { IHomepageGameDataResponseModel } from "../../interface/Response/IHomepageGameDataResponseModel";
import { AppConfig } from "../../constants/AppConfig.constant";
import { useLanguage } from "../../screens/LanguageProvider";
import GoogleTranslate from "../googleTranslate";
import { useDispatch } from "react-redux";

const LandingPage: FunctionComponent<PageProps> = ({ title }) => {
  const dispatch = useDispatch();
  // YouTube video options
  const opts = {
    height: "400",
    width: "100%",
    playerVars: {
      autoplay: 0, // Autoplay the video
      controls: 1, // Show player controls
      mute: 0, // Mute the video
      modestbranding: 1, // Remove YouTube logo from player
      fs: 1, // Show fullscreen button
      rel: 0, // Do not show related videos at the end
    },
  };

  const [homeGameData, setHomeGameDate] = useState<
    IHomepageGameDataResponseModel[]
  >();

  // YouTube video ID
  const videoId = "vH864uS3Juk";
  const navigate = useNavigate();
  const { language, toggleLanguage } = useLanguage();
  const _playGameService = new PlayGameService(dispatch);
  const content = {
    EN: {
      welcome: `Welcome to ${AppConfig.applicationName}!`,
      play: "Play Thailottery Online",
    },
    TH: {
      welcome: `ยินดีต้อนรับสู่ ${AppConfig.applicationName}!`,
      play: "เล่นหวยออนไลน์",
    },
  };

  useEffect(() => {
    getGameData();
    async function getGameData() {
      var response: IApiResponse = await _playGameService.getHomeGameData();
      var homepageData = response.response as IHomepageGameDataResponseModel[];
      setHomeGameDate(homepageData);
    }
  }, []);

  return (
    <Box className="pageMain landingPage">
      <Box className="">
        <Box className="landingPageNavBar">
          <Container>
            <Grid container spacing={1}>
              <Grid item xs={3} md={3} lg={4} className="">
                <Box className="HeaderLogo">
                  <img
                    alt={AppConfig.applicationName}
                    className="commonImage"
                    src={require("../../assets/images/common/logo.png")}
                  />
                </Box>
              </Grid>

              <Grid item xs={9} md={9} lg={8}>
                {/* <GoogleTranslate /> */}

                <Stack
                  spacing={2}
                  direction="row"
                  className="floatR actionsBar"
                >
                  <Button
                    type="submit"
                    variant="contained"
                    className="primaryFillBtn"
                    onClick={(event) => {
                      event.preventDefault();
                      navigate("/login");
                    }}
                  >
                    <span>Login</span>
                  </Button>
                  <Button
                    type="submit"
                    variant="outlined"
                    className="primaryLineBtn"
                    onClick={(event) => {
                      event.preventDefault();
                      navigate("/signup");
                    }}
                  >
                    <span className="whiteColor">Signup</span>
                  </Button>
                  {/* <GoogleTranslate /> */}
                </Stack>
              </Grid>
            </Grid>
          </Container>
        </Box>
        <Box className="pageViewBody">
          <Box className="heroSection">
            <Box className="heroSectionBody">
              <Box className="overlayBanner"></Box>
              <Box className="bannerContent">
                <Grid container spacing={1}>
                  <Grid item xs={12} md={12} lg={12}>
                    <Typography className="bannerTitle">
                      <i className="whiteColor">
                        Welcomes to {AppConfig.applicationName}
                      </i>{" "}
                      <br />
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
              <Box>
                <Grid container spacing={1}>
                  <Grid item xs={12} md={12} lg={12}>
                    <Box className="announcementsSection gradientLine">
                      <Marquee>
                        <Typography>
                          National Lottery is overseen by the Government Lottery
                          Office. The Lottery is drawn on the first and
                          sixteenth day of every month.{" "}
                          <a href="#" className="whiteText">
                            {" "}
                            Download Lottery Result Chart{" "}
                          </a>
                        </Typography>
                      </Marquee>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </Box>

          <Box className="explanationSection saperationSection">
            <Container>
              <Grid container spacing={1}>
                <Grid item xs={12} md={7} lg={7}>
                  <h1 className="primaryTitle animate_line">About Lottery</h1>
                  <h3 className="secondaryTitle">Lottery</h3>
                  <Typography className="commonDescription">
                    Play Lottery games online through our website wahlotto.com
                    by registering your account with just a few clicks. Lottery
                    is the official government lottery in Thailand. Saudi
                    Arabia, Kuwait and Pakistan are administered by the
                    Government Lottery Office (GLO). Lottery drawings are issued
                    on the first (1st) and sixteenth (16th) days of every month.
                    Lottery drawings in Thailand historically began in During
                    the reign of King Chulalongkorn, the reign of King Rama V,
                    an Englishman named "Teacher Al Baster" "was the leader in
                    issuing European lottery prizes for sale for the first time
                    called "Lottery" by His Majesty King Chulalongkorn. His
                    Majesty the King graciously graciously He granted royal
                    authority to the Royal Thai Army Department to issue the
                    first lottery in Thailand in 1874 for the coronation
                    ceremony. The objective is to assist foreign merchants who
                    bring their products to be displayed in the museum at the
                    Khong Krakok Building in the Grand Palace.
                  </Typography>
                  <Stack spacing={2} direction="row" className="floatL">
                    <Button
                      type="submit"
                      variant="outlined"
                      className="appDownloadBtn"
                    >
                      <img
                        alt="Play Store"
                        className=""
                        src={require("../../assets/images/landingPage/PlayStoreIcon.png")}
                      />
                    </Button>
                    <Button
                      type="submit"
                      variant="outlined"
                      className="appDownloadBtn"
                    >
                      <img
                        alt="Profile Photo"
                        className="App Store"
                        src={require("../../assets/images/landingPage/AppStoreIcon.png")}
                      />
                    </Button>
                  </Stack>
                </Grid>
                <Grid item xs={12} md={5} lg={5}>
                  <Box className="explanationImage">
                    <img
                      className="width100"
                      src={require("../../assets/images/landingPage/about.jpg")}
                      alt="About Us"
                    />
                  </Box>
                </Grid>
              </Grid>
            </Container>
          </Box>

          <Box className="youtubeSection saperationSection">
            <Container>
              <Grid container spacing={1}>
                <Grid item xs={12} md={12} lg={12}>
                  <Box className="">
                    <YouTube videoId={videoId} opts={opts} />
                  </Box>
                </Grid>
              </Grid>
            </Container>
          </Box>

          <Box className="eventsSection saperationSection">
            <Container>
              <Grid container spacing={1}>
                {homeGameData &&
                  homeGameData.length > 0 &&
                  homeGameData.map((item: IHomepageGameDataResponseModel) => (
                    <Grid item xs={12} md={6} lg={6}>
                      <Box className="lotteryResult commonCard">
                        <Box className="commonBgCard">
                          <Typography className="gameDate cardTitle textCenter">
                            {item.title}
                          </Typography>

                          <Box className="lotteryNumbers">
                            <Box className="firstPrizeBox">
                              <Box className="prizeType textCenter">
                                First Prize
                              </Box>
                              <Box className="numberCircleGroup">
                                {item.firstPrize
                                  .split("")
                                  .map((x: string, index: number) => (
                                    <span key={index} className="numberCircle">
                                      {x}
                                    </span>
                                  ))}
                              </Box>
                            </Box>
                            <Box>
                              <Grid container spacing={1}>
                                <Grid item xs={6} md={6} lg={6}>
                                  <Box className="secondPrizeBox">
                                    <Box className="prizeType textCenter">
                                      3-Up
                                    </Box>
                                    <Box className="numberCircleGroup">
                                      {item.threeUpStraight
                                        .split("")
                                        .map((x: string, index: number) => (
                                          <span
                                            key={index}
                                            className="numberCircle"
                                          >
                                            {x}
                                          </span>
                                        ))}
                                    </Box>
                                  </Box>
                                </Grid>
                                <Grid item xs={6} md={6} lg={6}>
                                  <Box className="thirdPrizeBox">
                                    <Box className="prizeType textCenter">
                                      2-Down
                                    </Box>
                                    <Box className="numberCircleGroup">
                                      {item.twoDownStraight
                                        .split("")
                                        .map((x: string, index: number) => (
                                          <span
                                            key={index}
                                            className="numberCircle"
                                          >
                                            {x}
                                          </span>
                                        ))}
                                    </Box>
                                  </Box>
                                </Grid>
                              </Grid>
                            </Box>
                            <Box>
                              <Button
                                onClick={() =>
                                  navigate("resultsHistory", {
                                    state: item.gameId,
                                  })
                                }
                                variant="text"
                                className="width100 allResultsList"
                              >
                                See All Lottery Results
                              </Button>
                            </Box>
                          </Box>
                          <Box className="nextDraw">
                            <Box className="prizeType textCenter">
                              NEXT DRAW
                            </Box>
                            <Typography className="prizeType">
                              {item.nextDrawDate}
                            </Typography>
                            <Box className="timer">
                              <CountdownTimer calcFor={item.nextDrawDateTime} />
                            </Box>
                          </Box>
                        </Box>
                      </Box>
                    </Grid>
                  ))}
              </Grid>
            </Container>
          </Box>

          <Box className="referandearnSection saperationSection">
            <Container>
              <Box className="commonBgCard">
                <Box>
                  <Typography className="referEarnTitle">
                    If You are interested <br />
                    in lottery Online
                  </Typography>
                </Box>
                <Button
                  type="submit"
                  variant="contained"
                  className="primaryFillBtn referEarnBtn"
                >
                  <span>Refer a Friend and Earn Money</span>
                </Button>
              </Box>
            </Container>
          </Box>

          <Box className="acceptedPayments saperationSection">
            <Typography className="landingPageHeading">
              Accepted Payment Methods
            </Typography>
            <Marquee>
              <List>
                <ListItem className="commonListItem">
                  <ListItemAvatar>
                    <Avatar className="listAvtar">
                      <img
                        className="width100"
                        src={require("../../assets/images/home/Payment_UPI.png")}
                        alt="Payment UPI"
                      />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    className="listAvtarTitle"
                    primary="Payment UPI"
                  />
                </ListItem>
                <ListItem className="commonListItem">
                  <ListItemAvatar>
                    <Avatar className="listAvtar">
                      <img
                        className="width100"
                        src={require("../../assets/images/home/Payment_Bank.png")}
                        alt="Payment Bank"
                      />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    className="listAvtarTitle"
                    primary="Payment Bank"
                  />
                </ListItem>
                <ListItem className="commonListItem">
                  <ListItemAvatar>
                    <Avatar className="listAvtar">
                      <img
                        className="width100"
                        src={require("../../assets/images/home/Payment_StcPay.png")}
                        alt="Payment STC PAY"
                      />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    className="listAvtarTitle"
                    primary="Payment STC Pay"
                  />
                </ListItem>
                <ListItem className="commonListItem">
                  <ListItemAvatar>
                    <Avatar className="listAvtar">
                      <img
                        className="width100"
                        src={require("../../assets/images/home/Payment_NCB.png")}
                        alt="Payment NCB"
                      />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    className="listAvtarTitle"
                    primary="Payment NCB"
                  />
                </ListItem>
                <ListItem className="commonListItem">
                  <ListItemAvatar>
                    <Avatar className="listAvtar">
                      <img
                        className="width100"
                        src={require("../../assets/images/home/Payment_AlRajhiBank.png")}
                        alt="Payment AlRajhiBank"
                      />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    className="listAvtarTitle"
                    primary="Payment AlRajhiBank"
                  />
                </ListItem>
                <ListItem className="commonListItem">
                  <ListItemAvatar>
                    <Avatar className="listAvtar">
                      <img
                        className="width100"
                        src={require("../../assets/images/home/Payment_WesternUnion.png")}
                        alt="Payment WesternUnion"
                      />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    className="listAvtarTitle"
                    primary="Payment WesternUnion"
                  />
                </ListItem>
                <ListItem className="commonListItem">
                  <ListItemAvatar>
                    <Avatar className="listAvtar">
                      <img
                        className="width100"
                        src={require("../../assets/images/home/Payment_EVoucher.png")}
                        alt="Payment EVoucher"
                      />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    className="listAvtarTitle"
                    primary="Payment EVoucher"
                  />
                </ListItem>
                <ListItem className="commonListItem">
                  <ListItemAvatar>
                    <Avatar className="listAvtar">
                      <img
                        className="width100"
                        src={require("../../assets/images/home/Payment_MasterCard.png")}
                        alt="Payment MasterCard"
                      />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    className="listAvtarTitle"
                    primary="Payment MasterCard"
                  />
                </ListItem>
                <ListItem className="commonListItem">
                  <ListItemAvatar>
                    <Avatar className="listAvtar">
                      <img
                        className="width100"
                        src={require("../../assets/images/home/Payment_Visa.png")}
                        alt="Payment Visa"
                      />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    className="listAvtarTitle"
                    primary="Payment Visa"
                  />
                </ListItem>
                <ListItem className="commonListItem">
                  <ListItemAvatar>
                    <Avatar className="listAvtar">
                      <img
                        className="width100"
                        src={require("../../assets/images/home/Payment_PayPal.png")}
                        alt="Payment PayPal"
                      />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    className="listAvtarTitle"
                    primary="Payment PayPal"
                  />
                </ListItem>
                <ListItem className="commonListItem">
                  <ListItemAvatar>
                    <Avatar className="listAvtar">
                      <img
                        className="width100"
                        src={require("../../assets/images/home/Payment_Crypto.png")}
                        alt="Payment Crypto"
                      />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    className="listAvtarTitle"
                    primary="Payment Crypto"
                  />
                </ListItem>
              </List>
            </Marquee>
          </Box>

          <Box className="playStoreSection saperationSection"></Box>

          <Box className="landingPageFooter">
            <MainFooter />
          </Box>
          <Box className="pageViewFooter">
            <Footer />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
export default LandingPage;
